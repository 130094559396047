<form [formGroup]="vCEvHUBForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Overlay - Connect vCE to vHUB</legend>
        <div mat-dialog-content class="position-relative">
            <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="onStepChange($event);">
                <mat-step completed="false" label="VCE" #step1 errorMessage="All fields are required"
                    [hasError]="step1.interacted && (vCEvHUBForm.get('vce_mac').invalid)">
                    <div>
                        <mat-form-field>
                            <mat-label>Select VCE (Hostname)</mat-label>
                            <mat-select formControlName="vce_mac" (click)="searchVceMacFocus()"
                                (selectionChange)="onVceMacChange($event)" *ngIf="deviceList.length">
                                <div class="dropdown-search">
                                    <input #matVceInput [(ngModel)]="searchVceMacPipe"
                                        [ngModelOptions]="{standalone: true}" matInput placeholder="Input MAC Address">
                                </div>
                                <div>
                                    <mat-option *ngFor="let device of deviceList | searchMac : searchVceMacPipe"
                                        [value]="device.mac">
                                        {{device.mac}} ({{device.orgName}}/{{device.name}})
                                    </mat-option>
                                    <mat-option [value]="searchVceMacPipe"
                                        *ngIf="searchVceMacPipe && searchVceMacPipe.length">
                                        {{searchVceMacPipe}}
                                    </mat-option>
                                </div>
                            </mat-select>
                            <input type="text" #matVceInput matInput formControlName="vce_mac"
                                *ngIf="!deviceList.length" placeholder="Input MAC Address" />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>vCE MAC</mat-label>
                            <input type="text" [(ngModel)]="vce_mac" [ngModelOptions]="{standalone: true}" matInput
                                readonly />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Organization</mat-label>
                            <input type="text" [(ngModel)]="vce_org" [ngModelOptions]="{standalone: true}" matInput
                                readonly />
                        </mat-form-field>
                    </div>
                </mat-step>

                <mat-step completed="false" label="VHUB" #step2 errorMessage="All fields are required"
                    [hasError]="step2.interacted && (vCEvHUBForm.get('vhub_mac').invalid || vCEvHUBForm.get('vhub_dpc').invalid)">
                    <div>
                        <mat-form-field>
                            <mat-label>Select vHUB</mat-label>
                            <mat-select formControlName="vhub_mac" (click)="searchVhubMacFocus()"
                                (selectionChange)="onVHubMacChange($event)" *ngIf="vHubDeviceList.length">
                                <div class="dropdown-search">
                                    <input #matVhubInput [(ngModel)]="searchVhubMacPipe"
                                        [ngModelOptions]="{standalone: true}" matInput placeholder="Input MAC Address">
                                </div>
                                <div>
                                    <mat-option *ngFor="let device of vHubDeviceList | searchMac : searchVhubMacPipe"
                                        [value]="device.mac">
                                        {{device.mac}} ({{(device.orgName?device.orgName+'/':'')}}{{device.name}})
                                    </mat-option>
                                    <mat-option [value]="searchVhubMacPipe"
                                        *ngIf="searchVhubMacPipe && searchVhubMacPipe.length">
                                        {{searchVhubMacPipe}}
                                    </mat-option>
                                </div>
                            </mat-select>
                            <input type="text" #matVhubInput matInput formControlName="vhub_mac"
                                *ngIf="!vHubDeviceList.length" placeholder="Input MAC Address" autocomplete="off"/>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Select DPC</mat-label>
                            <mat-select formControlName="vhub_dpc" (selectionChange)="onDPCChange($event)">
                                <mat-option *ngFor="let dpc of dpcList; let idx = index;" [value]="idx">
                                    DPC{{idx+1}} - {{dpc.link}} - (wg{{idx+1}}) mtu {{dpc.mtu}} [{{dpc.addresses}}]
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Keepalive (Seconds)</mat-label>
                            <input type="text" formControlName="keepalive" matInput readonly />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Location</mat-label>
                            <input type="text" [(ngModel)]="vhub_location" [ngModelOptions]="{standalone: true}"
                                matInput readonly />
                        </mat-form-field>
                    </div>
                </mat-step>

                <mat-step completed="false" label="IP Address" #step3 errorMessage="Overlay IP or Subnet is required"
                    [hasError]="step3.interacted && !isValidOverlay()">
                    <div>
                        <div>
                            <label style="font-size: 0.9em;">Add VCE Overlay's IP or Subnet</label>
                            <span class="f-right pointer" (click)="removeSubnetGroup()" *ngIf="overlay_ips.length>1">
                                <mat-icon matSuffix>remove</mat-icon>
                            </span>
                            <span class="f-right pointer" (click)="addSubnetGroup()">
                                <mat-icon matSuffix>add</mat-icon>
                            </span> 
                        </div>
                        <ng-container *ngFor="let ip of overlay_ips">
                            <div class="ml-2 mb-3" style="font-size: 0.9em;">
                                <mat-form-field>
                                    <mat-label>Select Type</mat-label>
                                    <mat-select [(ngModel)]="ip.subnet_type" [ngModelOptions]="{standalone: true}">
                                        <mat-option *ngFor="let type of overlay_subnet_type" [value]="type.value">
                                            {{type.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <ng-container *ngIf="ip.subnet_type=='dpc_ip'">
                                    <mat-form-field>
                                        <mat-label>Enter IP or Subnet (or select next available)</mat-label>
                                        <mat-select [(ngModel)]="ip.subnet_ip" [ngModelOptions]="{standalone: true}">
                                            <div>
                                                <mat-option
                                                    *ngFor="let subnet of overlay_subnets | searchSubnet : searchSubnetPipe"
                                                    [value]="subnet.ip">
                                                    {{subnet.ip}}/32
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="ip.subnet_type!='dpc_ip'">
                                    <mat-form-field>
                                        <mat-label>Enter IP or Subnet (or select next available)</mat-label>
                                        <input #matVhubInput [(ngModel)]="ip.subnet_ip"
                                            [ngModelOptions]="{standalone: true}" matInput placeholder="10.10.10.10/32">
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </mat-step>
                <mat-step completed="false" label="Keys" #step4 errorMessage="All fields are required"
                    [hasError]="step4.interacted && (vCEvHUBForm.get('pub_key').invalid || vCEvHUBForm.get('priv_key').invalid)">
                    <div>
                        <label style="font-size: 0.9em;">Generate New vCE Key Pair</label>
                        <span class="f-right pointer" (click)="generateKey()">
                            <mat-icon matSuffix>lock_outline</mat-icon>
                        </span>
                    </div>
                    <div class="ml-2">
                        <mat-form-field>
                            <mat-label>Public Key</mat-label>
                            <input type="password" formControlName="pub_key" matInput />
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Private Key</mat-label>
                            <input type="password" formControlName="priv_key" matInput />
                        </mat-form-field>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onNext()"
                *ngIf="selectedIndex!=3">Next</button>
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()"
                *ngIf="selectedIndex==3">Add</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>