import { Component, Input, OnInit } from '@angular/core';
import { FormatterHelper } from 'src/app/shared/helper/formatter-helper';
import moment from 'moment-timezone';

@Component({
  selector: 'app-system-information-monitor',
  templateUrl: './system-information-monitor.component.html',
  styleUrls: ['./system-information-monitor.component.scss']
})
export class SystemInformationMonitorComponent implements OnInit {
  _device: any;
  uptime= {
    label: "Device Uptime",
    value: "",
    append: "d",
    max: "0"
  }; 
  load_avg= {
    label: "Load Avg",
    value: ""
  };
  cpu_usage= {
    label: "CPU Usage",
    value: ""
  };
  memory_usage= {
    label: "Memory Usage",
    value: ""
  };
  thresholdConfig = {
    '0': {color: 'green'},
    '60': {color: 'orange'},
    '80': {color: 'red'}
  };
  thresholdConfig1 = {
    '0': {color: 'green'},   
  };
  @Input() set device(obj) {
    if (obj) {
      this._device = obj[0];
      this.loadData();
    }
  }
  constructor() {

  }
  ngOnInit(): void {
   
  }
  loadData(){   
    if(this._device.statistics){ //system
      const system = this._device.statistics?.system && !this._device.statistics?.system.length ? this._device.statistics.system : [];
      this.uptime_format(system.uptime);
      this.load_avg.value = this._device.statistics.avg_load[1];
      this.cpu_usage.value = Number(system.cpu_usage).toFixed(2);
      this.memory_usage.value = Number(system.mem_usage).toFixed(2);
    }
  }
  uptime_format(value){
    let day, hour, minutes, seconds, n;
    n = Math.floor(value);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    if(day != 0){
      this.uptime.append = "d"
      this.uptime.max = moment(new Date()).daysInMonth().toString()
      this.uptime.value = day.toFixed(2)
    }
    else if(hour != 0) {
      this.uptime.append = "h"
      this.uptime.max = "24"
      this.uptime.value = hour.toFixed(2)
    }  else if(minutes != 0) {
      this.uptime.append = "m"
      this.uptime.max = "60"
      this.uptime.value = minutes.toFixed(2)
    } 
    
  }
}


