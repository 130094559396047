import { ForgotpasswordService } from 'src/app/shared/services/forgotpassword.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordform: FormGroup;
  submitted = false;
  id: any;
  public label: string;
  forgotpasswordvalue: any;
  constructor(private formBuilder: FormBuilder,
              private forgotpasswordService: ForgotpasswordService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {
    this.forgotpasswordform = this.formBuilder.group({
      emailId: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
    });
  }
  get f() { return this.forgotpasswordform.controls; }

  onSubmit() {
    this.submitted = true;
    if (!this.forgotpasswordform.invalid) {
      this.forgotpasswordvalue = {
        email: this.forgotpasswordform.value.emailId
      };
      this.forgotpasswordService.forgotPassword(this.forgotpasswordvalue).subscribe(res => {
        console.log(res);
        if (res.status === 'success') {
          this.toastr.success('Reset password link sent successfully');
          this.router.navigate(['/login']);
        }
      },
      err => {
        this.toastr.error('Error: ' + err.error.error);
      });
    }
  }
}
