<div>
    <h4>Showing results for '<span class="text-warning">{{searchTxt}}</span>'</h4>
    <div *ngIf="deviceArray && deviceArray.length">
        <h6 class="mt-3 mb-1">Devices Results:</h6>
        <app-device-table [dataSource]="deviceDataSource" [selfSort]="true"></app-device-table>
    </div>

    <div *ngIf="orgDataSource && orgDataSource.length">
        <h6 class="mt-3 mb-1">Organization Results:</h6>
        <app-organization-table [dataSource]="orgDataSource" [selfSort]="true" (refresh)="loadData()"></app-organization-table>
    </div>
    
</div>