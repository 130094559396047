<div>
    <mat-card>
        <mat-card-content>
            <form class="main">
                <fieldset>
                    <legend class="mb-1">
                        <span *ngIf="data">Edit </span>
                        <span *ngIf="!data">Add </span>
                        PRTG Server
                    </legend>
                    <a class="device-link" [routerLink]="['/layout/prtg/'+data.name+'_'+data._id+'/devices']"
                        routerLinkActive="router-link-active" *ngIf="data">
                        Devices
                    </a>
                    <form [formGroup]="prtgForm">
                        <div>
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input type="text" matInput formControlName="name" name="name" autocomplete="off" />
                                <mat-error *ngIf="prtgForm.controls['name'].hasError('required')">
                                    Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Endpoint</mat-label>
                                <input type="text" matInput formControlName="endpoint" name="endpoint" autocomplete="off"/>
                                <mat-error *ngIf="prtgForm.controls['endpoint'].hasError('required')">
                                    Endpoint is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>User Name</mat-label>
                                <input type="text" matInput formControlName="userName" name="userName" autocomplete="off"/>
                                <mat-error *ngIf="prtgForm.controls['userName'].hasError('required')">
                                    User Name is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>PassHash</mat-label>
                                <input type="text" matInput formControlName="passHash" name="passHash" autocomplete="off"/>
                                <mat-error *ngIf="prtgForm.controls['passHash'].hasError('required')">
                                    PassHash is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Root Group ID</mat-label>
                                <input type="text" matInput formControlName="rootGroupId" name="rootGroupId" autocomplete="off"/>
                                <mat-error *ngIf="prtgForm.controls['rootGroupId'].hasError('required')">
                                    Root Group ID is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Root Group Device ID</mat-label>
                                <input type="text" matInput formControlName="rootGroupDeviceId"
                                    name="rootGroupDeviceId" autocomplete="off" />
                                <mat-error *ngIf="prtgForm.controls['rootGroupDeviceId'].hasError('required')">
                                    Root Group Device ID is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Empty Group ID to Clone</mat-label>
                                <input type="text" matInput formControlName="emptyGroupIDToClone"
                                    name="emptyGroupIDToClone" autocomplete="off" />
                                <mat-error
                                    *ngIf="prtgForm.controls['emptyGroupIDToClone'].hasError('required')">
                                    Empty Group ID to Clone is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Empty Device ID to Clone</mat-label>
                                <input type="text" matInput formControlName="emptyDeviceIDToClone"
                                    name="emptyDeviceIDToClone" autocomplete="off" />
                                <mat-error
                                    *ngIf="prtgForm.controls['emptyDeviceIDToClone'].hasError('required')">
                                    Empty Device ID to Clone is required
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Empty Sensor to Clone</mat-label>
                                <input type="text" matInput formControlName="emptySensorIDToClone"
                                    name="emptySensorIDToClone" autocomplete="off" />
                                <mat-error
                                    *ngIf="prtgForm.controls['emptySensorIDToClone'].hasError('required')">
                                    Empty Sensor to Clone is required
                                </mat-error>
                            </mat-form-field>
                            <section class="example-section">
                                <label>Email Notification Administrators</label>
                                <div class="example-full-width">
                                    <mat-chip-list #chipList aria-label="Email Recipients">
                                        <mat-chip *ngFor="let email of emails" [selectable]="selectable"
                                            [removable]="removable" (removed)="removeRecipient(email)">
                                            {{email}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel
                                            </mat-icon>
                                        </mat-chip>
                                        <input placeholder="new recipient email..." [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addRecipient($event)">
                                    </mat-chip-list>
                                </div>
                            </section>
                        </div>
                        <div style="float: right;">
                            <button mat-raised-button color="primary" class="btn btnn"
                                (click)="onSubmit()">Save</button>
                        </div>
                    </form>
                </fieldset>
            </form>
        </mat-card-content>
    </mat-card>
</div>