import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/shared/services/template.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WifiTypes } from 'src/app/shared/model/template';
import { NetworkDataComponent } from './network-data/network-data.component';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { Subscription } from 'rxjs';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-add-network',
  templateUrl: './add-network.component.html',
  styleUrls: ['./add-network.component.scss']
})

export class AddNetworkComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  submitted = false;
  editMode: boolean = true;
  networks: any[] = WifiTypes;
  org: any;
  name = "";
  showError: boolean = false;
  orgId: any;
  allOrgList = [];
  searchPipe: any;
  @ViewChild('orginput') searchElement: ElementRef;
  user: any;
  wnetworkId: any;
  tabIdx: number = 0;
  templateId: any;
  newNetworks: any[] = new Array(6).fill({});
  isValidNetworks: any[] = new Array(6).fill(false);

  wifiSettings = ["country", "txpower", "hwmode", "htmode", "channel"];

  private loadDataSubscription: Subscription;

  setting2GSelected(value) {
    if (this.networks[0].label.includes('2G') || this.networks[1].label.includes('2G')) {
      for (let i = 0; i < this.wifiSettings.length; i++) {
        if (value[0] === this.wifiSettings[i]) {
          this.networks[0][`${value[0]}`] = value[1];
          this.networks[1][`${value[0]}`] = value[1];
        }
      }
    }
  }

  setting5GSelected(value) {
    if (this.networks[2].label.includes('5G') || this.networks[3].label.includes('5G')) {
      for (let i = 0; i < this.wifiSettings.length; i++) {
        if (value[0] === this.wifiSettings[i]) {
          this.networks[2][`${value[0]}`] = value[1];
          this.networks[3][`${value[0]}`] = value[1];
        }
      }
    }
  }

  constructor(
    public wnetworkService: TemplateService,
    private toastr: ToastrService,
    private router: Router,
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private topnavbarService: TopnavbarService,
    public userService: UserService,
    public dialog: MatDialog
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');
        if (id) {
          this.loadData(id.split("_").pop());
        }
      }
    });
    this.route.queryParams.subscribe(
      params => {
        this.templateId = params['template']
        if (this.templateId) {
          this.loadData(params['template'])
        }
      });

  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.user = storedUser;
    if (!this.orgId) {
      this.orgId = this.user.organization
    }
    this.loadAllOrganization();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    if (this.router.url.includes("/layout/wireless-network")) {
      if (!this.router.url.includes("_")) {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/wireless-network",
            "name": "WiFi Network",
            "type": "link",
          }
        ])
      } else {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/wireless-network",
            "name": "WiFi Network",
            "type": "link",
          },
          {
            "route": "/layout/wireless-network" + this.name + "_" + this.orgId,
            "name": this.name ? this.name : "",
            "type": "text"
          },
        ])
      }
    }
  }

  loadAllOrganization() {
    let obj = {
      id: this.user.organization
    }
    this.userService.getOrganizationUsers(obj).subscribe(res => {
      try {
        this.allOrgList = this.getDomainOrgs(res.data[0]);
      } catch (e) {
        console.log(e);
      }
    })
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, alertDeviceId: domain.alertDeviceId, alertOrgId: domain.alertOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  loadData(wnetworkId) {
    this.loadDataSubscription = this.wnetworkService.getWnetwork(wnetworkId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.orgId = res.orgId;
        this.name = res.name;
        if (res.network) {
          Object.keys(res.network).forEach((key, idx) => {
            this.networks[idx] = {
              "label": WifiTypes[idx].label,
              "enabled": res.network[key]['enabled'] ? res.network[key]['enabled'] : false,
              "enc": res.network[key]['enc'] ? res.network[key]['enc'] : "",
              "cipher": res.network[key]['cipher'] ? res.network[key]['cipher'] : "",
              "key": res.network[key]['key'] ? res.network[key]['key'] : "",
              "ssid": res.network[key]['ssid'] ? res.network[key]['ssid'] : "",
              "hidden": res.network[key]['hidden'] ? res.network[key]['hidden'] : false,
              "country": res.network[key]['country'] ? res.network[key]['country'] : "",
              "txpower": res.network[key]['network'] ? res.network[key]['txpower'] : "",
              "hwmode": res.network[key]['hwmode'] ? res.network[key]['hwmode'] : "",
              "htmode": res.network[key]['htmode'] ? res.network[key]['htmode'] : "",
              "channel": res.network[key]['channel'] ? res.network[key]['channel'] : "",
            }
          })
        }
        if (!this.templateId) {
          this.wnetworkId = res['_id']
        }
      }
      this.setTopNavMenu();
    });
  }

  setData(data, idx) {
    this.newNetworks[idx] = data;
  }

  setValid(data, idx) {
    this.isValidNetworks[idx] = data;
    this.cdr.detectChanges();
  }

  isInvalid() {
    return this.isValidNetworks.includes(false);
  }

  onSubmit() {
    for (let i = 0; i < this.isValidNetworks.length; i++) {
      if (this.isValidNetworks[i] == false) {
        this.tabIdx = i;
        this.toastr.error("Please fill all required fields from "+this.networks[i].label);
        return;
      }
    }
    if (this.submitted) return;
    if (this.name.length) {
      let network = {};
      this.newNetworks.forEach(n => {
        WifiTypes.forEach(t => {
          if (t.label == n.label) {
            network[t.label.replace(" ", "_").toLowerCase()] = {
              enabled: n.enabled,
              ssid: n.ssid,
              key: n.key,
              enc: n.enc,
              cipher: n.cipher,
              hidden: n.hidden,
              country: n.country,
              txpower: n.txpower,
              hwmode: n.hwmode,
              htmode: n.htmode,
              channel: n.channel,
            };
          }
        })
      })
      let data = {
        name: this.name,
        network: network,
        orgId: this.orgId,
        wnetworkId: this.wnetworkId,
        templateTrigger: 1
      }
      if (this.templateId) {
        delete data['wnetworkId']
      }
      if (!this.wnetworkId) {
        this.submitted = true;
        this.wnetworkService.addNetwork(data).subscribe(res => {
          this.submitted = false;
          this.toastr.success('Wireless Network is created successfully');
          this.router.navigate(['/layout/wireless-network']);
        }, (error => {
          this.submitted = false;
          this.toastr.error(error.error.error);
        }));
      } else {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          width: '350px',
        });
        dialogRef.afterClosed().subscribe(result => {
          if (typeof (result) == 'undefined') {
            return;
          }
          data.templateTrigger = Number(result)
          this.submitted = true;
          this.wnetworkService.updateNetworkdata(data, this.wnetworkId).subscribe(res => {
            this.submitted = false;
            this.toastr.success('Wireless Network is updated successfully');
            this.router.navigate(['/layout/wireless-network']);
          }, (error => {
            this.submitted = false;
            this.toastr.error(error.error.error);
          }));
        })
      }

    } else {
      this.showError = true;
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
