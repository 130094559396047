import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditDeviceModelComponent } from '../edit-device-model/edit-device-model.component';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/shared/services/user.service';
import { ApService } from 'src/app/shared/services/ap.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { FirmwareService } from 'src/app/shared/services/firmware.service';

import { TemplateService } from 'src/app/shared/services/template.service';
import { FirmwareUpdateModelComponent } from '../firmware-update-model/firmware-update-model.component';
import { Subscription } from 'rxjs';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { BaseLicensesTypes } from 'src/app/shared/model/template';
import { MatTabGroup } from '@angular/material/tabs';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';
import { SubcriberInfoModalComponent } from '../subcriber-info-modal/subcriber-info-modal.component';


@Component({
  selector: 'app-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss']
})
export class DeviceMonitorComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  orgId: any;
  org: any;
  device_id: any;
  device: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'mac', 'domain', 'location', 'model', 'firmware', 'baseLicense', 'createdAt', 'upTime', 'lastSeen', 'Status', 'Action'];
  showQos = false;
  showMonitor = false;
  showWnetwork = false;
  show4glte = false;
  showTeam = false;
  showDeviceSystem = false;
  showAlerts = false;
  showInterfaces = false;
  showOverlay = true;
  showJump = false;
  showAttackShield = true;
  showSipwatch = true;
  lan1_status = false;
  lan2_status = false;
  firmwareList = [];
  firmware = null;
  role: any;
  ras: boolean = false;

  private loadOrganizationSubscription: Subscription;
  private loadDataDeviceSubscription: Subscription;
  private loadDataFirmwareSubscription: Subscription;
  private loadDataRasSubscription: Subscription;
  mode: any;
  selectedTabIndex: number;

  @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
  rasData: any = [];


  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private changeDetection: ChangeDetectorRef,
    public userService: UserService,
    public apService: ApService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private topnavbarService: TopnavbarService,
    public firmwareService: FirmwareService,
    private templateService: TemplateService,
    public vceVhubService: VceVhubService
  ) {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.device_id = this.route.snapshot.paramMap.get('device_id');
    if (this.device_id) {
      this.device_id = this.device_id.split("_").pop()
    }

    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];

    this.loadData();
    setInterval(() => {
      this.loadData()
    }, 480000);
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": this.org && this.org[0] ? "/layout/organization/" + this.org[0]['_id'] : "/layout/organization",
        "name": this.org && this.org[0] ? this.org[0]['name'] : "",
        "type": "link",
      },
      {
        "route": this.org && this.org[0] ? "/layout/org/" + this.org[0]['_id'] + "/devices" : "/layout/devices",
        "name": "Devices",
        "type": "link"
      },
      {
        "route": "",
        "name": this.device && this.device[0] ? this.device[0]['name'] : "",
        "type": "text"
      },
    ])
  }

  selectedTabChange(evt){
    this.selectedTabIndex = evt.index;
    if(this.router.url.includes("?type=")){
      let url = this.router.url.split("?type=").shift()
      if(evt.tab.textLabel == 'Analytics & Info'){
        this.router.navigateByUrl(url + "?type=analytics-info-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Alerts'){
        this.router.navigateByUrl(url + "?type=alerts-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Attack Shield'){
        this.router.navigateByUrl(url + "?type=attack-shield-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'SIPWatch'){
        this.router.navigateByUrl(url + "?type=sipwatch-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Team Links'){
        this.router.navigateByUrl(url + "?type=team-links-" + (evt.index + 1))
      } else if(evt.tab.textLabel == '4GLTE'){
        this.router.navigateByUrl(url + "?type=4glte-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Interfaces'){
        this.router.navigateByUrl(url + "?type=interfaces-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Device System'){
        this.router.navigateByUrl(url + "?type=device-system-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'QoS'){
        this.router.navigateByUrl(url + "?type=qos-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'WiFi Network'){
        this.router.navigateByUrl(url + "?type=wifi-network-" + (evt.index + 1))
      } 
      // else if(evt.tab.textLabel == 'Monitoring'){
      //   this.router.navigateByUrl(url + "?type=monitoring-" + (evt.index + 1))
      // } 
      else if(evt.tab.textLabel == 'Overlay'){
        this.router.navigateByUrl(url + "?type=overlay-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'RAS'){
        this.router.navigateByUrl(url + "?type=ras-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'LAN Jump'){
        this.router.navigateByUrl(url + "?type=lan-jump-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Notes'){
        this.router.navigateByUrl(url + "?type=notes-" + (evt.index + 1))
      } 

    } else {
      if(evt.tab.textLabel == 'Analytics & Info'){
        this.router.navigateByUrl(this.router.url + "?type=analytics-info-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Alerts'){
        this.router.navigateByUrl(this.router.url + "?type=alerts-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Attack Shield'){
        this.router.navigateByUrl(this.router.url + "?type=attack-shield-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'SIPWatch'){
        this.router.navigateByUrl(this.router.url + "?type=sipwatch-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Device System'){
        this.router.navigateByUrl(this.router.url + "?type=device-system-" + (evt.index + 1))
      }  else if(evt.tab.textLabel == 'Interfaces'){
        this.router.navigateByUrl(this.router.url + "?type=interfaces-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Team Links'){
        this.router.navigateByUrl(this.router.url + "?type=team-links-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'QoS'){
        this.router.navigateByUrl(this.router.url + "?type=qos-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Wifi Network'){
        this.router.navigateByUrl(this.router.url + "?type=wifi-network-" + (evt.index + 1))
      } else if(evt.tab.textLabel == '4GLTE'){
        this.router.navigateByUrl(this.router.url + "?type=4glte-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Monitoring'){
        this.router.navigateByUrl(this.router.url + "?type=monitoring-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Overlay'){
        this.router.navigateByUrl(this.router.url + "?type=overlay-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'RAS'){
        this.router.navigateByUrl(this.router.url + "?type=ras-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'LAN Jump'){
        this.router.navigateByUrl(this.router.url + "?type=lan-jump-" + (evt.index + 1))
      } else if(evt.tab.textLabel == 'Notes'){
        this.router.navigateByUrl(this.router.url + "?type=notes-" + (evt.index + 1))
      }
    }
    this.changeDetection.detectChanges();
  }

  loadData() {
    this.loadDataDeviceSubscription = this.apService.getdeviceById(this.device_id).subscribe(res => {
      this.device = res.data;
      if (this.device[0].orgId) {
        this.orgId = this.device[0].orgId;
        this.loadOrganizationData()
      }

      this.device.forEach(element => {
        element.upTime = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
      });
      this.mode = this.device[0]?.templateConfig?.team?.config?.team1?.mode;
      this.dataSource.data = this.device;
      if (!this.device[0]) {
        return;
      }

      if(this.device[0].mac){
        this.vceVhubService.getRas(this.device[0].mac).subscribe(res => {
          this.rasData = [res];
          if(res == null){
            this.ras = false;
          } else {
            this.ras = ([res][0].ras_group != null || [res][0].ras_asset != null || [res][0].ras_policy != null || [res][0].wg0.peers != null);
          }

          this.loadTab()
        })
      }

      if (this.device[0].tprofile) {
        this.templateService.getTprofile(this.device[0].tprofile).subscribe(res => {
          if (!res || !res.length) {
            return;
          }
          if (res[0].qos) {
            this.showQos = true;
            this.device[0].qos = res[0].qos;
          }
          if (res[0].alertId) {
            this.showMonitor = true;
            this.device[0].alertId = res[0].alertId;
          }
          if (res[0].wnetwork) {
            this.showWnetwork = true;
            this.device[0].wnetwork = res[0].wnetwork;
          }
          if (res[0]['4glte']) {
            this.show4glte = true;
            this.device[0]['4glte'] = res[0]['4glte'];
          }
          if (res[0]['team']) {
            this.showTeam = true;
            this.device[0]['team'] = res[0]['team'];
          }
          if (res[0].team?.config?.team1?.links.length) {
            this.showOverlay = true;
            this.device[0]['team']['config']['team1'] = res[0]['team']['config']['team1'];
          }         
        })
      } else {
        if (this.device[0].qos) {
          this.showQos = true;
        } else if (this.device[0]?.templateConfig?.qos?.config?.length) {
          this.showQos = true;
        }
        if (this.device[0].alertId || this.device[0].alert) {
          this.showMonitor = true;
        } else if (this.device[0]?.templateConfig?.monitor?.config) {
          this.showMonitor = true;
        }
        if (this.device[0].wnetwork) {
          this.showWnetwork = true;
        } else if (this.device[0]?.templateConfig?.wnetwork?.config) {
          this.showWnetwork = true;
        }
        if (this.device[0]['4glte']) {
          this.show4glte = true;
        } else if (this.device[0].templateConfig && this.device[0].templateConfig['4glte'] && this.device[0].templateConfig['4glte']['config']) {
          this.show4glte = true;
        }
        if (this.device[0]['team']) {
          this.showTeam = true;
        } else if (this.device[0]?.templateConfig?.team?.config) {
          this.showTeam = true;
        }
        if (this.device[0].team?.config?.team1?.links.length) {
          this.showOverlay = true;
        } else if (this.device[0]?.templateConfig?.team?.config?.team1.links.length) {
          this.showOverlay = true;
        }
        if (this.device[0]?.templateConfig?.jump) {
          this.showJump = true;
        }
        if (this.device[0]?.templateConfig?.jump?.landevice1?.enabled && this.device[0].templateConfig.jump.landevice1.enabled == 1) {
            let time1 = parseInt(this.device[0].templateConfig.jump.landevice1.applied_time) * 1000;
            let ex1 = parseInt(this.device[0].templateConfig.jump.landevice1.expire_time) * 60 * 1000;
            let date1 = new Date();
            if ((time1 + ex1 -  date1.getTime()) > 0) {
              this.lan1_status = true;
            }
        }
        if (this.device[0]?.templateConfig?.jump?.landevice2?.enabled && this.device[0].templateConfig.jump.landevice2.enabled == 1) {
            let time2 = parseInt(this.device[0].templateConfig.jump.landevice2.applied_time) * 1000;
            let ex2 = parseInt(this.device[0].templateConfig.jump.landevice2.expire_time) * 60 * 1000;
            let date2 = new Date();
            if ((time2 + ex2 -  date2.getTime()) > 0) {
              this.lan2_status = true;
            }
        }
      }
      if(this.device[0]?.templateConfig){
        if (this.device[0]?.templateConfig?.jump?.landevice1?.enabled && this.device[0].templateConfig.jump.landevice1.enabled == 1) {
          let time1 = parseInt(this.device[0].templateConfig.jump.landevice1.applied_time) * 1000;
          let ex1 = parseInt(this.device[0].templateConfig.jump.landevice1.expire_time) * 60 * 1000;
          let date1 = new Date();
          if ((time1 + ex1 -  date1.getTime()) > 0) {
            this.lan1_status = true;
          }
        }
        if (this.device[0]?.templateConfig?.jump?.landevice2?.enabled && this.device[0].templateConfig.jump.landevice2.enabled == 1) {
            let time2 = parseInt(this.device[0].templateConfig.jump.landevice2.applied_time) * 1000;
            let ex2 = parseInt(this.device[0].templateConfig.jump.landevice2.expire_time) * 60 * 1000;
            let date2 = new Date();
            if ((time2 + ex2 -  date2.getTime()) > 0) {
              this.lan2_status = true;
            }
        }
      }    
      if (this.device[0]) {
        this.showDeviceSystem = this.device[0].statistics;
        if (this.device[0].statistics) {
          this.showInterfaces = this.device[0].statistics.interfaces && this.device[0].statistics.interfaces.length;
        }
      }
      if(this.rasData.length == 0){
        this.loadTab()
      }
    }, (err) => {
      this.toastr.error(err.error.error);
    })

    this.loadDataFirmwareSubscription = this.firmwareService.getFirmware().subscribe(res => {
      this.firmwareList = res;
    })
  }

  loadTab(){
    if(this.router.url.includes("?type=")){
      let url = this.router.url.split("?type=").pop().split("-").reverse()
      this.selectedTabIndex = Number(url[0]) - 1;
      this.changeDetection.detectChanges();
    }
  }

  loadOrganizationData() {
    if (this.orgId && this.orgId != 'pending') {
      let obj = {
        id: this.orgId
      }
      this.loadOrganizationSubscription = this.userService.getOrganization(obj).subscribe(res => {
        res = res.data;
        this.org = res;
        this.setTopNavMenu()
      })
    }
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;

  }
  getStatus(date, track) {
    if(track && track.length>0){      
      // if(track[0].lastDeviceState == "alert_on"){
      //   return "on"
      // }else if(track[0].lastDeviceState == "alert_off"){
      //   return "off"
      // } else {
      //   return track[0].lastDeviceState
      // } OLD TRACK STATUS
      if(track[0].lastDeviceState === 1){
        return "on"
      }else if(track[0].lastDeviceState === 2){
        return "warn"
      } else {
        return "off"
      }
      
    } else {
      const time = new Date(date);
      let cdate = new Date();
      let datedifferance = cdate.getTime() - time.getTime();
      if (datedifferance > 600e3) { // greater than 10 min
        return 'off';
      } else if(datedifferance < 600e3  && datedifferance > 300e3){
        return 'warn';
      } else {
        return 'on';
      }
    }
    
  }


  teminalclick(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "terminal").subscribe()
    const url = element.terminal_url;
    window.open(url, '_blank');
  }

  webclick(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "webui").subscribe()
    const url = element.host_url;
    window.open(url, '_blank');
  }

  delete(id) {
    const deviceArray = [];
    deviceArray.push(id);
    if (confirm('Are you sure you want to delete?')) {
      this.apService.deviceDelete(deviceArray).subscribe(res => {
        this.toastr.success('Device Deleted');
      });
    }
  }

  redirect_lan1(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "lan").subscribe()
    window.open(element.lan1_url, '_blank');
  }

  redirect_lan2(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "lan").subscribe()
    window.open(element.lan2_url, '_blank');
  }

  resetPwd(id) {
    if (confirm('Are you sure you want to reset password?')) {
      this.apService.setDeviceCommand(id, { name: "resetPwd" }).subscribe(res => {
        this.toastr.success('Password is reset successfully.');
      });
    }
  }

  editDevice(data): void {
    const dialogRef = this.dialog.open(EditDeviceModelComponent, {
      disableClose: true,
      width: '700px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  viewSubs(data): void {
    const dialogRef = this.dialog.open(SubcriberInfoModalComponent, {
      disableClose: true,
      width: '1000px',
      data: data
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   this.loadData();
    // });
  }

  updateFirmware(data) {
    const dialogRef = this.dialog.open(FirmwareUpdateModelComponent, {
      disableClose: true,
      width: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.apService.getdeviceById(this.device_id).subscribe(res => {
        this.device = res.data;
      })
    });
  }

  selectFirmware(firm) {
    this.firmware = firm;
  }

  getLicenseLabel(license) {
    if (license) {
      return BaseLicensesTypes.filter(l => l.value == license)[0]?.name
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadOrganizationSubscription) {
      this.loadOrganizationSubscription.unsubscribe();
    }
    if (this.loadDataDeviceSubscription) {
      this.loadDataDeviceSubscription.unsubscribe();
    }
    if (this.loadDataFirmwareSubscription) {
      this.loadDataFirmwareSubscription.unsubscribe();
    }
  }

}
