import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-vce-vhub-info',
  templateUrl: './device-vce-vhub-info.component.html',
  styleUrls: ['./device-vce-vhub-info.component.scss']
})
export class DeviceVceVhubInfoComponent implements OnInit {
  vHub_mode: any;
  vCE_mode: any;
  public wireguards = [];
  public peers = [];
  public deviceOrganizations: any;
  vce_devices = [];
  testEmitter$ = new BehaviorSubject<any>(this.peers);
  wireguard_sync: any[];
  peers_sync: any[];

  @Input() set device(obj) {
    if (!obj) {
      return;
    }

    this.vHub_mode = obj.templateConfig?.team?.config?.team1?.mode == 'VHUB';
    this.vCE_mode = obj.templateConfig?.team?.config?.team1?.mode == 'VCE';

    if (obj && obj && obj['statistics'] && obj['statistics']['wireguard']) {
      let _wireguards = obj['statistics']['wireguard']
      let _links = obj['statistics']['teams']['team1']['links']
      this.wireguards = Object.keys(_wireguards).map(function (wireguardIndex) {
        let link = _links.find(x=> x.link == _wireguards[wireguardIndex]['link'])        
        let desc = _wireguards[wireguardIndex]['description'];
        if (desc) {
          _wireguards[wireguardIndex]['title'] = _wireguards[wireguardIndex]['cfg'] && _wireguards[wireguardIndex]['cfg'].length ? _wireguards[wireguardIndex]['cfg'] : desc.substr(desc.length - 10);
        } else {
          _wireguards[wireguardIndex]['title'] = _wireguards[wireguardIndex]['cfg'] && _wireguards[wireguardIndex]['cfg'].length ? _wireguards[wireguardIndex]['cfg'] : "";
        }
        if(link && link.action == "cloudconnectplus"){
          desc = "[plus]" + desc
        }
        let wg = _wireguards[wireguardIndex];
        return wg;
      });
    }
  }

  @Input() set vCE_devices(arr) {

    this.vce_devices = [...arr];
    this.merge2Peer()
  };

  @Input() role;

  @Output() delete = new EventEmitter<any>();
  @Output() toggle = new EventEmitter<any>();

  constructor(
    private apService: ApService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
  }

  merge2Peer() {
    let peers = new Array(this.wireguards.length).fill([]);
    for (let i = 0; i < this.vce_devices.length; i++) {
      if (
        peers[Number(this.vce_devices[i]['vhub_dpc'])] && this.vce_devices[i]['request_action'] != "delete" &&
        (!peers[Number(this.vce_devices[i]['vhub_dpc'])].filter(v => { return v._id == this.vce_devices[i]._id }).length)
      ) {
        peers[Number(this.vce_devices[i]['vhub_dpc'])] = [...peers[Number(this.vce_devices[i]['vhub_dpc'])], ...[this.vce_devices[i]]]
      }
    }
    this.ngZone.run(() => {
      this.peers = peers;
    });
    this.getDeviceOrganizations();
  }

  formatBytes(bytes, decimals = 2) {
    bytes = Number(bytes);
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getDeviceOrganizations() {
    let macs = [];
    for (let i = 0; i < this.peers.length; i++) {
      let peer = this.peers[i];
      for (let j = 0; j < peer.length; j++) {
        if (this.vCE_mode) {
          macs.push(peer[j].vhub_mac.toLowerCase())
        } else if (this.vHub_mode) {
          macs.push(peer[j].vce_mac ? peer[j].vce_mac.toLowerCase(): "")
        }
      }
    }
    this.apService.getDeviceOrganizations(macs).subscribe(res => {
      this.deviceOrganizations = res;
    })
  }

  getOrgPath(mac) {
    try {
      if (this.deviceOrganizations && this.deviceOrganizations[mac.toLowerCase()]) {
        return this.deviceOrganizations[mac.toLowerCase()].map(o => o.name).reverse().slice(1).join('/')
      }
    } catch (e) {

    }
  }

  getOrganization(mac) {
    try {
      if (this.deviceOrganizations && this.deviceOrganizations[mac.toLowerCase()]) {
        return this.deviceOrganizations[mac.toLowerCase()].map(o => o.name)[0]
      }
    } catch (e) {

    }
    return false;
  }

  getTitle(str) {
    if (str)
      return str.substr(str.length - 10)
  }

  _delete(con) {
    this.delete.emit(con)
  }

  _toggle(con, b) {
    this.toggle.emit({ con, b })
  }

}
