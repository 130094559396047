<div class="mt-2">
    <div class="mb-2">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="0">
            <mat-tab label="This Week"></mat-tab>
            <mat-tab label="Last Week"></mat-tab>
            <mat-tab label="This Month"></mat-tab>
            <mat-tab label="Last Month"></mat-tab>
            <!-- <mat-tab label="Overview">
                <hr class="y-border"/>
                <app-system-information-monitor *ngIf="type==5" [device]="_device"></app-system-information-monitor>
            </mat-tab> -->
        </mat-tab-group>
        <hr class="y-border" />
        <!-- <div class="col-md-4">           
            <h6>Alerts Graph</h6>
        </div> -->
        <div class="mb-2">
            <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
            (matSortChange)="sortData($event)">
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="Mac">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mac</th>
                <td mat-cell *matCellDef="let element">{{ element.mac }}</td>
            </ng-container>
            <ng-container matColumnDef="MSP">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>MSP</th>
                <td mat-cell *matCellDef="let element">{{ element.domain }}</td>
            </ng-container>
            <ng-container matColumnDef="Org">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Org</th>
                <td mat-cell *matCellDef="let element">{{ element.org }}</td>
            </ng-container>
                <ng-container matColumnDef="Device State">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Device State</th>
                    <td mat-cell *matCellDef="let element"><strong>{{ displayDeviceState(element.lastDeviceState) }}</strong></td>
                </ng-container>
                <!-- <ng-container matColumnDef="Timezone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Timezone</th>
                    <td mat-cell *matCellDef="let element">{{ displayTimezone(element.timezone) }}</td>
                </ng-container> -->
                <ng-container matColumnDef="Last Seen">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
                    <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.lastSeen, element.timezone) }}</td>
                </ng-container>
                <ng-container matColumnDef="Date Processed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Processed</th>
                    <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.novuDateProcessed, element.timezone) }}</td>
                </ng-container>
                <ng-container matColumnDef="TransactionId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>TransactionId</th>
                    <td mat-cell *matCellDef="let element">{{ element.novuTransactionId }}</td>
                </ng-container>
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                    <td mat-cell *matCellDef="let element">{{ displayStatus(element.isDeviceAlertSent) }}</td>
                </ng-container>
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex">
                            <mat-icon class="pointer" matTooltip="View Details" role="img" aria-hidden="true"
                                (click)="viewNovu(element)">view_comfy</mat-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
        </mat-paginator>    
        </div>
    </div>
</div>
