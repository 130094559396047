<div class="wrapper">
    <label>URLs List</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let url of urls; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="url" readonly>
                <mat-icon matSuffix class="delete" (click)="removeURL(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newURL" (keyup.enter)="addURL()" (blur)="addURL()" autocomplete="off">
            <mat-icon matSuffix (click)="addURL()">add</mat-icon>
            <mat-hint>Example: application.com</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="wrapper">
    <label>Ports List</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let port of ports; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="port" readonly>
                <mat-icon matSuffix class="delete" (click)="removePort(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>       
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newPort" (keyup.enter)="addPort()" (blur)="addPort()" autocomplete="off">
            <mat-icon matSuffix (click)="addPort()">add</mat-icon>
            <mat-hint>Example: 5060 or 5060-5061</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="wrapper">
    <label>IP Addresses List</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let ip of ips; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="ip" readonly>
                <mat-icon matSuffix class="delete" (click)="removeIpAddress(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newIp" (keyup.enter)="addIPAddress()" (blur)="addIPAddress()" autocomplete="off">
            <mat-icon matSuffix (click)="addIPAddress()">add</mat-icon>
            <mat-hint>Example: 172.16.1.1</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="wrapper">
    <label>Networks List</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let network of networks; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="network" readonly>
                <mat-icon matSuffix class="delete" (click)="removeNetwork(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>        
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newNetwork" (keyup.enter)="addNetwork()" (blur)="addNetwork()" autocomplete="off">
            <mat-icon matSuffix (click)="addNetwork()">add</mat-icon>
            <mat-hint>example: 172.16.1.0/24</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="wrapper">
    <label>IP Ports List</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let ipPort of ipPorts; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="ipPort" readonly>
                <mat-icon matSuffix class="delete" (click)="removeIpPort(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newIpPort" (keyup.enter)="addIpPort()" (blur)="addIpPort()" autocomplete="off">
            <mat-icon matSuffix (click)="addIpPort()">add</mat-icon>
            <mat-hint>example: 172.16.1.1,5060</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="wrapper">
    <label>Networks/Ports List & Port-Range</label>
    <div class="mat-form-group inline-block ml-20">
        <ng-container *ngFor="let networkPort of networkPorts; let idx = index;">
            <mat-form-field appearance="standard">
                <input matInput placeholder="" [ngModel]="networkPort" readonly>
                <mat-icon matSuffix class="delete" (click)="removeNetworkPort(idx)">clear</mat-icon>
            </mat-form-field>
        </ng-container>
        <mat-form-field appearance="standard">
            <input matInput placeholder="" [(ngModel)]="newNetworkPort" (keyup.enter)="addNetworkPort()" (blur)="addNetworkPort()" autocomplete="off">
            <mat-icon matSuffix (click)="addNetworkPort()">add</mat-icon>
            <mat-hint>Example: 172.16.1.1/24,5060 or 172.16.1.1/24,5060-5061</mat-hint>
        </mat-form-field>
    </div>
</div>
<div class="mt-4 text-right wrapper">
    <button mat-raised-button color="primary" class="btn btnn" (click)="onSubmit()">Save</button>
</div>