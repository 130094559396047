import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApService } from 'src/app/shared/services/ap.service';
import { FirmwareService } from 'src/app/shared/services/firmware.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firmware-update-model',
  templateUrl: './firmware-update-model.component.html',
  styleUrls: ['./firmware-update-model.component.scss']
})
export class FirmwareUpdateModelComponent implements OnInit {
  firmwareList = [];
  firmware = null;
  factoryReset = true;
  configureType = 0;

  constructor(
    public firmwareService: FirmwareService,
    public dialogRef: MatDialogRef<FirmwareUpdateModelComponent>,
    public apService: ApService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log('data', data);
  }

  ngOnInit(): void {
    this.firmwareService.getFirmware().subscribe(res => {
      this.firmwareList = res;
    })
  }

  selectFirmware(firm) {
    this.firmware = this.firmwareList.find(f => f._id == firm.value);
  }

  selectConfigureType(event, type) {
    if (event.checked) {
      this.configureType = type;
    } else {
      this.configureType = null;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (!this.firmware) {
      this.toastr.error('Please choose firmware.');
      return;
    } else if (this.configureType == null) {
      this.toastr.error('Please choose upgrade time.');
      return;
    } else if (this.data.state == "offline") {
      this.toastr.error('Update not allowed when device is off');
      return;
    }
    this.apService.setDeviceCommand(this.data._id, {
      name: "firmUpdate",
      arguments: {
        fw_version: this.firmware.version,
        md5sum: this.firmware.checksum,
        url: environment.endpoints.firmwareDonloadLink + '/' + this.firmware.version,
        keep_config: this.factoryReset ? this.factoryReset : 0,
        trigger: this.configureType
      }
    }).subscribe(res => {
      this.toastr.success('Firmware will be upgraded soon.');
      this.dialogRef.close();
    });
  }

}
