import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/shared/services/template.service';
import { CountryTypes, TxPowerTypes, ChannelTypes1, ChannelTypes2, HWModeTypes1, HWModeTypes2, HTModeTypes1, HTModeTypes2, EncryptionmalgorithmTypes, EncryptionmModeTypes } from 'src/app/shared/model/template';
import { ApService } from 'src/app/shared/services/ap.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-monitor-wnetwork',
  templateUrl: './device-monitor-wnetwork.component.html',
  styleUrls: ['./device-monitor-wnetwork.component.scss']
})
export class DeviceMonitorWnetworkComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['config', '2g_ap', '2g_guest', '5g_ap', '5g_guest', '2g_client', '5g_client'];
  networks = new Array(6).fill({});
  dataSource = []
  label = ""
  checked = false
  hideToggle = false;
  encryptionArray: any = EncryptionmModeTypes;
  encryptionalgorithamArray: any = EncryptionmalgorithmTypes;

  _device: any;
  hideOldConfig: boolean = true;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  private loadDataSubscription: Subscription;

  constructor(
    public wnetworkService: TemplateService,
    public apService: ApService,
  ) {
  }

  ngOnInit(): void {
  }

  loadData() {
    if (this._device.templateConfig && this._device.templateConfig.wnetwork && (this._device.templateConfig.wnetwork.template_enabled == false) && this._device.templateConfig.wnetwork.config) {
      if (this._device.templateConfig.wnetwork.name) {
        this.label = " (Template-Disabled)"
        let config = this._device.templateConfig.wnetwork.config, networks = [];
        let keys = ["enabled", "ssid", "cipher", "enc", "key", "hidden", "country", "txpower", 'hwmode', 'htmode', "channel"]
        if (config) {
          this.updateConfig(config);
        }
      } else if (this._device.wnetwork) {
        this.loadDataSubscription = this.wnetworkService.getWnetwork(this._device.wnetwork).subscribe(res => {
          if (res && res[0]) {
            res = res[0];
            this.label = res.name + ' (Template-Disabled)'
          }
        })
        let config = this._device.templateConfig.wnetwork.config, networks = [];
        let keys = ["enabled", "ssid", "cipher", "enc", "key", "hidden", "country", "txpower", 'hwmode', 'htmode', "channel"]
        if (config) {
          this.updateConfig(config);
        }
      } else {
        this.updateConfig(this._device.templateConfig.wnetwork.config);
        this.hideOldConfig = true;
        this.hideToggle = true;
        this.label = this._device.templateConfig['wnetwork'].template_name !== null ? (this._device.templateConfig['wnetwork'].template_enabled == false ? this._device.templateConfig['wnetwork'].template_name + " (Template-Disabled)" : this._device.templateConfig['wnetwork'].template_name + " (Template-Enabled)") : "Device (Configuration)";
      }
    } else if (this._device.templateConfig && this._device.templateConfig.wnetwork && this._device.templateConfig.wnetwork.template_enabled && this._device.wnetwork) {
      this.checked = true;
      this.loadTpl(this._device.wnetwork);
    } else if (this._device && this._device.templateConfig && this._device.templateConfig.wnetwork) {
      this.updateConfig(this._device.templateConfig.wnetwork.config);
      this.hideOldConfig = true;
      this.hideToggle = true;
      this.label = this._device.templateConfig['wnetwork'].template_name !== null ? (this._device.templateConfig['wnetwork'].template_enabled == false ? this._device.templateConfig['wnetwork'].template_name + " (Template-Disabled)" : this._device.templateConfig['wnetwork'].template_name + " (Template-Enabled)") : "Device (Configuration)";
    }
  }

  loadTpl(wnetworkId) {
    this.wnetworkService.getWnetwork(wnetworkId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.label = res.name + (this._device.templateConfig && this._device.templateConfig.wnetwork && this._device.templateConfig.wnetwork.template_enabled ? " (Template - Enabled)" : this._device.templateConfig && this._device.templateConfig.wnetwork && this._device.templateConfig.wnetwork.template_enabled == false ? " (Template - Disabled)" : " (Template)");
        let config = res.network
        if (config) {
          this.updateConfig(config);
          this.hideOldConfig = true;
        }
      }
    });
  }

  updateConfig(config) {
    let keys = ["enabled", "ssid", "cipher", "enc", "key", "hidden", "country", "txpower", 'hwmode', 'htmode', "channel"]
    keys.forEach(key => {
      if (key == "key") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? (config['2g_ap'][key]).replace(/./g, "*") : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length ? (config['2g_guest'][key]).replace(/./g, "*") : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? (config['5g_ap'][key]).replace(/./g, "*") : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? (config['5g_guest'][key]).replace(/./g, "*") : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? (config['2g_client'][key]).replace(/./g, "*") : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? (config['5g_client'][key]).replace(/./g, "*") : ''
        })

      } else if (key == "enabled") {
          this.dataSource.push({
          "config": 'status',
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined ? config['2g_ap'][key] == true? 'Enable' : 'Disable'  : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined ? config['2g_guest'][key] == true? 'Enable' : 'Disable' : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined ? config['5g_ap'][key] == true? 'Enable' : 'Disable' : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined ? config['5g_guest'][key] == true? 'Enable' : 'Disable' : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined ? config['2g_client'][key] == true? 'Enable' : 'Disable' : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined ? config['5g_client'][key] == true? 'Enable' : 'Disable' : ''
          })
      } else if (key == "hidden") {
          this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined ? config['2g_ap'][key] == true? 'Enable' : 'Disable'  : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined ? config['2g_guest'][key] == true? 'Enable' : 'Disable' : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined ? config['5g_ap'][key] == true? 'Enable' : 'Disable' : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined ? config['5g_guest'][key] == true? 'Enable' : 'Disable' : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined ? config['2g_client'][key] == true? 'Enable' : 'Disable' : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined ? config['5g_client'][key] == true? 'Enable' : 'Disable' : ''
          })
      } else if (key == "country") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['2g_ap'][key])).name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['2g_guest'][key])).name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['5g_ap'][key])).name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['5g_guest'][key])).name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['2g_client'][key])).name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length && CountryTypes.find(c => (c.value == config['2g_ap'][key])) ? CountryTypes.find(c => (c.value == config['5g_client'][key])).name : ''
        })

      } else if (key == "txpower") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length && TxPowerTypes.find(c => (c.value == config['2g_ap'][key])) ? TxPowerTypes.find(c => (c.value == config['2g_ap'][key])).name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length && TxPowerTypes.find(c => (c.value == config['2g_guest'][key])) ? TxPowerTypes.find(c => (c.value == config['2g_guest'][key])).name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length && TxPowerTypes.find(c => (c.value == config['5g_ap'][key]))? TxPowerTypes.find(c => (c.value == config['5g_ap'][key])).name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length && TxPowerTypes.find(c => (c.value == config['5g_guest'][key]))? TxPowerTypes.find(c => (c.value == config['5g_guest'][key])).name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length && TxPowerTypes.find(c => (c.value == config['2g_client'][key])) ? TxPowerTypes.find(c => (c.value == config['2g_client'][key])).name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length && TxPowerTypes.find(c => (c.value == config['5g_client'][key])) ? TxPowerTypes.find(c => (c.value == config['5g_client'][key])).name : ''
        })
      } else if (key == "channel") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['2g_ap'][key]))?.name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['2g_guest'][key]))?.name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['5g_ap'][key]))?.name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['5g_guest'][key]))?.name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['2g_client'][key])).name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? [...ChannelTypes1, ...ChannelTypes2].find(c => (c.value == config['5g_client'][key])).name : ''
        })
      } else if (key == "enc") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? this.encryptionArray.find(c => (c.value == config['2g_ap'][key]))?.name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length ? this.encryptionArray.find(c => (c.value == config['2g_guest'][key]))?.name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? this.encryptionArray.find(c => (c.value == config['5g_ap'][key]))?.name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? this.encryptionArray.find(c => (c.value == config['5g_guest'][key]))?.name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? this.encryptionArray.find(c => (c.value == config['2g_client'][key]))?.name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? this.encryptionArray.find(c => (c.value == config['5g_client'][key]))?.name : ''
        })
      } else if (key == "cipher") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? this.encryptionalgorithamArray.find(c => (c.value == config['2g_ap'][key]))?.name : '',
          '2g_guest': (config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length) ? this.encryptionalgorithamArray.find(c => (c.value == config['2g_guest'][key]))?.name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? this.encryptionalgorithamArray.find(c => (c.value == config['5g_ap'][key]))?.name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? this.encryptionalgorithamArray.find(c => (c.value == config['5g_guest'][key]))?.name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? this.encryptionalgorithamArray.find(c => (c.value == config['2g_client'][key]))?.name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? this.encryptionalgorithamArray.find(c => (c.value == config['5g_client'][key]))?.name : ''
        })
      } else if (key == "hwmode") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['2g_ap'][key]))?.name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['2g_guest'][key]))?.name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['5g_ap'][key]))?.name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['5g_guest'][key]))?.name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['2g_client'][key]))?.name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? [...HWModeTypes1, ...HWModeTypes2].find(c => (c.value == config['5g_client'][key]))?.name : ''
        })
      } else if (key == "htmode") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined && config['2g_ap'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['2g_ap'][key]))?.name : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined && config['2g_guest'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['2g_guest'][key]))?.name : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined && config['5g_ap'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['5g_ap'][key]))?.name : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined && config['5g_guest'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['5g_guest'][key]))?.name : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined && config['2g_client'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['2g_client'][key]))?.name : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined && config['5g_client'][key].length ? [...HTModeTypes1, ...HTModeTypes2].find(c => (c.value == config['5g_client'][key]))?.name : ''
        })
      } else if (key == "enabled") {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined ? config['2g_ap'][key] : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined ? config['2g_guest'][key] : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined ? config['5g_ap'][key] : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined ? config['5g_guest'][key] : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined ? config['2g_client'][key] : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined ? config['5g_client'][key] : ''
        })
      } else {
        this.dataSource.push({
          "config": key,
          '2g_ap': config['2g_ap'] && config['2g_ap'][key] != undefined ? config['2g_ap'][key] : '',
          '2g_guest': config['2g_guest'] && config['2g_guest'][key] != undefined ? config['2g_guest'][key] : '',
          '5g_ap': config['5g_ap'] && config['5g_ap'][key] != undefined ? config['5g_ap'][key] : '',
          '5g_guest': config['5g_guest'] && config['5g_guest'][key] != undefined ? config['5g_guest'][key] : '',
          '2g_client': config['2g_client'] && config['2g_client'][key] != undefined ? config['2g_client'][key] : '',
          '5g_client': config['5g_client'] && config['5g_client'][key] != undefined ? config['5g_client'][key] : ''
        })
      }
    })
  }

  render(arr) {
    if (Array.isArray(arr)) {
      return arr.map(r => (`<div>${r}</div>`)).join('')
    } else {
      return arr;
    }
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "wnetwork", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig.wnetwork) {
        this._device.templateConfig.wnetwork.template_enabled = e.checked;
      } else {
        this._device.templateConfig.wnetwork = {
          template_enabled: e.checked
        }
      }
      this.loadData()
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
