import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { title } from 'process';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { NoteService } from 'src/app/shared/services/note.service';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss']
})
export class NoteModalComponent implements OnInit {
  @Output() noteSubmitted = new EventEmitter<void>();
  noteForm: FormGroup;
  selectedFiles: File[] = []
  user: any
  role: any
  constructor(public dialogRef: MatDialogRef<NoteModalComponent>,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private noteService: NoteService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
    
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.role = this.user.role;
    this.noteForm = this.formBuilder.group({
          type: new FormControl(1, []),
          title: new FormControl('', [Validators.required]),
          notes: new FormControl('', [Validators.required]),
          files: new FormControl('', [Validators.required]),         
        });
    if(this.data.deviceId){
      if(this.data.noteData){
        this.noteForm.controls["type"].setValue(this.data.noteData.type)
        this.noteForm.controls["title"].setValue(this.data.noteData.title)
        this.noteForm.controls["notes"].setValue(this.data.noteData.notes)
      }
    } 
  }

  closeModal(): void {
    this.dialogRef.close();
  }  

  onFileSelected(event){
    const files: FileList = event.target.files;
    this.selectedFiles = Array.from(files);

    this.noteForm.controls["files"].setValue(this.selectedFiles.map(f => f.name).join(', '));
    this.cdr.detectChanges(); // Forces UI to update
  }

  onSubmit(){
    const formData = new FormData();
    formData.append('deviceId', this.data.deviceId);
    formData.append('createdBy', this.user.name);
    formData.append('type', this.noteForm.get('type').value);
    formData.append('title', this.noteForm.get('title').value);
    formData.append('notes', this.noteForm.get('notes').value);
    this.selectedFiles.forEach(file => formData.append('files', file));    

    if(this.data.noteData){
      this.noteService.updateNote(this.data.noteData._id, formData).subscribe(res => {
        this.noteSubmitted.emit();
        this.toastr.success(' Note updated Successfully!');
        this.dialogRef.close('refresh');
      }, (error => {
       
        this.toastr.error(error.error.error);
      }));    
    } else {
      this.noteService.addNote(formData).subscribe(res => {
        this.noteSubmitted.emit();
        this.toastr.success(' Note added Successfully!');
        this.dialogRef.close('refresh');
      }, (error => {
        this.toastr.error(error.error.error);
      }));
    }   
  }
}
