<div class="mt-2 monitor-device-system-wrapper panel" *ngIf="_device">
    <div class="mt-4">
        <span class="pointer vhub-vce-btn" *ngIf="showNote">
            Attack Shield License not selected - please select and subscribe if required.
        </span>
        <mat-tab-group animationDuration="0ms" >          
            <mat-tab label="States">
                <hr class="y-border" />
                <!-- <table mat-table [dataSource]="_dataSource" multiTemplateDataRows class="mat-elevation-z8"> -->
                    <table mat-table [dataSource]="_dataSource" multiTemplateDataRows class="mat-elevation-z8 w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
                    <ng-container matColumnDef="expand">
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">                       
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>                       
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>                        
                        </button>
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of displayedColumns; track column; index as i" matColumnDef="{{column}}">
                        <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="stateColor(element.state)"> 
                            <ng-template [ngIf]="column == 'Date'">{{ element.date }}</ng-template>
                            <ng-template [ngIf]="column == 'State'">{{ element.state }}</ng-template>
                            <ng-template [ngIf]="column == 'Flow Total'">{{ element.flowTotal }}</ng-template>
                            <ng-template [ngIf]="column == 'Flow Use %'">{{ element.flowUse }}</ng-template>
                            <ng-template [ngIf]="column == 'Trouble Strays %'">{{ element.troublePkts }}</ng-template>
                            <ng-template [ngIf]="column == 'Trble Disjointed %'">{{ element.trblDisjointed }}</ng-template>
                            <ng-template [ngIf]="column == 'Max Disjointed %'">{{ element.maxDisjointed }}</ng-template>
                            <ng-template [ngIf]="column == 'Disjointed'">{{ element.disjointed }}</ng-template>
                            <ng-template [ngIf]="column == 'Strays'">{{ element.strays }}</ng-template>
                            <ng-template [ngIf]="column == 'UniqueIPs'">{{ element.uniqueIps }}</ng-template>
                            <ng-template [ngIf]="column == 'Action'">{{ element.action }}</ng-template>
                        </td>
                    </ng-container>
                     <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                   <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <table>
                                <tr>
                                    <th>Link</th>
                                    <th>Iface</th>
                                    <th>RX</th>
                                    <th>TX</th>
                                </tr>
                                <tr *ngFor="let l of element.links">
                                    <td>{{l.link}}</td>
                                    <td>{{l.iface}}</td>
                                    <td>{{l.rx}}</td>
                                    <td>{{l.tx}}</td>
                                </tr>
                            </table>
                        </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                        class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> 
                    </table>
                    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
                </mat-paginator>
            </mat-tab>
              <!-- (selectedTabChange)="selectedTabChange($event)"
        [selectedIndex]="selectedTabIndex" -->
            <mat-tab label="Config">
                <hr class="y-border" />
                <table>
                    <tbody>
                        <tr>
                            <th>State</th>
                            <th>Status</th>                            
                            <th>Sensitivity</th>
                            <th>Log Size</th>
                            <th>State Log Size</th>
                            <th>Max Flows</th>
                            <th>Flows in Use</th>
                            <th>Strays</th>
                        </tr>
                        <tr [ngClass]="stateColor(state)">
                            <td>{{ state }}</td>
                            <td>{{ status }}</td>                            
                            <td>{{ sensitivity }}</td>
                            <td>{{ logSize }}</td>
                            <td>{{ stateLogSize }}</td>
                            <td>{{ maxFlows }}</td>
                            <td>{{ flowsUse }}</td>
                            <td>{{ troublePkts }}</td>
                        </tr>                                             
                    </tbody>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
