<div class="row pt-3 submsp-background" *ngFor="let element of org.subOrganizations; let idx = index;"
    style="min-height: 40px;">
    <div class="col-md-3" (click)="expand(element, idx)">
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <i class="material-icons" style="font-size: 16px; margin-left: -16px; vertical-align: middle;"
            matTooltip="Expand Organization"
            *ngIf="element.subOrganizations.length && !expand_detail[idx]">expand_more</i>
        <i class="material-icons" style="font-size: 16px; margin-left: -16px; vertical-align: middle;"
            matTooltip="Expand Organization"
            *ngIf="element.subOrganizations.length && expand_detail[idx]">expand_less</i>
        <span>{{ element.name }}</span>
    </div>
    <div class="col-md-3" (click)="expand(element, idx)">
        {{ element.domain }}
    </div>
    <div class="col-md-3" (click)="expand(element, idx)">
        <span *ngIf="element.role==1"> Master MSP</span>
        <span *ngIf="element.role==2"> MSP</span>
        <span *ngIf="element.role==3"> Organization</span>
    </div>
    <div class="col-md-3" (click)="expand(element, idx)">
        <div class="d-flex">
            <mat-icon class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                matTooltip="Edit" role="img" aria-hidden="true" (click)="editEl(element)" *ngIf="role<=2">edit
            </mat-icon>
            <a class="table-link" [routerLink]="['/layout/organization/'+element.name+'_'+element._id]"
                routerLinkActive="router-link-active">
                <mat-icon class="pointer" matTooltip="View Organizations" role="img" aria-hidden="true"
                    *ngIf="element.role != 3">apartment</mat-icon>
            </a>
            <a class="table-link" [routerLink]="['/layout/users/'+element.name+'_'+element._id]"
                routerLinkActive="router-link-active">
                <mat-icon class="pointer" matTooltip="View Users" role="img" aria-hidden="true">
                    person
                </mat-icon>
            </a>
            <a class="table-link" [routerLink]="['/layout/org/'+element.name+'_'+element._id+'/devices']"
                routerLinkActive="router-link-active">
                <mat-icon class="pointer" matTooltip="View Devices" role="img" aria-hidden="true">
                    device_hub
                </mat-icon>
            </a>
            <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true" (click)="deleteEl(element)"
                *ngIf="role<=3">restore_from_trash</mat-icon>
        </div>
    </div>
    <div class="col-md-12 suborg-background" *ngIf="element.subOrganizations.length && expand_detail[idx]">
        <ng-container *ngFor="let subOrg of element.subOrganizations">
            <div class="row pt-3" style="min-height: 40px;" (click)="loadDevice(subOrg)">
                <div class="col-md-3">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>{{ subOrg.name }}</span>
                </div>
                <div class="col-md-3">
                    {{ subOrg.domain }}
                </div>
                <div class="col-md-3">
                    <span *ngIf="subOrg.role==1"> Master MSP</span>
                    <span *ngIf="subOrg.role==2"> MSP</span>
                    <span *ngIf="subOrg.role==3"> Organization</span>
                </div>
                <div class="col-md-3">
                    <div class="d-flex">
                        <mat-icon
                            class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                            matTooltip="Edit" role="img" aria-hidden="true" (click)="editEl(subOrg)" *ngIf="role<=2">
                            edit
                        </mat-icon>
                        <a class="table-link" [routerLink]="['/layout/organization/'+subOrg.name+'_'+subOrg._id]"
                            routerLinkActive="router-link-active">
                            <mat-icon class="pointer" matTooltip="View Organizations" role="img" aria-hidden="true"
                                *ngIf="subOrg.role != 3">apartment</mat-icon>
                        </a>
                        <a class="table-link" [routerLink]="['/layout/users/'+subOrg.name+'_'+subOrg._id]"
                            routerLinkActive="router-link-active">
                            <mat-icon class="pointer" matTooltip="View Users" role="img" aria-hidden="true">
                                person
                            </mat-icon>
                        </a>
                        <a class="table-link" [routerLink]="['/layout/org/'+subOrg.name+'_'+subOrg._id+'/devices']"
                            routerLinkActive="router-link-active">
                            <mat-icon class="pointer" matTooltip="View Devices" role="img" aria-hidden="true">
                                device_hub
                            </mat-icon>
                        </a>
                        <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true"
                            (click)="deleteEl(subOrg)" *ngIf="role<=3">restore_from_trash</mat-icon>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="deviceArray.length && (selectedOrg == subOrg._id)">
                <div class="col-md-12 pt-2">
                    <app-device-table [dataSource]="deviceArray" [selfSort]="true"></app-device-table>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="col-md-12">
        <ng-container *ngIf="deviceArray.length && (selectedOrg == element._id)">
            <div class="col-md-12 pt-2">
                <app-device-table [dataSource]="deviceArray" [selfSort]="true"></app-device-table>
            </div>
        </ng-container>
    </div>
</div>