import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrtgDevicesComponent } from './prtg-devices/prtg-devices.component';
import { PrtgAddComponent } from './prtg-add/prtg-add.component';
import { PrtgComponent } from './prtg.component';

const routes: Routes = [
  { path: '', component: PrtgComponent },
  { path: 'add', component: PrtgAddComponent },
  { path: ':id', component: PrtgAddComponent },
  { path: ':prtgId/devices', component: PrtgDevicesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrtgRoutingModule { }
