import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ApService } from 'src/app/shared/services/ap.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { ActivatedRoute, NavigationEnd, Router, Event, RoutesRecognized } from '@angular/router';
import { Chart } from 'chart.js';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { FormControl } from '@angular/forms';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM DD YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM DD YYYY',
  },
};

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class BillingComponent implements OnInit, AfterViewInit, OnDestroy {
  private pageAvailable: boolean = true;
  startDate: any;
  endDate: any;
  startMonth = new FormControl(moment(new Date()).subtract(2, 'months'));
  endMonth = new FormControl(moment());
  minDate = moment(new Date()).subtract(3, 'year');
  maxDate = moment(new Date());

  orgDataSource = [];
  displayedOrgColumns: string[] = ['name', 'domain', 'role', 'subOrgs', 'subUsers', 'deviceCnt']
  allOrgData: any = null;
  deviceArray: any = null;
  deviceArrayAll: any = null;
  licenseArray: any = null;
  monthlyDeviceCntSource: MatTableDataSource<any>;
  displayedTemplateColumns: string[] = ['monthsArray', 'monthlyDeviceCount'];
  @Output() sortChange = new EventEmitter<any>();

  orgId: any;
  org: any;
  user: any;
  domain: any;
  orgName: string;
  arrBillingUrl: any[];

  parentId: any;
  hasParentOrg: any;
  arrReportUrl: any[];

  chart: any;

  displayedLicenseColumns: string[] = ['date', 'mac', 'devicename', 'location', 'mmsp', 'msp', 'org', 'old_license', 'license', 'user']
  licenseHistorySource: MatTableDataSource<any>;

  private loadDataSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(
    public userService: UserService,
    public apService: ApService,
    private topnavbarService: TopnavbarService,
    private ref: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.monthlyDeviceCntSource = new MatTableDataSource<any>();
    this.licenseHistorySource = new MatTableDataSource<any>();

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');

        if (id && id != this.orgId) {
          this.orgId = id.split("_").pop();

          //topnav route
          if (this.router.url.includes('/layout/report')) {
            if (this.router.url === '/layout/report/overview' || this.router.url === '/layout/report/billing') {
              this.parentId = this.user.organization;
            } else {
              let clickId;
              if (this.router.url.includes("_")) {
                clickId = this.router.url.split("_").pop();
              } else {
                clickId = this.router.url.split("/").pop();
              }
              this.parentId = clickId;
              this.loadData();
            }
          }
        }
      }
    });
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);

    let id = this.route.snapshot.paramMap.get('id');
    this.orgId = id ? id.split("_").pop() : this.user.organization;

    //topnav route
    if (this.router.url.includes('/layout/report')) {
      if (this.router.url === '/layout/report/overview' || this.router.url === '/layout/report/billing') {
        this.parentId = this.user.organization
      } else {
        let clickId;
        if (this.router.url.includes("_")) {
          clickId = this.router.url.split("_").pop();
        } else {
          clickId = this.router.url.split("/").pop();
        }
        this.parentId = clickId;
      }
    }

    this.loadData();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    //Set Report links
    if (this.router.url.includes('/layout/report')) {
      //Detect if page is in Billing or overview
      let page = this.router.url.includes('billing') ? "/layout/report/billing/" : "/layout/report/overview/";

      //Root link
      this.arrReportUrl = [
        {
          "route": page,
          "name": "Root",
          "type": "link",
        }];

      //Detect if org has sub-orgs or not
      if (this.hasParentOrg && this.hasParentOrg.length) {
        for (var i = 0; i < this.hasParentOrg.length; i++) {
          let tempData = {};

          //detect if org has role of 0 or not
          if (i === 0) {
            tempData = {
              "route": page,
              "name": this.hasParentOrg[i] ? this.hasParentOrg[i]['name'] : "",
              "type": "link"
            }
          } else {
            tempData = {
              "route": this.hasParentOrg[i] ? page + this.hasParentOrg[i]['name'] + '_' + this.hasParentOrg[i]['_id'] : page,
              "name": this.hasParentOrg[i] ? this.hasParentOrg[i]['name'] : "",
              "type": "link"
            }
          }

          this.arrReportUrl.push(tempData);
        }
      } else {
        //parent org
        this.arrReportUrl.push(
          {
            "route": this.org ? page + this.org['name'] + '_' + this.org['_id'] : page,
            "name": this.org ? this.org['name'] : "",
            "type": "link",
          }
        );
      }
      //Billing or Overview dropdown
      this.arrReportUrl.push({
        "route": "",
        "name": this.router.url.includes('billing') ? "Billing" : "Overview",
        "type": "dropdown",
        "sub": [
          {
            "route": this.getOverviewLink(),
            "name": "Overview",
            "type": "link",
          },
          {
            "route": this.getBillingLink(),
            "name": "Billing",
            "type": "link",
          }
        ]
      });
      this.topnavbarService.set(this.arrReportUrl)
    } else {
      //Set other links
      this.topnavbarService.set([
        {
          "route": "/layout/organization",
          "name": "Root",
          "type": "link",
        },
        {
          "route": "",
          "name": this.org ? this.org['name'] : "",
          "type": "text"
        }
      ])
    }
  }

  getOverviewLink() {
    if (this.orgId) {
      if (this.org.parentOrganization && typeof this.org.parentOrganization !== "undefined") {
        let org = this.orgId ? this.org['name'] + '_' + this.orgId : this.user.organization;
        return '/layout/report/overview/' + org;
      } else {
        return '/layout/report/overview/';
      }
    } else {
      return '/layout/report/overview/'
    }
  }

  getBillingLink() {
    if (this.orgId) {
      if (this.org.parentOrganization && typeof this.org.parentOrganization !== "undefined") {
        let org = this.orgId ? this.org['name'] + '_' + this.orgId : this.user.organization;
        return '/layout/report/billing/' + org;
      } else {
        return '/layout/report/billing/';
      }
    } else {
      return '/layout/report/billing/';
    }
  }

  loadData() {
    let obj = {};
    obj['id'] = this.orgId
    let startMonth = moment(this.startMonth.value).format('YYYY-MM-DD')
    let endMonth = moment(this.endMonth.value).add(2, 'days').format('YYYY-MM-DD')
    this.loadDataSubscription = combineLatest([
      this.userService.getOrganization(obj),
      this.apService.getdeviceByMonth(this.orgId, startMonth, endMonth),
      this.userService.parentOrganizationList(this.parentId),
      this.apService.getLicenseHistoryByMonth(this.orgId, startMonth, endMonth),
      this.apService.getDevicesByOrg(this.orgId),
    ]).subscribe(results => {
      let res = results[0];
      if (res.data[0]) {
        this.allOrgData = res.data[0]['subOrganizations']
        this.orgDataSource = this.allOrgData;
        this.org = res.data[0];
      }

      res = results[1];
      this.deviceArray = res.data;
      res = results[4];
      this.deviceArrayAll = res.data;
      /*
      this.deviceArray.forEach(element => {
        element.upTime = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
      });
      */
      this.countByMonth();

      res = results[2];
      if (res) {
        res = res.sort(function (a, b) {
          return a.role - b.role;
        });
        this.hasParentOrg = res;
        this.setTopNavMenu();
      }

      res = results[3];
      if (res) {
        this.licenseArray = res;
        this.displayLicenseActivity();
      }
    })
  }

  countByMonth(sort = null) {
    let monthArray = [];
    let d = moment(this.startMonth.value);
    let deviceMonthObj = {};
    while (moment(d) < moment(this.endMonth.value)) {
      let month = moment(d).format('YYYY-MM')
      if (!monthArray.includes(month)) {
        monthArray.push(month)
        deviceMonthObj[month] = this.deviceArray.filter(device => {
          return (moment(device.createdAt).format('YYYY-MM') == month)
        }).length
      }
      d = moment(d).add(1, 'day')
    }
    let month_data = monthArray.map(m => ({
      month: m,
      count: deviceMonthObj[m]
    }))
    if (sort && sort._direction) {
        if (sort._direction == "asc") {
          month_data.sort((a, b) => (parseInt(a.month.replace("-", "")) > parseInt(b.month.replace("-", ""))) ? 1 : -1);
        }
        if (sort._direction == "desc") {
          month_data.sort((a, b) => (parseInt(a.month.replace("-", "")) < parseInt(b.month.replace("-", ""))) ? 1 : -1);
        }
    }

    this.monthlyDeviceCntSource = new MatTableDataSource<any>(month_data);

    const dynamicColors = () => {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var j = 0; j < 6; j++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const poolColors = (a) => {
      let pool = [];
      for (let i = 0; i < a; i++) {
        switch (i) {
          case 0:
            pool.push('#ff6384');
            break;
          case 1:
            pool.push('#36a2eb');
            break;
          case 2:
            pool.push('#cc65fe');
            break;
          case 3:
            pool.push('#ffce56');
            break;
          case 4:
            pool.push('#f39c12');
            break;
          case 5:
            pool.push('#f48fb1');
            break;
          case 6:
            pool.push('#99ff00');
            break;
          case 7:
            pool.push('#3399ff');
            break;
          case 8:
            pool.push('#cc9933');
            break;
          case 9:
            pool.push('#99cccc');
            break;
          default:
            pool.push(dynamicColors());
        }
      }
      return pool;
    }

    this.chart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: monthArray,
        datasets: [
          {
            data: monthArray.map(m => deviceMonthObj[m]),
            backgroundColor: poolColors(monthArray.length),
            maxBarThickness: 30,
          },
        ]
      },
      options: {
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
              // OR //
              beginAtZero: true,   // minimum value will be 0.
            },
          }],
        },
        legend: {
          display: false,
        }
      },
    });

    setTimeout(() => {
      this.ref.detectChanges();
    }, 500)
  }

  displayLicenseActivity(sort = null) {
    let licences_data = this.licenseArray.map(h => ({
      date: h.createdAt.split("T")[0],
      date_sort: parseFloat(h.createdAt.replaceAll("-", "").replaceAll(":", "").replace("T", "").replace("Z", "")),
      mac: this.getDeviceDetail(h, 'mac'),
      devicename: this.getDeviceDetail(h, 'name'),
      location: this.getDeviceDetail(h, 'location'),
      mmsp: this.getDeviceOrgDetail(h, 1),
      msp: this.getDeviceOrgDetail(h, 2),
      org: this.getDeviceOrgDetail(h, 3),
      old_license: h.old_license?.replace(" Add-on License", "<br>Add-on License"),
      license: h.license?.replace(" Add-on License", "<br>Add-on License"),
      user: h.username + " (" + h.email + ")",
    }));
    if (sort && sort._direction && sort._direction == "asc") {
        licences_data.sort((a, b) => (a.date_sort > b.date_sort) ? 1 : -1);
    } else {
        licences_data.sort((a, b) => (a.date_sort < b.date_sort) ? 1 : -1);
    }
    this.licenseHistorySource = new MatTableDataSource<any>(licences_data);
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  getDeviceDetail(h, property) {
    try {
      let d = this.deviceArrayAll.find(d => d._id == h.deviceId)
      if (d && d[property]) {
        return d[property]
      }
    } catch (e) { }
    return ''
  }

  getDeviceOrgDetail(h, role) {
    try {
      let d = this.deviceArrayAll.find(d => d._id == h.deviceId)
      let allOrg = this.getDomainOrg(this.org);
      let org = allOrg.find(o => o._id == d.orgId)
      let orgs = [org];
      while (org.parentOrganization) {
        org = allOrg.find(o => o._id == org.parentOrganization)
        orgs.push(org)
      }
      let o = orgs.find(o => o.role == role)
      return o.name
    } catch (e) { }
    return ''
  }

  getDomainOrg(domain) {
    let filtered = [];
    filtered.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filtered = [...filtered, ...this.getDomainOrg(org)]
    }
    return filtered;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.sortChange.emit(this.sort)
      if (this.sort.active && this.sort.active == "monthsArray") {
        this.countByMonth(this.sort);
      }
      if (this.sort.active && this.sort.active == "date") {
        this.displayLicenseActivity(this.sort);
      }

    });
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}
