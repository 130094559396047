<div>
    <mat-card-content>
        <div class="text-right">
            <mat-form-field class="example-form-field">
                <mat-label>Search Here ...</mat-label>
                <input matInput type="text" [(ngModel)]="searchTxt" (keyup.enter)="loadData()" autocomplete="off">
                <button mat-button *ngIf="searchTxt" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchTxt='';loadData();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <app-device-table [dataSource]="dataSource" [selfSort]="true" [vhub]="true" (connectDevice)="_connectDevice($event)"></app-device-table>
    </mat-card-content>
</div>