export const EncryptionmalgorithmTypes = [
    {id: '0',  value: 'auto', name: "Auto"},
    {id: '1', value: 'ccmp', name: "Force CCMP (AES)"},
    {id: '2',  value: 'tkip', name: "Force TKIP"},
    {id: '3',  value: 'ccmp+tkip', name: "Force TKIP and CCMP (AES)"},
];

export const EncryptionmModeTypes = [
    { id: '0', value: 'psk', name: 'WPA2-PSK (strong security)' },
    { id: '1', value: 'psk2', name: "WPA2-PSK/WPA-PSK Mixed Mode (medium security)" },
    { id: '2', value: 'psk-mixed', name: "WPA-PSK (medium security)" },
    { id: '3', value: 'wep-open', name: "WEP Open System (weak security)" },
    { id: '4', value: 'wep-shared', name: "WEP Shared Key (weak security)" },
    { id: '5', value: 'none', name: "No Encryption (open network)" },
]

export const NetworkTypes = [
    {
        name: "LAN",
        value: "lan"
    },
    {
        name: "MyFi2G",
        value: "MyFi2G"
    },
    {
        name: "MyFi5G",
        value: "MyFi5G"
    },
    {
        name: "GUEST",
        value: "guest"
    }
];

export const WifiTypes = [
    {
        "enabled": true,
        "label": "2G AP",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    },
    {
        "enabled": false,
        "label": "2G Guest",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    },
    {
        "enabled": true,
        "label": "5G AP",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    },
    {
        "enabled": false,
        "label": "5G Guest",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    },
    {
        "enabled": false,
        "label": "2G Client",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    },
    {
        "enabled": false,
        "label": "5G Client",
        "enc": "psk",
        "cipher": "auto",
        "key": "",
        "ssid": "",
        "hidden": false,
        "country": "ss",
        "txpower": "0",
        "channel": "auto",
    }
]

export const CountryTypes = [
    {
        name: "Global",
        value: "ss"
    },
    {
        name: "North America(US)",
        value: "us"
    }
];

export const TxPowerTypes = [
    {
        name: "0 dBb (0 mW)",
        value: "0"
    },
    {
        name: "1 dBb (1 mW)",
        value: "1"
    },
    {
        name: "2 dBb (2 mW)",
        value: "2"
    },
    {
        name: "3 dBb (3 mW)",
        value: "3"
    },
    {
        name: "4 dBb (4 mW)",
        value: "4"
    },
    {
        name: "5 dBb (5 mW)",
        value: "5"
    },
    {
        name: "6 dBb (6 mW)",
        value: "6"
    },
    {
        name: "7 dBb (7 mW)",
        value: "7"
    },
    {
        name: "8 dBb (8 mW)",
        value: "8"
    },
    {
        name: "9 dBb (9 mW)",
        value: "9"
    },
    {
        name: "10 dBb (10 mW)",
        value: "10"
    },
    {
        name: "11 dBb (11 mW)",
        value: "11"
    },
    {
        name: "12 dBb (12 mW)",
        value: "12"
    },
    {
        name: "13 dBb (13 mW)",
        value: "13"
    },
    {
        name: "14 dBb (14 mW)",
        value: "14"
    },
    {
        name: "15 dBb (15 mW)",
        value: "15"
    },
    {
        name: "16 dBb (16 mW)",
        value: "16"
    },
    {
        name: "17 dBb (17 mW)",
        value: "17"
    },
    {
        name: "18 dBb (18 mW)",
        value: "18"
    },
    {
        name: "19 dBb (19 mW)",
        value: "19"
    },
    {
        name: "20 dBb (20 mW)",
        value: "20"
    },
]

export const ChannelTypes1 = [
    {
        name: "Auto",
        value: "auto"
    },
    {
        name: "Channel 1 (2412 Mhz)",
        value: "1"
    },
    {
        name: "Channel 2 (2417 Mhz)",
        value: "2"
    },
    {
        name: "Channel 3 (2422 Mhz)",
        value: "3"
    },
    {
        name: "Channel 4 (2427 Mhz)",
        value: "4"
    },
    {
        name: "Channel 5 (2432 Mhz)",
        value: "5"
    },
    {
        name: "Channel 6 (2437 Mhz)",
        value: "6"
    },
    {
        name: "Channel 7 (2442 Mhz)",
        value: "7"
    },
    {
        name: "Channel 8 (2447 Mhz)",
        value: "8"
    },
    {
        name: "Channel 9 (2452 Mhz)",
        value: "9"
    },
    {
        name: "Channel 10 (2457 Mhz)",
        value: "10"
    },
    {
        name: "Channel 11 (2462 Mhz)",
        value: "11"
    },
]
export const ChannelTypes2 = [
    {
        name: "Auto",
        value: "auto"
    },
    {
        name: "Channel 36 (5180 Mhz)",
        value: "36"
    },
    {
        name: "Channel 40 (5200 Mhz)",
        value: "40"
    },
    {
        name: "Channel 44 (5220 Mhz)",
        value: "44"
    },
    {
        name: "Channel 48 (5240 Mhz)",
        value: "48"
    },
    {
        name: "Channel 52 (5260 Mhz)",
        value: "52"
    },
    {
        name: "Channel 56 (5280 Mhz)",
        value: "56"
    },
    {
        name: "Channel 60 (5300 Mhz)",
        value: "60"
    },
    {
        name: "Channel 64 (5320 Mhz)",
        value: "64"
    },
    {
        name: "Channel 100 (5500 Mhz)",
        value: "100"
    },
    {
        name: "Channel 104 (5520 Mhz)",
        value: "104"
    },
    {
        name: "Channel 108 (5540 Mhz)",
        value: "108"
    },
    {
        name: "Channel 112 (5560 Mhz)",
        value: "112"
    },
    {
        name: "Channel 116 (5580 Mhz)",
        value: "116"
    },
    {
        name: "Channel 120 (5600 Mhz)",
        value: "120"
    },
    {
        name: "Channel 124 (5620 Mhz)",
        value: "124"
    },
    {
        name: "Channel 128 (5640 Mhz)",
        value: "128"
    },
    {
        name: "Channel 132 (5660 Mhz)",
        value: "132"
    },
    {
        name: "Channel 136 (5680 Mhz)",
        value: "136"
    },
    {
        name: "Channel 140 (5700 Mhz)",
        value: "140"
    },
    {
        name: "Channel 144 (5720 Mhz)",
        value: "144"
    },
    {
        name: "Channel 149 (5745 Mhz)",
        value: "149"
    },
    {
        name: "Channel 153 (5765 Mhz)",
        value: "153"
    },
    {
        name: "Channel 157 (5785 Mhz)",
        value: "157"
    },
    {
        name: "Channel 161 (5805 Mhz)",
        value: "161"
    },
    {
        name: "Channel 165 (5825 Mhz)",
        value: "165"
    },
]
export const ChannelTypes3 = [
    {
        name: "Auto",
        value: "auto"
    }
]

export const HTModeTypes1 = [
    {
        name: "20MHz (HT20)",
        value: "HT20"
    },
    {
        name: "40MHz (HT40)",
        value: "HT40"
    }
]
export const HTModeTypes2 = [
    {
        name: "20MHz (VHT20)",
        value: "HT20"
    },
    {
        name: "40MHz (VHT40)",
        value: "HT40"
    },
    {
        name: "80MHz (VHT80)",
        value: "HT80"
    }
]

export const HWModeTypes1 = [
    {
        name: "Legacy 802.11b (11Mbps)",
        value: "11b",
        hwmode: "11b",
        htmode: ""
    },
    {
        name: "Legacy 802.11g (54Mbps)",
        value: "11g",
        hwmode: "11g",
        htmode: ""
    },
    {
        name: "802.11n",
        value: "11n",
        hwmode: "11n",
        htmode: HTModeTypes1
    }
]

export const HWModeTypes2 = [
    {
        name: "802.11n",
        value: "11n",
        hwmode: "11n",
        htmode: HTModeTypes1
    },
    {
        name: "802.11ac",
        value: "11ac",
        hwmode: "11ac",
        htmode: HTModeTypes2
    }
]

export const PdpTypes = [
    {
        name: "IPv4",
        value: "ipv4"
    },
    {
        name: "IPv4/IPv6",
        value: "ipv4v6"
    },
    {
        name: "IPv6",
        value: "ipv6"
    }
]
export const SdtTypes = [
    {
        name: "Voice Only",
        value: 0
    },
    {
        name: "Data Only",
        value: 1
    },
    {
        name: "Both",
        value: 2
    },
]
export const AuthTypes = [
    {
        name: "PAP",
        value: "pap"
    },
    {
        name: "CHAP",
        value: "chap"
    },
    {
        name: "PAP and CHAP",
        value: "both"
    },
    {
        name: "None",
        value: "none"
    },
]
export const TeamModeTypes = [
    {
        name: "VHUB",
        value: "VHUB"
    },
    {
        name: "VCE",
        value: "VCE"
    },
]
export const TeamLoadBalancerTypes = [
    {
        name: "Single-Path Per Session",
        value: "client"
    },
    {
        name: "Multi-Path Per Session",
        value: "session"
    }
]

export const DeviceLicenseTypes = [
    {
        name: "Entry - Single Link (1L)",
        value: "1L"
    },
    {
        name: "Failsafe - Duel Link (2L)",
        value: "2L"
    },
    {
        name: "Cloud Connect - Overlay (3L)",
        value: "3L1"
    },
    {
        name: "Cloud Connect - Overlay DMZ (3L)",
        value: "3L2"
    }
]


export const BaseLicensesTypes = [
    {
        value: "1l",
        name: "Single Link (Entry, 1L)",
        links: 1,
    },
    {
        value: "1l_lte",
        name: "Single Link LTE (Entry, LTE, 1L)",
        links: 1,
    },
    {
        value: "2l",
        name: "Failover (Failsafe, 2L)",
        links: 2,
    },
    {
        value: "2l_lte",
        name: "Failover LTE (Failsafe, LTE, 2L)",
        links: 2,
    },
    {
        value:"nfr",
        name: "NFR (Failsafe, LTE, Overlay, 3L)",
        links: 4,
    },
    {
        value: "spare",
        name: "Spare (Not Licensed)",
        links: 0
    }
]


export const AddOnLicensesTypes = [
    {
        value: "overlay",
        name: "Overlay (+1L)",
        links: 3,
        entry: "vhub-pub"
    },
    {
        value: "cloud_dmz",
        name: "Cloud DMZ (+1L)",
        links: 3,
        entry: "vhub_pub_ip"
    },
    {
        value: "wan_connect",
        name: "WAN Connect (+1L)",
        links: 4,
        entry: "vhub-priv"
    },
    {
        value: "lte_1g",
        name: "1GB LTE Data (+1GB)",
        links: 0,
        entry: "4GDATA-1GB"
    },
    {
        value: "ashield",
        name: "Attack Shield",
        links: 5,
        entry: "a_shield"
    },
    {
        value: "SIPCID04",
        name: "Sipwatch - SIPCID04",
        links: 6,
        entry: "sipwatch_SIPCID04"
    },
    {
        value: "SIPCID06",
        name: "Sipwatch - SIPCID06",
        links: 7,
        entry: "sipwatch_SIPCID06"
    },
    {
        value: "SIPCID10",
        name: "Sipwatch - SIPCID10",
        links: 8,
        entry: "sipwatch_SIPCID10"
    },
    {
        value: "SIPCID20",
        name: "Sipwatch - SIPCID20",
        links: 9,
        entry: "sipwatch_SIPCID20"
    }
]

export const LinkTypes = [
    {
        name: "Underlay",
        value: "underlay"
    },
    {
        name: "Overlay",
        value: "overlay"
    }
]

export const LinkTransportTypes = [
    {
        name: "Link1",
        value: "link1"
    },
    {
        name: "Link2",
        value: "link2"
    }
]

export const LinkPath = [
    {
        name: "wan",
        value: "wan",
        linktype: 0
    },
    {
        name: "wan2",
        value: "wan2",
        linktype: 0
    },
    {
        name: "4GLTE",
        value: "4GLTE",
        linktype: 0
    }, 
    {
        name: "MyFi2G",
        value: "MyFi2G",
        linktype: 0
    },
    {
        name: "MyFi5G",
        value: "MyFi5G",
        linktype: 0
    }, 
    {
        name: "wg1",
        value: "wg1",
        linktype: 1
    }

]

export const LinkAction = [
    {
        name: 'Primary',
        value: 'primary'
    },
    {
        name: "Backup",
        value: "backup"
    },
    {
        name: "Cloud Connect",
        value: "cloudconnect"
    },
    {
        name: "Disabled",
        value: "disabled"
    }
]

export const RateLimitTypes = [
    {
        name: "300mbit",
        value: "300mbit"
    },
    {
        name: "Custom",
        value: "custom"
    }
]

export const LinkQualityTypes = [
    {
        name: "Good Quality Broadband",
        value: "good"
    },
    {
        name: "Average Quality Broadband",
        value: "avg"
    },
    {
        name: "Medium Quality Broadband",
        value: "med"
    },
    {
        name: "Wireless Quality Broadband or 4GLTE",
        value: "poor"
    },
    {
        name: "Overlay w/Fail Down to Underlays",
        value: "overlay"
    },
    {
        name: "Custom",
        value: "custom"
    }
]

export const LinkHopTypes = [
    {
        name: "wan - 10.10.11.1",
        value: "10.10.11.1"
    },
    {
        name: "wan2 - 192.168.2.1",
        value: "192.168.2.1"
    },
    {
        name: "4GLTE - 10.103.110.221",
        value: "10.103.110.221"
    },
    {
        name: "Default",
        value: "10.0.41.254"
    }
]
