import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApService {
  constructor(private http: HttpClient) { }
  getListing(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap, data);
  }

  getPendingDevice(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.getpendingdevicesdata, data)
  }

  movePendingDevices(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.postdevice, data)
  }

  getdeviceById(id): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/' + id)
  }

  getdeviceByMonth(id, startDate, endDate): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/search?orgId=' + id + '&start_date=' + startDate + '&end_date=' + endDate)
  }

  getDevicesByOrg(id): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/search?orgId=' + id)
  }

  getHistoryDataById(id, data): Observable<any> {
    return this.http.post<any>(environment.endpoints.ap + '/history-data/' + id, data)
  }

  getDevicesHistoryDataById(id, data): Observable<any> {    
    return this.http.get<any>(environment.endpoints.deviceHistoryData + '?deviceId=' + id,  { params: data } )
  }

  posdevicedata(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.postdevice, data)
  }

  updateDevice(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.updateDevice, data)
  }

  deviceDelete(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        device: data
      }
    };
    return this.http.delete<any>(environment.endpoints.devicedelete, options);
  }

  getbackup(id): Observable<any> {
    return this.http.get<any>(environment.endpoints.deviceBackup + '/' + id + '/' + 'backup')
  }

  createBackup(data, id): Observable<any> {
    return this.http.post<any>(environment.endpoints.deviceBackup + '/' + id + '/setCommand', data)
  }

  deleteBackup(data, id): Observable<any> {
    return this.http.delete<any>(environment.endpoints.deviceBackup + '/' + id + '/backup/' + data);
  }

  getPrtgDevices(data, prtgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.getPrtgDevice + '/prtg/' + prtgId + '/templateDevices', data)
  }

  getWnetworkDevices(data, wnetworkId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/wnetwork/' + wnetworkId + '/templateDevices', data)
  }

  getQosDevices(data, qosId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/qos/' + qosId + '/templateDevices', data)
  }

  getI4glteDevices(data, i4glteId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/i4glte/' + i4glteId + '/templateDevices', data)
  }

  getTeamDevices(data, teamId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/team/' + teamId + '/templateDevices', data)
  }

  getTprofileDevices(data, tprofileId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/tprofile/' + tprofileId + '/templateDevices', data)
  }

  getAlertDevices(data, alertId): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/alert/' + alertId + '/templateDevices', data)
  }

  setDeviceCommand(deviceId, data): Observable<any> {
    return this.http.post<any>(environment.endpoints.ap + '/' + deviceId + '/setCommand', data)
  }

  toggleTemplate(deviceId, template, value): Observable<any> {
    return this.http.post<any>(environment.endpoints.ap + '/' + deviceId + '/templateConfig', {
      template: template, value: value, _id: deviceId
    });
  }

  getLicenseHistoryByMonth(orgId, startDate, endDate): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/license-audit/history?orgId=' + orgId + '&startDate=' + startDate + '&endDate=' + endDate)
  }

  getDeviceOrganizations(macs): Observable<any> {
    return this.http.post<any>(environment.endpoints.ap + '/device-organizations', { macs })
  }

  createDeviceBrowseHistory(mac, type): Observable<any> {
    return this.http.post<any>(environment.endpoints.ap + '/history/device-browse-history', { mac, type })
  }

  getDeviceBrowseHistory(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.ap + '/history/device-browse-history', data)
  }

  getLanData(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.lan, data)
  }

  getDeviceState(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.trackDeviceStatus, { params: data })
  }

  getLinkState(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.trackLinkStatus, { params: data })
  }

  getDeviceCalc(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.trackDeviceCalc, { params: data })
  }

  getLinkCalc(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.trackLinkCalc, { params: data })
  }

  getAttackShieldHist(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.attackShield + '/history', { params: data })
  }

  getLinkStatesHist(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.linkStateHist + '/history', { params: data })
  }

  getSipwatchAlerts(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.sipwatchAlert, { params: data })
  }

  getSipwatchCallerIdHist(data): Observable<any> {
    return this.http.get<any>(environment.endpoints.sipwatchCallerIdHist, { params: data })
  }
}
