<div>
    <div class="mt-4">
        <mat-tab-group class="mt-2 monitor-device-alert-wrapper panel" animationDuration="0ms">          
            <mat-tab label="Links State">
                <app-device-alert-link-state [deviceInfo]="_device"></app-device-alert-link-state>
            </mat-tab>
            <mat-tab label="Device State">
                <app-device-alert-device-state [deviceInfo]="_device"></app-device-alert-device-state>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
