<div>
    <label>Device View {{mode ? '('+mode+')' : ''}}</label>
    <div class="w-100 overflow-auto">
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="mac">
                <th mat-header-cell *matHeaderCellDef>Mac Address</th>
                <td mat-cell *matCellDef="let element">{{ element.mac | uppercase }}</td>
            </ng-container>
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef>Domain</th>
                <td mat-cell *matCellDef="let element">{{element.domain }}</td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>Location</th>
                <td mat-cell *matCellDef="let element">{{element.location }}</td>
            </ng-container>
            <ng-container matColumnDef="model">
                <th mat-header-cell *matHeaderCellDef>Model</th>
                <td mat-cell *matCellDef="let element">{{element.deviceMetaInfo.deviceModel}}</td>
            </ng-container>
            <ng-container matColumnDef="firmware">
                <th mat-header-cell *matHeaderCellDef>Firmware</th>
                <td mat-cell *matCellDef="let element">{{element.statistics.firmware ?
                    element.statistics.firmware.currentVersion:element.firmware?element.firmware: '' }}</td>
            </ng-container>
            <ng-container matColumnDef="baseLicense">
                <th mat-header-cell *matHeaderCellDef>License</th>
                <td mat-cell *matCellDef="let element">{{element.baseLicense ?
                    getLicenseLabel(element.baseLicense): '' }}</td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>Install Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.createdAt | date: "M/d/yy, h:mm:ss a" }}
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="upTime">
                <th mat-header-cell *matHeaderCellDef>Up Time</th>
                <td mat-cell *matCellDef="let element">{{ element.upTime }}</td>
            </ng-container>
            <ng-container matColumnDef="lastSeen">
                <th mat-header-cell *matHeaderCellDef>Last Seen</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.lastSeen | date: "M/d/yy, h:mm:ss a" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{ getStatus(element.lastSeen, element.track) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef class="bg-color">Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                        <mat-icon class="pointer v-align-middle" matTooltip="Edit" role="img" aria-hidden="true"
                            (click)="editDevice(element)" *ngIf="role<=3">
                            edit
                        </mat-icon>
                        <mat-icon class="pointer" matTooltip="Alerts" role="img" aria-hidden="true"
                            (click)="viewSubs(element)" *ngIf="role<=3">contacts
                        </mat-icon>
                        <mat-icon class="pointer v-align-middle" matTooltip="Website Jump" role="img" aria-hidden="true"
                            (click)="webclick(element)">
                            language</mat-icon>
                        <mat-icon class="pointer v-align-middle" matTooltip="Teminal Jump" role="img" aria-hidden="true"
                            (click)="teminalclick(element)">cast_connected</mat-icon>
                        <mat-icon class="pointer v-align-middle" matTooltip="Reset Password" role="img"
                            aria-hidden="true" (click)="resetPwd(element._id)" *ngIf="role<=3">
                            lock_reset</mat-icon>
                        <!-- <mat-icon class="pointer v-align-middle" matTooltip="Firmware Update" role="img" aria-hidden="true"
                        (click)="updateFirmware(element)" *ngIf="role<=3">memory</mat-icon> -->
                        <mat-icon class="pointer v-align-middle" matTooltip="LAN Jump 1" role="img" aria-hidden="true"
                            (click)="redirect_lan1(element)" *ngIf="role<=3 && (lan1_status)">
                            laptop</mat-icon>
                        <mat-icon class="pointer v-align-middle" matTooltip="LAN Jump 2" role="img" aria-hidden="true"
                        (click)="redirect_lan2(element)" *ngIf="role<=3 && (lan2_status)">
                        laptop</mat-icon>
                        <mat-icon class="pointer v-align-middle" matTooltip="Delete" role="img" aria-hidden="true"
                            (click)="delete(element._id)" *ngIf="role==0">
                            restore_from_trash</mat-icon>                        
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <div class="mt-4">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)"
        [selectedIndex]="selectedTabIndex">
            <mat-tab label="Analytics & Info">
                <app-device-monitor-analytics [device]="device" [dataSource]="dataSource"
                    (edit)=editDevice(dataSource.data[0])>
                </app-device-monitor-analytics>
            </mat-tab>
            <mat-tab label="Alerts">
                <app-device-monitor-alert [device]="device"></app-device-monitor-alert>
            </mat-tab>
            <mat-tab label="Attack Shield" *ngIf="showAttackShield">
                <app-device-attack-shield [device]="device"></app-device-attack-shield>
            </mat-tab>
            <mat-tab label="SIPWatch" *ngIf="showSipwatch">
                <app-device-monitor-sipwatch [device]="device"></app-device-monitor-sipwatch>
            </mat-tab>
            <mat-tab label="Team Links" *ngIf='showTeam'>
                <app-device-monitor-team1 [device]="device"></app-device-monitor-team1>
            </mat-tab>
            <mat-tab label="4GLTE" *ngIf='show4glte'>
                <app-device-monitor-i4glte [device]="device"></app-device-monitor-i4glte>
            </mat-tab>            
            <!-- <mat-tab label="Interfaces" *ngIf="showInterfaces">
              <app-device-monitor-interfaces [device]="device"></app-device-monitor-interfaces>
            </mat-tab> -->
            <mat-tab label="Device System" *ngIf="showDeviceSystem">
                <app-device-monitor-system [device]="device"></app-device-monitor-system>
            </mat-tab>           
            <mat-tab label="QoS" *ngIf='showQos'>
                <app-device-monitor-qos [device]="device"></app-device-monitor-qos>
            </mat-tab>
            <mat-tab label="Wireless" *ngIf='showWnetwork'>
                <app-device-monitor-wnetwork [device]="device"></app-device-monitor-wnetwork>
            </mat-tab>            
            <!-- <mat-tab label="Monitoring" *ngIf='showMonitor'>
                <app-device-monitor-monitor [device]="device"></app-device-monitor-monitor>
            </mat-tab> -->
            <mat-tab label="Overlay" *ngIf='showOverlay'>
                <app-device-vce-vhub [device]="device"></app-device-vce-vhub>
            </mat-tab>
            <mat-tab label="RAS" *ngIf="(mode == 'VCE') && ras">
                <app-device-ras [device]="device"></app-device-ras>
            </mat-tab>
            <mat-tab label="LAN Jump" *ngIf="showJump">
                <app-device-lan-jump [device]="device"></app-device-lan-jump>
            </mat-tab>
            <mat-tab label="Notes">
                <app-device-notes [device]="device"></app-device-notes>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
