import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NovuService } from 'src/app/shared/services/novu.service';

@Component({
  selector: 'app-subcriber-info-modal',
  templateUrl: './subcriber-info-modal.component.html',
  styleUrls: ['./subcriber-info-modal.component.scss']
})
export class SubcriberInfoModalComponent implements OnInit {
  subsInfo: any = [];
  noData = false
  private loadSubscriberSubscription: Subscription;
  constructor(
    public novuService: NovuService,
    private router: Router,
    public dialogRef: MatDialogRef<SubcriberInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data && this.data._id) {
      this.loadSubscriberSubscription = this.novuService.getSubscribersInfo(this.data._id).subscribe(res => {
        this.subsInfo = res;
        if(!res.data){
          this.noData = true
        }
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // this.pageAvailable = false;
    if (this.loadSubscriberSubscription) {
      this.loadSubscriberSubscription.unsubscribe();
    }
  }
}
