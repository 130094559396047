<mat-card>
    <h4 class="text-center">4GLTE and Overlay Billing Report <span *ngIf="org">for {{org.name}}</span></h4>    
    <div class="row" [formGroup]="searchForm">        
        <mat-form-field>
            <mat-label>Select Month</mat-label>
            <mat-select formControlName="month" (selectionChange)="onSelectMonth($event)">
                <mat-option *ngFor="let item of months" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Select Year</mat-label>
            <mat-select formControlName="year" (selectionChange)="onSelectYear($event)">
                <mat-option *ngFor="let item of years" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="currentRole == 0">
            <mat-label>MMSP</mat-label>
            <mat-select formControlName="mmsp" (selectionChange)="onSelectMMSP($event)">
                <mat-option *ngFor="let organization of mmspList" [value]="organization.orgId">
                    {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                    == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                    "Organization": "") }})
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>MSP</mat-label>
            <mat-select formControlName="msp" (selectionChange)="onSelectMSP($event)">
                <mat-option *ngFor="let organization of mspList" [value]="organization.orgId">
                    {{ organization.name }} {{ (organization.role == 0? "(Root)": organization.role
                    == 1? "(Master Msp)":organization.role == 2? "(Msp)": organization.role == 3?
                    "Organization": "") }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Organizations</mat-label>
            <mat-select formControlName="linked_orgs" (click)="searchFocus()" [multiple]="true"  (selectionChange)="onSelectOrgs($event)">
                <div class="dropdown-search">
                    <input #orginput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
                        placeholder="Search Organization" autocomplete="off">
                </div>
                <div>
                    <mat-option *ngFor="let organization of orgList | searchOrg : searchPipe"
                        [value]="organization.orgId"  (click)="toggleSelection($event)">
                        {{ organization.name }} {{ (organization.role == 0? "(Root)": organization.role
                        == 1? "(Master Msp)":organization.role == 2? "(Msp)": organization.role == 3?
                        "Organization": "") }}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field class="m-4">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDate" [min]="minDate" [max]="maxDate" [formControl]="startMonth">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate panelClass="example-date-picker">
            </mat-datepicker>
        </mat-form-field>
        <mat-form-field class="m-4">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDate" [min]="startDate" [max]="maxDate" [formControl]="endMonth">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate panelClass="example-date-picker">
            </mat-datepicker>
        </mat-form-field> -->
        <div class="btn-wrapper">
            <button mat-raised-button color="primary" class="btn btnn mb-1" (click)="loadData()" >Search</button>
        </div>
        <div class="btn-wrapper">
            <button mat-raised-button color="secondary" class="btn btnn mb-1" (click)="exportToExcel()" >Export</button>
        </div>
    </div>
    <div class="row ml-2 mr-2">
        <table mat-table matTableExporter [dataSource]="reportMonthlyDataSource" #exporter="matTableExporter" matSort>
            <!-- <mat-table [dataSource]="reportMonthlyDataSource" style="width: 100%;" matTableExporter matSort> -->
                <ng-container matColumnDef="mmsp">
                    <th mat-header-cell *matHeaderCellDef>MMSP</th>
                    <td mat-cell *matCellDef="let element">{{element.mmsp}}</td>
                </ng-container>

                <ng-container matColumnDef="msp">
                    <th mat-header-cell *matHeaderCellDef>MSP</th>
                    <td mat-cell *matCellDef="let element">{{element.msp}}</td>
                </ng-container>

                <ng-container matColumnDef="org">
                    <th mat-header-cell *matHeaderCellDef>ORG</th>
                    <td mat-cell *matCellDef="let element">{{element.org}}</td>
                </ng-container>
                

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Hostname</th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <ng-container matColumnDef="mac">
                    <th mat-header-cell *matHeaderCellDef>Mac</th>
                    <td mat-cell *matCellDef="let element">{{element.mac.toUpperCase()}}</td>
                </ng-container>

                
                <ng-container matColumnDef="firmware">
                    <th mat-header-cell *matHeaderCellDef>Firmware</th>
                    <td mat-cell *matCellDef="let element">{{element.firmware}}</td>
                </ng-container>                

                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef>Model</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHtml]="element.model"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="activationAt">
                    <th mat-header-cell *matHeaderCellDef>Activation Date</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHtml]="element.activationAt"></span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="reportDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Report Date</th>
                    <td mat-cell *matCellDef="let element">{{element.reportDate}}</td>
                </ng-container>

                <ng-container matColumnDef="overlay">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Overlay</th>
                    <td mat-cell *matCellDef="let element">{{element.overlay}}</td>
                </ng-container>

                <ng-container matColumnDef="glte">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>4Glte</th>
                    <td mat-cell *matCellDef="let element">{{element.glte}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedReportColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedReportColumns"></tr>
            </table>        
    </div>
</mat-card>