import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseObj } from '../model/responseObj';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetpasswordService {
  
  constructor(private http: HttpClient) { }
  resetpassword(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.resetpassword, data)
  }
}
