<div class="mt-2 monitor-lan-jump-wrapper panel">
    <hr class="y-border" />
    <div class="pointer add-notes-btn">
        <button mat-raised-button color="primary" class="btn btnn"
            (click)="addModal()">Add Note</button>
    </div>
    <div class="mt-2 monitor-device-system-wrapper panel" *ngIf="_device">
        <div class="mt-4">           
                    <!-- <table mat-table [dataSource]="_dataSource" multiTemplateDataRows class="mat-elevation-z8"> -->
                        <table mat-table [dataSource]="_dataSource" multiTemplateDataRows class="mat-elevation-z8 w-100 d-md-table d-block overflow-auto" matSort
            (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="expand">
                            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                            <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">                       
                                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>                       
                                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>                        
                            </button>
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of displayedColumns; track column; index as i" matColumnDef="{{column}}">
                            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                            <td mat-cell *matCellDef="let element"> 
                                <ng-template [ngIf]="column == 'Date'">{{ element.createdAt }}</ng-template>
                                <ng-template [ngIf]="column == 'Type'">{{ displayType(element.type) }}</ng-template>
                                <ng-template [ngIf]="column == 'Title'">{{ element.title }}</ng-template>
                                <ng-template [ngIf]="column == 'Notes'">{{ element.notes }}</ng-template>
                                <ng-template [ngIf]="column == 'Created By'">{{ element.createdBy }}</ng-template>
                                <ng-template [ngIf]="column == 'Action'">
                                    <mat-icon class="pointer v-align-middle" matTooltip="Edit" role="img" aria-hidden="true"
                                             (click)="editModal(element)" (noteSubmitted)="loadData()">
                                            edit
                                        </mat-icon>
                                        <mat-icon class="pointer" matTooltip="delete" role="img" aria-hidden="true"
                                            (click)="delete(element)">delete
                                        </mat-icon>
                                </ng-template>
                            </td>
                        </ng-container>
                         <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                       <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <table>
                                    <tr>
                                        <th>Date Upload</th>
                                        <th>Filename</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr *ngFor="let l of element.files">
                                        <td>{{l.uploadDate}}</td>
                                        <td>{{l.filename}}</td>
                                        <td>
                                            <mat-icon class="pointer v-align-middle" matTooltip="download" role="img" aria-hidden="true"
                                            (click)="download(element._id,l)">
                                           download
                                       </mat-icon>
                                       <mat-icon class="pointer" matTooltip="delete file" role="img" aria-hidden="true"
                                           (click)="deleteFile(element,l._id)">delete_forever
                                       </mat-icon>
                                        </td>
                                        
                                    </tr>
                                </table>
                            </div>
                            </td>
                        </ng-container>
    
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                            class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> 
                        </table>
                        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
                    </mat-paginator>
                
        </div>
    </div>