<form [formGroup]="deviceForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Add new device</legend>
        <div mat-dialog-content class="position-relative">
            <mat-horizontal-stepper [linear]="false" #stepper (selectionChange)="onStepChange($event);">
                <mat-step completed="false" label="Device" #step1 errorMessage="All fields are required"
                    [hasError]="step1.interacted && (deviceForm.get('macid').invalid || deviceForm.get('name').invalid || deviceForm.get('org').invalid || 
                    deviceForm.get('country').invalid || deviceForm.get('state').invalid || deviceForm.get('city').invalid)">
                    <div>
                        <mat-form-field>
                            <mat-label>Mac Address (pending)</mat-label>
                            <mat-select formControlName="macid" (click)="searchMacFocus()" *ngIf="deviceList.length">
                                <div class="dropdown-search">
                                    <input #matInput mask="AA:AA:AA:AA:AA:AA" [dropSpecialCharacters]="false"
                                        [(ngModel)]="searchMacPipe" [ngModelOptions]="{standalone: true}" matInput
                                        placeholder="Input MAC Address">
                                </div>
                                <div>
                                    <mat-option *ngFor="let device of deviceList | searchMac : searchMacPipe"
                                        [value]="device.mac">
                                        {{device.mac}}
                                    </mat-option>
                                    <mat-option [value]="searchMacPipe" *ngIf="searchMacPipe && searchMacPipe.length">
                                        {{searchMacPipe}}
                                    </mat-option>
                                </div>
                            </mat-select>
                            <input type="text" #matInput mask="AA:AA:AA:AA:AA:AA" [dropSpecialCharacters]="false"
                                matInput formControlName="macid" *ngIf="!deviceList.length"
                                placeholder="Input MAC Address" autocomplete="off"/>
                        </mat-form-field>

                        <mat-form-field *ngIf="this.data">
                            <mat-label>Select Organization</mat-label>
                            <mat-select formControlName="org" (selectionChange)="onDomainChange($event)"
                                (click)="searchOrgFocus()">
                                <div class="dropdown-search">
                                    <input #orginput [(ngModel)]="searchOrgPipe" [ngModelOptions]="{standalone: true}"
                                        matInput placeholder="Search Organization">
                                </div>
                                <div>
                                    <mat-option *ngFor="let organization of filteredOrg | searchOrg : searchOrgPipe"
                                        [value]="organization._id">
                                        {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                                        == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                                        "Organization": "") }})
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Host Name</mat-label>
                            <input type="text" matInput formControlName="name" autocomplete="off"/>

                            <mat-error *ngIf="deviceForm.get('name').invalid">
                                Host Name is required
                            </mat-error>
                        </mat-form-field>

                        <div class="row w-100">
                            <div class='col-md-4'>
                                <mat-form-field>
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                                        <div class="dropdown-search">
                                            <input (keyup)="onCountrySearch($event.target.value)"
                                                matInput placeholder="Search Country">
                                        </div>
                                        <mat-option *ngFor="let country of selectedCountries" [value]="country.name">
                                            {{country.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class='col-md-4'>
                                <mat-form-field>
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="state" (selectionChange)="onStateChange($event)">
                                        <div class="dropdown-search">
                                            <input (keyup)="onStateSearch($event.target.value)"
                                                matInput placeholder="Search State">
                                        </div>
                                        <mat-option *ngFor="let state of selectedStates" [value]="state.name">
                                            {{state.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class='col-md-4'>
                                <mat-form-field>
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="city">
                                        <div class="dropdown-search">
                                            <input (keyup)="onCitySearch($event.target.value)"
                                                matInput placeholder="Search City">
                                        </div>
                                        <mat-option *ngFor="let city of selectedCities" [value]="city.name">
                                            {{city.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" formControlName="activated">Activate this
                                device<br><span class="hint">Start billing immediately</span>
                            </mat-checkbox>
                        </section>
                    </div>
                </mat-step>

                <mat-step completed="false" label="License" #step2 errorMessage="Select a License"
                    [hasError]="step2.interacted && baseLicense.hasError('required')">
                    <mat-error *ngIf="baseLicense.hasError('required')">Please choose a license</mat-error>
                    <section>
                        <div class="d-block d-sm-inline-flex w-50 mt-2">
                            <div>
                                <mat-label>Base License:</mat-label>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="radio-group"
                                    [formControl]="baseLicense">
                                    <mat-radio-button class="mt-2" *ngFor="let item of baseLicenseArray"
                                        [value]="item.value">
                                        {{ item.name }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="d-block d-sm-inline-flex w-50 mt-2">
                            <div>
                                <mat-label>Add-On License:</mat-label>
                                <mat-selection-list [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedAddOn"
                                    (ngModelChange)="onNgModelChange($event)">
                                    <mat-list-option *ngFor="let item of addOnLicenseArray" checkboxPosition="before"
                                        [value]="item.value">
                                        {{ item.name }}
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                        </div>
                    </section>
                </mat-step>

                <mat-step completed="false" label="Template">
                    <mat-error class="mb-2" *ngIf="deviceForm.get('org').invalid">Choose a domain from step 1 first.
                    </mat-error>
                    <div>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectConfigureType($event, 1)"
                                [checked]="configureType==1">Select
                                Configurable Profile<br><span class="hint">- Contains Multiple Templates &
                                    License</span>
                            </mat-checkbox>
                        </section>

                        <mat-form-field [hidden]="configureType!=1">
                            <mat-label>Assign Template Profile</mat-label>
                            <mat-select formControlName="tprofile">
                                <mat-option *ngFor="let tprofile of this.tprofileList" [value]="tprofile['_id']">
                                    {{ tprofile.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectConfigureType($event, 2)"
                                [checked]="configureType==2">Select
                                Configurable Templates<br><span class="hint">- Individual Templates</span>
                            </mat-checkbox>
                        </section>

                        <mat-form-field [hidden]="configureType!=2">
                            <mat-label>Add to Monitoring</mat-label>
                            <mat-select formControlName="alertId">
                                <mat-option *ngFor="let monitor of this.monitors" [value]="monitor['_id']">
                                    {{ monitor.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field [hidden]="configureType!=2">
                            <mat-label>Assign WiFi Network</mat-label>
                            <mat-select formControlName="wnetwork">
                                <mat-option *ngFor="let wnetwork of this.wnetworks" [value]="wnetwork['_id']">
                                    {{ wnetwork.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field [hidden]="configureType!=2">
                            <mat-label>Assign QOS</mat-label>
                            <mat-select formControlName="qos">
                                <mat-option *ngFor="let qos of this.qosList" [value]="qos['_id']">
                                    {{ qos.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field [hidden]="configureType!=2">
                            <mat-label>Assign 4GLTE</mat-label>
                            <mat-select formControlName="i4glte">
                                <mat-option *ngFor="let i4glte of this.i4glteList" [value]="i4glte['_id']">
                                    {{ i4glte.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field [hidden]="configureType!=2">
                            <mat-label>Assign Team</mat-label>
                            <mat-select formControlName="team">
                                <mat-option *ngFor="let team of this.teamList" [value]="team['_id']">
                                    {{ team.name }}
                                </mat-option>
                                <mat-option [value]="null">
                                    None
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field [hidden]="configureType!=1 && configureType!=2">
                            <mat-label>Template Update Time</mat-label>
                            <mat-select formControlName="trigger">
                                <mat-option [value]="0">
                                    Update on restart
                                </mat-option>
                                <mat-option [value]="1">
                                    Update immediately
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-step>
                <mat-step completed="false" label="Alerts">
                    <div>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectAlert($event, 'device')"
                                [checked]="alert.device">Device Status<br><span class="hint">Notify if device is
                                    down</span>
                            </mat-checkbox>
                        </section>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectAlert($event, 'team')"
                                [checked]="alert.team">Team Status<br><span class="hint">Notify if any link is
                                    down</span>
                            </mat-checkbox>
                        </section>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectAlert($event, 'link1')"
                                [checked]="alert.link1">Link1 Status<br><span class="hint">Notify if link1 is
                                    down</span>
                            </mat-checkbox>
                        </section>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectAlert($event, 'link2')"
                                [checked]="alert.link2">Link2 Status<br><span class="hint">Notify if link2 is
                                    down</span>
                            </mat-checkbox>
                        </section>
                        <section class="example-section custom">
                            <mat-checkbox class="example-margin" (change)="selectAlert($event, 'link3')"
                                [checked]="alert.link3">Link3 Status<br><span class="hint">Notify if link3 is
                                    down</span>
                            </mat-checkbox>
                        </section>
                        <section class="example-section mt-2 custom">
                            <label>Email Notification Recipients</label>
                        </section>
                        <div class="example-section custom">
                            <mat-chip-list #chipList aria-label="Email Recipients">
                                <mat-chip *ngFor="let email of alert.emails" [selectable]="selectable"
                                    [removable]="removable" (removed)="removeRecipient(email)">
                                    {{email}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel
                                    </mat-icon>
                                </mat-chip>
                                <input placeholder="new recipient email..." [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addRecipient($event)">
                            </mat-chip-list>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onNext()"
                *ngIf="selectedIndex!=3">Next</button>
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()"
                *ngIf="selectedIndex==3">Add</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>