import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchOrg'
})
export class SearchOrgPipe implements PipeTransform {

  transform(allOrgList: any[], searchPipe: string): any[] {
    if(!allOrgList || !allOrgList.length) return allOrgList;
    if(!searchPipe || !searchPipe.length) return allOrgList;
    return allOrgList.filter(organization => {
      return organization.name.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
    });
  }

}
