import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule, MatMenu } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DataTablesModule } from 'angular-datatables';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { DeviceTableComponent } from '../components/device-table/device-table.component';
import { OrgDevicesTableComponent } from '../components/org-devices-table/org-devices-table.component';
import { DeviceBrowseHistoryComponent } from '../components/device-browse-history/device-browse-history.component';
import { OrganizationTableComponent } from '../components/organization-table/organization-table.component';
import { UsersTableComponent } from '../components/users-table/users-table.component';
import { SearchDevicePipe } from '../code/search-device.pipe';
import { SearchOrgPipe } from '../code/search-org.pipe';
import { SearchMacPipe } from '../code/search-mac.pipe';
import { searchSubnetPipe } from '../code/search-ip.pipe';
import { SearchSelectPipe } from '../code/lib/search-select.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import { ExpandedOrganizationComponent } from '../components/organization-table/expanded-organization/expanded-organization.component';

const sharedSearch = [
  SearchDevicePipe,
  SearchOrgPipe,
  SearchMacPipe,
  searchSubnetPipe,  
  SearchSelectPipe
]

const TableComponents = [
  OrganizationTableComponent,
  OrgDevicesTableComponent,
  DeviceBrowseHistoryComponent,
  DeviceTableComponent,
  UsersTableComponent,
  ExpandedOrganizationComponent
];

const MainModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  DataTablesModule,
  ChartsModule,
];

const MaterialModules = [
  MatSelectModule,
  MatFormFieldModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatTabsModule,
  MatSortModule,
  MatTableModule,
  MatExpansionModule,
  MatMenuModule,
  MatRippleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NgxMatFileInputModule,
  MatRadioModule,
  MatStepperModule,
  MatChipsModule
];

@NgModule({
  imports: [
    ...MainModules,
    ...MaterialModules
  ],
  exports: [
    ...MainModules,
    ...MaterialModules,
    ...TableComponents,
    ...sharedSearch
  ],
  declarations: [
    ...TableComponents,
    ...sharedSearch
  ]
})
export class SharedModule { }
