import { animate, state, style, transition, trigger } from '@angular/animations';
import { T } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'jszip';
import moment from 'moment';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-attack-shield',
  templateUrl: './device-attack-shield.component.html',
  styleUrls: ['./device-attack-shield.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DeviceAttackShieldComponent implements OnInit {
  _dataSource: MatTableDataSource<any>;  
  _device: any = []
  deviceId = ""
  status = ""
  state = ""
  sensitivity = ""
  logSize = ""
  stateLogSize = ""
  maxFlows = ""
  flowsUse = ""
  troublePkts = ""
  stateHistory = []
  showNote = true
  displayedColumns = ["Date", "State", "Flow Total", "Flow Use %", "Trouble Strays %", "Trble Disjointed %", "Max Disjointed %", "Disjointed", "Strays", "UniqueIPs", "Action"]
  columnsToDisplayWithExpand = [ 'expand', ...this.displayedColumns,];
  col: any;
  dir: any;
  expandedElement: any = []
  
  currentPage = 1;
  page = 0
  pageSize = 10
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  
  @Input() selfSort: any;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this.stateHistory = []
    this._device = obj[0];
    if(this._device.baseLicense === "nfr" || this._device.addOnLicense.length < 0 || this._device.addOnLicense.find(x=> x ==="ashield").length > 0){
      this.showNote = false
    }
    this.status = this._device.statistics?.flowdoscheck.state.enabled !== undefined ? this._device.statistics?.flowdoscheck.state.enabled == 1 ? "Enabled": "Disabled": ""
    this.state = this._device.statistics?.flowdoscheck.state.STATE !== undefined ? this._device.statistics?.flowdoscheck.state.STATE : ""
    this.sensitivity = this._device.statistics?.flowdoscheck.config.mode !== undefined ? this._device.statistics?.flowdoscheck.config.mode : ""
    this.logSize = this._device.statistics?.flowdoscheck.config.log_size !== undefined ? this._device.statistics?.flowdoscheck.config.log_size: "0"
    this.stateLogSize = this._device.statistics?.flowdoscheck.config.state_log_size !== undefined ? this._device.statistics?.flowdoscheck.config.state_log_size: "0"
    this.maxFlows = this._device.statistics?.flowdoscheck.statistics.MaxFlows !== undefined ? this._device.statistics?.flowdoscheck.statistics.MaxFlows: "0"
    this.flowsUse = this._device.statistics?.flowdoscheck.statistics.FlowTotal !== undefined ? this._device.statistics?.flowdoscheck.statistics.FlowTotal: "0"
    this.troublePkts = this._device.statistics?.flowdoscheck.statistics.TroublePktsPct !== undefined ? this._device.statistics?.flowdoscheck.statistics.TroublePktsPct: "0"
    // if(this._device.statistics?.flowdoscheck.state_hist !== undefined && this._device.statistics?.flowdoscheck.state_hist.length > 0){
    //   this._device.statistics?.flowdoscheck.state_hist.forEach(x=> {
    //     const csv = x.csv.replace("'","").split(",")
    //     let links = []
    //     for (let index = 12; index < x.csv.length; index+=5) {
    //       if(csv[index]){
    //         links.push({
    //           link: csv[index],
    //           iface: csv[index+1],
    //           rx: csv[index+2],
    //           tx: csv[index+3]
    //         })
    //       }          
    //     }
    //     if(csv[10] != ""){
    //       this.stateHistory.push({
    //         date: moment.unix(Number(csv[0])).tz('America/New_York').format('ddd MMM D HH:mm:ss zz YYYY'),
    //         state: csv[1],
    //         flowTotal: csv[2],
    //         flowUse: csv[3],
    //         troublePkts: csv[4],
    //         trblDisjointed: csv[5],
    //         maxDisjointed: csv[6],
    //         disjointed: csv[7],
    //         strays: csv[8],
    //         uniqueIps: csv[9],
    //         action: csv[10],
    //         links: links
    //       })
    //     }        
    //   })
    //   this._dataSource.data = this.stateHistory
    // }
    
  }

  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public apService: ApService,
    public route: ActivatedRoute,
  ) {
    this._dataSource = new MatTableDataSource<any>();
   }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('device_id');
    this.loadData()
  }
  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadData()
  }
  loadData(){
    const request =   {
      deviceId: this.deviceId,
      showSafe: true,
      page: this.currentPage,
      pageSize: this.pageSize
    }
    this.apService.getAttackShieldHist(request).subscribe(res=>{
      this.stateHistory = []      
      const response = res.data
      this.length = res.totalCount
      response.forEach(x=> {
        const csv = x.csv.replace("'","").split(",")
        let links = []
        for (let index = 12; index < x.csv.length; index+=5) {
          if(csv[index]){
            links.push({
              link: csv[index],
              iface: csv[index+1],
              rx: csv[index+2],
              tx: csv[index+3]
            })
          }          
        }
        this.stateHistory.push({
          date: moment.unix(Number(csv[0])).tz('America/New_York').format('ddd MMM D HH:mm:ss zz YYYY'),
          state: csv[1],
          flowTotal: csv[2],
          flowUse: csv[3],
          troublePkts: csv[4],
          trblDisjointed: csv[5],
          maxDisjointed: csv[6],
          disjointed: csv[7],
          strays: csv[8],
          uniqueIps: csv[9],
          action: csv[10],
          links: links
        })
      })
      this._dataSource.data = this.stateHistory
    })
    
  }
  sortData(sort: Sort) {
    if (this.selfSort) {
      const data = this.stateHistory.slice();
      if (!sort.active || sort.direction === '') {
        this._dataSource.data = data;
        return;
      }

      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

      this._dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return compare(a.name, b.name, isAsc);
          case 'mac': return compare(a.mac, b.mac, isAsc);
          case 'msp': return compare(a.msp, b.msp, isAsc);
          case 'domain': return compare(a.domain, b.domain, isAsc);
          case 'location': return compare(a.location, b.location, isAsc);
          case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
          case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
          case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
          case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
          case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
          case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
          case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
          // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
          default: return 0;
        }
      });
    }
  }
  stateColor(value){
    if (value == "Safe") {
      return "safe";
    } else if (value == "ICMP-BYTESPERPKT-ALERT!" || value == "ICMP-DISJOINTED-ALERT!" || value == "MAX-Disjointed-ALERT!" || value == "Strays-ALERT!" || value == "DNS-ALERT!" || value == "Disjointed-ALERT!") {  
        return "alert"
    } else if (value == "MAX-Disjointed-WARNING!" || value == "Strays-WARNING!" || value == "DNS-WARNING!" || value == "Disjointed-WARNING!") {
        return "warn"
    } 
  }
}
