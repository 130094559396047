import { Component, OnInit } from '@angular/core';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.authService.clearCookie("USER_TOKEN");
    localStorage.removeItem(UserConstant.TOKEN);
    localStorage.removeItem(UserConstant.USER);
    localStorage.removeItem(UserConstant.LOGINTYPE);
  }

}
