<div class="mt-2 monitor-device-ras panel">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab label="RAS Server">
            <hr class="y-border" />
            <h6>Remote Access Network</h6>
            <table>
                <tbody>
                    <tr>
                        <th>RAS DPC</th>
                        <th>RAS CPD IP</th>
                        <th>DPC MTU</th>
                        <th>Listen Port</th>
                        <th>Listen IP/FQDN</th>
                        <th>Listen Iface(s)</th>
                        <th>Description</th>
                    </tr>
                    <tr *ngFor="let wg of rasWg">
                        <td>
                            <span *ngIf="wg.cfg">
                                RAS{{wg.cfg.replace("wg","")}}({{wg.cfg}})
                            </span>
                        </td>
                        <td>{{wg.dpc_ips}}</td>
                        <td>{{wg.mtu}}</td>
                        <td>{{wg.listen_port}}</td>
                        <td [ngStyle]="{'color': !wg.ras_endpoint ? 'red' : '' }">
                            {{!wg.ras_endpoint ? "Not Configured" : wg.ras_endpoint}}
                        </td>
                        <td>
                            <div *ngFor="let iface of wg.listen_iface">
                                {{iface}}
                            </div>
                        </td>
                        <td>{{wg.description}}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <h6>Remote Access Users</h6>
            <table>
                <tbody *ngFor="let wg of rasWg">
                    <tr>
                        <th>Iface</th>
                        <th>Peer</th>
                        <th>Peer IP:SPort</th>
                        <th>Peer Overlay IP</th>
                        <th>Transmitted</th>
                        <th>Received</th>
                        <th>Last Handshake</th>
                        <th>Keep Alive</th>
                        <th>State</th>
                    </tr>
                    <tr *ngFor="let peers of wg.peers" [hidden]="!wg.peers">
                        <td>{{peers.iface}}</td>
                        <td>
                            <div>{{peers.ras_username}}</div>
                            <div>{{peers.ras_mac}}</div>
                        </td>
                        <td>{{peers.addr}}</td>
                        <td>{{peers.peer_vpn_ip}}</td>
                        <td>{{formatBytes(peers.tx)}}</td>
                        <td>{{formatBytes(peers.rx)}}</td>
                        <td>{{peers.last_handshake == 0 ? '' : peers.last_handshake*1000 | date:'short'}}</td>
                        <td>{{peers.keepalive}}</td>
                        <td [ngStyle]="{'color': peers.up == 0 ? '#b0b0b0' : '#45d645' }" style="font-weight: 600;">
                            {{peers.up == 0 ? 'Down' : 'Up'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab label="RAS User Groups" [hidden]="!ras.ras_group">
            <hr class="y-border" />
            <h6>RAS User Groups</h6>
            <table>
                <tbody *ngFor="let ras of ras">
                    <tr>
                        <th>Group Name</th>
                        <th>Group IPs</th>
                    </tr>
                    <tr *ngFor="let group of ras.ras_group" [hidden]="!ras.ras_group">
                        <td>{{group.name}}</td>
                        <td>
                            <span *ngFor="let entries of group.entry; let last = last">
                                {{entries}}<span *ngIf="!last">, </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <!--
            <h6>RAS Asset Groups</h6>
            <table>
                <tbody *ngFor="let ras of ras">
                    <tr>
                        <th>Asset Name</th>
                        <th>Asset IPs</th>
                    </tr>
                    <tr *ngFor="let asset of ras.ras_asset" [hidden]="!ras.ras_asset">
                        <td>{{asset.name}}</td>
                        <td>
                            <span *ngFor="let entries of asset.entry; let last = last">
                                {{entries}}<span *ngIf="!last">, </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            -->
        </mat-tab>
        <mat-tab label="RAS Policy Rules">
            <hr class="y-border" />
            <h6>Firewall Rules</h6>
            <table>
                <tbody *ngFor="let ras of ras">
                    <tr>
                        <th>Policy</th>
                        <th>Action</th>
                        <th>Match RAS Group</th>
                        <th>Match LAN Group</th>
                        <th>Proto</th>
                        <th>Ports</th>
                        <th>Limit</th>
                        <th>Time</th>
                    </tr>
                    <tr *ngFor="let policy of ras.ras_policy" [hidden]="!ras.ras_policy">
                        <td>{{policy.name}}</td>
                        <td [ngStyle]="{'color': policy.target != 'ACCEPT' ? 'red' : '#45d645' }">
                            {{policy.target}}
                        </td>
                        <td>{{policy.group}}</td>
                        <td>{{policy.asset}}</td>
                        <td>
                            <span *ngFor="let proto of policy.proto; let last = last">
                                {{proto}}<span *ngIf="!last">, </span>
                            </span>
                        </td>
                        <td>{{policy.dest_port}}</td>
                        <td>{{policy.limit}}</td>
                        <td>
                            <span *ngIf="policy.start_time && policy.stop_time">
                                {{policy.start_time}} - {{policy.stop_time}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-tab>
    </mat-tab-group>
</div>
