import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertsReportComponent } from './alerts-report/alerts-report/alerts-report.component';
import { BillingComponent } from './billing/billing.component';
import { DeviceReportComponent } from './device-report/device-report.component'

const routes: Routes = [
  { path: '', redirectTo: 'overview', pathMatch: 'full' },
  { path: 'overview', component: DeviceReportComponent },
  { path: 'overview/:id', component: DeviceReportComponent },
  { path: 'billing', component: BillingComponent },
  { path: 'billing/:id', component: BillingComponent },
  { path: 'alerts', component: AlertsReportComponent },
  { path: 'alerts/:id', component: AlertsReportComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
