<div class="mt-2 monitor-analytics-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab [label]="label"></mat-tab>
    </mat-tab-group>
    <hr class="y-border"/>
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="_device.alertId">
        Enable
    </mat-slide-toggle>
    <div class="mb-4" *ngIf="config">
        <h6>
            General
        </h6>
        <table>
            <tr>
                <th>Remote Monitoring Status</th>
                <th>Poll Interval</th>
            </tr>
            <tr>
                <td>{{config.enabled ? 'Enabled' : 'Disabled'}}</td>
                <td>{{config.interval}}</td>
            </tr>
        </table>
    </div>

    <div class="mb-4" *ngIf="config">
        <h6>
            System
        </h6>
        <table>
            <tr>
                <th>Device Uptime</th>
                <th>Load Average</th>
                <th>CPU Utilization</th>
                <th>Memory Usage</th>
                <th>Team Stats</th>
            </tr>
            <tr>
                <td>{{config.uptime}}</td>
                <td>{{config.load_avg}}</td>
                <td>{{config.cpu_usage}}</td>
                <td>{{config.mem_usage}}</td>
                <td>{{config.team_state}}</td>
            </tr>
        </table>
    </div>

    <div class="mb-4" *ngIf="config">
        <h6>
            Links
        </h6>
        <table>
            <tr>
                <th>Link Data Transfer</th>
                <th>Link Latency</th>
                <th>Link Jitter</th>
                <th>Link Loss</th>
                <th>Link Mode</th>
                <th>Link Type</th>
                <th>Link Link Action</th>
                <th>Link State</th>
                <th>Link Bandwidth</th>
                <th>Link MOS</th>
            </tr>
            <tr>
                <td>{{config.link_xferred}}</td>
                <td>{{config.link_latency}}</td>
                <td>{{config.link_jitter}}</td>
                <td>{{config.link_loss}}</td>
                <td>{{config.link_mode}}</td>
                <td>{{config.link_type}}</td>
                <td>{{config.link_action}}</td>
                <td>{{config.link_state}}</td>
                <td>{{config.link_bandwidth}}</td>
                <td>{{config.link_mos}}</td>
            </tr>
        </table>
    </div>

    <div class="mb-4" *ngIf="notification">
        <h6>
            {{ template_notification_name ? template_notification_name : 'Notification'}}
        </h6>
        <table>
            <tr>
                <th>Enable</th>
                <th>Email Notification Recipients</th>
            </tr>
            <tr>
                <td>{{notification.enabled}}</td>
                <td>{{notification.emails}}</td>
            </tr>
        </table>
    </div>

    <div class="mb-4" *ngIf="alert">
        <table>
            <tr>
                <th>Device Status</th>
                <th>Team Status</th>
                <th>Link1 Status</th>
                <th>Link2 Status</th>
                <th>Link3 Status</th>
                <th>Email Recipients</th>
            </tr>
            <tr>
                <td>{{alert.device}}</td>
                <td>{{alert.team}}</td>
                <td>{{alert.link1}}</td>
                <td>{{alert.link2}}</td>
                <td>{{alert.link3}}</td>
                <td>{{alert.emails}}</td>
            </tr>
        </table>
    </div>
</div>