import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {FormBuilder, FormControl} from '@angular/forms';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { ApService } from 'src/app/shared/services/ap.service';
import { DialogChartContentComponent } from '../dialog-chart-content/dialog-chart-content.component';
import { Timezone } from 'src/app/shared/model/timezone'

@Component({
  selector: 'app-device-monitor-analytics',
  templateUrl: './device-monitor-analytics.component.html',
  styleUrls: ['./device-monitor-analytics.component.scss']
})

export class DeviceMonitorAnalyticsComponent implements OnInit, OnDestroy {
  mosData = [];
  bandData = [];
  linkState = [];
  linkLatency = [];
  dataUsageChart = [];
  graphData = [];
  upTime: any;
  dataUsage: any
  type = 0;
  res: any;
  _device: any;
  dpcDataArray: any[] = [];
  dpcData: any[] = [];
  vHub_mode: boolean = false;
  searchPipe = "";
  deviceForm: any;
  timezoneArray = Timezone;
  selectedTimezone = 'utc';

  @Input() set device(obj) {
    if (obj) {
      this._device = obj;
      this.vHub_mode = obj[0]?.templateConfig?.team?.config?.team1?.mode == 'VHUB' ? true : false;
      var _timezone =  this._device[0].statistics.timezone !== undefined ? this._device[0].statistics.timezone : "EST5EDT,M3.2.0,M11.1.0";
      var systemTimeZone = this.timezoneArray.filter(x=> x['tz-posix'] == _timezone);
      this.selectedTimezone = systemTimeZone[0].tz;
      this.deviceForm.controls.searchTimezone.setValue(this.selectedTimezone);
      this.loadHistoryData(0);
    }
  }
  @Input() dataSource;
  @Output() edit = new EventEmitter();
  

  private loadDataSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public apService: ApService,
    public dialog: MatDialog,
  ) { 
    this.deviceForm = this.formBuilder.group({
      searchTimezone: new FormControl(null)
    });
  }

  ngOnInit(): void {    
  }

  loadHistoryData(t) {
    if (!this._device[0]) {
      return;
    }
    this.type = t;
    let ed, sd, date_from, date_to, interval;
    if (this.type == 0) {
      ed = moment(new Date()).tz(this.selectedTimezone); // Get Time in EST time UTC -5
      sd = moment(new Date(new Date(ed).getTime() - (4 * 60 * 60 * 1000))).tz(this.selectedTimezone); 
      date_from = moment(sd).toISOString()
      date_to = moment(ed).toISOString()
      interval = 3; // Every 5 minutes
    } else if (this.type == 1) {
      ed = moment(new Date()).tz(this.selectedTimezone); // Get Time in EST time UTC -5
      sd = moment(new Date(new Date(ed).getTime() - (24 * 60 * 60 * 1000))).tz(this.selectedTimezone);
      date_from = moment(sd).toISOString()
      date_to = moment(ed).toISOString()
      interval = 12; // Every 5 minutes
    } else if (this.type == 2) {
      ed = moment(new Date()).tz(this.selectedTimezone); // Get Time in EST time UTC -5
      sd = moment(new Date(new Date(ed).getTime() - (7 * 24 * 60 * 60 * 1000))).tz(this.selectedTimezone);
      date_from = moment(sd).toISOString()
      date_to = moment(ed).toISOString()
      interval = 72; // Every 30 minutes
    } else if (this.type == 3) {
      ed = moment(new Date()).tz(this.selectedTimezone); // Get Time in EST time UTC -5
      date_from = moment(ed).startOf('month').format("YYYY-MM-DD HH:mm:ss");
      date_to = moment(ed).format("YYYY-MM-DD HH:mm:ss");
      interval = 432; // Every 6 hour
    } else if (this.type == 4) {
      ed = moment(new Date()).tz(this.selectedTimezone); // Get Time in EST time UTC -5
      date_from = moment(ed).subtract(1, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss"); 
      date_to = moment(ed).subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss");
      // date_from = moment(ed).startOf('month').subtract(1, 'months').format("YYYY-MM-DD-HH-mm-ss")
      // date_to = moment(ed).endOf('month').subtract(1, 'months').format("YYYY-MM-DD-HH-mm-ss")
      interval = 432; // Every 12 hours
    }
    if(this.type !==5){
      let data =  {
        date_from,
        date_to
      }
      this.loadDataSubscription = this.apService.getDevicesHistoryDataById(this._device[0]._id, data).subscribe(res => {
        if (!res || !res.data || !res.data.lists) {
          return;
        }
        this.res = res;
        this.graphData = res.data.lists.map(h => ({
          ...h,
          ...{ datetime: this.type == 1 ? h.datetime.split(" - ")[0].substring(h.datetime.split(" - ")[0].indexOf(" ") + 1) : h.datetime.split(" - ")[0], },
        })).filter((m, index) => {
          return index && (index % interval == 0 || index == res.data.lists.length - 1);
        })
        if (this.type == 1) {
          this.upTime = String(res.data[res.data.lists.length - 1]['Device Uptime']);
        }
        this.dataUsage = {
          "histdata": this.res.data.lists,
          "LastHistData": res[res.data.lists.length - 1]
        }
      })
    }
      
  }

  selectedTabChange(evt) {
    this.type = evt.index;
    this.loadHistoryData(this.type);
  }

  openDialog(type) {
    let interval = this.type == 0 ? 1 : this.type == 1 ? 6 : this.type == 2 ? 36 : this.type == 3 ? 72 : this.type == 4 ? 72 : 1
    let data
    if(type == 0){       
      data = this.res.histdata.map(h => ({
        ...h,
        ...{ datetime: this.type == 1 ? h.datetime.split(" - ")[0].substring(h.datetime.split(" - ")[0].indexOf(" ") + 1) : h.datetime.split(" - ")[0], },
      })).filter((m, index) => {
        return index && (index % interval == 0 || index == this.res.histdata.length - 1);
      })
    } else {       
      data = this.res.data.lists.map(h => ({
        ...h,
        ...{ datetime: this.type == 1 ? h.datetime.split(" - ")[0].substring(h.datetime.split(" - ")[0].indexOf(" ") + 1) : h.datetime.split(" - ")[0], },
      })).filter((m, index) => {
        return index && (index % interval == 0 || index == this.res.data.lists.length - 1);
      })
    }
   
    const dialogRef = this.dialog.open(DialogChartContentComponent, {
      data: {
        chartData: data,
        type: type
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  editDevice() {
    this.edit.emit();
  }

  isUsefulData() {
    return this.graphData.length && Object.keys(this.graphData[0]).length>3
  }

  onSelectionChange(event){
    if(event.value){
      this.selectedTimezone = event.value;
      this.loadHistoryData(this.type);
    }
  }

  searchFocus(){
    // setTimeout(() => {
    //   this.searchElement.nativeElement.focus();
    // }, 50);
  }
  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
