import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ApService } from 'src/app/shared/services/ap.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-devices',
  templateUrl: './pending-devices.component.html',
  styleUrls: ['./pending-devices.component.scss']
})
export class PendingDevicesComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dnetwork: any = [];
  displayedColumns: string[] = ['name', 'mac', 'email', 'domain', 'location', 'model', 'firmware', 'upTime', 'lastSeen', 'createdAt', 'Status', 'Action'];
  divicessArray: any = [];
  dataSource: any = [];
  today = new Date();
  todaysDataTime: any;
  clock: Observable<any>;
  datedifferance: any;
  cdate: any;
  status: any;
  menuObj: any;
  uptimeToString: any;

  user: any;
  domain: any;
  id: any;
  orgId: any;

  length = 0;
  pageSize = 25;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  pageIndex = 0;
  @ViewChild('paginator') paginator: MatPaginator;
  searchTxt = ""
  sortData: any;

  private loadDataSubscription: Subscription;

  constructor(public dialog: MatDialog, public apService: ApService,
    private toastr: ToastrService, private submenuService: SubMenuService,
    private router: Router, public route: ActivatedRoute,
    private topnavbarService: TopnavbarService, public userService: UserService,) {
    this.todaysDataTime = formatDate(this.today, 'hh:mm:ss', 'en-US', '+0530');
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.user = storedUser;

    this.id = this.route.snapshot.paramMap.get('id');
    this.orgId = this.id ? this.id.split("_").pop() : this.user.organization;

    this.userService.getOrganizationUsers({ id: this.user.organization }).subscribe(res => {
      res = res.data;
      this.domain = res[0];
    });

    if (this.router.url.includes("page=")) {
      this.currentPage = parseInt(this.router.url.split("page=").pop());
      this.pageIndex = this.currentPage - 1;
    }

    this.loadData();
    this.setTopNavMenu();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "",
        "name": "Pending Devices",
        "type": "text",
      },
    ])
  }


  loadData(init_p = false) {
    if (init_p) {
      this.currentPage = 1;
      this.pageIndex = 0
      if (this.paginator) 
        this.paginator.firstPage();
      if (this.router.url === "/layout/pendingDevices") {
        this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
      } else {
        this.router.navigateByUrl(this.router.url.split('?').shift() + '?page=' + this.currentPage);
      }
    }
    let data = {
      params: {
        orgId: this.orgId,
        page: this.currentPage,
        pageSize: this.pageSize,
        searchTxt: this.searchTxt,
      }
    };
    if (this.sortData && this.sortData.active) {
      data.params['sortBy'] = this.sortData.active;
      data.params['sortDirection'] = this.sortData.direction;
    }
    this.loadDataSubscription = this.apService.getPendingDevice(data).subscribe(res => {
      if (res) {
        this.divicessArray = res.data;
        this.length = res.count;
        this.divicessArray.forEach(element => {
          this.uptimeToString = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
          element.upTime = this.uptimeToString;
        });
        this.dataSource = this.divicessArray;

      } else {
        this.dataSource = [];
      }

    });
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    if (this.router.url === "/layout/pendingDevices") {
      this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
    } else {
      this.router.navigateByUrl(this.router.url.split('?').shift() + '?page=' + this.currentPage);
    }
    this.pageSize = event.pageSize;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
