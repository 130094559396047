<div class="mt-2 monitor-analytics-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="0">
        <mat-tab label="Last 4 Hrs"></mat-tab>
        <mat-tab label="Last 24 Hrs"></mat-tab>
        <mat-tab label="Last 7 Days"></mat-tab>
        <mat-tab label="This Month"></mat-tab>
        <mat-tab label="Last Month"></mat-tab>
        <!-- <mat-tab label="Overview">
            <hr class="y-border"/>
            <app-system-information-monitor *ngIf="type==5" [device]="_device"></app-system-information-monitor>
        </mat-tab> -->
    </mat-tab-group>
    <hr class="y-border" *ngIf="type!=5"/>
    <div class="col-md-12" *ngIf="type!=5">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2">
                    <app-device-data-usage [dataUsage]="dataUsage" [device]="_device" 
                        [model]="dataSource.data[0] && dataSource.data[0].deviceMetaInfo.deviceModel" (edit)="editDevice()">
                    </app-device-data-usage>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-2">
                    <form [formGroup]="deviceForm">
                        <fieldset>
                            <mat-form-field>
                                <mat-label>Select Timezone</mat-label>
                                <mat-select formControlName="searchTimezone" (click)="searchFocus()"
                                    (selectionChange)="onSelectionChange($event)">
                                    <div class="dropdown-search">
                                        <input #tzinput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}"
                                            matInput placeholder="Search Timezone">
                                    </div>
                                    <div>
                                        <mat-option *ngFor="let tz of timezoneArray | searchSelect : searchPipe"
                                            [value]="tz.tz">
                                            {{ tz.name }}, {{ tz['tz-posix'] }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </fieldset>
                    </form>
                </div>
                
            </div>
            <div class="col-md-6 col-sm-12 mb-2" *ngIf="graphData.length && isUsefulData()">                               
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(1)">
                    <app-device-bandwidth [bandData]="graphData"></app-device-bandwidth>
                </mat-card>
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(4)">
                    <app-device-link-state [linkState]="graphData"></app-device-link-state>
                </mat-card>
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(6)" >
                    <app-device-uptime [upTime]="graphData"></app-device-uptime>
                </mat-card>     
            </div>
            <div class="col-md-6 col-sm-12 mb-2" *ngIf="graphData.length && isUsefulData()">
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(3)">
                    <app-device-mos [mosData]="graphData"></app-device-mos>
                </mat-card>
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(2)">
                    <app-device-link-latency [linkLatency]="graphData"></app-device-link-latency>
                </mat-card>
                <mat-card class="mb-2 cursor" (dblclick)="openDialog(5)">
                    <app-device-data-usage-chart [dataUsageChart]="graphData"></app-device-data-usage-chart>
                </mat-card>    
            </div>           
        </div>
    </div>
</div>