import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-sipwatch-alert-logs',
  templateUrl: './device-sipwatch-alert-logs.component.html',
  styleUrls: ['./device-sipwatch-alert-logs.component.scss']
})
export class DeviceSipwatchAlertLogsComponent implements OnInit {
  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
    }
  }
  @Input() selfSort: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  _dataSource: MatTableDataSource<any>; 
  displayedColumns: string[] = ["date" ,"cfg", "SIPName", "SIPServerPort", 'alert', 'alarm', 'ptrack'];
  dynamicColumns: string [] = []
  _device: any = []
  deviceId = ""
  alertLogs = []

  currentPage = 1;
  page = 0
  pageSize = 10
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  
  constructor(
    public apService: ApService,
    public route: ActivatedRoute,
  ) {
    this._dataSource = new MatTableDataSource<any>();
   }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('device_id');
    this.loadData()
  }

  displayAlarm(e){
    if(e === 1) {
      return "ALARM"
    }
    return "OFF"
  }
  displayAlert(e){
    if(e === 1) {
      return "ON"
    }
    return "OFF"
  }
  displayDate(e){   
    if(e.watchlist_alert.ts){
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      return d.setUTCSeconds(e.watchlist_alert.ts);
      // return new Date(e.watchlist_alert.ts)
      
    }
    if(e?.createdDate){
      return new Date(e.createdDate)
    }
  }
  
  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadData()
  }

  async loadData(){
    const request =   {
      deviceId: this.deviceId,
      page: this.currentPage,
      pageSize: this.pageSize
    }
    await this.apService.getSipwatchAlerts(request).subscribe(res=>{
      this.alertLogs = []  
      this.length = res.count
      this._dataSource.data = res.data
    })
    
  }

  sortData(sort: Sort) {
    if (this.selfSort) {
      const data  = []
      // const data = this.trackStatus.slice();
      // if (!sort.active || sort.direction === '') {
      //   this._dataSource.data = data;
      //   return;
      // }

      // function compare(a: number | string, b: number | string, isAsc: boolean) {
      //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      // }

      // this._dataSource.data = data.sort((a, b) => {
      //   const isAsc = sort.direction === 'asc';
      //   switch (sort.active) {
      //     case 'name': return compare(a.name, b.name, isAsc);
      //     case 'mac': return compare(a.mac, b.mac, isAsc);
      //     case 'msp': return compare(a.msp, b.msp, isAsc);
      //     case 'domain': return compare(a.domain, b.domain, isAsc);
      //     case 'location': return compare(a.location, b.location, isAsc);
      //     case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
      //     case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
      //     case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
      //     case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
      //     case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
      //     case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
      //     case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
      //     // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
      //     default: return 0;
      //   }
      // });
    }
  }
}
