import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TemplateService } from 'src/app/shared/services/template.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qos',
  templateUrl: './qos.component.html',
  styleUrls: ['./qos.component.scss']
})
export class QosComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'orgId', 'action'];
  qosArray: any = [];
  filteredOrg: any = [];
  searchTxt = ""
  allData = [];

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  orgs = [];
  role: any;
  user: any;
  orgId: any;

  @ViewChild(MatSort) sort: MatSort;

  private loadDataSubscription: Subscription;

  constructor(
    private topnavbarService: TopnavbarService,
    public userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private templateService: TemplateService,
    public route: ActivatedRoute,
  ) {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;
    let id = this.route.snapshot.paramMap.get('id');
    this.orgId = id ? id.split('_').pop() : this.user.organization;

    this.loadData();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "/layout/qos",
        "name": "QOS",
        "type": "text",
      },
    ])
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.loadDataSubscription = this.templateService.getQosofOrg(this.orgId).subscribe(res => {
      if (res) {
        this.qosArray = res;
        this.dataSource.data = this.qosArray;
        this.allData = this.qosArray;
      } else {
        this.dataSource.data = [];
      }
      this.setTopNavMenu();
    });

    this.userService.getOrganization({ id: this.orgId }).subscribe(res => {
      res = res.data[0];
      if (res) {
        let domain = res;
        this.filteredOrg = this.getDomainOrg(domain);
      }
    });
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ id: domain._id, domainOwnerId: domain.owner, name: domain.name, domain: domain.domain, prtg: domain.prtg, prtgDeviceId: domain.prtgDeviceId, prtgOrgId: domain.prtgOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  searchData() {
    this.dataSource.data = this.allData.filter(element => {
      if (element['name'].toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (this.findOrgName(element['orgId']).toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
  }

  findOrgName(orgId) {
    for (let i = 0; i < this.filteredOrg.length; i++) {
      let organization = this.filteredOrg[i];
      if (organization['id'] == orgId) {
        return organization['name']
      }
    }
  }

  openDialog() {
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.loadData();
  }

  edit(element) {
  }

  delete(element) {
    if (confirm('Are you sure you want to delete?')) {
      const qos = [];
      qos.push(element._id);
      this.templateService.removeQos(qos).subscribe(res => {
        if (res.subDevices && res.subDevices.length > 0) {
          this.toastr.error('Template contains active devices. Please remove them first.');
          return;
        }
        this.toastr.success('Qos template deleted successfully');
        this.loadData();
      })
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
