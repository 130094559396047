import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchSubnet'
})
export class searchSubnetPipe implements PipeTransform {

  transform(ipList: any[], searchPipe: string): any[] {
    if(!ipList || !ipList.length) return ipList;
    if(!searchPipe || !searchPipe.length) return ipList;
    return ipList.filter(ip => {
      return ip == searchPipe
    });
  }

}
