import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-chart-content',
  templateUrl: './dialog-chart-content.component.html',
  styleUrls: ['./dialog-chart-content.component.scss']
})
export class DialogChartContentComponent implements OnInit {
  mosData = [];
  bandData = [];
  linkState = [];
  linkLatency = [];
  dataUsageChart = [];
  uptime=[];
  type = 1;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.type = this.data.type;
    if (this.type == 1) {
      this.bandData = this.data.chartData;
    } else if (this.type == 2) {
      this.linkLatency = this.data.chartData;
    } else if (this.type == 3) {
      this.mosData = this.data.chartData;
    } else if (this.type == 4) {
      this.linkState = this.data.chartData;
    } else if (this.type == 5) {
      this.dataUsageChart = this.data.chartData;
    } else if (this.type == 6) {
      this.uptime = this.data.chartData;
    }
  }

}
