import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-sipwatch-callerid-logs',
  templateUrl: './device-sipwatch-callerid-logs.component.html',
  styleUrls: ['./device-sipwatch-callerid-logs.component.scss']
})
export class DeviceSipwatchCalleridLogsComponent implements OnInit {
  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
    }
  }
  @Input() selfSort: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  _dataSource: MatTableDataSource<any>; 
  displayedColumns: string[] = ["SIPId","line_num" ,"event", "datetime", "from", 'phoneNumber', 'destination'];
  dynamicColumns: string [] = []
  _device: any = []
  deviceId = ""

  currentPage = 1;
  page = 0
  pageSize = 10
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;
  
  constructor(
    public apService: ApService,
    public route: ActivatedRoute,
  ) {
    this._dataSource = new MatTableDataSource<any>();
   }

  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('device_id');
    this.loadData()
  }
  displayDate(e){
    if(e){
      return moment.unix(e).tz('America/New_York').format('ddd MMM D YYYY HH:mm:ss')//'ddd MMM D HH:mm:ss zz YYYY'
    }
    return ""
  }
  displayEvent(e){
    if(e === 1){
      return "Start"
    } else if(e ===2) {
      return "Ring"
    }  else if(e ===3) {
      return "Answer"
    } else if(e ===4) {
      return "Expire"
    } else if(e ===5) {
      return "Term"
    } else if(e ===0) {
      return "End"
    } else if(e ===255) {
      return "Start but license limit"
    } else if(e ===256) {
      return "Stop but license limit"
    }
    return ""
  }
  getFrom(e){
    if(e){
      const val = e.split("<")
      return val[0]
    }
  }

  getPhoneNumber(e){
    if(e){
      const val = e.split("<")
      if(val[1]){
        return val[1].split(":")[1].split("@")[0]
      }      
    }
  }

  getSipExt(e){
    if(e){
      const val = e.split("<") 
      if(val[1]){
        return val[1].split(":")[1].split("@")[0]
      }      
    }
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadData()
  }

  async loadData(){
    const request =   {
      deviceId: this.deviceId,
      page: this.currentPage,
      pageSize: this.pageSize
    }
    await this.apService.getSipwatchCallerIdHist(request).subscribe(res=>{
      this.length = res.count
      this._dataSource.data = res.data
    })
    
  }

  sortData(sort: Sort) {
    if (this.selfSort) {
      const data  = []
      // const data = this.trackStatus.slice();
      // if (!sort.active || sort.direction === '') {
      //   this._dataSource.data = data;
      //   return;
      // }

      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

      this._dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return compare(a.name, b.name, isAsc);
          case 'mac': return compare(a.mac, b.mac, isAsc);
          case 'msp': return compare(a.msp, b.msp, isAsc);
          case 'domain': return compare(a.domain, b.domain, isAsc);
          case 'location': return compare(a.location, b.location, isAsc);
          case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
          case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
          case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
          case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
          case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
          case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
          case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
          // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
          default: return 0;
        }
      });
    }
  }
}
