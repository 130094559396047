<form [formGroup]="userForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Create User</legend>
        <div mat-dialog-content>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" autocomplete="off"/>

                <mat-error *ngIf="userForm.controls['name'].hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" />

                <mat-error *ngIf="userForm.controls['email'].hasError('required')">
                    Email Address is required
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input [type]="hide1 ? 'password' : 'text'" matInput formControlName="password" autocomplete="off"/>
                <mat-icon class="visibility-icon" matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="userForm.controls['password'].hasError('required')">
                    Password is required
                </mat-error>
                <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
                    Password length must be not less than 6
                </mat-error>
                <mat-error *ngIf="userForm.controls['password'].hasError('pattern') && !userForm.controls['password'].hasError('minlength')">
                    Password must contain at least one uppercase, one lowercase, one special character, and one number
                </mat-error>
            </mat-form-field>
            <mat-form-field [ngClass]="{'mt-5': userForm.controls['password'].hasError('pattern')}">
                <mat-label>Confirm Password</mat-label>
                <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="Confirm password" formControlName="confirmpassword" />
                <mat-icon class="visibility-icon" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="userForm.controls['confirmpassword'].hasError('mustMatch')"> Password must match
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Select Organization</mat-label>
                <mat-select formControlName="parent" (click)="searchFocus()" (selectionChange)="onOrgChange($event)"
                    [(ngModel)]="selected">
                    <div class="dropdown-search">
                        <input #orginput [(ngModel)]="searchOrgPipe" [ngModelOptions]="{standalone: true}" matInput
                            placeholder="Search Domain">
                    </div>
                    <div>
                        <mat-option *ngFor="let organization of this.filteredOrg | searchOrg : searchOrgPipe"
                            [value]="organization.orgId">
                            {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                            == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                            "Organization": "") }})
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>User Role</mat-label>
                <mat-select formControlName="role">
                    <mat-option [value]="1">
                        Admin
                    </mat-option>
                    <mat-option [value]="0">
                        User
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.controls['role'].hasError('required')">
                    Role is required
                </mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()">Save</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>