import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MustMatch } from 'src/app/shared/code/password-confirm';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  register: any;
  hide1: boolean = true;
  hide2: boolean = true;

  constructor(private formBuilder: FormBuilder, public Registrationservices: RegistrationService,
              private toastr: ToastrService, private router: Router) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      repass: new FormControl(''),
    }, {
      validator: MustMatch('password', 'repass')
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    } else {
      this.register = {
        name: this.registerForm.get('name').value,
        email: this.registerForm.get('email').value,
        password: this.registerForm.get('password').value,
      };
      this.Registrationservices.registration(this.register).subscribe(res => {
        if (res.token) {
          this.toastr.success('Registration success!!!');
          this.router.navigate(['/login']);
        }
      }, (err => {
        err.status = 400;
        this.toastr.error(err.error.error);
      }));
    }
  }
}
