import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ApService } from 'src/app/shared/services/ap.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  userForm: FormGroup;
  submitted = false;
  device = [];
  monitors = [];
  wnetworks = [];
  qosList = [];
  teamList = [];
  i4glteList = [];
  tprofileList = [];
  orgDialogData: any;
  userId: any;
  user: any;
  organization: any;
  role: any;
  isRootUser: boolean;
  changePassword: boolean = false;
  pendingList = [];
  filteredOrg = [];
  searchPipe: any;
  searchOrgPipe: any;

  orgType = [{
    name: "Master MSP",
    id: 1
  },
  {
    name: "MSP",
    id: 2
  },
  {
    name: "Organization",
    id: 3
  }]

  updateProfilevalue: any;

  @ViewChild('orginput') searchElement: ElementRef;

  selected: any;
  orgId: any;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public apService: ApService,
    private toastr: ToastrService,
    private router: Router,
    public profileService: ProfileService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let storedUser = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.isRootUser = storedUser.role == 0;
    this.user = storedUser;
    this.orgId = this.user.organization;
    this.selected = this.orgId;

    this.userService.getOrganization({id: this.user.organization}).subscribe(res => {
      this.filteredOrg = this.getDomainOrg(res.data[0])
    })

    
    if (this.data && this.data._id) {
      this.orgDialogData = this.data;
      setTimeout(() => {
        this.userId = this.orgDialogData._id;

        if(this.orgDialogData.role == 5){
          this.role = 0
        } else {
          this.role = 1
        }
      }, 850);
    }
    
    this.userForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      organization: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required])
    });
    
    setTimeout(() => {
      if (this.userId) {
        this.userForm.controls.name.setValue(this.orgDialogData.name);
        this.userForm.controls.email.setValue(this.orgDialogData.email);
        this.userForm.controls.organization.setValue(this.orgDialogData.organization);
        this.userForm.controls.role.setValue(this.role);
      }
    }, 1000);
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, role: domain.role })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  onSubmit() {
    if (this.submitted) return;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    } else {
      let email = this.userForm.get('email').value;
      let name = this.userForm.get('name').value;
      let orgId = this.userForm.get('organization').value;
      let role = this.userForm.get('role').value;
      let _org = this.filteredOrg.filter(function (obj) { return obj['orgId'] == orgId })
      let profile = {
        id: this.userId,
        name: name,
        email: email,
        organization: orgId,
        role: (role && _org[0]) ? _org[0].role : 5, // 5 is User
      };
      this.profileService.profile(profile).subscribe(res => {
        this.toastr.success('User "' + name + '" update success');
        this.dialogRef.close();
      }, (err) => {
        this.toastr.error(err.error.error);
      });
    }
  }

}
