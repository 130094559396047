<div>
    <mat-card-content>
        <div class="text-right">
            <mat-form-field class="example-form-field">
                <mat-label>Search Here ...</mat-label>
                <input matInput type="text" [(ngModel)]="searchTxt" (keyup.enter)="loadData(true)" autocomplete="off">
                <button mat-button *ngIf="searchTxt" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchTxt='';loadData();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <app-device-table [dataSource]="dataSource" (refresh)="loadData()" [pending]="true" (sortChange)="sortData=$event;loadData()"></app-device-table>

        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex" (page)="pageEvent = $event" (page)="handlePageEvent($event)" *ngIf="length">
        </mat-paginator>
    </mat-card-content>
</div>