import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {
  createFirmware$: any;
  getFirmware$: any;
  removeFirmware$: any;

  constructor(private http: HttpClient) { }

  createFirmware(data): Observable<any> {
    return this.http.post(environment.endpoints.firmware, data)
  }

  getFirmware(): Observable<any> {
    return this.http.get(environment.endpoints.firmware)
  }

  removeFirmware(_id): Observable<any> {
    return this.http.delete(environment.endpoints.firmware + "/" + _id)
  }
}
