import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchSelect'
})
export class SearchSelectPipe implements PipeTransform {

  transform(value: any, searchPipe: string): any {
    if(!value || !value.length) return value;
    if(!searchPipe || !searchPipe.length) return value;

    return value.filter(val => {
      if(val.name !== undefined){
        if(val.name.toLowerCase().includes(searchPipe.toLowerCase())){
          return val.name.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1 
        }
      }
    });
  }

}
