<div>
  <mat-card-content>
    <div class="text-right">
      <div class="nav-btn add-btn" *ngIf="role<=3">
        <button mat-button class="assign-device add-btn" [routerLink]="['/layout/wireless-network/add']">
          <mat-icon>add</mat-icon> Add
        </button>
      </div>

      <mat-form-field class="example-form-field">
        <mat-label>Search Here ...</mat-label>
        <input matInput type="text" [(ngModel)]="searchTxt" (keyup.enter)="searchData()" autocomplete="off">
        <button mat-button *ngIf="searchTxt" matSuffix mat-icon-button aria-label="Clear"
          (click)="searchTxt='';searchData();">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="w-100 overflow-auto">
      <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element" class="pointer">
            <a class="table-link" [routerLink]="['/layout/wireless-network/'+element.name+'_'+element._id]"
              routerLinkActive="router-link-active">
              {{ element.name }}
            </a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="orgId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
          <td mat-cell *matCellDef="let element">
            <a class="table-link" [routerLink]="['/layout/org/'+findOrg(element.orgId)+'_'+element.orgId+'/wireless-network']"
              routerLinkActive="router-link-active">
              {{findOrg(element.orgId)}}
            </a>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="bg-color">Action</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <a class="table-link" [routerLink]="['/layout/wireless-network/'+element.name+'_'+element._id]"
                routerLinkActive="router-link-active" *ngIf="role<=3">
                <mat-icon class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                  matTooltip="Edit" role="img" aria-hidden="true">edit</mat-icon>
              </a>
              <a class="table-link" [routerLink]="['/layout/wireless-network/'+element.name+'_'+element._id+'/devices']"
                routerLinkActive="router-link-active">
                <mat-icon class="pointer" matTooltip="View Devices" role="img" aria-hidden="true">device_hub
                </mat-icon>
              </a>
              <a class="table-link" [routerLink]="['/layout/wireless-network/add']" [queryParams]="{ template: element._id }"
                  routerLinkActive="router-link-active">
                  <mat-icon class="pointer" matTooltip="Clone Template" role="img" aria-hidden="true" *ngIf="role<=3">content_copy
                  </mat-icon>
              </a>
              <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true" (click)="delete(element)"
                *ngIf="role<=3">
                restore_from_trash</mat-icon>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
</div>