import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, NavigationEnd, Event, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApModelComponent } from "src/app/pages/device/ap-model/ap-model.component";
import { OrganizationModelComponent } from "src/app/pages/organization/organization-model/organization-model.component";
import { EditUserComponent } from "src/app/pages/user/edit-user/edit-user.component";
import { UserModelComponent } from "src/app/pages/user/user-model/user-model.component";
import { UserConstant } from "../../constants/user-constant";
import { ApService } from "../../services/ap.service";
import { SubMenuService } from "../../services/sub-menu.service";
import { TopnavbarService } from "../../services/topnavbar.service";
import { UserService } from "../../services/user.service";


@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'email', 'organization', 'orgType', 'role', 'status', 'action'];
  col: string;
  dir: string;
  url: string[];
  @Input() set dataSource(data) {
    this.allData = data;
    if(this.router.url.includes("page=")){
      this.currentPage = parseInt(this.router.url.split("page=").pop());
      this.pageIndex = this.currentPage;
      if (this.paginator)
        this.paginator.pageIndex = this.currentPage - 1;
    }
    this._dataSource.data = this.allData;
    this.length = data.length;
  };
  // @Input() orgId: any;
  @Input() selfSort: any;
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();

  _dataSource: MatTableDataSource<any>;
  id: any;
  orgId: string;
  orgName: string;
  role: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 25;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  pageIndex = 0;
  searchTxt = "";
  allData:any = [];
  orgType = [
    "Master MSP", "MSP", "Organization", "", "Admin", "User"
  ]
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog, public userService: UserService, public apService: ApService, public route: ActivatedRoute,
    private toastr: ToastrService, private submenuService: SubMenuService, private topnavbarService: TopnavbarService,
    private router: Router, private changeDetection: ChangeDetectorRef) {
      this._dataSource = new MatTableDataSource<any>();
  }


  dtOptions: any = {};

  ngOnInit(): void {
    this.orgId = this.route.snapshot.paramMap.get('id');
    if (this.orgId) {
      this.orgId = this.orgId.split("_").pop()
    }

    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    if(this.router.url.includes("page=")){
      this.currentPage = parseInt(this.router.url.split("page=").pop());
    }
  }

  ngAfterViewInit() {
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = this.url[0].split("=").pop()
        this.col = this.url[1].split("=").pop()
        this.dir = this.url[2].split("=").pop()
      } else {
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = this.url[0].split("=").pop()
        this.dir = this.url[1].split("=").pop()
      }
      if(this.dir == 'asc'){
        this.sortData({active: `${this.col}`, direction: 'asc'})
      } else if (this.dir == 'desc'){
        this.sortData({active: `${this.col}`, direction: 'desc'})
      }
    } else {
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,1)
        this.searchTxt = this.url[0].split("=").pop()
      }
    }

    if (this.selfSort) {
      this.paginator.length = this.length;
      this.paginator.pageIndex =  this.currentPage;
      this.paginator.pageSize = this.pageSize;
      // Start: sort columns by URL parameters
      if(this.router.url.includes("column")){
        this.sort.active = this.col;
        if(this.dir == 'asc'){
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
          } else {
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&column='+this.col+'&order=asc');
          }
          this.sort.direction = 'asc';
        } else if(this.dir == 'desc') {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
          } else {
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&column='+this.col+'&order=desc');
          }
          this.sort.direction = 'desc'
        } else {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt);
          } else {
            this.router.navigateByUrl('/layout/users');
          }
          this.sort.direction = '';
        }
      }
      // End: sort columns by URL parameters
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
    this.changeDetection.detectChanges();
  }

  edit(objData) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh.emit()
    });
  }

  shadowUser(user) {
    this.userService.getAccessToken(user.email).subscribe(res => {
      const userobject = JSON.parse(localStorage.getItem(UserConstant.USER));
      userobject.name = user.name;
      userobject.email = user.email;
      userobject.organization = user.organization;
      userobject.role = user.role;
      localStorage.setItem(UserConstant.USER, JSON.stringify(userobject));
      localStorage.setItem(UserConstant.TOKEN, res.token)
      window.location.reload()
    }, (err) => {
      this.toastr.error('Unable to shadow this user');
    })
  }

  getUserLink() {
    if (this.orgId) {
      return '/layout/users/' + this.orgId;
    } else {
      return '/layout/users'
    }
  }

  getDeviceLink() {
    if (this.orgId) {
      return '/layout/org/' + this.orgId + '/devices';
    } else {
      return '/layout/devices'
    }
  }

  getDomainLink() {
    if (this.orgId) {
      let url = '/layout/users/' + this.orgId;
      return url;
    } else {
      return '/layout/users'
    }
  }

  addUserClick() {
    let objData = {};
    objData['orgId'] = this.orgId;
    objData['role'] = this.domain.role;
    objData['domain'] = this.domain;
    const dialogRef = this.dialog.open(UserModelComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  addDeviceClick() {
    const dialogRef = this.dialog.open(ApModelComponent, {
      disableClose: true,
      width: '700px',
      data: {
        domain: this.domain.domain,
        domainObj: this.domain
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  addDomainClick(): void {
    const dialogRef = this.dialog.open(OrganizationModelComponent, {
      disableClose: true,
      width: '350px',
      data: { role: this.domain.role, domain: this.domain }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  networkClick(id) {
    const url = '/#/layout/users/' + id + '/wireless-network';
    location.href = url;

  }

  delete(id) {
    if (confirm('Are you sure you want to delete?')) {
      let obj = {
        id: id
      }
      this.userService.deleteUser(obj).subscribe(res => {
        this.toastr.success('User deleted successfully');
        this.refresh.emit()
      });
    }
  }

  ngOnDestroy() {
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;

    // Succeeding code are for the URL parameters on click next/previous page
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
        this.col = url[1].split("=").pop()
        this.dir = url[2].split("=").pop()
      } else {
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = url[0].split("=").pop()
        this.dir = url[1].split("=").pop()
      }
    } else {
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
      }
    }

    if (this.router.url === "/layout/users") {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
        }
      }
    } else {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page=' + this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url.split('?').shift() + '?page=' + this.currentPage);
        }
      }
    }
  }

  sortDefault(){
    this.sort.direction = 'asc';
    this.sort.active = "organization";
    this._dataSource.sort = this.sort;
    this.changeDetection.markForCheck();
  }

  sortData(sort: Sort) {
    // Succeeding code are for the URL parameters on click on the sortable columns
    this.col = sort.active;
    if(this.router.url.includes("page=")){
      if(this.router.url.includes("column")){
        if(sort.direction == 'asc'){
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
          } else {
            this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+sort.active+'&order=asc');
          }          
          this.dir = 'asc';
        } else if(sort.direction == 'desc') {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+sort.active+'&order=desc');
          } else {
           this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&column='+sort.active+'&order=desc');
          }
          this.dir = 'desc';
        } else {
          if(this.router.url.includes("page=1")){
            if(this.router.url.includes("search=")){
              if(this.searchTxt && this.searchTxt.length > 0){
                this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt);
              } else {
                this.router.navigateByUrl("/layout/users")
              }
            } else {
              this.router.navigateByUrl("/layout/users")
            }
          } else {
            this.router.navigateByUrl('/layout/users?page='+this.currentPage);
          }
        }
      } else {
        if(sort.direction == 'asc'){
          this.router.navigateByUrl(this.router.url+'&column='+sort.active+'&order=asc');
          this.dir = 'asc';
        } else if(sort.direction == 'desc') {
          this.router.navigateByUrl(this.router.url+'&column='+sort.active+'&order=desc');
          this.dir = 'desc';
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    } else {
      if(sort.direction == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
        } else {
          this.router.navigateByUrl(this.router.url+'?page=1&column='+sort.active+'&order=asc');
        }
        this.dir = 'asc';
      } else if(sort.direction == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
        } else {
          this.router.navigateByUrl(this.router.url+'?page=1&column='+sort.active+'&order=desc');
        }
        this.dir = 'desc';
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    }
  }

}
