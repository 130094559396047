import { Component, OnInit, Inject, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { MatHorizontalStepper, MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { forkJoin, Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';
import IPCIDR from 'ip-cidr';
import { overlay_subnet_type } from 'src/app/shared/model/vce-vhub';

@Component({
  selector: 'app-vhub-vce-modal',
  templateUrl: './vhub-vce-modal.component.html',
  styleUrls: ['./vhub-vce-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class VhubVceModalComponent implements OnInit, OnDestroy {
  vCEvHUBForm: FormGroup;
  submitted = false;
  selectedIndex = 0;

  searchVceMacPipe: any;
  @ViewChild('matVceInput') searchVceMacElement: ElementRef;
  searchVhubMacPipe: any;
  @ViewChild('matVhubInput') searchVhubMacElement: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  private loadDataSubscription: Subscription;
  role: any;
  user: any;
  orgId: any;
  deviceList: any = [];

  mode: any;

  vce_mac: any;
  vce_org: any;
  allOrgList: any = [];
  vhub_location: any;

  vHubDeviceList: any = [];
  vCE_devices: any = [];
  dpcList: any = [];
  overlay_subnet_type = overlay_subnet_type;
  overlay_ips = [{
    subnet_type: overlay_subnet_type[0].value,
    subnet_ip: null
  }];
  overlay_subnets = []
  customPatterns = { 'A': { pattern: new RegExp(/^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$/) } };
  searchSubnetPipe: any;
  @ViewChild('matSubnetInput') searchSubnetElement: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    public apservices: ApService,
    private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<VhubVceModalComponent>,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private vceVhubService: VceVhubService
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;
    this.mode = this.data?.templateConfig?.team?.config?.team1?.mode;

    this.vCEvHUBForm = this.formBuilder.group({
      vce_mac: new FormControl('', [Validators.required]),
      vhub_mac: new FormControl('', [Validators.required]),
      vhub_dpc: new FormControl('', [Validators.required]),
      keepalive: new FormControl(10, [Validators.required]),
      dpc_ips: new FormControl(''),
      dmz_ips: new FormControl(''),
      subnet_ips: new FormControl(''),
      pub_key: new FormControl('', [Validators.required]),
      priv_key: new FormControl('', [Validators.required]),
      psk_key: new FormControl('', [Validators.required]),
      vce_hostname: new FormControl('', [Validators.required]),
      dpc_public_key: new FormControl(''),
      dpc_vpn_ip: new FormControl(''),
      mtu: new FormControl(''),
      endpoint_host: new FormControl(''),
      endpoint_port: new FormControl(''),
    });
    this.loadData();
  }

  loadData() {
    let data = {
      params: {
        id: this.user.organization,
        type: 'overlay'
      }
    };
    let obj = {
      id: this.user.organization,
    }
    this.loadDataSubscription = forkJoin([
      this.userService.getOrganization(obj),
      this.apservices.getListing(data),
      this.vceVhubService.getRelatedDevices(this.user.organization)
    ]).subscribe(results => {
      try {
        this.allOrgList = this.getDomainOrgs(results[0].data[0]);
        let allDeviceList = results[1].data.map(device => {
          device.orgName = this.getOrgName(device.orgId)
          return device
        });
        this.vHubDeviceList = allDeviceList.filter(device => {
          return device.templateConfig?.team?.config?.team1?.mode == 'VHUB'
        })
        this.deviceList = allDeviceList.filter(device => {
          return device.templateConfig?.team?.config?.team1?.mode != 'VHUB'
        })
        if (results[2] && results[2].length) {
          results[2].forEach(device => {
            if (!this.vHubDeviceList.find(d => d._id == device._id)) {
              device.orgName = this.getOrgName(device.orgId)
              this.vHubDeviceList.push(device);
            }
          });
        }
        if (this.mode == 'VHUB') {
          this.vCEvHUBForm.controls.vhub_mac.setValue(this.data.mac);
          this.dpcList = this.vHubDeviceList.filter(d => d.mac == this.data.mac)[0]['statistics']['wireguard']
          this.vceVhubService.loadVceConnection(this.data.mac).subscribe(res => {
            this.vCE_devices = [...res.data]
          })
        } else if (this.mode == 'VCE') {
          this.vCEvHUBForm.controls.vce_mac.setValue(this.data.mac);
          this.vce_mac = this.data.mac;
          this.vce_org = this.deviceList.filter(d => d.mac == this.data.mac)[0].orgName
          this.vCEvHUBForm.controls.vce_hostname.setValue(this.deviceList.filter(d => d.mac == this.data.mac)[0]['name']);
        }
      } catch (e) {
        console.log(e);
      }
    })
  }

  onStepChange(event) {
    if (event) {
      this.selectedIndex = event.selectedIndex;
    }
  }

  onNext() {
    this.stepper.selectedIndex += 1;
  }

  onSubmit() {
    if (this.submitted) return;
    if (this.overlay_ips.length == 0) {
      this.vCEvHUBForm.controls.dpc_ips.setValue(null);
      this.vCEvHUBForm.controls.dmz_ips.setValue(null);
      this.vCEvHUBForm.controls.subnet_ips.setValue(null);
      this.stepper.selectedIndex = 2;
    }
    this.vCEvHUBForm.controls.dpc_ips.setValue(this.overlay_ips.filter(o => o.subnet_type == overlay_subnet_type[0].value).map(o => o.subnet_ip+"/32").join(" "))
    this.vCEvHUBForm.controls.dmz_ips.setValue(this.overlay_ips.filter(o => o.subnet_type == overlay_subnet_type[1].value).map(o => o.subnet_ip).join(" "))
    this.vCEvHUBForm.controls.subnet_ips.setValue(this.overlay_ips.filter(o => o.subnet_type == overlay_subnet_type[2].value).map(o => o.subnet_ip).join(" "))
    if (this.vCEvHUBForm.controls.vce_hostname.invalid) {
      this.toastr.error('vHub hostname cannot be null, please choose another peer!');
      return;
    }
    this.submitted = true;
    this.vceVhubService.createVceVhub(this.vCEvHUBForm.value).subscribe(res => {
      this.submitted = false;
      this.toastr.success('vCE-vHub connection is updated Successfully!');
      this.dialogRef.close();
    }, (err) => {
      this.submitted = false;
      this.toastr.error(err.error.error);
    })
  }

  searchVceMacFocus() {
    setTimeout(() => {
      this.searchVceMacElement.nativeElement.focus();
    }, 50);
  }

  searchVhubMacFocus() {
    setTimeout(() => {
      this.searchVhubMacElement.nativeElement.focus();
    }, 50);
  }

  searchSubnetFocus() {
    setTimeout(() => {
      this.searchSubnetElement.nativeElement.focus();
    }, 50);
  }

  addSubnetGroup() {
    this.overlay_ips.push({
      subnet_type: 'dpc_ip',
      subnet_ip: null
    })
  }

  removeSubnetGroup() {
    this.overlay_ips.pop()
  }

  generateKey() {
    this.vceVhubService.generateWGKey().subscribe(res => {
      this.vCEvHUBForm.controls.pub_key.setValue(res.publicKey)
      this.vCEvHUBForm.controls.priv_key.setValue(res.privateKey)
      this.vCEvHUBForm.controls.psk_key.setValue(res.preSharedKey)
    })
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role, parentOrganization: domain.parentOrganization })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  getOrgName(orgId) {
    let org = this.allOrgList.filter(o => o.orgId == orgId)
    if (org && org[0] && org[0].role) {
      if (org[0].parentOrganization) {
        let parent_name = this.getOrgName(org[0].parentOrganization)
        if (parent_name) {
          return parent_name + '/' + org[0].name
        } else {
          return org[0].name
        }
      } else {
        return org[0].name
      }
    } else {
      return null
    }
  }

  onVceMacChange(evt) {
    try {
      this.vce_mac = evt.value;
      this.vce_org = this.deviceList.filter(d => d.mac == evt.value)[0].orgName
      this.vCEvHUBForm.controls.vce_hostname.setValue(this.deviceList.filter(d => d.mac == evt.value)[0]['name']);
      this.vhub_location = this.getVhubLocation();
    } catch (e) {
      console.log(e)
    }
  }

  onVHubMacChange(evt) {
    try {
      this.dpcList = this.vHubDeviceList.filter(d => d.mac == evt.value)[0]['statistics']['wireguard']
      this.vceVhubService.loadVceConnection(evt.value).subscribe(res => {
        this.vCE_devices = [...res.data]
      })
      this.vhub_location = this.getVhubLocation();
    } catch (e) {
      console.log(e)
    }
  }

  onDPCChange(evt) {
    try {
      let wg = this.dpcList[evt.value]
      const cidr = new IPCIDR(wg.addresses)
      var old_ips = [];
      for (let i = 0; i < this.vCE_devices.length; i++) {
        if (this.vCE_devices[i].peer_vpn_ip) {
          old_ips.push(this.vCE_devices[i].peer_vpn_ip)
        }
      }
      this.overlay_subnets = cidr.toArray().map(v => ({ ip: v })).filter(v => {
        if (v.ip.split('.').pop() == '0') {
          return false;
        } else if (v.ip.split('.').pop() == '255') {
          return false;
        }
        for (let i = 0; i < old_ips.length; i++) {
          if (old_ips[i].indexOf(v.ip) > -1) {
            return false;
          }
        }
        return true;
      })
      this.vCEvHUBForm.controls.endpoint_host.setValue(wg['vhub_endpoint']);
      this.vCEvHUBForm.controls.endpoint_port.setValue(wg['listen_port']);
      this.vCEvHUBForm.controls.dpc_vpn_ip.setValue(wg['addresses']);
      this.vCEvHUBForm.controls.dpc_public_key.setValue(wg['pub_key']);
      this.vCEvHUBForm.controls.mtu.setValue(wg['mtu']);
    } catch (e) {
      console.log(e)
    }
  }

  isValidOverlay() {
    if (this.overlay_ips.length == 0) {
      return false;
    }
    for (let i = 0; i < this.overlay_ips.length; i++) {
      if (!this.overlay_ips[i].subnet_ip) {
        return false;
      }
    }
    return true;
  }

  getVhubLocation() {
    try {
      let d = this.vHubDeviceList.filter(d => d.mac == this.vCEvHUBForm.controls.vhub_mac.value)[0]
      if (d?.statistics?.teams?.team1?.links?.link1?.isp_info) {
        let isp_info = d['statistics']['teams']['team1']['links']['link1']['isp_info']
        return isp_info?.region + " " + isp_info?.city + " (" + isp_info?.isp + ")"
      } else {
        let d = this.vHubDeviceList.filter(d => d.mac == this.vCEvHUBForm.controls.vhub_mac.value)[0]
        return d.location || d.city || d.state || d.country
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
