import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-device-sipwatch-alert-settings',
  templateUrl: './device-sipwatch-alert-settings.component.html',
  styleUrls: ['./device-sipwatch-alert-settings.component.scss']
})
export class DeviceSipwatchAlertSettingsComponent implements OnInit {
  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
      if(obj[0]?.statistics?.sipwatch?.watchlist) {
        this.watchlists = obj[0]?.statistics?.sipwatch?.watchlist
        this.siptrack = obj[0]?.statistics?.sipwatch?.siptrack
        this.common = obj[0]?.statistics?.sipwatch?.common
        this.sipwatch = obj[0]?.statistics?.sipwatch?.sipwatch ?? []
        this.siptrack.sort((a, b) => b.ts - a.ts);  
        this.countReg = this.siptrack.filter(e=> e.reg_type == "reg").length
        this.countDereg = this.siptrack.filter(e=>  e.reg_type == "dereg").length
      }
    }
  }
  countReg = 0
  countDereg = 0
  sipwatch
  common
  _device: any = []
  watchlists: any = []
  siptrack: any = {}
  constructor() { }

  ngOnInit(): void {    
  }

  displayAlarm(e){
    if(e === 1) {
      return "ALARM"
    } else if(e ===0){
      return "OFF"
    }
    return ""
  }
  displayAlert(e){
    if(e === 1) {
      return "ON"
    }
    return "OFF"
  }
  displayDate(e){
    if(e){
      return moment.unix(e).tz('America/New_York').format('ddd MMM D YYYY HH:mm:ss')//'ddd MMM D HH:mm:ss zz YYYY'
    }
    return ""
  }
  displayEnabled(e){
    if(e === 1) {
      return "Enabled"
    }
    return "Disabled"
  }
  displayMSGState(e){
    if(e === "reg") {
      return "OK"
    } else if(e === "dereg") {
      return "Unauthorized"
    }
    return ""
  }
  displayWatched(e){
    if(e) {
      return "YES"
    } 
    return "DETECT"
  }
}
