<div style="display: block;">
    <mat-expansion-panel [expanded]="true" *ngIf="is_oldfirmware">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Connections Info and Data Usage (<span style="font-style: italic;">Note: Billing is done in UTC+00:00</span>)
            </mat-panel-title>
        </mat-expansion-panel-header>

        <table>
            <tr>
                <th>Connection</th>
                <th>Type</th>
                <th>Name</th>
                <th>Iface</th>
                <th>Addresss</th>
                <th>External IP</th>
                <th>ISP</th>
                <th>Action</th>
                <th>State</th>
                <th>Data Usage</th>
            </tr>
            <tr *ngIf="(model=='RRT-100' || model=='RRT-200')">
                <td>link 1</td>
                <td>{{getKey("link1 type")}}</td>
                <td>wan</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link1 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link1 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link1 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("eth0.2")}}</td>
            </tr>
            <tr *ngIf="model=='RRT-500'">
                <td>link 1</td>
                <td>{{getKey("link1 type")}}</td>
                <td>wan</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link1 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link1 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link1 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("eth2")}}</td>
            </tr>
            <tr *ngIf="(model=='RRT-100' || model=='RRT-200')">
                <td>link 2</td>
                <td>{{getKey("link2 type")}}</td>
                <td>wan2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link2 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link2 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link2 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("eth0.3")}}</td>
            </tr>
            <tr *ngIf="(model=='RRT-500')">
                <td>link 2</td>
                <td>{{getKey("link2 type")}}</td>
                <td>wan2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link2 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link2 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link2 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("eth3")}}</td>
            </tr>
            <tr *ngIf="(model=='RRT-200' || model=='RRT-500') && getData('wwan0')!='0 Bytes'">
                <td>link 2</td>
                <td>{{getKey("link2 type")}}</td>
                <td>4GLTE</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link2 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link2 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link2 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("wwan0")}}</td>
            </tr>
            <tr>
                <td>link 3</td>
                <td>{{getKey("link3 type")}}</td>
                <td>wg1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getKey("link3 action")}}</td>
                <td>
                    <span style='color: #45d645; font-weight: 600;' *ngIf='getKey("link3 state")=="Active"'>Up</span>
                    <span style='color: #b0b0b0; font-weight: 600;' *ngIf='getKey("link3 state")!="Active"'>Down</span>
                </td>
                <td>{{getData("wg1")}}</td>
            </tr>
            <tr *ngIf="!vHub_mode">
                <td>wlan0</td>
                <td>2.4GHz</td>
                <td>wlan0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getData("wlan0")}}</td>
            </tr>
            <tr *ngIf="!vHub_mode">
                <td>wlan1</td>
                <td>5GHz</td>
                <td>wlan1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getData("wlan1")}}</td>
            </tr>
            <tr *ngIf="!vHub_mode">
                <td>br-lan</td>
                <td>bridge</td>
                <td>br-lan</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{getData("br-lan")}}</td>
            </tr>
        </table>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" *ngIf="!is_oldfirmware">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Connections Info and Data Usage (<span style="font-style: italic;">Note: Billing is done in UTC+00:00</span>)
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let team of teams">
            <table>
                <tr>
                    <th>Connection</th>
                    <th *ngIf="vHub_mode">DPC</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Iface</th>
                    <th>Addresss/MAC</th>
                    <th>External IP</th>
                    <th>ISP</th>
                    <th>Action</th>
                    <th>State</th>
                    <th>Data Usage</th>
                </tr>
                <tr *ngFor="let link of team.links">
                    <td>{{link.link}}</td>
                    <td *ngIf="vHub_mode">{{getDPC(link.ifname)}}</td>
                    <td>{{link.type}}</td>
                    <td>{{link.ifname}}</td>
                    <td>{{link.interface}}</td>
                    <td>{{link.address}}</td>
                    <td>{{link?.isp_info?.ip}}</td>
                    <td>{{link?.isp_info?.isp}}</td>
                    <td>{{link.action}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="link.state">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!link.state">Down</span>
                    </td>
                    <!--<td *ngIf="link.link == 'linkB1' && link.action=='disabled'">*ngIf="link.link == 'linkB1' && link.action!='disabled'"</td>-->
                    <td>
                        <span *ngIf="link.link != 'linkB1' && link.action!='disabled'">
                            {{getDataLink(link.ifname, link.link)}}                            
                        </span>
                        <span *ngIf="link.link == 'linkB1' && link.action!='disabled'">
                            {{getDataLink(link.ifname, link.link)}}                            
                        </span>
                        <span *ngIf="link.link == 'linkB1' && link.action=='disabled'">
                            0 Bytes                          
                        </span>     
                    </td>
                </tr>
                <tr *ngIf="wifi_2g_ap && !vHub_mode">
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>2.4GHz</td>
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>{{wifi_2g_ap.mac}}</td>
                    <td>{{wifi_2g_ap.ssid}}</td>
                    <td>{{wifi_2g_ap.name}}</td>
                    <td>{{wifi_2g_ap.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_2g_ap.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_2g_ap.up">Down</span>
                    </td>
                    <td>{{getData(wifi_2g_ap.ifname)}}</td>
                </tr>
                <tr *ngIf="!wifi_2g_ap && !vHub_mode">
                    <td>wlan0</td>
                    <td>2.4GHz</td>
                    <td>wlan0</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{getData("wlan0")}}</td>
                </tr>
                <tr *ngIf="wifi_2g_guest && !vHub_mode">
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>2.4GHz</td>
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>{{wifi_2g_guest.mac}}</td>
                    <td>{{wifi_2g_guest.ssid}}</td>
                    <td>{{wifi_2g_guest.name}}</td>
                    <td>{{wifi_2g_guest.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_2g_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_2g_guest.up">Down</span>
                    </td>
                    <td>{{getData(wifi_2g_guest.ifname)}}</td>
                </tr>
                <tr *ngIf="wifi_5g_ap && !vHub_mode">
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>5GHz</td>
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>{{wifi_5g_ap.mac}}</td>
                    <td>{{wifi_5g_ap.ssid}}</td>
                    <td>{{wifi_5g_ap.name}}</td>
                    <td>{{wifi_5g_ap.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_5g_ap.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_5g_ap.up">Down</span>
                    </td>
                    <td>{{getData("wlan1")}}</td>
                </tr>
                <tr *ngIf="!wifi_5g_ap && !vHub_mode">
                    <td>wlan1</td>
                    <td>5GHz</td>
                    <td>wlan1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{getData("wlan1")}}</td>
                </tr>
                <tr *ngIf="wifi_5g_guest && !vHub_mode">
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>5GHz</td>
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>{{wifi_5g_guest.mac}}</td>
                    <td>{{wifi_5g_guest.ssid}}</td>
                    <td>{{wifi_5g_guest.name}}</td>
                    <td>{{wifi_5g_guest.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_5g_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_5g_guest.up">Down</span>
                    </td>
                    <td>{{getData(wifi_5g_guest.ifname)}}</td>
                </tr>
                <tr *ngIf="bridges_lan && !vHub_mode">
                    <td>{{bridges_lan.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan.ifname}}</td>
                    <td>{{bridges_lan.iface}}</td>
                    <td>{{bridges_lan.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan.up">Down</span>
                    </td>
                    <td>{{getData(bridges_lan.ifname)}}</td>
                </tr>
                <tr *ngIf="!bridges_lan && !vHub_mode">
                    <td>br-lan</td>
                    <td>bridge</td>
                    <td>br-lan</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{getData("br-lan")}}</td>
                </tr>
                <tr *ngIf="bridges_lan2 && !vHub_mode">
                    <td>{{bridges_lan2.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan2.ifname}}</td>
                    <td>{{bridges_lan2.iface}}</td>
                    <td>{{bridges_lan2.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan2.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan2.up">Down</span>
                    </td>
                    <td>{{getData(bridges_lan2.ifname)}}</td>
                </tr>
                <tr *ngIf="bridges_lan3 && !vHub_mode">
                    <td>{{bridges_lan3.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan3.ifname}}</td>
                    <td>{{bridges_lan3.iface}}</td>
                    <td>{{bridges_lan3.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan3.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan3.up">Down</span>
                    </td>
                    <td>{{getData(bridges_lan3.ifname)}}</td>
                </tr>
                <tr *ngIf="bridges_guest && !vHub_mode">
                    <td>{{bridges_guest.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_guest.ifname}}</td>
                    <td>{{bridges_guest.iface}}</td>
                    <td>{{bridges_guest.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_guest.up">Down</span>
                    </td>
                    <td>{{getData(bridges_guest.ifname)}}</td>
                </tr>
            </table>
        </ng-container>
    </mat-expansion-panel>
    <!-- <mat-expansion-panel [expanded]="true" *ngIf="!is_oldfirmware">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Connections Info and Data Usage (<span style="font-style: italic;">Note: Billing is done in UTC+00:00</span>)
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let team of teams">
            <table>
                <tr>
                    <th>Connection</th>
                    <th *ngIf="vHub_mode">DPC</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Iface</th>
                    <th>Addresss</th>
                    <th>External IP</th>
                    <th>ISP</th>
                    <th>Action</th>
                    <th>State</th>
                </tr>
                <tr *ngFor="let link of team.links">
                    <td>{{link.link}}</td>
                    <td *ngIf="vHub_mode">{{getDPC(link.ifname)}}</td>
                    <td>{{link.type}}</td>
                    <td>{{link.ifname}}</td>
                    <td>{{link.interface}}</td>
                    <td>{{link.address}}</td>
                    <td>{{link?.isp_info?.ip}}</td>
                    <td>{{link?.isp_info?.isp}}</td>
                    <td>{{link.action}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="link.state">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!link.state">Down</span>
                    </td>
                </tr>
                <tr *ngIf="wifi_2g_ap && !vHub_mode">
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>2.4GHz</td>
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>{{wifi_2g_ap.ifname}}</td>
                    <td>{{wifi_2g_ap.mac}}</td>
                    <td>{{wifi_2g_ap.ssid}}</td>
                    <td>{{wifi_2g_ap.name}}</td>
                    <td>{{wifi_2g_ap.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_2g_ap.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_2g_ap.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="!wifi_2g_ap && !vHub_mode">
                    <td>wlan0</td>
                    <td>2.4GHz</td>
                    <td>wlan0</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngIf="wifi_2g_guest && !vHub_mode">
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>2.4GHz</td>
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>{{wifi_2g_guest.ifname}}</td>
                    <td>{{wifi_2g_guest.mac}}</td>
                    <td>{{wifi_2g_guest.ssid}}</td>
                    <td>{{wifi_2g_guest.name}}</td>
                    <td>{{wifi_2g_guest.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_2g_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_2g_guest.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="wifi_5g_ap && !vHub_mode">
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>5GHz</td>
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>{{wifi_5g_ap.ifname}}</td>
                    <td>{{wifi_5g_ap.mac}}</td>
                    <td>{{wifi_5g_ap.ssid}}</td>
                    <td>{{wifi_5g_ap.name}}</td>
                    <td>{{wifi_5g_ap.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_5g_ap.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_5g_ap.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="!wifi_5g_ap && !vHub_mode">
                    <td>wlan1</td>
                    <td>5GHz</td>
                    <td>wlan1</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngIf="wifi_5g_guest && !vHub_mode">
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>5GHz</td>
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>{{wifi_5g_guest.ifname}}</td>
                    <td>{{wifi_5g_guest.mac}}</td>
                    <td>{{wifi_5g_guest.ssid}}</td>
                    <td>{{wifi_5g_guest.name}}</td>
                    <td>{{wifi_5g_guest.mode}}</td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="wifi_5g_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!wifi_5g_guest.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="bridges_lan && !vHub_mode">
                    <td>{{bridges_lan.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan.ifname}}</td>
                    <td>{{bridges_lan.iface}}</td>
                    <td>{{bridges_lan.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="!bridges_lan && !vHub_mode">
                    <td>br-lan</td>
                    <td>bridge</td>
                    <td>br-lan</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr *ngIf="bridges_lan2 && !vHub_mode">
                    <td>{{bridges_lan2.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan2.ifname}}</td>
                    <td>{{bridges_lan2.iface}}</td>
                    <td>{{bridges_lan2.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan2.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan2.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="bridges_lan3 && !vHub_mode">
                    <td>{{bridges_lan3.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_lan3.ifname}}</td>
                    <td>{{bridges_lan3.iface}}</td>
                    <td>{{bridges_lan3.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_lan3.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_lan3.up">Down</span>
                    </td>
                </tr>
                <tr *ngIf="bridges_guest && !vHub_mode">
                    <td>{{bridges_guest.ifname}}</td>
                    <td>bridge</td>
                    <td>{{bridges_guest.ifname}}</td>
                    <td>{{bridges_guest.iface}}</td>
                    <td>{{bridges_guest.address}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <span style='color: #45d645; font-weight: 600;' *ngIf="bridges_guest.up">Up</span>
                        <span style='color: #b0b0b0; font-weight: 600;' *ngIf="!bridges_guest.up">Down</span>
                    </td>
                </tr>
            </table>
        </ng-container>
    </mat-expansion-panel> -->
    <div class="mt-4" *ngIf="is_oldfirmware">To add this device to Monitor server, click <a
            class="btn-here" (click)="editDevice()">here.</a></div>
</div>