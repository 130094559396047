<form [formGroup]="noteForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>{{displayTitle}} Note</legend>
        <div mat-dialog-content>
            <mat-form-field>
                <mat-label>Select Type</mat-label>
                <mat-select formControlName="type">
                    <div>
                        <mat-option [value]="1">Installation</mat-option>
                        <mat-option [value]="2">Troubleshooting</mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Title</mat-label>
                <input type="text" matInput formControlName="title" autocomplete="off"/>

                <!-- <mat-error *ngIf="deviceForm.controls['name'].hasError('required')">
                    Name is required
                </mat-error> -->
            </mat-form-field>
            <mat-form-field>
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="notes" class="file-textarea" autocomplete="off"></textarea>

                <!-- <mat-error *ngIf="deviceForm.controls['name'].hasError('required')">
                    Name is required
                </mat-error> -->
            </mat-form-field>
            <mat-form-field>
                <mat-label>Choose Files</mat-label>
                <input type="file" hidden (change)="onFileSelected($event)"  multiple #fileInput>
                    <textarea matInput type="text" formControlName="files" class="file-textarea" readonly></textarea>
                    <button mat-button matSuffix (click)="fileInput.click()">Browse</button>

                <!-- <mat-error *ngIf="deviceForm.controls['name'].hasError('required')">
                    Name is required
                </mat-error> -->
            </mat-form-field>
           
        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="warn" class=" btn btnn" (click)="closeModal()">Cancel</button>
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()">Save</button>           
        </div>
    </fieldset>
</form>