<nav class="navbar navbar-expand-lg navbar-light ">
    <a class="navbar-brand" href="#">
        <img src="assets/images/brand.png" class="imgg"></a>
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link  menu" href="#">
                    <span class="sr-only">(current)</span></a>
            </li>
        </ul>
    </div> -->
</nav>

<div id="logreg-forms">
    <h1 class="h3 mb-3 font-weight-normal forget_pwd"> Reset password</h1>
    <form class="example-form" [formGroup]="resetpasswordform">
        <mat-form-field class="example-full-width">
            <mat-label>New password</mat-label>
            <input [type]="hide1 ? 'password' : 'text'" matInput formControlName="password" />
            <mat-icon class="visibility-icon" matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="resetpasswordform.controls['password'].hasError('required')">
                password is requrid
            </mat-error>
            <mat-error *ngIf="resetpasswordform.controls['password'].hasError('minlength')">
                password must be at least 6 characters long
            </mat-error>
            <mat-error *ngIf="resetpasswordform.controls['password'].hasError('pattern') && !resetpasswordform.controls['password'].hasError('minlength')">
                Password must contain at least one uppercase, one lowercase, one special character, and one number
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" [ngClass]="{'mt-4': resetpasswordform.controls['password'].hasError('pattern')}">
            <mat-label>Confirm password</mat-label>
            <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="Confirm password" formControlName="confirmpassword" />
            <mat-icon class="visibility-icon" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="resetpasswordform.controls['confirmpassword'].hasError('mustMatch')"> Passwords
                must match</mat-error>
        </mat-form-field>
        <div class="row">
            <div class="col-md-12">
                <button class="w-full" mat-raised-button color="primary" [disabled]="resetpasswordform.invalid || isDisabled"
                    (click)="onSubmit()">Submit</button>
            </div>
        </div>
        <div class="m-t-12">
            <div class="row">
                <div class="col-md-12 text-right">
                    <mat-card-actions>
                        <a [routerLink]="['/login']" routerLinkActive="router-link-active"> Go back to Login</a>
                    </mat-card-actions>
                </div>
            </div>
        </div>
    </form>
</div>
