import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddNetworkComponent } from './add-network/add-network.component';
import { NetworkDevicesComponent } from './network-devices/network-devices.component';
import { WirelessNetworkComponent } from './wireless-network/wireless-network.component';

const routes: Routes = [
  { path: '', component: WirelessNetworkComponent },
  { path: 'add', component: AddNetworkComponent },
  { path: ':id', component: AddNetworkComponent },
  { path: ':id/devices', component: NetworkDevicesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NetworkRoutingModule { }
