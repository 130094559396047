import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-alerts-report',
  templateUrl: './alerts-report.component.html',
  styleUrls: ['./alerts-report.component.scss']
})
export class AlertsReportComponent implements OnInit {

  user: any;
  orgId: any;
  org: any;
  constructor( public route: ActivatedRoute,
    public userService: UserService  ) { }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);

    let id = this.route.snapshot.paramMap.get('id');
    this.orgId =  this.user.organization;
    // this.getOverviewLink()
    
    this.userService.getOrganization({ id: this.orgId }).subscribe(res => {
      if (res && res.data && res.data[0]) {
        this.org = res.data[0]
      }
      //this.displayResult()
    })    
  }

}
