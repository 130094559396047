import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(private http: HttpClient) { }
  addNote(data: FormData): Observable<any> {
    return this.http.post(environment.endpoints.notes, data);
  }
  updateNote(id, data: FormData): Observable<any> {
    return this.http.post(environment.endpoints.notes + '/' + id, data);
  }
  deleteNote(id): Observable<any> {
    return this.http.delete(environment.endpoints.notes + '/' + id);
  }
  deleteFile(id,fileId): Observable<any> {
    return this.http.delete(environment.endpoints.notes + '/' + id + '/files/' + fileId);
  }
  getNotes(id, data): Observable<any> {
    return this.http.get(environment.endpoints.notes + '/' + id, { params: data});
  }
  getFile(id,fileId): Observable<any> {
    return this.http.get(environment.endpoints.notes + '/' + id + '/files/' + fileId,
      {
        responseType: "blob", // Important: Expect binary data
      });
    
  }
  
}
