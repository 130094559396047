import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ApService } from 'src/app/shared/services/ap.service';
import { FormatterHelper } from 'src/app/shared/helper/formatter-helper';

@Component({
  selector: 'app-device-monitor-interfaces',
  templateUrl: './device-monitor-interfaces.component.html',
  styleUrls: ['./device-monitor-interfaces.component.scss']
})
export class DeviceMonitorInterfacesComponent implements OnInit, OnDestroy {
  label = "Interfaces";
  _device: any;
  interfaces: any = [];

  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  private loadDataSubscription: Subscription;

  constructor(
    public apService: ApService
  ) { }

  ngOnInit(): void {

  }

  loadData() {
    this.interfaces = [];
    if(this._device.statistics && this._device.statistics.interfaces && this._device.statistics.interfaces.length){
      let interfaces_tmp = this._device.statistics.interfaces;
      interfaces_tmp.map(ifc => {
        if (ifc.zone && ifc.zone != "") {
          // AP: Only accept new payload. Old payload is lacking data.
          this.interfaces.push(ifc);
        }
      });
      this.interfaces.map(ifc => {
        if (ifc.up && ifc.up == "true") {
          ifc.status = "Up";
        } else {
          ifc.status = "Down";
        }
        ifc.uptime_str = FormatterHelper.uptimeToStr(ifc.uptime ?? 0);
        ifc.tx = FormatterHelper.formatBytes(ifc.rx_bytes ?? 0);
        ifc.rx = FormatterHelper.formatBytes(ifc.rx_bytes ?? 0);
        ifc.rxpkt = FormatterHelper.formatNumber(ifc.rx_pkts ?? 0, 0);
        ifc.txpkt = FormatterHelper.formatNumber(ifc.tx_pkts ?? 0, 0);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
