import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrtgRoutingModule } from './prtg-routing.module';

import { PrtgDevicesComponent } from './prtg-devices/prtg-devices.component';
import { PrtgAddComponent } from './prtg-add/prtg-add.component';
import { PrtgComponent } from './prtg.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';

@NgModule({
  declarations: [
    PrtgDevicesComponent,
    PrtgAddComponent,
    PrtgComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PrtgRoutingModule,
  ]
})
export class PrtgModule { }
