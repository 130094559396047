<div>
    <mat-card-content>
        <h4 class="text-center mt-3" *ngIf="domain && id">Devices connected with {{domain.name}}</h4>
        <div class="text-right">
            <div class="nav-btn add-btn" *ngIf="role<=3">
                <button mat-button [matMenuTriggerFor]="addMenu">
                    <mat-icon>add</mat-icon> Add
                </button>
                <mat-menu #addMenu="matMenu" yPosition="below">
                    <button mat-menu-item (click)="addDeviceClick()">Device</button>
                    <button mat-menu-item (click)="addDomainClick()" *ngIf="role<=2">Domain</button>
                    <button mat-menu-item (click)="addUserClick()">User</button>
                </mat-menu>
            </div>

            <mat-form-field class="example-form-field">
                <mat-label>Search Here ...</mat-label>
                <input matInput type="text" [(ngModel)]="searchTxt" (keyup.enter)="loadData(true)" autocomplete="off">
                <button mat-button *ngIf="searchTxt" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchTxt='';loadData(true);">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <app-device-table [dataSource]="divicessArray" [orgId]="orgId" (refresh)="loadData()" (sortChange)="sortData=$event;loadData()"></app-device-table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex" (page)="pageEvent = $event" (page)="handlePageEvent($event)" *ngIf="length">
        </mat-paginator>
    </mat-card-content>
</div>