import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VceVhubService {
  constructor(private http: HttpClient) { }
  generateWGKey(): Observable<any> {
    return this.http.get<any>(environment.endpoints.vceVhub + '/gen-wireguard-key')
  }

  createVceVhub(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.vceVhub, data)
  }

  loadVceConnection(mac): Observable<any> {
    return this.http.get<any>(environment.endpoints.vceVhub + '?vhub_mac=' + mac)
  }

  loadVHubConnection(mac): Observable<any> {
    return this.http.get<any>(environment.endpoints.vceVhub + '?vce_mac=' + mac)
  }

  deleteConnection(_id: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        _id,
        type: 'delete'
      }
    };
    return this.http.delete<any>(environment.endpoints.vceVhub, options)
  }

  toggleConnection(_id: any, b: boolean) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        _id: _id,
        type: 'disable',
        value: b
      }
    };
    return this.http.delete<any>(environment.endpoints.vceVhub, options)
  }

  createRelation(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.vceVhub + '/vhub-org-relation', data)
  }

  getRelatedDevices(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.vceVhub + '/vhub-org-relation?orgId=' + orgId)
  }

  getRelatedOrganizations(mac): Observable<any> {
    return this.http.get<any>(environment.endpoints.vceVhub + '/vhub-org-relation?mac=' + mac)
  }

  getRas(mac): Observable<any> {
    return this.http.get<any>(environment.endpoints.ras + '?mac=' + mac)
  }

  getVceConfig(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.config, data)
  }

  getVhubConfig(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.config, data)
  }
}
