import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { TemplateService } from 'src/app/shared/services/template.service';
import { BaseLicensesTypes, AddOnLicensesTypes } from 'src/app/shared/model/template';
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Subscription } from 'rxjs';
import { Country, State, City }  from 'country-state-city';


@Component({
  selector: 'app-edit-device-model',
  templateUrl: './edit-device-model.component.html',
  styleUrls: ['./edit-device-model.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class EditDeviceModelComponent implements OnInit, OnDestroy {
  deviceForm: FormGroup;

  submitted = false;
  getdeviceArray = [];
  device = [];
  alertDeviceId: any;
  alertOrgId: any;
  orgAlert: any;
  monitors = [];
  wnetworks = [];
  qosList = [];
  i4glteList = [];
  teamList = [];
  allOrgList = [];
  tprofileList = [];

  addOnLicenseSelected = ""
  configureType = 0;
  licenseType = 0;
  searchOrg: any;
  searchPipe: any;

  tabIndex = 0;
  licenseList = [];
  baseLicense = new FormControl('', [Validators.required]);
  addOnLicense = new FormControl('', []);
  selectedBase: any;

  baseLicenseArray: any = BaseLicensesTypes;
  addOnLicenseArray: any = AddOnLicensesTypes;

  @ViewChild('orginput') searchElement: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  selectedIndex = 0;

  // Alert Section
  escalateConfig = 0;
  alert = {
    device: false,
    team: false,
    link1: false,
    link2: false,
    link3: false,
    emails: [],
    escalateConfig: 0,
    glteConfig: 0,
    additionalEmails: []
  }
  // Recipient Email
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectedAddOn: any;

  showPRTGWarn = false;
  private loadDataSubscription: Subscription;
  role: any;

  countries = Country.getAllCountries();
  selectedCountries = this.countries;
  states = State.getStatesOfCountry('US');
  selectedStates = this.states;
  cities = [];
  selectedCities = this.cities;

  constructor(private formBuilder: FormBuilder, public apservices: ApService, private toastr: ToastrService, public userService: UserService,
    private router: Router,
    public dialogRef: MatDialogRef<EditDeviceModelComponent>,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.role = user.role;
    this.loadAllOrganization();
    this.deviceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9_@./#&+-]+')]),
      country: new FormControl('United States', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      org: new FormControl(null, [Validators.required]),
      prtgSensorId: new FormControl(''),
      baseLicense: this.baseLicense,
      addOnLicense: this.addOnLicense,
      alertId: new FormControl(null, []),
      wnetwork: new FormControl(null, []),
      qos: new FormControl(null, []),
      i4glte: new FormControl(null, []),
      team: new FormControl(null, []),
      tprofile: new FormControl(null, []),
      trigger: new FormControl(1, []),
      activated: new FormControl(false, [])
    });

    if (this.data && this.data._id) {
      this.deviceForm.controls.name.setValue(this.data.name);
      this.deviceForm.controls.country.setValue(this.data.country);
      this.onCountryChange({value: this.data.country})
      this.deviceForm.controls.state.setValue(this.data.state);
      this.onStateChange({value: this.data.state})
      this.deviceForm.controls.city.setValue(this.data.city);
      this.deviceForm.controls.activated.setValue(this.data.activatedAt ? true : false)
      this.deviceForm.controls.org.setValue(this.data.orgId);
      this.deviceForm.controls.prtgSensorId.setValue(this.data.prtgSensorId);
      this.deviceForm.controls.prtgSensorId.valueChanges.subscribe((v) => {
        this.showPRTGWarn = true;
      })
      this.deviceForm.controls.alertId.setValue(this.data.alertId);
      this.deviceForm.controls.trigger.setValue(this.data.templateTrigger ? this.data.templateTrigger : 0);
      this.deviceForm.controls.baseLicense.setValue(this.data.baseLicense);
      this.selectedBase = this.data.baseLicense;
      this.deviceForm.controls.addOnLicense.setValue(this.data.addOnLicense);
      this.selectedAddOn = typeof this.data.addOnLicense !== 'object' ? [this.data.addOnLicense] : this.data.addOnLicense;

      if (this.data.alert) {
        this.alert = this.data.alert;
      }
      if (this.data.tprofile) {
        this.deviceForm.controls.tprofile.setValue(this.data.tprofile);
        this.configureType = 1;
      } else {
        if (this.data.alertId) {
          this.configureType = 2;
        }
        if (this.data.wnetwork) {
          this.deviceForm.controls.wnetwork.setValue(this.data.wnetwork);
          this.configureType = 2;
        }
        if (this.data.qos) {
          this.deviceForm.controls.qos.setValue(this.data.qos);
          this.configureType = 2;
        }
        if (this.data['4glte']) {
          this.deviceForm.controls.i4glte.setValue(this.data['4glte']);
          this.configureType = 2;
        }
        if (this.data.team) {
          this.deviceForm.controls.team.setValue(this.data.team);
          this.configureType = 2;
        }
      }
    }
  }

  radioBaseChange($event: MatRadioChange) {
    this.selectedBase = $event.value;
  }

  onNgModelChange($event) {
    const intersect = (a, b) => {
      const setB = new Set(b);
      return a.filter(el => setB.has(el));
    }  

    const sipwatchLicenses = ["SIPCID04", "SIPCID06", "SIPCID10", "SIPCID20"]    

    const selectedSipwatch = intersect(sipwatchLicenses, this.selectedAddOn);
    let removeAddOn = ""
    if(selectedSipwatch.length > 0){
      if(this.addOnLicenseSelected == ""){
        this.addOnLicenseSelected = selectedSipwatch[0]
      } else {
        removeAddOn = this.addOnLicenseSelected
        this.addOnLicenseSelected = selectedSipwatch.find(x=> x != removeAddOn) ?? this.addOnLicenseSelected
      }
      if(removeAddOn != '' && removeAddOn != this.addOnLicenseSelected){
        // let index = $event.indexOf(removeAddOn);
        // if(index !== -1){
        //   $event.splice(index, 1);
        //   this.selectedAddOn = $event
        // }
        this.selectedAddOn = $event.filter(x=> x!= removeAddOn)
      }
      return
    }
    this.selectedAddOn = $event;
  }

  onChangeEscConfig($event){
    if($event.value){
      this.alert.escalateConfig = Number($event.value)
    }    
  }

  onChangeLteConfig($event){
    if($event.value){
      this.alert.glteConfig = Number($event.value)
    }    
  }
  
  loadAllOrganization() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    let orgId = user.organization;
    let obj = {
      id: orgId
    }
    this.loadDataSubscription = this.userService.getOrganizationUsers(obj).subscribe(res => {
      try {
        this.allOrgList = this.getDomainOrgs(res.data[0]);
      } catch (e) {
        console.log(e);
      }
    })
    if (this.data && this.data.orgId) {
      this.templateService.getAlertForOrganization(this.data.orgId).subscribe(res => {
        this.monitors = res;
      })
      this.templateService.getWnetworkforOrg(this.data.orgId).subscribe(res => {
        this.wnetworks = res;
      })
      this.templateService.getQosForOrg(this.data.orgId).subscribe(res => {
        this.qosList = res;
      })
      this.templateService.getI4glteForOrg(this.data.orgId).subscribe(res => {
        this.i4glteList = res;
      })
      this.templateService.getTeamForOrg(this.data.orgId).subscribe(res => {
        this.teamList = res;
      })
      this.templateService.getTprofileForOrg(this.data.orgId).subscribe(res => {
        this.tprofileList = res;
      })
    }
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    if (domain.role == 3) {
      filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role })
    }
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  getDomainUsers(domain) {
    let filteredUser = [];
    filteredUser = [...filteredUser, ...domain.subUsers]
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredUser = [...filteredUser, ...this.getDomainUsers(org)]
    }
    return filteredUser;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  onDomainChange(e) {
    let org = this.allOrgList.find(o => o['orgId'] == e.value);

    if (!org) {
      return;
    }
    this.templateService.getAlertForOrganization(org.orgId).subscribe(res => {
      this.monitors = res;
    })
    this.templateService.getWnetworkforOrg(org.orgId).subscribe(res => {
      this.wnetworks = res;
    })
    this.templateService.getQosForOrg(org.orgId).subscribe(res => {
      this.qosList = res;
    })
    this.templateService.getI4glteForOrg(org.orgId).subscribe(res => {
      this.i4glteList = res;
    })
    this.templateService.getTeamForOrg(org.orgId).subscribe(res => {
      this.teamList = res;
    })
    this.templateService.getTprofileForOrg(org.orgId).subscribe(res => {
      this.tprofileList = res;
    })
  }

  onStepChange(event) {
    if (event) {
      this.selectedIndex = event.selectedIndex;
    }
  }

  onNext() {
    this.stepper.selectedIndex += 1;
  }

  onSubmit() {
    if (this.submitted) {
      return
    }
    let org = this.allOrgList.find(o => o['orgId'] == this.deviceForm.get('org').value)

    // stop here if form is invalid
    if (this.deviceForm.invalid || !org) {
      if (this.deviceForm.invalid || !this.deviceForm.valid) {
        if ((this.deviceForm.get('name').value == '' && this.deviceForm.get('baseLicense').value == undefined) || (this.deviceForm.get('name').value == '' && this.deviceForm.get('baseLicense').value != undefined)) {
          this.stepper.selectedIndex = 0;
        } else if (this.deviceForm.get('name').value != '' && this.deviceForm.get('baseLicense').value == undefined) {
          this.stepper.selectedIndex = 1;
        } else if (this.deviceForm.get('country').value == '' || this.deviceForm.get('state').value == '' || this.deviceForm.get('city').value == '') {
          this.stepper.selectedIndex = 0;
        };;
        return this.stepper.selectedIndex;
      }
    } else {
      const postObject = {
        _id: this.data._id,
        mac: this.data.mac,
        name: this.deviceForm.get('name').value,
        country: this.deviceForm.get('country').value,
        state: this.deviceForm.get('state').value,
        city: this.deviceForm.get('city').value,
        activated: this.deviceForm.get('activated').value,
        baseLicense: this.selectedBase,
        addOnLicense: this.selectedAddOn,
        domain: org.name,
        orgId: this.deviceForm.get('org').value,
        templateTrigger: this.deviceForm.get('trigger').value, // This is to set Device Template Upgrade Time
        pending: false,
      };

      // Show prtgSensorId modification for Super Admin
      if (this.role == 0) {
        postObject['prtgSensorId'] = this.deviceForm.get('prtgSensorId').value
      }

      if (this.deviceForm.get('tprofile').value && (this.configureType == 1)) {
        postObject['tprofile'] = this.deviceForm.get('tprofile').value;
        let tp = this.tprofileList.find(v => v._id == this.deviceForm.get('tprofile').value)
        postObject['alertId'] = tp.alertId;
        postObject['wnetwork'] = tp.wnetwork;
        postObject['qos'] = tp.qos;
        postObject['4glte'] = tp['4glte'];
        postObject['team'] = tp.team;
      } else if (this.configureType == 2) {
        postObject['alertId'] = this.deviceForm.get('alertId').value;
        postObject['wnetwork'] = this.deviceForm.get('wnetwork').value;
        postObject['qos'] = this.deviceForm.get('qos').value;
        postObject['4glte'] = this.deviceForm.get('i4glte').value;
        postObject['team'] = this.deviceForm.get('team').value;
      }
      postObject['templateTriggerDesc'] = this.deviceForm.get('trigger').value == 0 ? { value: 0, description: 'Update on next restart' } : { value: 1, description: 'Update immediately' }
      postObject['alert'] = this.alert;
      this.submitted = true;
      this.apservices.updateDevice(postObject).subscribe(res => {
        this.submitted = false;
        this.toastr.success(' Device updated Successfully!');
        this.dialogRef.close();
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }));
    }
  }

  selectConfigureType(e, type) {
    if (e.checked) {
      this.configureType = type;
      if (type == 1) {
        this.deviceForm.controls.alertId.setValue(null);
        this.deviceForm.controls.wnetwork.setValue(null)
        this.deviceForm.controls.qos.setValue(null);
        this.deviceForm.controls.i4glte.setValue(null);
        this.deviceForm.controls.team.setValue(null);
      } else if (type == 2) {
        this.deviceForm.controls.tprofile.setValue(null);
      }
    } else {
      this.configureType = 0;
    }
  }

  selectAlert(event, type) {
    this.alert[type] = event.checked;
  }

  addRecipient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.alert.emails.push(value);
    }
    // Clear the input value
    event.input.value = "";
  }

  addEscRecipient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.alert.additionalEmails.push(value);
    }
    // Clear the input value
    event.input.value = "";
  }


  removeRecipient(email): void {
    const index = this.alert.emails.indexOf(email);

    if (index >= 0) {
      this.alert.emails.splice(index, 1);
    }
  }

  removeEscRecipient(email): void {
    const index = this.alert.additionalEmails.indexOf(email);

    if (index >= 0) {
      this.alert.additionalEmails.splice(index, 1);
    }
  }

  onCountryChange(event) {
    let country = this.countries.filter(v => v.name == event.value)[0];
    this.states = State.getStatesOfCountry(country?.isoCode)
    this.selectedStates = this.states;
  }

  onStateChange(event) {
    let state = this.states.filter(v => v.name == event.value)[0];
    this.cities = City.getCitiesOfState(state?.countryCode, state?.isoCode)
    this.selectedCities = this.cities;
  }

  onCountrySearch(value: string) {
    this.selectedCountries = this.countries.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  onStateSearch(value: string) {
    this.selectedStates = this.states.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  onCitySearch(value: string) {
    this.selectedCities = this.cities.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
