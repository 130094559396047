<div class="mt-2">
    <div class="mb-2">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="0">
            <mat-tab label="Last 4 Hrs"></mat-tab>
            <mat-tab label="Last 24 Hrs"></mat-tab>
            <mat-tab label="Last 7 Days"></mat-tab>
            <mat-tab label="This Month"></mat-tab>
            <mat-tab label="Last Month"></mat-tab>
            <!-- <mat-tab label="Overview">
                <hr class="y-border"/>
                <app-system-information-monitor *ngIf="type==5" [device]="_device"></app-system-information-monitor>
            </mat-tab> -->
        </mat-tab-group>
        <hr class="y-border" />
        <div class="row">            
            <div class="col-md-3">                
                <h6>Link1 Graph</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="link1-canvas" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chartLink1">
                    <div class="detail-list-item m-2">
                        <div class="square link1Up"></div>
                        <div class="ml-2">Up</div>
                        <div class="count ml-2">{{upLink1Cnt | number:'0.3-3'}}%</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square link1Down"></div>
                        <div class="ml-2">Down</div>
                        <div class="count ml-2">{{downLink1Cnt | number:'0.3-3'}}%</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <h6>Link2 Graph</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="link2-canvas" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chartLink2">
                    <div class="detail-list-item m-2">
                        <div class="square link1Up"></div>
                        <div class="ml-2">Up</div>
                        <div class="count ml-2">{{upLink2Cnt | number:'0.3-3'}}%</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square link1Down"></div>
                        <div class="ml-2">Down</div>
                        <div class="count ml-2">{{downLink2Cnt | number:'0.3-3'}}%</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <h6>Link3 Graph</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="link3-canvas" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chartLink3">
                    <div class="detail-list-item m-2">
                        <div class="square link1Up"></div>
                        <div class="ml-2">Up</div>
                        <div class="count ml-2">{{upLink3Cnt | number:'0.3-3'}}%</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square link1Down"></div>
                        <div class="ml-2">Down</div>
                        <div class="count ml-2">{{downLink3Cnt | number:'0.3-3'}}%</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <h6>LinkB1 Graph</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="linkB1-canvas" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chartLinkB1">
                    <div class="detail-list-item m-2">
                        <div class="square link1Up"></div>
                        <div class="ml-2">Up</div>
                        <div class="count ml-2">{{upLinkB1Cnt | number:'0.3-3'}}%</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square link1Down"></div>
                        <div class="ml-2">Down</div>
                        <div class="count ml-2">{{downLinkB1Cnt | number:'0.3-3'}}%</div>
                    </div>
                </div>
            </div>
        </div>       
        <form [formGroup]="deviceForm">
            <fieldset>
                <mat-form-field>
                    <mat-label>Select Timezone</mat-label>
                    <mat-select formControlName="searchTimezone" (click)="searchFocus()"
                        (selectionChange)="onSelectionTZChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Timezone">
                        </div>
                        <div>
                            <mat-option *ngFor="let tz of timezoneArray | searchSelect : searchPipe"
                                [value]="tz.tz">
                                {{ tz.name }}, {{ tz['tz-posix'] }}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label>Select Month</mat-label>
                    <mat-select formControlName="searchMonth" (click)="searchMonthFocus()"
                        (selectionChange)="onSelectionMonthChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchMonthPipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Date">
                        </div>
                        <div>
                            <mat-option *ngFor="let month of listOfMonths | searchSelect : searchMonthPipe" [value]="month">
                               {{month}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Select Date</mat-label>
                    <mat-select formControlName="searchDate" (click)="searchDateFocus()"
                        (selectionChange)="onSelectionDateChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchDatePipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Date">
                        </div>
                        <div>
                            <mat-option *ngFor="let date of listOfDates | searchSelect : searchMonthPipe" [value]="date">
                               {{date}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field> -->
            </fieldset>
        </form>
    </div>
    <div class="mb-2">
        <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
            <ng-container *ngFor="let column of dynamicColumns" [matColumnDef]="column">                
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <ng-template *ngIf="column == 'link1 State Changed' || column == 'link2 State Changed' || column == 'link3 State Changed' || column == 'linkB1 State Changed'; then stateChangedDisplay; else linkAlert"></ng-template>
                    
                   
                    <ng-template #stateChangedDisplay><span>State Changed</span></ng-template>
                    <ng-template #linkAlert>
                        <ng-template *ngIf="column == 'link1 Link Alert' || column == 'link2 Link Alert' || column == 'link3 Link Alert' || column == 'linkB1 Link Alert'; then linkAlertDisplay; else defaultDisplay"></ng-template>
                    </ng-template>
                    <ng-template #linkAlertDisplay><span>Alert</span></ng-template>    
                    <ng-template #defaultDisplay><span>{{column}}</span></ng-template>
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-template [ngIf]="column == 'link1'"><strong>{{ displayLinkState(element.links[0].lastLinkState) }}</strong></ng-template>
                    <!-- <ng-template [ngIf]="column == 'link1 State Changed'">{{ displayYesNo(element.links[0].stateChanged) }}</ng-template>
                    <ng-template [ngIf]="column == 'link1 Link Alert'">{{ displayYesNo(element.links[0].isLinkAlert) }}</ng-template> -->
                    <ng-template [ngIf]="column == 'link2'"><strong>{{ displayLinkState(element.links[1].lastLinkState) }}</strong></ng-template>
                    <!-- <ng-template [ngIf]="column == 'link2 State Changed'">{{ displayYesNo(element.links[1].stateChanged) }}</ng-template>
                    <ng-template [ngIf]="column == 'link2 Link Alert'">{{ displayYesNo(element.links[1].isLinkAlert) }}</ng-template> -->
                    <ng-template [ngIf]="column == 'link3'"><strong>{{ displayLinkState(element.links[2].lastLinkState) }}</strong></ng-template>
                    <!-- <ng-template [ngIf]="column == 'link3 State Changed'">{{ displayYesNo(element.links[2].stateChanged) }}</ng-template>
                    <ng-template [ngIf]="column == 'link3 Link Alert'">{{ displayYesNo(element.links[2].isLinkAlert) }}</ng-template> -->
                    <ng-template [ngIf]="column == 'linkB1'"><strong>{{ displayLinkState(element.links[3].lastLinkState) }}</strong></ng-template>
                    <!-- <ng-template [ngIf]="column == 'linkB1 State Changed'">{{ displayYesNo(element.links[3].stateChanged) }}</ng-template>
                    <ng-template [ngIf]="column == 'linkB1 Link Alert'">{{ displayYesNo(element.links[3].isLinkAlert) }}</ng-template> -->
                    <ng-template [ngIf]="column == 'link4'"><strong>{{ displayLinkState(element.links[4].lastLinkState) }}</strong></ng-template>
                    <ng-template [ngIf]="column == 'link5'"><strong>{{ displayLinkState(element.links[5].lastLinkState) }}</strong></ng-template>
                    <ng-template [ngIf]="column == 'link6'"><strong>{{ displayLinkState(element.links[6].lastLinkState) }}</strong></ng-template>
                    <ng-template [ngIf]="column == 'Timezone'">{{ displayTimezone(element.timezone) }}</ng-template>
                    <ng-template [ngIf]="column == 'Date Processed'">{{ displayFormatTimeZone(element.teamDateState, element.timezone) }}</ng-template>
                    <ng-template [ngIf]="column == 'TransactionId'">{{ element.novuTransactionId }}</ng-template>
                    <ng-template [ngIf]="column == 'Status'">{{ displayStatus(element.isLinkAlertSent) }}</ng-template>
                    <ng-template [ngIf]="column == 'Action'">
                         <div class="d-flex">
                            <mat-icon class="pointer" matTooltip="View Details" role="img" aria-hidden="true"
                            (click)="viewNovu(element)">view_comfy</mat-icon>
                        </div>
                    </ng-template>
                    <ng-template #defaultDisplay><span>{{column}}</span></ng-template>
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="Link1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Link1</th>
                <td mat-cell *matCellDef="let element">{{ displayLinkState(element.lastDeviceState) }}</td>
            </ng-container>
            <ng-container matColumnDef="Link2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Link2</th>
                <td mat-cell *matCellDef="let element">{{ displayLinkState(element.lastDeviceState) }}</td>
            </ng-container>
            <ng-container matColumnDef="Link3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Link3</th>
                <td mat-cell *matCellDef="let element">{{ displayLinkState(element.lastDeviceState) }}</td>
            </ng-container>
            <ng-container matColumnDef="LinkB1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>LinkB1</th>
                <td mat-cell *matCellDef="let element">{{ displayLinkState(element.lastDeviceState) }}</td>
            </ng-container>
            <ng-container matColumnDef="Timezone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Timezone</th>
                <td mat-cell *matCellDef="let element">{{ displayTimezone(element.timezone) }}</td>
            </ng-container>
            <ng-container matColumnDef="Last Seen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
                <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.lastSeen, element.timezone) }}</td>
            </ng-container>
            <ng-container matColumnDef="Date Processed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Processed</th>
                <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.novuDateProcessed, element.timezone) }}</td>
            </ng-container>
            <ng-container matColumnDef="TransactionId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>TransactionId</th>
                <td mat-cell *matCellDef="let element">{{ element.novuTransactionId }}</td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">{{ displayStatus(element.isLinkAlertSent) }}</td>
            </ng-container> -->
            <!-- <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                        <mat-icon class="pointer" matTooltip="View Details" role="img" aria-hidden="true"
                            (click)="viewNovu">view_comfy</mat-icon>
                    </div>
                </td>
            </ng-container> -->
            <tr mat-header-row *matHeaderRowDef="dynamicColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dynamicColumns"></tr>
        </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>
    </div>
</div>
