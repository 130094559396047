import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/shared/services/template.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-qos-add',
  templateUrl: './qos-add.component.html',
  styleUrls: ['./qos-add.component.scss']
})
export class QosAddComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  submitted = false;
  editMode: boolean = true;
  classes = new Array(8).fill({});
  template: any;
  name = "";
  showError: boolean = false;
  orgId: any;
  allOrgList = [];
  searchPipe: any;
  @ViewChild('orginput') searchElement: ElementRef;
  name_required: boolean = false;
  user: any;
  qosId: any;
  templateId: any;
  selectedTabIndex = 0;

  private loadDataSubscription: Subscription;

  constructor(
    public qosService: TemplateService,
    private toastr: ToastrService,
    private router: Router,
    public route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private topnavbarService: TopnavbarService,
    public userService: UserService,
    public dialog: MatDialog
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');
        if (id) {
          this.loadData(id.split("_").pop());
        }
      }
    });
    this.route.queryParams.subscribe(
      params => {
        this.templateId = params['template']
        if (this.templateId) {
          this.loadData(params['template'])
        }
      });
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    if (this.router.url.includes('/layout/qos')) {
      if (!this.router.url.includes('_')) {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/qos",
            "name": "QOS",
            "type": "link",
          }
        ])
      } else {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/qos",
            "name": "QOS",
            "type": "link",
          },
          {
            "route": "/layout/qos" + this.name + "_" + this.template._id,
            "name": this.name ? this.name : "",
            "type": "text"
          },
        ])
      }
    }
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.user = storedUser;
    this.orgId = this.user.organization;
    this.loadAllOrganization()
  }

  loadAllOrganization() {
    let obj = {
      id: this.user.organization
    }
    this.userService.getOrganizationUsers(obj).subscribe(res => {
      try {
        this.allOrgList = this.getDomainOrgs(res.data[0]);
      } catch (e) {
        console.log(e);
      }
    })
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, alertDeviceId: domain.alertDeviceId, alertOrgId: domain.alertOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  loadData(qosId) {
    this.loadDataSubscription = this.qosService.getQos(qosId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.template = res;
        this.name = res.name;
        this.classes = res.classes;
        this.orgId = res.orgId;
        if (!this.templateId) {
          this.qosId = res['_id']
        }
      }
      this.setTopNavMenu();
    });
  }

  setData(data, idx) {
    let classes_data = [];
    for (let i = 0; i < this.classes.length; i++) {
      if (i === idx) {
        classes_data = [...classes_data, ...[JSON.parse(JSON.stringify(data))]]
      } else {
        classes_data = [...classes_data, ...[this.classes[i]]]
      }
    }
    this.classes = JSON.parse(JSON.stringify(classes_data))
  }

  getLabel(idx) {
    return 'Class ' + (idx + 1) + (idx == 0 ? ' (First)' : (idx == this.classes.length - 1) ? ' (Last)' : '')
  }

  onSubmit() {
    if (this.submitted) return;
    if (this.name.length) {
      let data = {
        name: this.name,
        classes: this.classes,
        orgId: this.orgId,
        _id: this.qosId,
        templateTrigger: 1
      }
      if (this.templateId) {
        delete data._id
      }
      if (data['_id']) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          width: '350px',
        });
        dialogRef.afterClosed().subscribe(result => {
          if (typeof (result) == 'undefined') {
            return;
          }
          data.templateTrigger = Number(result)
          this.submitted = true;
          this.qosService.addQosdata(data).subscribe(res => {
            this.submitted = false;
            if (!this.qosId) {
              this.toastr.success('QOS is created successfully');
            } else {
              this.toastr.success('QOS is updated successfully');
            }
            this.router.navigate(['/layout/qos']);
          }, (error => {
            this.submitted = false;
            this.toastr.error(error.error.error);
          }));
        })
      } else {
        this.submitted = true;
        this.qosService.addQosdata(data).subscribe(res => {
          this.submitted = false;
          if (!this.qosId) {
            this.toastr.success('QOS is created successfully');
          } else {
            this.toastr.success('QOS is updated successfully');
          }
          this.router.navigate(['/layout/qos']);
        }, (error => {
          this.submitted = false;
          this.toastr.error(error.error.error);
        }));
      }
    } else {
      this.showError = true;
    }
  }

  selectedTabChange(evt) {
    this.selectedTabIndex = evt.index;
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
