import { Component, Input, OnInit } from '@angular/core';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-lan-jump',
  templateUrl: './device-lan-jump.component.html',
  styleUrls: ['./device-lan-jump.component.scss']
})
export class DeviceLanJumpComponent implements OnInit {
  type: any;
  _device: any;
  checked = false
  hideToggle = false;
  lanData: any[];

  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData(obj[0])
  }

  constructor(
    public apService: ApService
  ) { }

  ngOnInit(): void {
  }

  selectedTabChange(evt) {
    this.type = evt.index;
  }

  onTplToggle(e) {
    this.checked = e.checked
  }

  loadData(obj) {
    this.apService.getLanData({mac: obj.mac}).subscribe(res => {
      this.lanData = res;
    })
  }
}
