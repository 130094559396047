import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TemplateService } from 'src/app/shared/services/template.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Subscription } from 'rxjs';

export interface DialogData {
  animal: string;
  name: string;
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-wireless-network',
  templateUrl: './wireless-network.component.html',
  styleUrls: ['./wireless-network.component.scss']
})
export class WirelessNetworkComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  role: any;
  user: any;
  wnetwork: any = [];
  displayedColumns: string[] = ['name', 'orgId', 'action'];
  wirelessArray: any = [];
  dataSource: MatTableDataSource<any>;
  filteredOrg: any = [];
  searchTxt = ""
  allData = [];
  orgId: any;
  id: any;
  @ViewChild(MatSort) sort: MatSort;

  private loadDataWnetworkSubscription: Subscription;
  private loadDataOrganizationSubscription: Subscription;

  constructor(
    private networkService: TemplateService,
    public userService: UserService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    private topnavbarService: TopnavbarService,
  ) {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;
    this.id = this.route.snapshot.paramMap.get('id');
    this.orgId = this.id ? this.id : this.user.organization;
    if (this.orgId) {
      this.orgId = this.orgId.split("_").pop();
    }
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.loadDataWnetworkSubscription = this.networkService.getWnetworkofOrg(this.orgId).subscribe(res => {
      if (res) {
        this.wirelessArray = res;
        this.dataSource.data = this.wirelessArray;
        this.allData = this.wirelessArray;
      } else {
        this.dataSource.data = [];
      }
      this.setTopNavMenu();
    });

    this.loadDataOrganizationSubscription = this.userService.getOrganization({ id: this.orgId }).subscribe(res => {
      res = res.data[0];
      if (res) {
        let domain = res;
        this.filteredOrg = this.getDomainOrg(domain);
      }
    });
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "/layout/wireless-network",
        "name": "WiFi Network",
        "type": "link",
      }
    ])
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ _id: domain._id, name: domain.name, domain: domain.domain, prtg: domain.prtg, prtgDeviceId: domain.prtgDeviceId, prtgOrgId: domain.prtgOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  findOrg(orgId) {
    for (let i = 0; i < this.filteredOrg.length; i++) {
      let organization = this.filteredOrg[i];
      if (organization['_id'] == orgId) {
        return organization['name']
      }
    }
  }

  delete(element) {
    const wirlessnetwork = [];
    wirlessnetwork.push(element._id);
    if (confirm('Are you sure you want to delete?')) {
      this.networkService.deleteNetworkdata(wirlessnetwork).subscribe(res => {
        if (res.subDevices && res.subDevices.length > 0) {
          this.toastr.error('Template contains active devices. Please remove them first.');
          return;
        }
        this.toastr.success('Wireless network deleted successfully');
      });
      this.loadData();
    }
  }

  searchData() {
    this.dataSource.data = this.allData.filter(element => {
      if (element['name'].toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (this.findOrg(element.orgId).toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataWnetworkSubscription) {
      this.loadDataWnetworkSubscription.unsubscribe();
    }
    if (this.loadDataOrganizationSubscription) {
      this.loadDataOrganizationSubscription.unsubscribe();
    }
  }

}
