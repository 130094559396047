import { Component, Input, OnInit } from '@angular/core';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';

@Component({
  selector: 'app-device-ras',
  templateUrl: './device-ras.component.html',
  styleUrls: ['./device-ras.component.scss']
})
export class DeviceRasComponent implements OnInit {
  type: any;
  ras: any = [];
  rasWg: any = [];

  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this.loadData(obj[0])
  }
   
  constructor(
    public vceVhubService: VceVhubService
  ) { }

  ngOnInit(): void {
  }

  selectedTabChange(evt) {
    this.type = evt.index;
  }

  loadData(obj) {
    this.vceVhubService.getRas(obj.mac).subscribe(res => {
      this.ras = [res];
      if(res != null){
        this.rasWg = [res.wg0];
      }
    })
  }

  formatBytes(bytes, decimals = 2) {
    bytes = Number(bytes);
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) {
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }

}
