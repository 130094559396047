<form [formGroup]="deviceForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Connect vHUB device with Organization</legend>
        <div mat-dialog-content>
            <mat-form-field>
                <mat-label>Host Name</mat-label>
                <input type="text" matInput formControlName="name" autocomplete="off"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Mac</mat-label>
                <input type="text" matInput formControlName="mac" autocomplete="off"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Parent Organization</mat-label>
                <mat-select formControlName="org">
                    <mat-option *ngFor="let organization of allOrgList" [value]="organization.orgId">
                        {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                        == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                        "Organization": "") }})
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Connected Organizations</mat-label>
                <mat-select formControlName="linked_orgs" (click)="searchFocus()" [multiple]="true">
                    <div class="dropdown-search">
                        <input #orginput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
                            placeholder="Search Organization" autocomplete="off">
                    </div>
                    <div>
                        <mat-option *ngFor="let organization of allOrgList | searchOrg : searchPipe"
                            [value]="organization.orgId">
                            {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                            == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                            "Organization": "") }})
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>

        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()">Update</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>