<mat-card>
    <h4 class="text-center" *ngIf="org">Billing Report for {{org.name}}</h4>
    <a [routerLink]="['/layout/report/overview/'+orgId]" routerLinkActive="router-link-active"
        class="navigator">Overview</a>
    <div class="row">
        <mat-form-field class="m-4">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDate" [min]="minDate" [max]="maxDate" [formControl]="startMonth">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate panelClass="example-date-picker">
            </mat-datepicker>
        </mat-form-field>
        <mat-form-field class="m-4">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDate" [min]="startDate" [max]="maxDate" [formControl]="endMonth">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate panelClass="example-date-picker">
            </mat-datepicker>
        </mat-form-field>
        <div class="btn-wrapper">
            <button mat-raised-button color="primary" class="btn btnn mb-1" (click)="loadData()">Search</button>
        </div>
    </div>
    <mat-tab-group>
        <mat-tab label="Monthly Devices">
            <div class="row ml-2 mr-2">
                <div class="col-md-4">
                    <table mat-table [dataSource]="monthlyDeviceCntSource" style="width: 100%;">
                        <ng-container matColumnDef="monthsArray">
                            <th mat-header-cell *matHeaderCellDef><b>Month</b></th>
                            <td mat-cell *matCellDef="let element">{{element.month}}</td>
                        </ng-container>

                        <ng-container matColumnDef="monthlyDeviceCount">
                            <th mat-header-cell *matHeaderCellDef><b>Device Count</b></th>
                            <td mat-cell *matCellDef="let element">{{element.count}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedTemplateColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedTemplateColumns"></tr>
                    </table>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="chart-wrapper">
                        <canvas id="canvas"></canvas>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="License Activity">
            <table mat-table [dataSource]="licenseHistorySource" style="width: 100%;" matSort>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element">{{element.date}}</td>
                </ng-container>

                <ng-container matColumnDef="mac">
                    <th mat-header-cell *matHeaderCellDef>Device Mac</th>
                    <td mat-cell *matCellDef="let element">{{element.mac.toUpperCase()}}</td>
                </ng-container>

                <ng-container matColumnDef="devicename">
                    <th mat-header-cell *matHeaderCellDef>Device</th>
                    <td mat-cell *matCellDef="let element">{{element.devicename}}</td>
                </ng-container>

                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef>Location</th>
                    <td mat-cell *matCellDef="let element">{{element.location}}</td>
                </ng-container>

                <ng-container matColumnDef="mmsp">
                    <th mat-header-cell *matHeaderCellDef>MMSP</th>
                    <td mat-cell *matCellDef="let element">{{element.mmsp}}</td>
                </ng-container>

                <ng-container matColumnDef="msp">
                    <th mat-header-cell *matHeaderCellDef>MSP</th>
                    <td mat-cell *matCellDef="let element">{{element.msp}}</td>
                </ng-container>

                <ng-container matColumnDef="org">
                    <th mat-header-cell *matHeaderCellDef>ORG</th>
                    <td mat-cell *matCellDef="let element">{{element.org}}</td>
                </ng-container>

                <ng-container matColumnDef="old_license">
                    <th mat-header-cell *matHeaderCellDef>Orig License</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHtml]="element.old_license"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="license">
                    <th mat-header-cell *matHeaderCellDef>Lic Change</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHtml]="element.license"></span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>User</th>
                    <td mat-cell *matCellDef="let element">{{element.user}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedLicenseColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedLicenseColumns"></tr>
            </table>
        </mat-tab>
        <mat-tab label="All Organizations">
            <div class="mt-2" *ngIf="allOrgData && deviceArray">
                <app-org-devices-table [dataSource]="orgDataSource" [deviceSource]="deviceArray" [selfSort]="true"
                    (refresh)="loadData()" [nav]="'billing'"></app-org-devices-table>
            </div>
        </mat-tab>
        <mat-tab label="All Devices">
            <div class="mt-2" *ngIf="allOrgData && deviceArrayAll">
                <app-device-table [dataSource]="deviceArrayAll" [isReportView]="true" [selfSort]="true"></app-device-table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>
