import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) { }

  addNetwork(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.wirelessNetwork, data)
  }

  getWnetwork(wnetworkId) {
    return this.http.get<any>(environment.endpoints.wirelessNetwork + '/' + wnetworkId)
  }

  getWnetworkforOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.wirelessNetwork + '/for/' + orgId)
  }

  getWnetworkofOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.wirelessNetwork + '/of/' + orgId)
  }

  updateNetworkdata(data, id): Observable<any> {
    return this.http.post<any>(environment.endpoints.wirelessNetwork + '/' + id, data)
  }

  deleteNetworkdata(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        wnetwork: data
      }
    };
    return this.http.delete<any>(environment.endpoints.wirelessNetwork, options);
  }

  addQosdata(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.qos, data)
  }

  getQosofOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.qos + '/of/' + orgId)
  }

  getQos(qosId) {
    return this.http.get<any>(environment.endpoints.qos + '/' + qosId)
  }

  removeQos(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        qos: data
      }
    };
    return this.http.delete<any>(environment.endpoints.qos, options);
  }

  getQosForOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.qos + '/for/' + orgId)
  }

  createPRTG(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.prtg, data)
  }

  getPrtgForOrganization(ordId) {
    return this.http.get<any>(environment.endpoints.prtg + '/for/' + ordId)
  }

  getOrganizationOfPrtg(ordId) {
    return this.http.get<any>(environment.endpoints.prtg + '/of/' + ordId)
  }

  getPrtgInfo(prtgId) {
    return this.http.get<any>(environment.endpoints.prtg + '/' + prtgId)
  }

  removePrtg(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        prtg: data
      }
    };
    return this.http.delete<any>(environment.endpoints.prtg, options);
  }

  addI4gltetdata(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.i4glte, data)
  }

  getI4glteofOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.i4glte + '/of/' + orgId)
  }

  getI4glte(i4glteId) {
    return this.http.get<any>(environment.endpoints.i4glte + '/' + i4glteId)
  }

  removeI4glte(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        i4glte: data
      }
    };
    return this.http.delete<any>(environment.endpoints.i4glte, options);
  }

  getI4glteForOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.i4glte + '/for/' + orgId)
  }

  addTeamdata(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.team, data)
  }

  getTeamofOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.team + '/of/' + orgId)
  }

  getTeam(teamId) {
    return this.http.get<any>(environment.endpoints.team + '/' + teamId)
  }

  removeTeam(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        team: data
      }
    };
    return this.http.delete<any>(environment.endpoints.team, options);
  }

  getTeamForOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.team + '/for/' + orgId)
  }

  addTprofiledata(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.tprofile, data)
  }
  

  getTprofileofOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.tprofile + '/of/' + orgId)
  }

  getTprofile(profileId) {
    return this.http.get<any>(environment.endpoints.tprofile + '/' + profileId)
  }

  removeTprofile(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        tprofile: data
      }
    };
    return this.http.delete<any>(environment.endpoints.tprofile, options);
  }

  getTprofileForOrg(orgId): Observable<any> {
    return this.http.get<any>(environment.endpoints.tprofile + '/for/' + orgId)
  }

  createAlert(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.alert, data)
  }

  getAlertForOrganization(ordId) {
    return this.http.get<any>(environment.endpoints.alert + '/for/' + ordId)
  }

  getOrganizationOfAlert(ordId) {
    return this.http.get<any>(environment.endpoints.alert + '/of/' + ordId)
  }

  getAlertInfo(alertId) {
    return this.http.get<any>(environment.endpoints.alert + '/' + alertId)
  }

  removeAlert(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        alert: data
      }
    };
    return this.http.delete<any>(environment.endpoints.alert, options);
  }


}
