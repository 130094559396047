import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { TemplateService } from 'src/app/shared/services/template.service';
import { DefaultAlertConfig } from 'src/app/shared/model/alert_config';
import { ApService } from 'src/app/shared/services/ap.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-monitor-monitor',
  templateUrl: './device-monitor-monitor.component.html',
  styleUrls: ['./device-monitor-monitor.component.scss']
})
export class DeviceMonitorMonitorComponent implements OnInit, OnDestroy {
  _device: any;
  config: any;
  notification: any;
  type = 0;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }
  prtgForm: FormGroup;
  label = "";
  template_notification_name = "";
  checked = false
  alert: any = null;
  showAlert = false; // No template & Alert configuration on Device Side only

  private loadDataAlertDisabledSubscription: Subscription;
  private loadDataAlertEnabledSubscription: Subscription;
  private loadDataAlertExistSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public templateService: TemplateService,
    public apService: ApService
  ) { }

  ngOnInit(): void {
    this.prtgForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      endpoint: new FormControl('', [Validators.required]),
      userName: new FormControl('', [Validators.required]),
      passHash: new FormControl('', [Validators.required]),
      rootGroupId: new FormControl('', [Validators.required]),
      rootGroupDeviceId: new FormControl('', [Validators.required]),
      emptyGroupIDToClone: new FormControl('', [Validators.required]),
      emptyDeviceIDToClone: new FormControl('', [Validators.required]),
      emptySensorIDToClone: new FormControl('', [Validators.required]),
    });
  }

  selectedTabChange(evt) {
    this.type = evt.index;
  }

  loadData() {
    if (this._device.alert) {
      this.showAlert = true;
      this.alert = this._device.alert;
      this.type = 4;
      this.label = 'Device (Configuration)'
    }
    if (this._device.templateConfig && this._device.templateConfig.monitor && this._device.templateConfig.monitor.template_enabled && this._device.alertId) {
      this.loadDataAlertEnabledSubscription = this.templateService.getAlertInfo(this._device.alertId).subscribe(res => {
        if (res && res[0]) {
          let data = res[0];
          this.label = data.name + " (Template - Enabled)";
          this.template_notification_name = data.name + " Notification"
          this.config = data.config;
          this.notification = data.notification;
          this.checked = true;
        }
      })
    } else if (this._device.templateConfig && this._device.templateConfig.monitor) {
      this.config = this._device.templateConfig.monitor.config;
      if (this._device.alertId && this._device.templateConfig.monitor.name) {
        this.label = this._device.templateConfig.monitor.name + " (Template-Disabled)"
      } else if (this._device.alertId) {
        this.loadDataAlertDisabledSubscription = this.templateService.getAlertInfo(this._device.alertId).subscribe(res => {
          if (res && res[0]) {
            let data = res[0];
            this.label = data.name + " (Template - Disabled)";
          }
        })
      } else {
        this.label = 'Device (Configuration)'
      }
    } else if (this._device.alertId) {
      this.loadDataAlertExistSubscription = this.templateService.getAlertInfo(this._device.alertId).subscribe(res => {
        if (res && res[0]) {
          let data = res[0];
          this.label = data.name + " (Template - Enabled)";
          this.template_notification_name = data.name + " Notification"
          this.config = data.config;
          this.notification = data.notification;
          this.checked = true;
        }
      })
    }
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "monitor", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig.monitor) {
        this._device.templateConfig.monitor.template_enabled = e.checked;
      } else {
        this._device.templateConfig.monitor = {
          template_enabled: e.checked
        }
      }
      this.loadData()
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataAlertDisabledSubscription) {
      this.loadDataAlertDisabledSubscription.unsubscribe();
    }
    if (this.loadDataAlertEnabledSubscription) {
      this.loadDataAlertEnabledSubscription.unsubscribe();
    }
    if (this.loadDataAlertExistSubscription) {
      this.loadDataAlertExistSubscription.unsubscribe();
    }
  }
}
