<div class="mt-2 monitor-qos-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab [label]="label"></mat-tab>
    </mat-tab-group>
    <hr class="y-border" />
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="!hideToggle">
        Enable
    </mat-slide-toggle>

    <table>
        <tbody>
            <tr>
                <th>Team</th>
                <th>Status</th>
                <th>Mode</th>
                <th>Description</th>
                <th>Team Load Balancer</th>
                <th>MSS Enable</th>
                <th>MSS Size</th>
                <th>Turn On Debug Logs</th>
            </tr>
            <tr>
                <td>team1</td>
                <td>{{checked?"Enabled":"Disabled"}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.mode, teamTplOnPortal?.mode)}">{{teamTpl?.mode}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.description, teamTplOnPortal?.description)}">
                    {{teamTpl?.description}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.balancer, teamTplOnPortal?.balancer)}">
                    {{getBalancerLabel(teamTpl)}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.setmss_enable, teamTplOnPortal?.setmss_enable)}">
                    {{teamTpl?.setmss_enable}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.setmss_1_size, teamTplOnPortal?.setmss_1_size)}">
                    {{teamTpl?.setmss_1_size}}</td>
                <td [ngClass]="{'blue': isDifferent(teamTpl?.debug, teamTplOnPortal?.debug)}">{{teamTpl?.debug=="1"?
                    "On": "Off"}}</td>
            </tr>
        </tbody>
    </table>

    <table>
        <tbody>
            <tr>
                <th>Link Configuration</th>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <th>link{{i+1}}</th>
                </ng-container>
            </tr>
            <tr>
                <td class="link_tab" colspan="100%">General Settings</td>
            </tr>
            <tr>
                <td>Link type</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_type, linkTplsOnPortal[i]?.link_type)}">
                        {{getLinkTypeLabel(linkTpls[i]?linkTpls[i]:linkTplsOnPortal[i])}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Link Path</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.name, linkTplsOnPortal[i]?.name)}">
                        {{linkTpls[i]?.name?linkTpls[i]?.name:linkTplsOnPortal[i]?.name}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Description</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.description, linkTplsOnPortal[i]?.description)}">
                        {{linkTpls[i]?.description?linkTpls[i]?.description:linkTplsOnPortal[i]?.description}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Link Action</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.action, linkTplsOnPortal[i]?.action)}">
                        {{linkTpls[i]?.action?linkTpls[i]?.action:linkTplsOnPortal[i]?.action}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>MTU</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.mtu, linkTplsOnPortal[i]?.mtu)}">
                        {{linkTpls[i]?.mtu?linkTpls[i]?.mtu:linkTplsOnPortal[i]?.mtu}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Safeguard Data Usage</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.qos_safeguard_enabled, linkTplsOnPortal[i]?.qos_safeguard_enabled)}">
                        {{linkTpls[i]?.qos_safeguard_enabled?linkTpls[i]?.qos_safeguard_enabled:linkTplsOnPortal[i]?.qos_safeguard_enabled}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Link Traffic Distribution by Weight</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.weight, linkTplsOnPortal[i]?.weight)}">
                        {{linkTpls[i]?.weight?linkTpls[i]?.weight:linkTplsOnPortal[i]?.weight}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Link Traffic Distribution by (%)</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.weightpercent, linkTplsOnPortal[i]?.weightpercent)}">
                        {{linkTpls[i]?.weightpercent?linkTpls[i]?.weightpercent:linkTplsOnPortal[i]?.weightpercent}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>QoS Rate Limit (Outbound)</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.qos_rate_root, linkTplsOnPortal[i]?.qos_rate_root)}">
                        {{linkTpls[i]?.qos_rate_root?linkTpls[i]?.qos_rate_root:linkTplsOnPortal[i]?.qos_rate_root}}</td>
                </ng-container>
            </tr>
            <tr>
                <td class="link_tab" colspan="100%">Steering Settings</td>
            </tr>
            <tr>
                <td>Underlay Transport</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.transport, linkTplsOnPortal[i]?.transport)}">
                        {{linkTpls[i]?.transport?linkTpls[i]?.transport:linkTplsOnPortal[i]?.transport}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>URLs List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_url, linkTplsOnPortal[i]?.link_url)}">
                        <ng-container *ngIf="linkTpls[i]?.link_url">
                            <div *ngFor="let lnkTpl of linkTpls[i]?.link_url">
                                {{lnkTpl}}
                            </div> 
                        </ng-container> 
                        <ng-container *ngIf="!linkTpls[i]?.link_url">
                            <div *ngFor="let lnkTpl of linkTplsOnPortal[i]?.link_url">
                                {{lnkTpl}}
                            </div>
                        </ng-container>    
                        <!-- {{linkTpls[i]?.link_url?linkTpls[i]?.link_url:linkTplsOnPortal[i]?.link_url}}</td> -->
                </ng-container>
            </tr>
            <tr>
                <td>IP Addresses List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_ip, linkTplsOnPortal[i]?.link_ip)}">
                        <ng-container *ngIf="linkTpls[i]?.link_ip.length > 0">
                            <div *ngFor="let lnkTpl of linkTpls[i]?.link_ip">
                                {{lnkTpl}}
                            </div> 
                        </ng-container> 
                        <ng-container *ngIf="linkTpls[i]?.link_ip.length === 0">
                            <div *ngFor="let lnkTpl of linkTplsOnPortal[i]?.link_ip">
                                {{lnkTpl}}
                            </div>
                        </ng-container>     
                        <!-- {{linkTpls[i]?.link_ip?linkTpls[i]?.link_ip:linkTplsOnPortal[i]?.link_ip}}</td> -->
                </ng-container>
            </tr>
            <tr>
                <td>IP Ports List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_ip_port, linkTplsOnPortal[i]?.link_ip_port)}">
                        <ng-container *ngIf="linkTpls[i]?.link_ip_port">
                            <div *ngFor="let lnkTpl of linkTpls[i]?.link_ip_port">
                                {{lnkTpl}}
                            </div> 
                        </ng-container> 
                        <ng-container *ngIf="!linkTpls[i]?.link_ip_port">
                            <div *ngFor="let lnkTpl of linkTplsOnPortal[i]?.link_ip_port">
                                {{lnkTpl}}
                            </div>
                        </ng-container>   
                        <!-- {{linkTpls[i]?.link_ip_port?linkTpls[i]?.link_ip_port:linkTplsOnPortal[i]?.link_ip_port}}</td> -->
                </ng-container>
            </tr>
            <tr>
                <td>Networks List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_net, linkTplsOnPortal[i]?.link_net)}">
                        <ng-container>
                            <div *ngFor="let lnkTpl of linkTpls[i]?.link_net">
                                {{lnkTpl}}
                            </div> 
                        </ng-container> 
                        <ng-container *ngIf="linkTpls[i]?.link_net === 0">
                            <div *ngFor="let lnkTpl of linkTplsOnPortal[i]?.link_net">
                                {{lnkTpl}}
                            </div>
                        </ng-container>                    
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td>Network/Ports List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                        <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_net_port, linkTplsOnPortal[i]?.link_net_port)}">
                            <ng-container *ngIf="linkTpls[i]?.link_net_port">
                                <div *ngFor="let lnkTpl of linkTpls[i]?.link_net_port">
                                    {{lnkTpl}}
                                </div> 
                            </ng-container> 
                            <ng-container *ngIf="!linkTpls[i]?.link_net_port">
                                <div *ngFor="let lnkTpl of linkTplsOnPortal[i]?.link_net_port">
                                    {{lnkTpl}}
                                </div>
                            </ng-container>
                        <!-- {{linkTpls[i]?.link_net_port?linkTpls[i]?.link_net_port:linkTplsOnPortal[i]?.link_net_port}}</td> -->
                </ng-container>
            </tr>
            <tr *ngIf="">
                <td>Source IP Addresses List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_src_ip, linkTplsOnPortal[i]?.link_src_ip)}">
                        {{linkTpls[i]?.link_src_ip?linkTpls[i]?.link_src_ip:linkTplsOnPortal[i]?.link_src_ip}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Source IP Ports & Ranges List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_src_ip_port, linkTplsOnPortal[i]?.link_src_ip_port)}">
                        {{linkTpls[i]?.link_src_ip_port?linkTpls[i]?.link_src_ip_port:linkTplsOnPortal[i]?.link_src_ip_port}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Source Networks List</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_src_net, linkTplsOnPortal[i]?.link_src_net)}">
                        {{linkTpls[i]?.link_src_net?linkTpls[i]?.link_src_net:linkTplsOnPortal[i]?.link_src_net}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Source Network/Ports List & Port-Range</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.link_src_net_port, linkTplsOnPortal[i]?.link_src_net_port)}">
                        {{linkTpls[i]?.link_src_net_port?linkTpls[i]?.link_src_net_port:linkTplsOnPortal[i]?.link_src_net_port}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Refine Lists with Port Rules</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.traffic_ports_enabled, linkTplsOnPortal[i]?.traffic_ports_enabled)}">
                        {{(linkTpls[i]?.traffic_ports_enabled?linkTpls[i]?.traffic_ports_enabled:linkTplsOnPortal[i]?.traffic_ports_enabled)=="1"?"Enabled": "Disabled"}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Traffic Must Contain These Ports</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.traffic_pmulti, linkTplsOnPortal[i]?.traffic_pmulti)}">
                        {{linkTpls[i]?.traffic_pmulti?linkTpls[i]?.traffic_pmulti:linkTplsOnPortal[i]?.traffic_pmulti}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Traffic Must Contain These Port-Ranges</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.traffic_prange, linkTplsOnPortal[i]?.traffic_prange)}">
                        {{linkTpls[i]?.traffic_prange?linkTpls[i]?.traffic_prange:linkTplsOnPortal[i]?.traffic_prange}}</td>
                </ng-container>
            </tr>
          
            <tr>
                <td class="link_tab" colspan="100%">Probe Settings</td>
            </tr>
            <tr>
                <td>Link Quality</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_profile, linkTplsOnPortal[i]?.monitor_profile)}">
                        {{getLinkQualityLabel(linkTpls[i]?linkTpls[i]:linkTplsOnPortal[i])}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Monitoring Target</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_target, linkTplsOnPortal[i]?.monitor_target)}">
                        {{linkTpls[i]?.monitor_target?linkTpls[i]?.monitor_target:linkTplsOnPortal[i]?.monitor_target}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Monitoring Target Data Length</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_dlen, linkTplsOnPortal[i]?.monitor_dlen)}">
                        {{linkTpls[i]?.monitor_dlen?linkTpls[i]?.monitor_dlen:linkTplsOnPortal[i]?.monitor_dlen}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Request Interval</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_techo, linkTplsOnPortal[i]?.monitor_techo)}">
                        {{linkTpls[i]?.monitor_techo?linkTpls[i]?.monitor_techo:linkTplsOnPortal[i]?.monitor_techo}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Lost Interval</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_tlost, linkTplsOnPortal[i]?.monitor_tlost)}">
                        {{linkTpls[i]?.monitor_tlost?linkTpls[i]?.monitor_tlost:linkTplsOnPortal[i]?.monitor_tlost}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Average Interval</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_tperiod, linkTplsOnPortal[i]?.monitor_tperiod)}">
                        {{linkTpls[i]?.monitor_tperiod?linkTpls[i]?.monitor_tperiod:linkTplsOnPortal[i]?.monitor_tperiod}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Alert Interval</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_talert, linkTplsOnPortal[i]?.monitor_talert)}">
                        {{linkTpls[i]?.monitor_talert?linkTpls[i]?.monitor_talert:linkTplsOnPortal[i]?.monitor_talert}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Latency Alarm Interval</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_tlatency, linkTplsOnPortal[i]?.monitor_tlatency)}">
                        {{linkTpls[i]?.monitor_tlatency?linkTpls[i]?.monitor_tlatency:linkTplsOnPortal[i]?.monitor_tlatency}}</td>
                </ng-container>
            </tr>
            <tr>
                <td>Loss Thresold</td>
                <ng-container *ngFor="let item of [].constructor(linkLength); let i = index">
                    <td [ngClass]="{'blue': isDifferent(linkTpls[i]?.monitor_loss, linkTplsOnPortal[i]?.monitor_loss)}">
                        {{linkTpls[i]?.monitor_loss?linkTpls[i]?.monitor_loss:linkTplsOnPortal[i]?.monitor_loss}}</td>
                </ng-container>
            </tr>
        </tbody>
    </table>

    <table class="flaps_table" *ngIf="linkFlaps.length && !linkStates.length">
        <tbody>
            <tr>
                <th *ngFor="let team of teamLink">
                    {{team.link}}(Flaps/Down Event)
                </th>
            </tr>
            <tr class="flaps" >
                <td [attr.colspan]="linkFlaps.length" class="flaps_tag">Last 30 Incidents Combined for (F) = Failover/Failback/Faildown, (B) = Backup in Use, (U) = Up, (D) = Down</td>
              </tr>
            <ng-container *ngFor="let item of [].constructor(linkFlapMax); let i = index">
                <tr class="flaps">               
                    <td *ngFor="let linkstate of linkFlaps">
                        {{linkstate[i]}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>

    <table class="flaps_table" *ngIf="linkStates.length">
      <tbody>
      <tr>
        <th *ngFor="let team of teamLink">
          {{team.link}}(Link State Events)
        </th>
      </tr>     
      <tr class="flaps" >
        <td [attr.colspan]="linkStates.length" class="flaps_tag">Last 30 Incidents Combined for (F) = Failover/Failback/Faildown, (B) = Backup in Use, (U) = Up, (D) = Down</td>
      </tr>
      <ng-container *ngFor="let item of [].constructor(linkStatesMax); let i = index">
        <tr class="flaps" >
            <td *ngFor="let linkstate of linkStates">
                {{linkstate[i]}}
            </td>
          </tr>
      </ng-container> 
      </tbody>
    </table>
</div>
