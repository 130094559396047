import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from './report-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DeviceReportComponent } from './device-report/device-report.component';
import { BillingComponent } from './billing/billing.component';
import { AlertsReportComponent } from './alerts-report/alerts-report/alerts-report.component';
import { AlertLinksComponent } from './alerts-report/alerts-report/alert-links/alert-links.component';
import { AlertDevicesComponent } from './alerts-report/alerts-report/alert-devices/alert-devices.component';
import { MonitoringStreamComponent } from './monitoring-stream/monitoring-stream/monitoring-stream.component';

@NgModule({
  declarations: [
    DeviceReportComponent,
    BillingComponent,
    AlertsReportComponent,
    AlertLinksComponent,
    AlertDevicesComponent,
    MonitoringStreamComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    MatCardModule,
    MatTableModule,
    MatTabsModule,
    SharedModule
  ]
})
export class ReportModule { }
