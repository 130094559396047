import { Injectable } from '@angular/core';
import { UserConstant } from '../constants/user-constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor( ) { }
  isLogin() {
    let token = this.getToken();
    if (token && token.length) {
      return true;
    } else {
      return false;
    }
  }

  getToken() {
    let auth_cookie = this.getCookie("USER_TOKEN");
    if (auth_cookie && auth_cookie.length) {
      return auth_cookie;
    } else {
      return localStorage.getItem(UserConstant.TOKEN);
    }
  }

  isPasswordtoken() {
    return localStorage.getItem(UserConstant.PASSWORDTOKEN);
  }

  getCookie(cookie_name) {
    let name = cookie_name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cookie_name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    let expires = "Expires="+d.toUTCString();
    document.cookie = cookie_name + "=" + value + ";Domain=.rabbit.run;" + expires + ";path=/;SameSite=None;Secure;";
    console.log("set cookie");
    console.log(document.cookie);
  }

  clearCookie(cookie_name) {
    document.cookie = cookie_name + "=; Max-Age=0; SameSite=None; Secure;";
  }
}
