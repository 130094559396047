import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';

@Component({
  selector: 'app-organization-model',
  templateUrl: './organization-model.component.html',
  styleUrls: ['./organization-model.component.scss']
})
export class OrganizationModelComponent implements OnInit {
  deviceForm: FormGroup;
  submitted = false;
  getdeviceArray = [];
  device = [];
  filteredOrg = [];
  dlgData: any;
  orgId: any;
  role: any;
  domain: any;
  selected: any;
  searchOrgPipe: any;
  roleShow:boolean = false;
  userRole: any;

  @ViewChild('orginput') searchElement: ElementRef;

  orgType = [{
    name: "Master MSP",
    id: 1
  },
  {
    name: "MSP",
    id: 2
  },
  {
    name: "Organization",
    id: 3
  }
  ]
  selectedRole: any;
  organizationId: any;

  constructor(private formBuilder: FormBuilder, public userService: UserService, private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<OrganizationModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.userRole = storedUser['role'];
    this.organizationId = storedUser['_id']; 
    
    this.domain = this.data.domain;
    if (this.data && this.data._id) {
      this.dlgData = this.data;
      this.orgId = this.dlgData._id;
    }
    this.role = this.data.role;
    this.deviceForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      parent: new FormControl(null, []),
      domain: new FormControl('', [Validators.required]),
      role: new FormControl(null, [Validators.required]),

    });

    if (this.orgId) {
      this.deviceForm.controls.name.setValue(this.dlgData.name);
      this.deviceForm.controls.domain.setValue(this.dlgData.domain);
    }

    this.userService.getOrganization({id: this.orgId}).subscribe(res => {
      let filterOrg = this.getDomainOrg(res.data[0]);
      this.filteredOrg = filterOrg.filter(o => o.role <=2)

      this.selected = this.domain._id
      
      if(this.role == 3){
        this.roleShow = false;
      } else {
        this.roleShow = true;
        if(this.role == 2){
          this.selectedRole = 3;
        } else if(this.role == 1){
          this.selectedRole = 2;
        } else if(this.role == 0){
          this.selectedRole = 1;
        }
      }
    })
  }

  searchFocus() {
    setTimeout(()=>{ 
      this.searchElement.nativeElement.focus();
    },50);
  }

  onOrgChange(event) {
    let org = this.filteredOrg.find(o => o._id == event.value)
    this.role = org.role

    if(this.role == 3){
        this.roleShow = false;
      } else {
        this.roleShow = true;
        if(this.role == 2){
          this.selectedRole = 3;
        } else if(this.role == 1){
          this.selectedRole = 2;
        } else if(this.role == 0){
          this.selectedRole = 1;
        }
      }
  }

  getDomainOrg(domain) {
    let filtered = [];
    filtered.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filtered = [...filtered, ...this.getDomainOrg(org)]
    }
    return filtered;
  }

  onSubmit() {
    if (this.submitted) return;
    // stop here if form is invalid
    if (this.deviceForm.invalid) {

      return;
    } else {
      let name = this.deviceForm.get('name').value;
      let parent = this.deviceForm.get('parent').value;
      let domain = this.deviceForm.get('domain').value;
      let role = this.deviceForm.get('role').value;

      const postObject = {
        name: name,
        parentOrg: parent,
        domain: domain,
        role: role,
      };
      if (this.orgId) {
        postObject['_id'] = this.orgId;
        
      }
      this.submitted = true;
      this.userService.createOrganization(postObject).subscribe(res => {
        this.submitted = false;
        this.toastr.success(' Organization Created Successfully!');
        this.dialogRef.close();
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }));
    }
  }
}
