import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  NgZone,
} from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { TopMenu, TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { combineLatest, Subscription } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-header',
  host: {
    class: 'matero-header',
  },
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() showToggle = true;

  @Output() toggleSidenav = new EventEmitter<void>();

  userName: any;
  userInfo: any;
  loginType: any;

  menu$ = this.menu.getAll();
  menuList: TopMenu[] = [];
  private menuSubscription: Subscription;
  loadDataSubscription: Subscription;

  searchPipe: any;
  isVisible: boolean;
  smallScreen: boolean;
  allOrgList = [];
  deviceArray = [];
  org: any;

  isLoaded: boolean = false;

  @ViewChild('input') searchInput: ElementRef;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public apService: ApService,
    private loginserice: LoginService,
    private authService: AuthService,
    private toastr: ToastrService,
    private menu: TopnavbarService,
    public userService: UserService,
    private changeDetection: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
  }

  onResize(event) {
    if (event.target.innerWidth < 767 || window.innerWidth < 767) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }
  }

  ngOnInit() {
    if (window.innerWidth < 767) {
      this.smallScreen = true;
    } else {
      this.smallScreen = false;
    }

    this.userInfo = JSON.parse(localStorage.getItem('REBIT_USER'));
    this.loginType = localStorage.getItem(UserConstant.LOGINTYPE) ? localStorage.getItem(UserConstant.LOGINTYPE) : null;
    if (this.userInfo) {
      this.userName = this.userInfo.name;
    }

    this.menuSubscription = this.menu$.subscribe({
      next: res => {
        this.menuList = res;
        this.changeDetection.detectChanges();
      }
    })
  }

  loadAllOrganization() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    if (!user || this.isLoaded) return;
    let orgId = user.organization;
    let obj = {
      id: orgId
    }
    let data = {
      params: {
        id: orgId,
      }
    };

    this.loadDataSubscription = combineLatest([
      this.userService.getOrganization(obj),
      this.apService.getListing(data),
    ]).subscribe(results => {
      let res = results[0];
      try {
        this.allOrgList = this.getDomainOrgs(res.data[0]);
        this.org = res.data;
      } catch (e) {
        console.log(e);
      }

      res = results[1];
      try {
        this.deviceArray = res.data;
      } catch (e) {
        console.log(e);
      }
      this.isLoaded = true;
      this.changeDetection.detectChanges();
    });
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, prtgDeviceId: domain.prtgDeviceId, prtgOrgId: domain.prtgOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  logout(toast = true) {
    this.router.navigate(['/login']);
    localStorage.removeItem(UserConstant.TOKEN);
    localStorage.removeItem(UserConstant.USER);
    localStorage.removeItem(UserConstant.LASTUPDATETIME);
    if (toast) {
      this.toastr.success('Logout Successfully');
    }
  }

  openSearch() {
    this.isVisible = !this.isVisible;
    this.searchPipe = '';
  }

  loadBreadcrumbs(name,id){
    id = id.replace("/layout/organization/","")
    setTimeout(() => {
      this.router.navigate(["/layout/organization/" + name + "_" + id]).then(() => {
        if (this.router.url.includes('/layout/organization/')) {
          window.location.reload();
        }
      });
    }, 50);
  }

  clearSearch() {
    this.router.navigate(['layout/search', { text: this.searchPipe }]).then(() => {
      if (this.router.url.includes('/layout/search')) {
        window.location.reload();
      }
    });
    this.searchPipe = '';
  }

  clickOrgSearch(name, id) {
    this.router.navigate(["/layout/organization/" + name + "_" + id]).then(() => {
      if (this.router.url.includes('/layout/organization/')) {
        window.location.reload();
      }
    });
    this.searchPipe = '';
  }

  clickDeviceSearch(name, id) {
    this.router.navigate(["/layout/org/" + this.org[0]['_id'] + "/device/" + name + "_" + id]).then(() => {
      if (this.router.url.includes('/layout/org')) {
        window.location.reload();
      }
    });
    this.searchPipe = '';
  }

  ngOnDestroy() {
    this.menuSubscription?.unsubscribe();
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
