import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {
  showError = false;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
    public route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(
      params => {
        let email = params['email']
        let hash = params['hash']
        this.loadData(email, hash)
      });
  }

  ngOnInit(): void { }

  loadData(email, hash) {
    localStorage.removeItem(UserConstant.TOKEN);
    localStorage.removeItem(UserConstant.USER);
    localStorage.removeItem(UserConstant.LOGINTYPE);
    this.loginService.autoLogin({ email, hash }).subscribe(res => {
      if (res.token) {
        this.toastr.success('Login success');
        const d = new Date();
        d.setTime(d.getTime() + (60 * 24 * 60 * 60 * 1000));
        localStorage.setItem(UserConstant.TOKEN, res.token);
        localStorage.setItem(UserConstant.USER, JSON.stringify(res.user));
        localStorage.setItem(UserConstant.LOGINTYPE, "iframe")
        this.router.navigate(['/layout']);
      }
    }, (err => {
      err.status = 400;
      this.showError = true;
    }));
  }

}
