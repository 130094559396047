<form>
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Update Device Firmware</legend>
        <p style="color: red;" *ngIf="data.state != 'offline'"><i>Warning: THIS MAY AFFECT DEVICE OPERATION</i></p>

        <div mat-dialog-content>
            <p class="mb-1">Current Device Firmware: <span
                    style="padding: 5px 10px; background: #e47e30; color: white;">{{data.statistics.firmware ?
                    data.statistics.firmware.currentVersion:data.firmware?data.firmware: '' }}</span></p>
            <mat-form-field>
                <mat-label>Select Firmware</mat-label>
                <mat-select (selectionChange)="selectFirmware($event)" [disabled]="data.state === 'offline' ? 'true' : 'false'">
                    <mat-option *ngFor="let firm of firmwareList" [value]="firm._id">
                        {{ firm.version }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <section class="example-section custom">
                <mat-checkbox class="example-margin" [(ngModel)]="factoryReset" [disabled]="data.state === 'offline' ? 'true' : 'false'">Keep
                    Existing Device Configuration<br><span class="hint" style="color: #ff5252;">- If not selected, the
                        device will
                        factory reset</span>
                </mat-checkbox>
            </section>

            <section class="example-section custom">
                <mat-checkbox class="example-margin" (change)="selectConfigureType($event, 0)"
                    [checked]="configureType==0" [disabled]="data.state === 'offline' ? 'true' : 'false'">Update on
                    Next Restart<br><span class="hint">- Download and wait for next reboot</span>
                </mat-checkbox>
            </section>

            <section class="example-section custom">
                <mat-checkbox class="example-margin" (change)="selectConfigureType($event, 1)"
                    [checked]="configureType==1" [disabled]="data.state === 'offline' ? 'true' : 'false'">Upgrade
                    Immediately<br><span class="hint">- Download and upgrade now </span><br><span class="hint">- The
                        device will
                        reboot after Download </span>
                </mat-checkbox>
            </section>
        </div>

        <p class="my-2" style="color: red;" *ngIf="data.state == 'offline'"><i>Warning: CANNOT UPDATE FIRMWARE AS
                DEVICE'S STATUS IS OFF</i></p>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()"
                [disabled]="data.state === 'offline' ? 'true' : 'false'">Update</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>
<!-- <h1 mat-dialog-title class="mt-3 mb-1">Update Device Firmware</h1> -->