<div class="mt-2 monitor-device-sipwatch-callerid-settings-wrapper panel">
    <div class="mt-4">       
        <mat-tab-group animationDuration="0ms" >          
            <mat-tab label="Config">
                <hr class="y-border" />
                <table>
                    <tbody>
                        <tr>
                            <th>Status</th>
                            <th>POS Type</th>
                            <th>Caller ID Mode</th>
                            <th>Caller ID Broadcast Port</th>
                            <th>Broadcast Proto</th>
                            <th>Broadcast Port</th>
                            
                        </tr>
                        <tr *ngIf="callerIDConfig">
                            <td>{{ displayEnabled(callerIDConfig.enabled) }}</td>
                            <td>{{ callerIDConfig.pos_type }}</td>                            
                            <td>{{ callerIDConfig.mode }}</td>
                            <td>{{ callerIDConfig.ether_ports }}</td>
                            <td>{{ callerIDConfig.proto }}</td>
                            <td>{{ callerIDConfig.port }}</td>
                        </tr>
                        <tr *ngIf="!callerIDConfig">
                            <td style="text-align: center;" colspan="6">No data</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
              <!-- (selectedTabChange)="selectedTabChange($event)"
        [selectedIndex]="selectedTabIndex" -->
            <mat-tab label="Map">
                <hr class="y-border" />
                <table>
                    <tbody>
                        <tr>
                            <th>SIPWatch Phone</th>
                            <th>POS IP</th>
                        </tr>
                        <tr *ngFor="let element of callertIDMap;" >
                            <td>{{ element.siptrack_uid }}</td>
                            <td>{{ element.pos_ip }}</td>
                        </tr>
                        <tr *ngIf="!callertIDMap">
                            <td style="text-align: center;" colspan="2">No data</td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
            <mat-tab label="CallerID Log">
                <app-device-sipwatch-callerid-logs [deviceInfo]="_device"></app-device-sipwatch-callerid-logs>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
