<div>
    <div class="mt-4">
        <span class="pointer vhub-vce-btn" *ngIf="showNote">
            Sipwatch License not selected - please select and subscribe if required.
        </span>
        <mat-tab-group class="mt-2 monitor-device-alert-wrapper panel" animationDuration="0ms">          
            <mat-tab label="SIPWatch Settings">
                <app-device-sipwatch-alert-settings [deviceInfo]="_device"></app-device-sipwatch-alert-settings>
            </mat-tab>
            <!-- <mat-tab label="SIPWatch Alerts Log">
                <app-device-sipwatch-alert-logs [deviceInfo]="_device"></app-device-sipwatch-alert-logs>
            </mat-tab> -->
            <mat-tab label="CallerID Settings">
                <app-device-sipwatch-callerid-settings [deviceInfo]="_device"></app-device-sipwatch-callerid-settings>
            </mat-tab>
            <!-- <mat-tab label="CallerID Log">
                <app-device-sipwatch-callerid-logs [deviceInfo]="_device"></app-device-sipwatch-callerid-logs>
            </mat-tab> -->
        </mat-tab-group>
    </div>
</div>
