import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  getUsersId$: any;
  getUsers$: any;
  getOrganizationUsers$: any;
  getOrganizationId$: any;
  getOrganization$: any;
  parentOrganizationList$: any;
  createUser$: any;
  createOrganization$: any;
  updateUser$: any;
  posdevicedata$: any;
  getbackup$: any;
  createBackup$: any;

  constructor(private http: HttpClient) { }
  getUsers(id): Observable<any> {
    if (id) {
      return this.http.get<any>(environment.endpoints.updateprofile + '?id=' + id)
    } else {
      return  this.http.get<any>(environment.endpoints.updateprofile)
    }
  }

  getAccessToken(email: any) {
    return  this.http.get<any>(environment.endpoints.users+'/access_token?email='+email)
  }

  getOrganizationUsers(data): Observable<any> {
    return  this.http.get<any>(environment.endpoints.organization + '/' + data.id)
  }

  getOrganization(data): Observable<any> {
    if (data['id']) {
      return  this.http.get<any>(environment.endpoints.organization + '/' + data.id, data)
    } else {
      return  this.http.get<any>(environment.endpoints.organization, data)
    }
  }

  parentOrganizationList(id): Observable<any> {
    if (id.includes("_")) {
      id = id.split("_").pop()
    }
    return  this.http.get<any>(environment.endpoints.organization + '/parent/' + id)
  }

  createUser(data): Observable<any> {
    return  this.http.post<any>(environment.endpoints.users + '/' + data.orgId, data)
  }

  createOrganization(data): Observable<any> {
    return  this.http.post<any>(environment.endpoints.organization, data)
  }

  deleteOrganization(data): Observable<any> {
    return this.http.delete<any>(environment.endpoints.organization + '/' + data.orgId);
  }

  updateUser(data): Observable<any> {
    return  this.http.post<any>(environment.endpoints.updateprofile, data)
  }

  deleteUser(data): Observable<any> {
    return this.http.delete<any>(environment.endpoints.deleteUser + '/' + data.id);
  }

  posdevicedata(data): Observable<any> {
    return  this.http.post<any>(environment.endpoints.postdevice, data)
  }
  deviceDelete(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        device: data
      }
    };
    return this.http.delete<any>(environment.endpoints.devicedelete, options);
  }

  getbackup(id): Observable<any> {
    return  this.http.get<any>(environment.endpoints.deviceBackup + '/' + id + '/' + 'backup')
  }

  createBackup(data, id): Observable<any> {
    return  this.http.post<any>(environment.endpoints.deviceBackup + '/' + id + '/setCommand', data)
  }
  deleteBackup(data, id): Observable<any> {
    return this.http.delete<any>(environment.endpoints.deviceBackup + '/' + id + '/backup/' + data);
  }

}
