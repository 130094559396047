<h6>Link Latency</h6>
<div style="display: block; max-width: 900px;" *ngIf="!large && lineChartData.length">
    <canvas baseChart width="900" height="500" [datasets]="lineChartData" [labels]="lineChartLabels"
        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
    </canvas>
</div>
<div style="display: block;" *ngIf="large && lineChartData.length">
    <canvas baseChart width="1800" height="700" [datasets]="lineChartData" [labels]="lineChartLabels"
        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
    </canvas>
</div>