<div class="matero-sidebar-header" *ngIf="showHeader">
  <a class="matero-branding" href="/">
    <img src="./assets/images/brand.png" class="matero-branding-logo-expanded" alt="logo" />
    <!-- <span class="matero-branding-name">MATERO</span> -->
  </a>
  <span fxFlex></span>
  <mat-slide-toggle *ngIf="showToggle" [checked]="toggleChecked" (change)="toggleCollapsed.emit()" matTooltip="Toggle Menu">
  </mat-slide-toggle>
</div>

<div class="matero-sidebar-main scrollbar-none">
  <app-sidemenu [ripple]="showToggle"></app-sidemenu>
</div>