import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-monitor-alert',
  templateUrl: './device-monitor-alert.component.html',
  styleUrls: ['./device-monitor-alert.component.scss']
})
export class DeviceMonitorAlertComponent implements OnInit {
  _device: any = []
  @Input() set device(obj) {
    if (obj) {
      this._device = obj;
    }
  }
  constructor() { }

  ngOnInit(): void { }

  

}
