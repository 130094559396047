import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NetworkRoutingModule } from './network-routing.module';
import { AddNetworkComponent } from './add-network/add-network.component';
import { NetworkDevicesComponent } from './network-devices/network-devices.component';

import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { NetworkDataComponent } from './add-network/network-data/network-data.component';
import { WirelessNetworkComponent } from './wireless-network/wireless-network.component';

@NgModule({
  declarations: [
    AddNetworkComponent, 
    NetworkDevicesComponent,
    NetworkDataComponent,
    WirelessNetworkComponent
  ],
  imports: [
    CommonModule,
    NetworkRoutingModule,
    RouterModule,
    SharedModule
  ]
})
export class NetworkModule { }
