import { Component, OnInit, Inject, Input, Output, EventEmitter, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { TemplateService } from 'src/app/shared/services/template.service';
import { EncryptionmalgorithmTypes, NetworkTypes, CountryTypes, TxPowerTypes, ChannelTypes1, ChannelTypes2, HTModeTypes1, HTModeTypes2, HWModeTypes1, HWModeTypes2, EncryptionmModeTypes } from 'src/app/shared/model/template';

@Component({
  selector: 'app-network-data',
  templateUrl: './network-data.component.html',
  styleUrls: ['./network-data.component.scss']
})
export class NetworkDataComponent implements OnInit {
  networkForm: FormGroup;
  encryptionArray: any = EncryptionmModeTypes;
  encryptionalgorithamArray: any = EncryptionmalgorithmTypes;
  networkArray: any = NetworkTypes;
  countryArray: any = CountryTypes;
  txPowerArray: any = TxPowerTypes;
  channelArray: any = ChannelTypes1;
  hwmodeArray: any = HWModeTypes1;
  htmodeArray: any = HTModeTypes1;
  networkDialogData: any;
  networkId: any;

  oldVal: any;

  showCipher = true;
  showKey = true;
  showHtMode = true;

  @Input() networkData: any;
  @Input() country: any;
  @Input() txpower: any;
  @Input() channel: any;
  @Input() hwmode: any;
  @Input() htmode: any;

  @Input() isLast: boolean;

  isClient = true;
  is2G = false;
  @Output() wifi2GSettings = new EventEmitter<any>();
  @Output() wifi5GSettings = new EventEmitter<any>();

  firstLoad: boolean = true;

  _showToggle: any = true;
  @Input() set showToggle(b) {
    if (b.includes('2G') && !b.includes('Client')) {
      this.channelArray = ChannelTypes1;
      this.hwmodeArray = HWModeTypes1;
      this.isClient = false;
      this.is2G = true;
    } else if (b.includes('5G') && !b.includes('Client')) {
      this.channelArray = ChannelTypes2
      this.hwmodeArray = HWModeTypes2;
      this.isClient = false;
      this.is2G = false;
    }
  }
  @Input() index: number;
  @Output() validSubmit = new EventEmitter<any>();
  @Output() dataChange = new EventEmitter<any>();

  hide: boolean = true;

  constructor(
    public formBuilder: FormBuilder,
    public Networkservices: TemplateService,
  ) {

    this.networkForm = this.formBuilder.group({
      enabled: new FormControl(false),
      label: new FormControl(''),
      ssid: new FormControl('', [Validators.required]),
      key: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(63)]),
      enc: new FormControl(''),
      cipher: new FormControl(''),
      hidden: new FormControl(null),
      country: new FormControl(''),
      txpower: new FormControl(''),
      hwmode: new FormControl(''),
      htmode: new FormControl(''),
      channel: new FormControl(''),
    }, { updateOn: 'blur' });

    this.networkForm.controls['enc'].valueChanges.subscribe(value => {
      if (['psk', 'psk2', 'psk-mixed'].includes(value)) {
        this.showCipher = true;
      } else {
        this.showCipher = false;
      }
      if (['psk', 'psk2', 'psk-mixed'].includes(value)) {
        this.networkForm.controls["key"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(63)]);
        this.networkForm.controls["key"].updateValueAndValidity();
        // this.networkForm.controls["key"].setValue('');
      } else if (['wep-open', 'wep-shared'].includes(value)) {
        this.networkForm.controls["key"].setValidators([Validators.required, this.len5or13()]);
        this.networkForm.controls["key"].updateValueAndValidity();
        // this.networkForm.controls["key"].setValue('');
      } else {
        this.networkForm.controls["key"].clearValidators();
        this.networkForm.controls["key"].updateValueAndValidity();
        // this.networkForm.controls["key"].setValue('');
      }
      if (['none'].includes(value)) {
        this.showKey = false;
      } else {
        this.showKey = true;
      }
    });

    this.networkForm.controls['hwmode'].valueChanges.subscribe(value => {
      if (['11b', '11g'].includes(value)) {
        this.showHtMode = false;
      } else if (['11n'].includes(value)) {
        this.showHtMode = true;
        this.htmodeArray = HTModeTypes1
      } else if (['11ac'].includes(value)) {
        this.showHtMode = true;
        this.htmodeArray = HTModeTypes2;
      }
    })

    this.networkForm.valueChanges.subscribe(value => {
      if (this.networkForm.invalid) {
        this.validSubmit.emit(false);
      } else {
        this.dataChange.emit(this.networkForm.getRawValue())
        this.validSubmit.emit(true);
      }
    })
  }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('networkData' in changes || 'country' in changes || 'txpower' in changes
      || 'channel' in changes || 'hwmode' in changes || 'htmode' in changes) {
      this.setForm();
      this.firstLoad = false;
    }
  }

  settingSelected(value) {
    let data = this.networkData;
    if (data.label.includes('5G')) {
      this.wifi5GSettings.emit(value);
    } else if (data.label.includes('2G')) {
      this.wifi2GSettings.emit(value);
    }
  }

  setForm() {
    let data = this.networkData;
    this.networkForm.controls.enabled.setValue(data.enabled ? data.enabled : false);
    this.networkForm.controls.label.setValue(data.label);
    let old_ssid = this.networkForm.controls.ssid.value;
    this.networkForm.controls.ssid.setValue(old_ssid && old_ssid.length ? old_ssid : data.ssid);
    let old_enc = this.networkForm.controls.enc.value;
    this.networkForm.controls.enc.setValue(old_enc && old_enc.length ? old_enc : data.enc);
    let old_key = this.networkForm.controls.key.value;
    this.networkForm.controls.key.setValue(old_key && old_key.length ? old_key : data.key);
    let old_cipher = this.networkForm.controls.cipher.value;
    this.networkForm.controls.cipher.setValue(old_cipher && old_cipher.length ? old_cipher : data.cipher);
    this.networkForm.controls.country.setValue(data.country);
    if (this.firstLoad === true) {
      this.networkForm.controls.txpower.setValue(data.txpower = '18');
    } else {
      this.networkForm.controls.txpower.setValue(data.txpower);
    }
    this.networkForm.controls.hwmode.setValue(data.hwmode);
    this.networkForm.controls.htmode.setValue(data.htmode);
    this.networkForm.controls.channel.setValue(data.channel);
    if (data.hidden !== undefined) {
      this.networkForm.controls.hidden.setValue(data.hidden);
    }
    if (!data.enabled) {
      this.networkForm.controls.label.disable();
      this.networkForm.controls.ssid.disable();
      this.networkForm.controls.key.disable();
      this.networkForm.controls.enc.disable();
      this.networkForm.controls.cipher.disable();
      this.networkForm.controls.country.disable();
      this.networkForm.controls.txpower.disable();
      this.networkForm.controls.hwmode.disable();
      this.networkForm.controls.htmode.disable();
      this.networkForm.controls.channel.disable();
      this.networkForm.controls.hidden.disable();
    }
    this.validSubmit.emit(this.networkForm.valid);
    this.dataChange.emit(this.networkForm.getRawValue())
  }

  onToggle(evt) {
    if (evt.checked) {
      this.networkForm.controls.label.enable();
      this.networkForm.controls.ssid.enable();
      this.networkForm.controls.key.enable();
      this.networkForm.controls.enc.enable();
      this.networkForm.controls.cipher.enable();
      this.networkForm.controls.hidden.enable();
      this.networkForm.controls.country.enable();
      this.networkForm.controls.channel.enable();
      this.networkForm.controls.txpower.enable();
      this.networkForm.controls.hwmode.enable();
      this.networkForm.controls.htmode.enable();
    } else {
      this.networkForm.controls.label.disable();
      this.networkForm.controls.ssid.disable();
      this.networkForm.controls.key.disable();
      this.networkForm.controls.enc.disable();
      this.networkForm.controls.cipher.disable();
      this.networkForm.controls.hidden.disable();
      this.networkForm.controls.country.disable();
      this.networkForm.controls.channel.disable();
      this.networkForm.controls.txpower.disable();
      this.networkForm.controls.hwmode.enable();
      this.networkForm.controls.htmode.enable();
    }
  }

  len5or13(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      (control.value?.length === 5 || control.value?.length === 13)
        ? null : { wrongLength: control.value };
  }
}
