export const Timezone = [
{
  name: "(UTC-12:00) International Date Line West",
  tz: "Etc/GMT+12",
  "tz-posix":"GMT+12"
},
{
  name: "(UTC-11:00) Coordinated Universal Time-11",
  tz: "Etc/GMT+11",
  "tz-posix":"GMT+11"
},
{
  name: "(UTC-10:00) Hawaii",
  tz: "Pacific/Honolulu",
  "tz-posix":"HST10"
},
{
  name: "(UTC-09:00) Alaska",
  tz: "America/Anchorage",
  "tz-posix":"AKST9AKDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-08:00) Baja California",
  tz: "America/Santa_Isabel",
  "tz-posix":"PST8PDT,M4.1.0,M10.5.0"
},
{
  name: "(UTC-08:00) Pacific Time (US and Canada)",
  tz: "America/Los_Angeles",
  "tz-posix":"PST8PDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  tz: "America/Chihuahua",
  "tz-posix":"MST7MDT,M4.1.0,M10.5.0"
},
{
  name: "(UTC-07:00) Arizona",
  tz: "America/Phoenix",
  "tz-posix":"MST7"
},
{
  name: "(UTC-07:00) Mountain Time (US and Canada)",
  tz: "America/Denver",
  "tz-posix":"MST7MDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-06:00) Central America",
  tz: "America/Guatemala",
  "tz-posix":"CST6"
},
{
  name: "(UTC-06:00) Central Time (US and Canada)",
  tz: "America/Chicago",
  "tz-posix":"CST6CDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-06:00) Saskatchewa",
  tz: "America/Regina",
  "tz-posix":"CST6"
},
{
  name: "(UTC-06:00) Guadalajara, Mexico City, Monterey",
  tz: "America/Mexico_City",
  "tz-posix":"CST6CDT,M4.1.0,M10.5.0"
},
{
  name: "(UTC-05:00) Bogota, Lima, Quito",
  tz: "America/Bogota",
  "tz-posix":"COT5"
},
{
  name: "(UTC-05:00) Eastern Time (US and Canada)",
  tz: "America/New_York",
  "tz-posix":"EST5EDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-05:00) Indiana (East)",
  tz: "America/Indiana/Indianapolis",
  "tz-posix":"EST5EDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-04:30) Caracas",
  tz: "America/Caracas",
  "tz-posix":"VET4:30"
},
{
  name: "(UTC-04:00) Atlantic Time (Canada)",
  tz: "America/Halifax",
  "tz-posix":"AST4ADT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-04:00) Asuncion",
  tz: "America/Asuncion",
  "tz-posix":"PYT4PYST,M10.1.0/0,M3.4.0/0"
},
{
  name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  tz: "America/La_Paz",
  "tz-posix":"BOT4"
},
{
  name: "(UTC-04:00) Cuiaba",
  tz: "America/Cuiaba", 
  "tz-posix":"AMT4AMST,M10.3.0/0,M2.3.0/0"
},
{
  name: "(UTC-04:00) Santiago",
  tz: "America/Santiago",
  "tz-posix":"CLT3"
},
{
  name: "(UTC-03:30) Newfoundland",
  tz: "America/St_Johns",
  "tz-posix":"NST3:30NDT,M3.2.0,M11.1.0"
},
{
  name: "(UTC-03:00) Brasilia",
  tz: "America/Sao_Paulo",
  "tz-posix":"BRT3BRST,M10.3.0/0,M2.3.0/0"
},
{
  name: "(UTC-03:00) Greenland",
  tz: "America/Godthab",
  "tz-posix":"WGT3WGST,M3.5.0/-2,M10.5.0/-1"
},
{
  name: "(UTC-03:00) Cayenne, Fortaleza",
  tz: "America/Cayenne",
  "tz-posix":"GFT3"
},
{
  name: "(UTC-03:00) Buenos Aires",
  tz: "America/Argentina/Buenos_Aires",
  "tz-posix":"ART3"
},
{
  name: "(UTC-03:00) Montevideo",
  tz: "America/Montevideo",
  "tz-posix":"UYT3"
},
{
  name: "(UTC-02:00) Coordinated Universal Time-2",
  tz: "Etc/GMT+2",
  "tz-posix": "GMT+2"
},
{
  name: "(UTC-01:00) Cape Verde",
  tz: "Atlantic/Cape_Verde",
  "tz-posix":"CVT1"
},
{
  name: "(UTC-01:00) Azores",
  tz: "Atlantic/Azores",
  "tz-posix":"AZOT1AZOST,M3.5.0/0,M10.5.0/1"
},
{
  name: "(UTC+00:00) Casablanca",
  tz: "Africa/Casablanca",
  "tz-posix":"WET0WEST,M3.5.0,M10.5.0/3"
},
{
  name: "(UTC+00:00) Monrovia, Reykjavik",
  tz: "Atlantic/Reykjavik",
  "tz-posix":"GMT0"
},
{
  name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
  tz: "Europe/London",
  "tz-posix":"GMT0BST,M3.5.0/1,M10.5.0"
},
{
  name: "(UTC+00:00) Coordinated Universal Time",
  tz: "Etc/GMT",
  "tz-posix":"GMT",
},
{
  name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  tz: "Europe/Paris",
  "tz-posix":"CET-1CEST,M3.5.0,M10.5.0/3"
},
{
  name: "(UTC+01:00) West Central Africa",
  tz: "Africa/Lagos",
  "tz-posix":"WAT-1"
},
{
  name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  tz: "Europe/Budapest",
  "tz-posix":"CET-1CEST,M3.5.0,M10.5.0/3"
},
{
  name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  tz: "Europe/Warsaw",
  "tz-posix":"CET-1CEST,M3.5.0,M10.5.0/3"
},
{
  name: "(UTC+01:00) Windhoek	Africa/Windhoek",
  tz: "Africa/Windhoek",
  "tz-posix":"WAT-1WAST,M9.1.0,M4.1.0"
},
{
  name: "(UTC+02:00) Athens, Bucharest, Istanbul",
  tz: "Europe/Istanbul",
  "tz-posix":"EET-2EEST,M3.5.0/3,M10.5.0/4"
},
{
  name: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  tz: "Europe/Kiev",
  "tz-posix":"EET-2EEST,M3.5.0/3,M10.5.0/4"
},
{
  name: "(UTC+02:00) Cairo",
  tz: "Africa/Cairo",
  "tz-posix":"EET-2"
},
{
  name: "(UTC+02:00) Damascus",
  tz: "Asia/Damascus",
  "tz-posix":"EET-2EEST,M3.5.5/0,M10.5.5/0"
},
{
  name: "(UTC+02:00) Amman",
  tz: "Asia/Amman",
  "tz-posix":"EET-2EEST,M3.5.4/24,M10.5.5/1"
},
{
  name: "(UTC+02:00) Harare, Pretoria",
  tz: "Africa/Johannesburg",
  "tz-posix":"SAST-2"
},
{
  name: "(UTC+02:00) Jerusalem",
  tz: "Asia/Jerusalem",
  "tz-posix":"IST-2IDT,M3.4.4/26,M10.5.0"
},
{
  name: "(UTC+02:00) Beirut",
  tz: "Asia/Beirut",
  "tz-posix":"EET-2EEST,M3.5.0/0,M10.5.0/0"
},
{
  name: "(UTC+03:00) Baghdad",
  tz: "Asia/Baghdad",
  "tz-posix":"AST-3"
},
{
  name: "(UTC+03:00) Minsk",
  tz: "Europe/Minsk",
  "tz-posix":"MSK-3"
},
{
  name: "(UTC+03:00) Kuwait, Riyadh",
  tz: "Asia/Riyadh",
  "tz-posix":"AST-3"
},
{
  name: "(UTC+03:00) Nairobi",
  tz: "Africa/Nairobi",
  "tz-posix":"EAT-3"
},
{
  name: "(UTC+03:30) Tehran",
  tz: "Asia/Tehran",
  "tz-posix":"IRST-3:30IRDT,80/0,264/0"
},
{
  name: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
  tz: "Europe/Moscow",
  "tz-posix":"MSK-3"
},
{
  name: "(UTC+04:00) Tbilisi",
  tz: "Asia/Tbilisi",
  "tz-posix":"GET-4"
},
{
  name: "(UTC+04:00) Yerevan",
  tz: "Asia/Yerevan",
  "tz-posix":"AMT-4"
},
{
  name: "(UTC+04:00) Abu Dhabi, Muscat",
  tz: "Asia/Dubai",
  "tz-posix":"GST-4"
},
{
  name: "(UTC+04:00) Baku",
  tz: "Asia/Baku",
  "tz-posix":"AZT-4AZST,M3.5.0/4,M10.5.0/5"
},
{
  name: "(UTC+04:00) Port Louis",
  tz: "Indian/Mauritius",
  "tz-posix":"MUT-4"
},
{
  name: "(UTC+04:30) Kabul",
  tz: "Asia/Kabul",
  "tz-posix":"AFT-4:30"
},
{
  name: "(UTC+05:00) Tashkent",
  tz: "Asia/Tashkent",
  "tz-posix":"UZT-5"
},
{
  name: "(UTC+05:00) Islamabad, Karachi",
  tz: "Asia/Karachi",
  "tz-posix":"PKT-5"
},
{
  name: "(UTC+05:30) Sri Jayewardenepura Kotte",
  tz: "Asia/Colombo",
  "tz-posix":"IST-5:30"
},
{
  name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  tz: "Asia/Kolkata",
  "tz-posix":"IST-5:30"
},
{
  name: "(UTC+05:45) Kathmandu",
  tz: "Asia/Kathmandu",
  "tz-posix":"NPT-5:45"
},
{
  name: "(UTC+06:00) Astana",
  tz: "Asia/Almaty",
  "tz-posix":"ALMT-6"
},
{
  name: "(UTC+06:00) Dhaka",
  tz: "Asia/Dhaka",
  "tz-posix":"BDT-6"
},
{
  name: "(UTC+06:00) Yekaterinburg",
  tz: "Asia/Yekaterinburg",
  "tz-posix":"YEKT-5"
},
{
  name: "(UTC+06:30) Yangon",
  tz: "Asia/Yangon", 
  "tz-posix":"MMT-6:30"
},
{
  name: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  tz: "Asia/Bangkok",
  "tz-posix":"ICT-7"
},
{
  name: "(UTC+07:00) Novosibirsk",
  tz: "Asia/Novosibirsk",
  "tz-posix":"NOVT-6"
},
{
  name: "(UTC+08:00) Krasnoyarsk",
  tz: "Asia/Krasnoyarsk",
  "tz-posix":"KRAT-7"
},
{
  name: "(UTC+08:00) Ulaanbaatar",
  tz: "Asia/Ulaanbaatar",
  "tz-posix":"ULAT-8ULAST,M3.5.6,M9.5.6/0"
},
{
  name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  tz: "Asia/Shanghai",
  "tz-posix":"CST-8"
},
{
  name: "(UTC+08:00) Perth",
  tz: "Asia/Perth", 
  "tz-posix":"AWST-8"
},
{
  name: "(UTC+08:00) Kuala Lumpur, Singapore",
  tz: "Asia/Singapore", 
  "tz-posix":"SGT-8"
},
{
  name: "(UTC+08:00) Taipei",
  tz: "Asia/Taipei", 
  "tz-posix":"CST-8"
},
{
  name: "(UTC+09:00) Irkutsk",
  tz: "Asia/Irkutsk",
  "tz-posix":"IRKT-8"
},
{
  name: "(UTC+09:00) Seoul",
  tz: "Asia/Seoul", 
  "tz-posix":"KST-9"
},
{
  name: "(UTC+09:00) Osaka, Sapporo, Tokyo",
  tz: "Asia/Tokyo", 
  "tz-posix":"JST-9"
},
{
  name: "(UTC+09:30) Darwin",
  tz: "Asia/Darwin"
},
{
  name: "(UTC+09:30) Adelaide",
  tz: "Australia/Adelaide", 
  "tz-posix":"ACST-9:30"
},
{
  name: "(UTC+10:00) Hobart",
  tz: "Australia/Hobart",
  "tz-posix":"AEST-10AEDT,M10.1.0,M4.1.0/3"
},
{
  name: "(UTC+10:00) Yakutsk",
  tz: "Asia/Yakutsk",
  "tz-posix":"YAKT-9"
},
{
  name: "(UTC+10:00) Brisbane",
  tz: "Australia/Brisbane", 
  "tz-posix":"AEST-10"
},
{
  name: "(UTC+10:00) Guam, Port Moresby",
  tz: "Pacific/Port_Moresby", 
  "tz-posix":"PGT-10"
},
{
  name: "(UTC+10:00) Canberra, Melbourne, Sydney",
  tz: "Australia/Sydney", 
  "tz-posix":"AEST-10AEDT,M10.1.0,M4.1.0/3"
},
{
  name: "(UTC+11:00) Vladivostok",
  tz: "Asia/Vladivostok", 
  "tz-posix":"VLAT-10"
},
{
  name: "(UTC+11:00) Solomon Islands, New Caledonia",
  tz: "Pacific/Guadalcanal", 
  "tz-posix":"SBT-11"
},
{
  name: "(UTC+12:00) Coordinated Universal Time+12",
  tz: "Etc/GMT-12",
  "tz-posix": "GMT-12"
},
{
  name: "(UTC+12:00) Fiji, Marshall Islands",
  tz: "Pacific/Fiji", 
  "tz-posix":"FJT-12FJST,M11.1.0,M1.3.0/3"
},
{
  name: "(UTC+12:00) Magadan",
  tz: "Asia/Magadan",
  "tz-posix":"MAGT-10"
},
{
  name: "(UTC+12:00) Auckland, Wellington",
  tz: "Pacific/Auckland", 
  "tz-posix":"NZST-12NZDT,M9.5.0,M4.1.0/3"
},
{
  name: "(UTC+13:00) Nuku'alofa",
  tz: "Pacific/Tongatapu",
  "tz-posix":"TOT-13"
},
{
  name: "(UTC+13:00) Samoa",
  tz: "Pacific/Apia",
  "tz-posix":"WSST-13WSDT,M9.5.0/3,M4.1.0/4"
},
]
