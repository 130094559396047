import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { UserModelComponent } from './user-model/user-model.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { ApService } from 'src/app/shared/services/ap.service';

import { Subscription } from 'rxjs';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ApModelComponent } from '../device/ap-model/ap-model.component';
import { OrganizationModelComponent } from '../organization/organization-model/organization-model.component';
import { PageEvent } from '@angular/material/paginator';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dataSource: any = [];
  displayedColumns: string[] = ['name', 'email', 'organization', 'orgType', 'role', 'status', 'action'];
  id: any;
  orgId: string;
  orgName: string;
  role: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  pageIndex = 0;
  searchTxt = ""
  allData = [];
  orgType = [
    "Master MSP", "MSP", "Organization", "", "Admin", "User"
  ]

  private loadDataSubscription: Subscription;
  col: string;
  dir: string;
  url: string[];

  constructor(public dialog: MatDialog, public userService: UserService, public apService: ApService,
    public route: ActivatedRoute, private toastr: ToastrService, private submenuService: SubMenuService,
    private topnavbarService: TopnavbarService, private router: Router) {
  }

  dtOptions: any = {};

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id && this.router.url.includes("_")) {
      this.orgId = this.id.split("_").pop();
    } else if (this.id && !this.router.url.includes("_")) {
      this.orgId = this.id.split("/").pop();
    } else {
      this.orgId = this.user.organization;
    }

    this.loadData();

  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    //Set users links
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": this.domain ? "/layout/organization/" + this.domain['_id'] : "/layout/organization",
        "name": this.domain ? this.domain.name : '',
        "type": "link",
      },
      {
        "route": "",
        "name": "User",
        "type": "dropdown",
        "sub": [
          {
            "route": this.getDomainLink(),
            "name": "Domain",
            "type": "link",
          },
          {
            "route": this.getDeviceLink(),
            "name": "Device",
            "type": "link",
          },
          {
            "route": this.getUserLink(),
            "name": "User",
            "type": "link",
          }
        ]
      }
    ])
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role, prtgDeviceId: domain.prtgDeviceId, prtgOrgId: domain.prtgOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  getDomainUsers(domain) {
    let filteredUser = [];
    filteredUser = [...filteredUser, ...domain.subUsers]
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredUser = [...filteredUser, ...this.getDomainUsers(org)]
    }
    return filteredUser;
  }

  searchData() {
    if(this.router.url.includes("page=")){
      let url = (this.router.url.split("/layout/users?").pop()).split("&")
      this.currentPage = Number(url[0].split("=").pop())
    } else {
      this.currentPage = 1;
    }

    let data = this.allData.filter(subUser => {
      if (subUser) {
        if (subUser.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
          return true
        }
        if (subUser.email.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
          return true
        }
        if (subUser.orgName.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
          return true
        }
      }
      return false;
    })
    this.length = data.length;
    this.dataSource = data

    if(this.router.url === "/layout/users"){
      if(this.searchTxt.length > 0){
        this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt);
      } else {
        this.router.navigateByUrl(this.router.url);
      }
    } else {
      if(this.router.url.includes("column=")){
        if(this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order='+this.dir);
        } else {
          this.router.navigateByUrl('/layout/users?page=1&column='+this.col+'&order='+this.dir);
        }
      } else {
        if(this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/users?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url.split('?').shift());
        }
      }
    }
  }


  getUserLink() {
    if (this.orgId) {
      return '/layout/users/' + this.orgId;
    } else {
      return '/layout/users'
    }
  }

  getDeviceLink() {
    if (this.orgId) {
      return '/layout/org/' + this.orgId + '/devices';
    } else {
      return '/layout/devices'
    }
  }

  getDomainLink() {
    if (this.orgId) {
      let url = '/layout/organization/' + this.orgId;
      return url;
    } else {
      return '/layout/organization'
    }
  }

  addUserClick() {
    let objData = {};
    objData['orgId'] = this.orgId ? this.orgId : this.user.organization;
    objData['role'] = this.domain.role;
    objData['domain'] = this.domain;
    const dialogRef = this.dialog.open(UserModelComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  addDeviceClick() {
    const dialogRef = this.dialog.open(ApModelComponent, {
      disableClose: true,
      width: '700px',
      data: {
        domain: this.domain.domain,
        domainObj: this.domain
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  loadData() {
    this.loadDataSubscription = this.userService.getOrganizationUsers({ id: this.orgId }).subscribe(res => {
      res = res.data;
      this.orgName = res[0]['name'];
      this.domain = res[0];
      res = res[0];

      let subOrganizations = this.getDomainOrgs(res);
      let subUsers = this.getDomainUsers(res);
      subUsers = subUsers.map(user => {
        let subOrgUsers = subOrganizations.filter(org => { return org.orgId == user.organization });

        if(subOrgUsers.length){
          return ({
            ...user,
            ...{
              orgName: subOrgUsers[0]['name'],
              orgType: subOrgUsers[0]['role']
            }
          })
        }
      })

      this.allData = subUsers
      this.dataSource = this.allData;
      this.length = this.allData.length;
      this.setTopNavMenu();

      // Start: sort columns by URL parameters
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = this.url[0].split("=").pop()
        this.col = this.url[1].split("=").pop()
        this.dir = this.url[2].split("=").pop()
        this.searchData();
      } else {
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = this.url[0].split("=").pop()
        this.dir = this.url[1].split("=").pop()
      }
    } else {
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,1)
        this.searchTxt = this.url[0].split("=").pop()
        this.searchData();
      }
    }
    // End: sort columns by URL parameters
    });
  }

  addDomainClick(): void {
    const dialogRef = this.dialog.open(OrganizationModelComponent, {
      disableClose: true,
      width: '350px',
      data: { role: this.domain.role, domain: this.domain }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  networkClick(id) {
    const url = '/#/layout/users/' + id + '/wireless-network';
    location.href = url;

  }

  delete(id) {
    if (confirm('Are you sure you want to delete?')) {
      let obj = {
        id: id
      }
      let data = {
        params: {
          id: id,
        }
      };
      this.apService.getListing(data).subscribe(res => {
        const k = res;
        if (res && res.data.length == 0) {
          this.userService.deleteUser(obj).subscribe(res => {
            this.toastr.success('User deleted successfully');
            this.loadData();
          });
        } else {
          this.toastr.error('Devices are linked with this account, user can not be deleted.');
        }
      });

    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}

