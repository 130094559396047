import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApService } from 'src/app/shared/services/ap.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { PageEvent } from '@angular/material/paginator';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'app-network-devices',
  templateUrl: './network-devices.component.html',
  styleUrls: ['./network-devices.component.scss']
})
export class NetworkDevicesComponent implements OnInit {
  private pageAvailable: boolean = true;
  divicessArray: any = [];
  dataSource: any = [];
  wnetworkId: any;
  orgId: any;
  role: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  searchTxt = "";

  name = ""

  constructor(
    public dialog: MatDialog,
    public apService: ApService,
    public route: ActivatedRoute,
    public userService: UserService,
    private topnavbarService: TopnavbarService,
    private networkService: TemplateService,
  ) {
  }
  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    this.wnetworkId = this.route.snapshot.paramMap.get('id');
    if (this.wnetworkId) {
      this.wnetworkId = this.wnetworkId.split("_").pop()
    }
    this.orgId = this.user.organization;

    this.setTopNavMenu();
    this.loadData();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "/layout/wireless-network",
        "name": "WiFi Network",
        "type": "link",
      }
    ])
  }

  loadData() {
    let data = {
      params: {
        page: this.currentPage,
        pageSize: this.pageSize,
        searchTxt: this.searchTxt,
      }
    };
    this.apService.getWnetworkDevices(data, this.wnetworkId).subscribe(res => {
      if (res) {
        this.divicessArray = res;
        this.length = this.divicessArray.length;
        this.divicessArray.forEach(element => {
          let uptimeToString = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
          element.upTime = uptimeToString;
        });
        this.dataSource = this.divicessArray;
      } else {
        this.dataSource = [];
      }
    });
    
    this.networkService.getWnetwork(this.wnetworkId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.name = res.name;
      }
    })
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadData();
  }

  searchData() {
    this.dataSource = this.divicessArray.filter(device => {
      if (device.name && device.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.domain.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.mac.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.deviceMetaInfo.deviceModel.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.statistics.firmware.currentVersion.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
    this.length = this.dataSource.length;
  }

  ngOnDestroy() {
    this.pageAvailable = false;
  }
}
