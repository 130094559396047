<form [formGroup]="deviceForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Create Organization</legend>
        <div mat-dialog-content>
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" autocomplete="off"/>

                <mat-error *ngIf="deviceForm.controls['name'].hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Parent Organization</mat-label>
                <mat-select formControlName="parent" (click)="searchFocus()" (selectionChange)="onOrgChange($event)"
                    [(ngModel)]="selected">
                    <div class="dropdown-search">
                        <input #orginput [(ngModel)]="searchOrgPipe" [ngModelOptions]="{standalone: true}" matInput
                            placeholder="Search Domain">
                    </div>
                    <div>
                        <mat-option *ngFor="let organization of this.filteredOrg | searchOrg : searchOrgPipe"
                            [value]="organization._id">
                            {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                            == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                            "Organization": "") }})
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="roleShow">
                <mat-label>Type</mat-label>

                <mat-select formControlName="role" [(ngModel)]="selectedRole" disabled="true">

                    <mat-option *ngIf="(this.role <=0 && this.userRole==0) && selectedRole == 1" [value]="1">
                        Master MSP
                    </mat-option>
                    <mat-option *ngIf="(this.role <= 1 && this.userRole<=1) && selectedRole == 2" [value]="2">
                        MSP
                    </mat-option>
                    <mat-option *ngIf="(this.role <= 2 && this.userRole<=2) && selectedRole == 3" [value]="3">
                        Organization
                    </mat-option>

                </mat-select>

                <mat-error *ngIf="deviceForm.controls['role'].hasError('required')">
                    Type is required
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Domain</mat-label>
                <input type="text" matInput formControlName="domain" autocomplete="off"/>

                <mat-error *ngIf="deviceForm.controls['domain'].hasError('required')">
                    Domain is required
                </mat-error>
            </mat-form-field>

        </div>
        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class="btn btnn" (click)="onSubmit()">Save</button>
            <button mat-raised-button color="warn" class="btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>