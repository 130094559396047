<div class="">
    <mat-card>
        <h4 class="text-center">{{org?.name}} Alerts Overview</h4>
    </mat-card>
    <div class="mt-4">
        <mat-tab-group class="mt-2 monitor-device-alert-wrapper panel" animationDuration="0ms">          
            <mat-tab label="Links State">
                <app-alert-links></app-alert-links>
            </mat-tab>
            <mat-tab label="Device State">
                <app-alert-devices></app-alert-devices>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>