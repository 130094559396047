import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ApService } from 'src/app/shared/services/ap.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { PdpTypes, SdtTypes, AuthTypes } from 'src/app/shared/model/template';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-monitor-i4glte',
  templateUrl: './device-monitor-i4glte.component.html',
  styleUrls: ['./device-monitor-i4glte.component.scss']
})
export class DeviceMonitorI4glteComponent implements OnInit, OnDestroy {
  _device: any;
  name: any;
  apn: any;
  auth: any;
  username: any;
  password: any;
  pdptype: any;
  servicedomain: any;
  sim: boolean;
  imei: any;
  ccid: any;
  imsi: any;
  iccid: any;
  operator: any;
  lte_band_custom: any;
  lte_band: any;
  carrier: any;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  label = "";
  checked = false
  pdpTypeArray = PdpTypes
  sdtType = SdtTypes
  authType = AuthTypes
  hideToggle = false;

  private loadDataSubscription: Subscription;

  constructor(
    public templateService: TemplateService,
    private formBuilder: FormBuilder,
    public apService: ApService
  ) { }

  ngOnInit(): void {
  }

  loadData() {
    if (this._device.templateConfig && this._device.templateConfig['4glte'] && (this._device.templateConfig['4glte'].template_enabled == false)) {
      if (this._device.templateConfig['4glte'].name) {
        this.label = this._device.templateConfig['4glte'].template_name !== null ? (this._device.templateConfig['4glte'].template_enabled == false ? this._device.templateConfig['4glte'].template_name + " (Template-Disabled)" : this._device.templateConfig['4glte'].template_name + " (Template-Enabled)") : "Device (Configuration)";
      } else if (this._device['4glte']) {
        this.loadDataSubscription = this.templateService.getI4glte(this._device['4glte']).subscribe(res => {
          if (res && res[0]) {
            let data = res[0];
            this.label = data.name + " (Template - Disabled)";
          }
        })
      } else {
        let config = this._device.templateConfig['4glte']['config']
        this.apn = config.apn;
        this.auth = config.auth;
        this.username = config.username;
        this.password = config.password !== undefined ? config.password.replace(/./g, "*") : "";
        this.pdptype = config.pdptype;
        this.lte_band_custom = config.lte_band_custom !== undefined ? config.lte_band_custom.split(" ") : [];
        this.lte_band = config.lte_band;
        this.carrier = config.carrier;
        this.servicedomain = config.servicedomain;
        this.label = this._device.templateConfig['4glte'].template_name !== null ? (this._device.templateConfig['4glte'].template_enabled == false ? this._device.templateConfig['4glte'].template_name + " (Template-Disabled)" : this._device.templateConfig['4glte'].template_name + " (Template-Enabled)") : "Device (Configuration)";
        this.hideToggle = true;
      }
    }
    if (this._device.statistics && this._device.statistics['4glte']) {
      let config = this._device.statistics['4glte']
      this.apn = config.apn;
      this.auth = config.auth;
      this.username = config.username;
      this.password = config.password !== undefined ? config.password.replace(/./g, "*") : "";
      if (config.pdp)
        this.pdptype = config.pdp.replace(/-/g, " ");
      this.lte_band_custom = config.lte_band_custom !== undefined ? config.lte_band_custom.split(" ") : [];
      this.lte_band = config.lte_band;
      this.carrier = config.carrier;
      this.servicedomain = config.servicedomain;
      this.label = this._device.templateConfig['4glte'].template_name !== null ? (this._device.templateConfig['4glte'].template_enabled == false ? this._device.templateConfig['4glte'].template_name + " (Template-Disabled)" : this._device.templateConfig['4glte'].template_name + " (Template-Enabled)") : "Device (Configuration)";
      this.sim = config.sim_status == 'detected' ? true : false;
      this.operator = config.operator;
      this.imei = config.imei;
      this.ccid = config.ccid;
      this.imsi = config.imsi;
      this.iccid = config.iccid;
      this.hideToggle = true;
    } else {
      if (this._device['4glte'] !== null) {
        this.setI4glte(this._device['4glte'])
      } else if (this._device.templateConfig && this._device.templateConfig['4glte'] && this._device.templateConfig['4glte']['config']) {
        let config = this._device.templateConfig['4glte']['config']
        this.apn = config.apn;
        this.auth = config.auth;
        this.username = config.username;
        let pw = config.password !== undefined ? config.password.replace(/./g, "*") : "";
        this.password = pw;
        this.pdptype = config.pdptype;
        this.lte_band_custom = config.lte_band_custom !== undefined ? config.lte_band_custom.split(" ") : [];
        this.lte_band = config.lte_band;
        this.carrier = config.carrier;
        this.servicedomain = config.servicedomain;
        this.label = this._device.templateConfig['4glte'].template_name !== null ? (this._device.templateConfig['4glte'].template_enabled == false ? this._device.templateConfig['4glte'].template_name + " (Template-Disabled)" : this._device.templateConfig['4glte'].template_name + " (Template-Enabled)") : "Device (Configuration)";
        this.hideToggle = true;
      }
    }
  }

  setI4glte(i4glteId) {
    this.templateService.getI4glte(i4glteId).subscribe(res => {
      if (res && res[0]) {
        this.name = res[0].name;
        if (res[0].config) {
          this.apn = res[0].config.apn;
          this.auth = res[0].config.auth;
          this.username = res[0].config.username;
          this.password = res[0].config.password !== undefined ? res[0].config.password.replace(/./g, "*") : "";
          this.pdptype = res[0].config.pdptype;
          this.lte_band_custom = res[0].config.lte_band_custom !== undefined ? res[0].config.lte_band_custom.split(" ") : [];
          this.lte_band = res[0].config.lte_band;
          this.carrier = res[0].config.carrier;
          this.servicedomain = res[0].config.servicedomain;
        }

        this.label = this.name + (this._device.templateConfig && this._device.templateConfig['4glte'] && this._device.templateConfig['4glte'].template_enabled ? " (Template - Enabled)" : this._device.templateConfig && this._device.templateConfig['4glte'] && this._device.templateConfig['4glte'].template_enabled == false ? " (Template - Disabled)" : " (Template)");
        if (this._device.templateConfig && this._device.templateConfig['4glte'] && this._device.templateConfig['4glte'].template_enabled) {
          this.checked = true;
        }
      }
    });
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "4glte", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig['4glte']) {
        this._device.templateConfig['4glte'].template_enabled = e.checked;
      } else {
        this._device.templateConfig['4glte'] = {
          template_enabled: e.checked
        }
      }
      this.loadData()
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
