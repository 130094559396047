import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { MenuService } from '../core';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  menu$ = this.menu.getAll();
  prevUrl: any;
  buildRoute = this.menu.buildRoute;

  constructor(private menu: MenuService,
    private router: Router,
    public userService: UserService) {

      router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if(event.url.includes('/layout/organization')){
          if(this.prevUrl === undefined){
            this.prevUrl = '';
          } else {
            localStorage.setItem('prevUrl', event.url.replace("/layout/organization","").replace("/",""));
            this.prevUrl = localStorage.getItem('prevUrl');
          }

          if (this.prevUrl === '') {
            localStorage.removeItem('prevUrl');
          }
        }
      });
  }

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('REBIT_USER'));
    this.userService.getUsers(null).subscribe(res => {
      if (!res) {
        this.router.navigate(['/login']);
        localStorage.removeItem(UserConstant.TOKEN);
        localStorage.removeItem(UserConstant.USER);
        localStorage.removeItem(UserConstant.LASTUPDATETIME);
      } else {
        switch(userInfo.role) {
          case 0:
            this.menu.setRootMenu();
            break;
          case 1:
          case 2:
            this.menu.setMspMenu();
            break;
          case 3:
            this.menu.setOrgAdminMenu();
            break;
          case 4:
          case 5:
            if (res.organization.role == 2) { // MSP
              this.menu.setMspUserMenu();
            } else {
              this.menu.setOrgMenu();
            }
            break;
          default:
            this.menu.setOrgMenu();
        }
        if (userInfo.role == 0) { // root
          this.menu.setRootMenu();
        }
      }
    });
  }
}
