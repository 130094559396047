<div class="">
    <mat-card>
        <h4 class="text-center" *ngIf="org">{{org.name}} overview</h4>
        <a [routerLink]="['/layout/report/billing/'+orgId]" routerLinkActive="router-link-active"
            class="navigator">Billing Information</a>
        <div class="row">
            <div class="col-md-4">
                <div class="col-12 mb-5">
                    <h6>My Organizations</h6>
                    <hr>
                    <table mat-table [dataSource]="orgCatDataSource" style="width: 100%;" [hidden]="loading">
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Organization Type</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.type }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="count">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>Organization Count</th>
                            <td class="text-center" mat-cell *matCellDef="let element">{{ element.count }}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedOrgCatColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedOrgCatColumns"></tr>
                    </table>
                    <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                </div>
                <div class="col-12 mb-4">
                    <h6>My Templates</h6>
                    <hr>
                    <table mat-table [dataSource]="templateDataSource" style="width: 100%;" [hidden]="loading">
                        <ng-container matColumnDef="t_type">
                            <th mat-header-cell *matHeaderCellDef>Template Type</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.t_type }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="t_count">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>Template Count</th>
                            <td class="text-center" mat-cell *matCellDef="let element">{{ element.t_count }}</td>
                        </ng-container>
                        <ng-container matColumnDef="t_device_count">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>Connected Devices</th>
                            <td class="text-center" mat-cell *matCellDef="let element">{{element.t_device_count }}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedTemplateColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedTemplateColumns"></tr>
                    </table>
                    <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                </div>
            </div>
            <div class="col-md-4">
                <h6>My Devices</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="canvas" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chart">
                    <div class="detail-list-item m-2">
                        <div class="square online"></div>
                        <div class="ml-2">Online</div>
                        <div class="count ml-2">{{upDeviceCnt}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square offline"></div>
                        <div class="ml-2">Offline</div>
                        <div class="count ml-2">{{downDeviceCnt}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square warning"></div>
                        <div class="ml-2">Warning</div>
                        <div class="count ml-2">{{warnDeviceCnt}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square pending"></div>
                        <div class="ml-2">Pending</div>
                        <div class="count ml-2">{{pendingDeviceCnt}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <h6>License Devices</h6>
                <hr>
                <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
                <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                    <canvas id="license" width="200" height="200"></canvas>
                </div>
                <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chartLicense">
                    <div class="detail-list-item m-2">
                        <div class="square l_single_link"></div>
                        <div class="ml-2">Single Link</div>
                        <div class="count ml-2">{{l_single_link}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_single_link_lte"></div>
                        <div class="ml-2">Single Link LTE</div>
                        <div class="count ml-2">{{l_single_link_lte}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_failover"></div>
                        <div class="ml-2">Failover</div>
                        <div class="count ml-2">{{l_failover}}</div>
                    </div>
                    <div class="detail-list-item m-2">
                        <div class="square l_failover_lte"></div>
                        <div class="ml-2">Failover LTE</div>
                        <div class="count ml-2">{{l_failover_lte}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_nfr"></div>
                        <div class="ml-2">NFR</div>
                        <div class="count ml-2">{{l_nfr}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_spare"></div>
                        <div class="ml-2">Spare</div>
                        <div class="count ml-2">{{l_spare}}</div>
                    </div>
                    <div class="detail-list-item m-2">
                        <div class="square l_overlay"></div>
                        <div class="ml-2">Add-on: Overlay</div>
                        <div class="count ml-2">{{l_overlay}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_cloud_dmz"></div>
                        <div class="ml-2">Add-on: Cloud DMZ</div>
                        <div class="count ml-2">{{l_cloud_dmz}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_wan_connect"></div>
                        <div class="ml-2">Add-on: WAN Connect</div>
                        <div class="count ml-2">{{l_wan_connect}}</div>
                    </div>
                    <div class="detail-list-item m-2 flex-fill">
                        <div class="square l_1gb_lte_data"></div>
                        <div class="ml-2">Add-on: 1GB LTE Data</div>
                        <div class="count ml-2">{{l_1gb_lte_data}}</div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <mat-tab-group animationDuration="0ms" class="mt-3">
        <mat-tab label="All Organizations">
            <div class="mt-2" *ngIf="allOrgData && deviceArray">
                <app-org-devices-table [dataSource]="orgDataSource" [deviceSource]="deviceArray" (refresh)="loadData()"
                    [selfSort]="true"></app-org-devices-table>
            </div>
        </mat-tab>
        <mat-tab label="Devices">
            <div class="mt-2" *ngIf="allOrgData && deviceArray">
                <app-device-table [dataSource]="deviceArray" [selfSort]="true" [isReportView]="true"></app-device-table>
            </div>
        </mat-tab>

        <mat-tab label="Jump Access Logs">
            <div class="mt-2" *ngIf="jumpDataSource">
                <app-device-browse-history [dataSource]="jumpDataSource" [selfSort]="true"></app-device-browse-history>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>