import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'app-device-org-modal',
  templateUrl: './device-org-modal.component.html',
  styleUrls: ['./device-org-modal.component.scss'],
})
export class DeviceOrgModalComponent implements OnInit, OnDestroy {
  deviceForm: FormGroup;
  loadDataSubscription: any;
  submitted = false;
  allOrgList = [];
  searchOrg: any;
  searchPipe: any;
  @ViewChild('orginput') searchElement: ElementRef;

  constructor(private formBuilder: FormBuilder, public apservices: ApService, private toastr: ToastrService, public userService: UserService,
    public dialogRef: MatDialogRef<DeviceOrgModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.loadAllOrganization();
    this.deviceForm = this.formBuilder.group({
      mac: new FormControl('', [Validators.required]),
      org: new FormControl(null, [Validators.required]),
    });

    if (this.data && this.data._id) {
      this.deviceForm.controls.mac.setValue(this.data.mac);
      this.deviceForm.controls.org.setValue(this.data.orgId);
    }
  }

  loadAllOrganization() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    let orgId = user.organization;
    let obj = {
      id: orgId
    }
    this.loadDataSubscription = this.userService.getOrganizationUsers(obj).subscribe(res => {
      try {
        this.allOrgList = this.getDomainOrgs(res.data[0]);
      } catch (e) {
        console.log(e);
      }
    })
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  onSubmit() {
    if (this.submitted) {
      return
    }
    let org = this.allOrgList.find(o => o['orgId'] == this.deviceForm.get('org').value)

    // stop here if form is invalid
    if (this.deviceForm.invalid || !org) {
      if (this.deviceForm.invalid || !this.deviceForm.valid) {
        return
      }
    } else {
      const postObject = {
        _id: this.data._id,
        mac: [this.deviceForm.get('mac').value],
        orgId: this.deviceForm.get('org').value,
        type: "pending"
      };
      this.submitted = true;
      this.apservices.posdevicedata(postObject).subscribe(res => {
        this.submitted = false;
        this.toastr.success(' Device updated Successfully!');
        this.dialogRef.close();
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }));
    }
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
