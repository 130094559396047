import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

   getReportMonthly(mmspId, data): Observable<any> {
      return this.http.get(environment.endpoints.report + '/' + mmspId + '/monthlies', { params: data});
    }
}
