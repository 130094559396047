import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QosAddComponent } from './qos-add/qos-add.component';
import { QosDevicesComponent } from './qos-devices/qos-devices.component';
import { QosComponent } from './qos.component';

const routes: Routes = [
  { path: '', component: QosComponent },
  { path: 'add', component: QosAddComponent },
  { path: 'org/:id/qos', component: QosComponent },
  { path: ':id', component: QosAddComponent },
  { path: ':id/devices', component: QosDevicesComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QosRoutingModule { }
