<div>
    <mat-card-content>
        <h4 class="text-center mt-3" *ngIf="name">Devices connected with {{name}}</h4>
        <div class="text-right">
            <mat-form-field class="example-form-field">
                <mat-label>Search Here ...</mat-label>
                <input matInput type="text" [(ngModel)]="searchTxt" (keyup.enter)="searchData()" autocomplete="off">
                <button mat-button *ngIf="searchTxt" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchTxt='';searchData();">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <app-device-table [dataSource]="dataSource" [orgId]="orgId" (refresh)="loadData()" [tpl]="'wnetwork'"></app-device-table>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event" (page)="handlePageEvent($event)" *ngIf="length">
        </mat-paginator>
    </mat-card-content>
</div>