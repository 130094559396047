<div>
    <mat-card>
        <form>
            <fieldset>
                <legend class="mb-1">
                    <span *ngIf="wnetworkId">Edit </span>
                    <span *ngIf="!wnetworkId">Add </span>
                    WiFi Network Configuration
                </legend>
                <mat-card-content>
                    <a class="device-link" [routerLink]="['/layout/wireless-network/'+name+'_'+wnetworkId+'/devices']"
                        routerLinkActive="router-link-active" *ngIf="wnetworkId">
                        Devices
                    </a>
                    <mat-form-field class="mb-2">
                        <mat-label>Name</mat-label>
                        <input type="text" matInput [(ngModel)]="name" [ngModelOptions]="{standalone: true}" autocomplete="off"/>
                        <mat-hint class="mat-error" *ngIf="showError && !name.length">Name is required</mat-hint>
                    </mat-form-field>

                    <mat-form-field class="mb-2 d-block" style="max-width: 500px;">
                        <mat-label>Select Organization</mat-label>
                        <mat-select [(ngModel)]="orgId" [ngModelOptions]="{standalone: true}"
                            (click)="searchFocus()">
                            <div class="dropdown-search">
                                <input #orginput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
                                    placeholder="Search Organization">
                            </div>
                            <div>
                                <mat-option *ngFor="let organization of allOrgList | searchOrg : searchPipe"
                                    [value]="organization.orgId">
                                    {{ organization.name }}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-error>
                            Organization is required
                        </mat-error>
                    </mat-form-field>

                    <mat-tab-group animationDuration="0ms" [(selectedIndex)]="tabIdx">
                        <ng-container *ngFor="let network of networks; let idx = index;">
                            <mat-tab [label]="network.label">
                                <app-network-data [networkData]="networks[idx]" [country]="network.country"
                                    [txpower]="network.txpower" [channel]="network.channel" [hwmode]="network.hwmode"
                                    [htmode]="network.htmode" (dataChange)="setData($event, idx)"
                                    (validSubmit)="setValid($event, idx)" [showToggle]="network.label"
                                    (wifi2GSettings)="setting2GSelected($event)"
                                    (wifi5GSettings)="setting5GSelected($event)">
                                </app-network-data>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </mat-card-content>
                <div class="btn-wrapper">
                    <button mat-raised-button color="primary" class="btn btnn"
                        (click)="onSubmit()">Submit</button>
                </div>
            </fieldset>
        </form>
    </mat-card>
</div>