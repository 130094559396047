import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ResetpasswordService } from 'src/app/shared/services/resetpassword.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { MustMatch } from 'src/app/shared/code/password-confirm';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetpasswordform: FormGroup;
  submitted = false;
  public label: string;
  resetpass: { password: any; };
  PASSWORDTOKEN: any;
  id: any;
  hide1: boolean = true;
  hide2: boolean = true;
  isDisabled: boolean = true;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private resetpasswordService: ResetpasswordService,
    public router: Router, private route: ActivatedRoute) { }
  ngOnInit() {
    const token = this.route.snapshot.queryParams.id;
    localStorage.setItem(UserConstant.PASSWORDTOKEN, token);
    this.resetpasswordform = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]+$')]),
      confirmpassword: new FormControl(''),
    }, {
      validator: MustMatch('password', 'confirmpassword')
    });

    this.resetpasswordform.valueChanges.subscribe(value => {
      this.isDisabled = false;
    });
  }
  onSubmit() {
    this.isDisabled = true;
    if (this.submitted) return;
    if (this.resetpasswordform.invalid) {
      return;
    } else {
      this.resetpass = {
        password: this.resetpasswordform.get('password').value,
      };
      this.submitted = true;
      this.resetpasswordService.resetpassword(this.resetpass).subscribe(res => {
        const k = res;
        this.submitted = false;
        if (res.status === 'success') {
          this.toastr.success('Password reset successfully!!!');
          localStorage.removeItem(UserConstant.PASSWORDTOKEN);
          this.router.navigate(['/login']);
        }
      },
        error => {
          this.submitted = false;
          this.toastr.error("Unable to reset password. Please contact support team.");
        });
    }
  }
}
