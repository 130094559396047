// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const apiUrl = 'http://localhost:8101/v2/';
const apiUrl = 'https://api.rabbit.run/v2/';
// const apiUrl = 'https://api-dev.rabbit.run/v2/';
export const environment = {
  production: false,
  endpoints: {
    login: apiUrl + 'users/login',
    autologin: apiUrl + 'users/autologin',
    logout: apiUrl + 'users/logout',
    forgotPassword: apiUrl + 'users/forgotPassword',
    users: apiUrl + 'users',
    resetpassword: apiUrl + 'users/resetPassword',
    updateprofile: apiUrl + 'users/me',
    wirelessNetwork: apiUrl + 'template/wnetwork',
    ap: apiUrl + 'device',
    deleteUser: apiUrl + 'users',
    getpendingdevicesdata: apiUrl + 'device/pending',
    postdevice: apiUrl + 'org/device',
    devicedelete: apiUrl + 'org/device',
    deviceBackup: apiUrl + 'device',
    deviceHistoryData: apiUrl + 'devices-history-data',
    updateDevice: apiUrl + 'device/update',
    organization: apiUrl + 'organization',
    prtg: apiUrl + 'template/prtg',
    getPrtg: apiUrl + 'organization/prtg',
    getPrtgDevice: apiUrl + 'device',
    qos: apiUrl + 'template/qos',
    firmware: apiUrl + 'firmware',
    firmwareDonloadLink: apiUrl + 'firmware',
    i4glte: apiUrl + 'template/i4glte',
    team: apiUrl + 'template/team',
    tprofile: apiUrl + 'template/tprofile',
    templateConfig: apiUrl + 'template/config',
    alert: apiUrl + 'template/alert',
    vceVhub: apiUrl+ 'vce-vhub',
    config: apiUrl+ 'sd/vce-vhub/config',
    ras: apiUrl+ 'device/ras-data',
    lan: apiUrl + 'device/lan',
    trackstatus: apiUrl + 'track-status',
    trackDeviceStatus: apiUrl + 'track-device-status',
    trackLinkStatus: apiUrl + 'track-links-status',
    trackDeviceCalc: apiUrl + 'track-device-calc',
    trackLinkCalc: apiUrl + 'track-links-calc',
    novu: apiUrl + 'novu',
    attackShield: apiUrl + 'attack-shield',
    linkStateHist: apiUrl + 'link-states',
    sipwatchAlert: apiUrl + 'sipwatch-alerts',
    sipwatchCallerIdHist: apiUrl + 'sipwatch-callerId-hist',
    set4glteAlertByOrg: apiUrl + 'org/device-alert4glte',
    notes: apiUrl + 'device/notes',
    report: apiUrl + 'device/reports'
  }
};
