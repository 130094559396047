import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrgDevicesComponent } from '../org-devices/org-devices.component';

const routes: Routes = [
  { path: '', component: OrgDevicesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule { }
