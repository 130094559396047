import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-browse-history',
  templateUrl: './device-browse-history.component.html',
  styleUrls: ['./device-browse-history.component.scss']
})
export class DeviceBrowseHistoryComponent implements OnInit {
  @Input() set dataSource(data) {
    this.allJumpLog = data;
    this._dataSource.data = this.allJumpLog;
    this.length = data.length;
  }
  @Input() selfSort: any;
  @Output() sortChange = new EventEmitter<any>();
  searchTxt = ""
  sortData: any;
  private loadDataSubscription: Subscription;
  page: number = 1;
  displayedColumns: string[] = ['lastSeen', 'mac', 'name', 'msp', 'domain', 'ip', 'type', 'user_name', 'user_email'];
  _dataSource: MatTableDataSource<any>;

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  allJumpLog: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public apService: ApService,
    private changeDetection: ChangeDetectorRef
  ) {
    this._dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.selfSort) {
      this.paginator.length = this.length;
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  }

}
