<div class="w-100 overflow-hidden">
    <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="lastSeen">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date: "M/d/yy, h:mm:ss a" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="mac">
            <th mat-header-cell *matHeaderCellDef>Mac Address</th>
            <td mat-cell *matCellDef="let element">{{ element.mac.toUpperCase()}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Host Name</th>
            <td mat-cell *matCellDef="let element">{{ element.host_name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="msp">
            <th mat-header-cell *matHeaderCellDef>MSP</th>
            <td mat-cell *matCellDef="let element">{{element.msp_name}} </td>
        </ng-container>

        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef>Organization</th>
            <td mat-cell *matCellDef="let element">{{element.org_name}}</td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Web/Terminal</th>
            <td mat-cell *matCellDef="let element">{{element.log_type}}</td>
        </ng-container>

        <ng-container matColumnDef="ip">
            <th mat-header-cell *matHeaderCellDef>Access IP</th>
            <td mat-cell *matCellDef="let element">{{element.ipaddress}}</td>
        </ng-container>

        <ng-container matColumnDef="user_name">
            <th mat-header-cell *matHeaderCellDef>User Name</th>
            <td mat-cell *matCellDef="let element">{{element.user_name}}</td>
        </ng-container>

        <ng-container matColumnDef="user_email">
            <th mat-header-cell *matHeaderCellDef>User Email</th>
            <td mat-cell *matCellDef="let element">{{element.user_email}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)"  [hidden]="!length">
    </mat-paginator>
</div>