<div>
  <mat-card>
    <mat-card-content>
      <div class="card-body">
        <form [formGroup]="profile_form">
          <fieldset>
            <legend class="mb-1" mat-dialog-title>My Profile</legend>
          <!-- <div class="col-md-3 offset-md-0 col-6 offset-4">
                <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color profile-picture" aria-hidden="true">account_circle</mat-icon>
              </div> -->
            <div class="col-md-6 offset-md-3">
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="name" class="col-form-label"> Name</label>
                  <div class="col-12 pl-0">
                    <input id="name" name="name" placeholder="Name" class="form-control" type="text" formControlName="name" autocomplete="off">
                    <mat-error *ngIf="profile_form.controls['name'].hasError('required')">
                      Name is required
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="email" class="col-form-label">Email </label>
                  <div class="col-12 pl-0">
                    <input id="email" name="email" placeholder="Email" class="form-control" required="required" type="text"
                      formControlName="email" autocomplete="off">
                    <mat-error *ngIf="profile_form.controls['email'].hasError('required')">
                      Email is required
                    </mat-error>
                    <mat-error *ngIf="profile_form.controls['email'].hasError('pattern')">
                      Email is invalid
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-6">
                  <label for="organization" class="col-form-label"> Organization</label>
                  <div class="col-12 pl-0">
                    <input id="organization" organization="organization" placeholder="Organization" class="form-control"
                      type="text" formControlName="organization" autocomplete="off">
                    <mat-error *ngIf="profile_form.controls['organization'].hasError('required')">
                      Organization is required
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="role" class="col-form-label"> Role</label>
                  <div class="col-12 pl-0">
                    <input id="role" role="role" placeholder="Role" class="form-control" type="text" formControlName="role" autocomplete="off">
                    <mat-error *ngIf="profile_form.controls['role'].hasError('required')">
                      Role is required
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="mt-2">
                  <button name="submit" type="submit"
                    class="mat-focus-indicator btn btnn mat-raised-button mat-button-base mat-primary"
                    (click)="onSubmit()">{{btnLabel}}</button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>