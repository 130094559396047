import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { combineLatest, Subscription } from 'rxjs';
import { VceVhubService } from 'src/app/shared/services/vce-vhub.service';

@Component({
  selector: 'app-vhub-connect-modal',
  templateUrl: './vhub-connect-modal.component.html',
  styleUrls: ['./vhub-connect-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class VhubConnectModalComponent implements OnInit, OnDestroy {
  deviceForm: FormGroup;
  submitted = false;
  allOrgList = [];
  searchOrg: any;
  searchPipe: any;
  @ViewChild('orginput') searchElement: ElementRef;
  private loadDataSubscription: Subscription;
  role: any;

  constructor(
    private formBuilder: FormBuilder, public apservices: ApService,
    private toastr: ToastrService, public userService: UserService,
    private vceVhubService: VceVhubService,
    public dialogRef: MatDialogRef<VhubConnectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.role = user.role;
    this.loadAllOrganization();
    this.deviceForm = this.formBuilder.group({
      name: new FormControl({ value: '', disabled: true }),
      mac: new FormControl({ value: '', disabled: true }),
      org: new FormControl({ value: '', disabled: true }),
      linked_orgs: new FormControl()
    });

    if (this.data && this.data._id) {
      this.deviceForm.controls.name.setValue(this.data.name);
      this.deviceForm.controls.mac.setValue(this.data.mac);
      this.deviceForm.controls.org.setValue(this.data.orgId);
    }
  }


  loadAllOrganization() {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    let orgId = user.organization;
    let obj = {
      id: orgId
    }
    this.loadDataSubscription = combineLatest([
      this.userService.getOrganizationUsers(obj),
      this.vceVhubService.getRelatedOrganizations(this.data.mac)]
    ).subscribe(results => {
      this.allOrgList = this.getDomainOrgs(results[0].data[0]);
      if (results[1]?.linked_orgs) {
        this.deviceForm.controls.linked_orgs.setValue(results[1]?.linked_orgs)
      }
    })
  }

  getDomainOrgs(domain) {
    let filteredOrg = [];
    if (domain.role == 3) {
      filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role })
    }
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  onSubmit() {
    if (this.submitted) {
      return
    }
    // stop here if form is invalid
    if (this.deviceForm.invalid) {
      return
    } else {
      const postObject = {
        _id: this.data._id,
        mac: this.data.mac,
        linked_orgs: this.deviceForm.get('linked_orgs').value
      };

      this.submitted = true;
      this.vceVhubService.createRelation(postObject).subscribe(res => {
        this.submitted = false;
        this.toastr.success('Connection is updated Successfully!');
        this.dialogRef.close();
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }));
    }
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
