import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

import { ApService } from 'src/app/shared/services/ap.service';
import { formatDate, DatePipe } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { interval, Subscription } from 'rxjs';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { VhubConnectModalComponent } from '../device/vhub-connect-modal/vhub-connect-modal.component';

@Component({
  selector: 'app-vhub-device',
  templateUrl: './vhub-device.component.html',
  styleUrls: ['./vhub-device.component.scss']
})
export class VhubDeviceComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dnetwork: any = [];
  displayedColumns: string[] = ['name', 'mac', 'email', 'domain', 'location', 'model', 'firmware', 'upTime', 'lastSeen', 'createdAt', 'Status', 'Action'];
  divicessArray: any = [];
  dataSource: any = [];

  user: any;
  domain: any;
  orgId: any;

  searchTxt = ""
  sortData: any;

  private loadDataSubscription: Subscription;

  constructor(public dialog: MatDialog, public apService: ApService,
    private toastr: ToastrService, private submenuService: SubMenuService,
    private router: Router, public route: ActivatedRoute,
    private topnavbarService: TopnavbarService, public userService: UserService,) {
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.user = storedUser;
    this.orgId = this.user.organization;
    this.loadData();
    this.setTopNavMenu();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "",
        "name": "vHub Devices",
        "type": "text",
      },
    ])
  }


  loadData() {
    let data = {
      params: {
        id: this.orgId,
        searchTxt: this.searchTxt,
        type: 'vhub'
      }
    };
    this.loadDataSubscription = this.apService.getListing(data).subscribe(res => {
      if (res) {
        this.divicessArray = res.data.filter(device => {
          return device.templateConfig?.team?.config?.team1?.mode == 'VHUB'
        })
        this.divicessArray.forEach(element => {
          element.upTime = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
        });
        this.dataSource = this.divicessArray;

      } else {
        this.dataSource = [];
      }

    });
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  _connectDevice(device) {
    const dialogRef = this.dialog.open(VhubConnectModalComponent, {
      disableClose: true,
      width: '700px',
      data: device
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
