import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
// import { LineChartColors } from 'src/app/shared/model/chart';
import { FormatterHelper } from 'src/app/shared/helper/formatter-helper';
@Component({
  selector: 'app-device-uptime',
  templateUrl: './device-uptime.component.html',
  styleUrls: ['./device-uptime.component.scss']
})
export class DeviceUptimeComponent implements OnInit {  
  @Input() set upTime(v_arr) {
    this.lineChartLabels = [];
    this.lineChartData = [];
    if (v_arr[0]) {
      Object.keys(v_arr[0]).forEach((key, idx) => {
        let data = [];
        if ( key.includes("Device Uptime")|| key.includes("Downtime")) {
          this.lineChartLabels = [];
          v_arr.forEach(element => {
            data.push(FormatterHelper.uptimeToDecimal(element[key]))
            this.lineChartLabels.push(element.datetime)
          });
          this.lineChartData.push({
            data, label: key.replace("Device ", "")
          })
        }
      })
    }
  }
  @Input() large: any;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        display: true,
        // ticks: {
        //   min: 0,
        //   max: 100
        // }
      }]
    }
  };
  public lineChartColors = [
    {
      borderColor: '#34a854',
      borderWidth: 1,
      fill: false,
      tension: 0.5,
      pointRadius: 1.5,
    },
    {
      borderColor: '#e81123',
      borderWidth: 1,
      fill: false,
      tension: 0.5,
      pointRadius: 1.5,
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  constructor() { }

  ngOnInit(): void {
  }

}
