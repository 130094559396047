import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { UserConstant } from 'src/app/shared/constants/user-constant';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, public activatedRoute: ActivatedRoute) { }
  canActivate(): boolean {
    try {
      let tmp = JSON.parse(localStorage.getItem(UserConstant.USER));
      if (tmp['role']>=0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.router.navigate(['/login']);
      return false;
    }
  }
}