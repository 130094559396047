import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ApService } from 'src/app/shared/services/ap.service';
import { forkJoin, Subscription } from 'rxjs';
import { TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent implements OnInit, OnDestroy {
  orgForm: FormGroup;
  submitted = false;
  device = [];
  monitors = [];
  wnetworks = [];
  qosList = [];
  teamList = [];
  i4glteList = [];
  tprofileList = [];
  orgDialogData: any;
  orgId: any;
  user: any;
  domain: any;
  role: any;
  isRootUser: boolean;
  bPending: boolean = false;
  isMaster: boolean = false;
  pendingList = [];
  filteredOrg = [];
  searchPipe: any;
  searchOrgPipe: any;

  orgType = [{
    name: "Master MSP",
    id: 1
  },
  {
    name: "MSP",
    id: 2
  },
  {
    name: "Organization",
    id: 3
  }]

  selectedRole: any;

  @ViewChild('macinput') searchMacElement: ElementRef;
  @ViewChild('orginput') searchOrgElement: ElementRef;

  private loadDataSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public apService: ApService,
    private toastr: ToastrService,
    private router: Router,
    private templateService: TemplateService,
    public dialogRef: MatDialogRef<EditOrganizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    let storedUser = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.isRootUser = storedUser.role == 0;
    this.user = storedUser;

    this.domain = this.data.domain;
    
    if (this.data && this.data._id) {
      if(this.data.parentOrganization){
        this.orgDialogData = this.data;
      } else {
        this.userService.getOrganization({ id: this.data._id }).subscribe(res => {
          this.orgDialogData = res.data[0];
        })
      }
      setTimeout(() => {
        this.orgId = this.orgDialogData._id;
        this.role = this.orgDialogData.role;
      }, 850);
    }
    
    this.orgForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      domain: new FormControl('', [Validators.required]),
      parent: new FormControl(null, []),
      role: new FormControl('', [Validators.required]),
      pendings: new FormControl([])
    });
    
    setTimeout(() => {
      if (this.orgId) {
        this.orgForm.controls.name.setValue(this.orgDialogData.name);
        this.orgForm.controls.domain.setValue(this.orgDialogData.domain);
        this.orgForm.controls.parent.setValue(this.orgDialogData.parentOrganization);
        this.orgForm.controls.role.setValue(this.orgDialogData.role);
      }
  
      this.loadDataSubscription = this.userService.getOrganization({ id: this.user.organization }).subscribe(res => {
        res = res.data[0];
        if (res) {
          let filterOrg = this.getDomainOrg(res);
          this.filteredOrg = filterOrg.filter(o => o.role <= (this.orgDialogData.role - 1) && o._id !== this.orgId)
        }
      });
    }, 1000);
  }

  getDomainOrg(domain) {
    let filtered = [];
    filtered.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filtered = [...filtered, ...this.getDomainOrg(org)]
    }
    return filtered;
  }

  searchMacFocus() {
    setTimeout(() => {
      this.searchMacElement.nativeElement.focus();
    }, 50);
  }

  searchOrgFocus() {
    setTimeout(() => {
      this.searchOrgElement.nativeElement.focus();
    }, 50);
  }

  onSubmit() {
    if (this.submitted) return;
    // stop here if form is invalid
    if (this.orgForm.invalid) {
      return;
    } else {
      let name = this.orgForm.get('name').value;
      let domain = this.orgForm.get('domain').value;
      let parent = this.orgForm.get('parent').value;
      let role = this.orgForm.get('role').value;
      let pendings = this.bPending ? this.orgForm.get('pendings').value : [];
      const postObject = {
        name: name,
        domain: domain,
        parentOrg: parent,
        role: role,
      };
      if (this.orgId) {
        postObject['_id'] = this.orgId;
      }
      if (this.orgId && pendings.length) {
        const oject = {
          mac: pendings,
          orgId: this.orgId,
          type: 'pending',
          domain: name
        }
        this.submitted = true;
        forkJoin([
          this.apService.movePendingDevices(oject),
          this.userService.createOrganization(postObject)
        ]).subscribe(results => {
          this.submitted = false;
          this.dialogRef.close();
        }, (err => {
          this.submitted = false;
          this.toastr.error(err.error.error);
        }))
      } else {
        this.submitted = true;
        this.userService.createOrganization(postObject).subscribe(res => {
          this.submitted = false;
          this.toastr.success(' Organization Changed Successfully!');
          this.dialogRef.close();
        }, (error => {
          this.submitted = false;
          this.toastr.error(error.error.error);
        }));
      }

    }
  }

  loadData() {
    if (this.bPending && this.pendingList.length == 0) {
      this.loadDataSubscription = this.apService.getPendingDevice({}).subscribe(res => {
        this.pendingList = res.data;
        let pendings = this.pendingList.filter(d => d.orgId == this.orgId).map(d => d.mac)
        this.orgForm.controls.pendings.setValue(pendings);
      })
    }
  }

  onDomainChange(e) {
    let org = this.filteredOrg.find(o => o._id == e.value)
    this.role = org.role

    if(this.role != 3){
      if(this.role == 2){
        this.selectedRole = 3;
      } else if(this.role == 1){
        this.selectedRole = 2;
      } else if(this.role == 0){
        this.selectedRole = 1;
      }
    }

    if (!org) {
      return;
    }
    this.templateService.getPrtgForOrganization(org.orgId).subscribe(res => {
      this.monitors = res;
    })
    this.templateService.getWnetworkforOrg(org.orgId).subscribe(res => {
      this.wnetworks = res;
    })
    this.templateService.getQosForOrg(org.orgId).subscribe(res => {
      this.qosList = res;
    })
    this.templateService.getI4glteForOrg(org.orgId).subscribe(res => {
      this.i4glteList = res;
    })
    this.templateService.getTeamForOrg(org.orgId).subscribe(res => {
      this.teamList = res;
    })
    this.templateService.getTprofileForOrg(org.orgId).subscribe(res => {
      this.tprofileList = res;
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
