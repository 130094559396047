import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchMac'
})
export class SearchMacPipe implements PipeTransform {

  transform(pendingList: any[], searchPipe: string): any[] {
    if (!pendingList || !pendingList.length) return pendingList;
    if (!searchPipe || !searchPipe.length) return pendingList;
    return pendingList.filter(pending => {
      return (pending.mac.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1) ||
        (pending.orgName && pending.orgName.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1) ||
        (pending.name && pending.name.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1)
    });
  }

}
