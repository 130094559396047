import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { LineChartColors } from 'src/app/shared/model/chart';

@Component({
  selector: 'app-device-link-state',
  templateUrl: './device-link-state.component.html',
  styleUrls: ['./device-link-state.component.scss']
})
export class DeviceLinkStateComponent implements OnInit {
  @Input() set linkState(v_arr) {
    this.lineChartLabels = [];
    this.lineChartData = [];
    if (v_arr[0]) {
      Object.keys(v_arr[0]).forEach((key, idx) => {
        let data = [];
        if (key.includes("loss")) {
          this.lineChartLabels = [];
          v_arr.forEach(element => {
            data.push(element[key])
            this.lineChartLabels.push(element.datetime)
          });
          this.lineChartData.push({
            data, label: key.replace(" loss", "")
          })
        }
      })
    }
  }
  @Input() large: any;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          min: 0,
          max: 100
        }
      }]
    }
  };
  public lineChartColors = LineChartColors;
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor() { }

  ngOnInit(): void {
  }

}
