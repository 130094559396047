export const LineChartColors = [
    {
        borderColor: '#0078d7',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#34a854',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#e47e30',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    }, {
        borderColor: '#fff100',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#e81123',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#00bcf2',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    }, {
        borderColor: '#bad80a',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#cf853e',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#00188f',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    }, {
        borderColor: '#009e49',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#68217a',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
    {
        borderColor: '#00b294',
        borderWidth: 1,
        fill: false,
        tension: 0.5,
        pointRadius: 1.5,
    },
];