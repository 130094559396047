import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-device-sipwatch-callerid-settings',
  templateUrl: './device-sipwatch-callerid-settings.component.html',
  styleUrls: ['./device-sipwatch-callerid-settings.component.scss']
})
export class DeviceSipwatchCalleridSettingsComponent implements OnInit {

  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
      if(obj[0]?.statistics?.sipwatch?.callerid) {
        this.callerIDConfig = obj[0]?.statistics?.sipwatch?.callerid
        this.callertIDMap = obj[0]?.statistics?.sipwatch?.callerid_map
      }
    }
  }

  _device: any = []
  callerIDConfig
  callertIDMap = []
  
  constructor() { }

  ngOnInit(): void {
  }

  displayEnabled(e){
    if(e === 1){
      return 'Enabled'
    } 
    return "Disabled"
  }
}
