<form [formGroup]="networkForm">
    <div mat-dialog-content>
        <div class="mb-2">
            <mat-checkbox formControlName="enabled" [labelPosition]="'before'" (change)="onToggle($event)">Enabled
            </mat-checkbox>
        </div>
        <mat-form-field *ngIf="_showToggle && !isClient">
            <mat-label>SSID</mat-label>
            <input matInput formControlName="ssid" autocomplete="off" type="text" />
            <mat-error *ngIf="networkForm.controls['ssid'].hasError('required')">
                ssid is required
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="_showToggle && !isClient" style="height: 0px; width: 0px;">
            <mat-label>FAKE SSID</mat-label>
            <input matInput autocomplete="off" />
        </mat-form-field>
        <mat-form-field *ngIf="_showToggle && !isClient">
            <mat-label>Encryption</mat-label>
            <mat-select formControlName="enc">
                <mat-option *ngFor="let item of encryptionArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="_showToggle && showCipher && !isClient">
            <mat-label>Cipher</mat-label>
            <mat-select formControlName="cipher">
                <mat-option *ngFor="let item of encryptionalgorithamArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="_showToggle && showKey && !isClient" style="height: 0px; width: 0px;">
            <mat-label>Key</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" autocomplete="off" />
        </mat-form-field>
        <mat-form-field *ngIf="_showToggle && showKey && !isClient">
            <mat-label>Key</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="key" autocomplete="off" />
            <mat-icon class="visibility-icon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            <mat-error *ngIf="networkForm.controls['key'].hasError('minlength')">
                key is minimum 8 character
            </mat-error>
            <mat-error *ngIf="networkForm.controls['key'].hasError('maxlength')">
                key is maximum 32 character
            </mat-error>
            <mat-error *ngIf="networkForm.controls['key'].hasError('wrongLength')">
                key is 5 or 13 characters
            </mat-error>
            <mat-error *ngIf="networkForm.controls['key'].hasError('required')">
                key is required
            </mat-error>
        </mat-form-field>
        <div *ngIf="_showToggle && !isClient">
            <mat-checkbox formControlName="hidden" [labelPosition]="'before'">Hide SSID</mat-checkbox>
        </div>
        <mat-form-field class="mt-4" *ngIf="!isClient">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country" (selectionChange)="settingSelected(['country',$event.value])">
                <mat-option *ngFor="let item of countryArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isClient">
            <mat-label>TX Power</mat-label>
            <mat-select formControlName="txpower" (selectionChange)="settingSelected(['txpower',$event.value])">
                <mat-option *ngFor="let item of txPowerArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isClient">
            <mat-label>Mode</mat-label>
            <mat-select formControlName="hwmode" (selectionChange)="settingSelected(['hwmode',$event.value])">
                <mat-option *ngFor="let item of hwmodeArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isClient && showHtMode">
            <mat-label>Channel Width</mat-label>
            <mat-select formControlName="htmode" (selectionChange)="settingSelected(['htmode',$event.value])">
                <mat-option *ngFor="let item of htmodeArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!isClient">
            <mat-label>Channel</mat-label>
            <mat-select formControlName="channel" (selectionChange)="settingSelected(['channel',$event.value])">
                <mat-option *ngFor="let item of channelArray" [value]="item.value">
                    {{ item.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>