import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {

constructor(private authService: AuthService) { }
intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  // We retrieve the token, if any
  let newHeaders = req.headers;
  if (this.authService.isLogin()) {
     // If we have a token, we append it to our new headers
     newHeaders = newHeaders.append('Authorization', 'Bearer ' + this.authService.getToken());
  }
  if (this.authService.isPasswordtoken() ) {
    newHeaders = newHeaders.append('Authorization', 'Bearer ' + this.authService.isPasswordtoken());
  }
  // Finally we have to clone our request with our new headers
  // This is required because HttpRequests are immutable
  const authReq = req.clone({headers: newHeaders});
  // Then we return an Observable that will run the request
  // or pass it to the next interceptor if any
  return next.handle(authReq);
}
}
