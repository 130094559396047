
export class UserConstant {
  public static TOKEN = 'USER_TOKEN';
  public static USER = 'REBIT_USER';
  public static PASSWORDTOKEN = 'PASSWORD_TOKEN';
  public static LASTUPDATETIME = "UPDATE_AT";
  public static LOGINTYPE = 'REMOTE';
  
public static MENU = [
  {
    displayName: 'Organization',
    iconName: 'domain',
    route: '/ layout/organization',
    children: [
      
    ]
  },
  {
    displayName: 'Devices',
    iconName: 'assignment',
    route: '/layout/devices',
    children: [
      {
        displayName: 'System',
        iconName: 'settings_system_daydream',
        route: '/layout/devices/system/',
      },
      {
        displayName: 'SD-WAN',
        iconName: 'vertical_align_center',
        route: '/layout/devices/sd-wan/',
      },
      {
        displayName: 'Links',
        iconName: 'speaker_notes',
        route: '/layout/devices/links/',
      },
      {
        displayName: 'UCaaS connector',
        iconName: 'feedback',
        route: '/layout/devices/ucaas-connector/',
      },
      {
        displayName: 'Device Backups',
        iconName: 'feedback',
        route: '/layout/devices/device-backups/',
      },
    ]
  },
  {
    displayName: 'Wireless',
    iconName: 'domain',
    route: '/layout/wireless-network',
    children: [
      {
        displayName: 'Speakers',
        iconName: 'group',
        route: 'devfestfl/speakers',
      },
      {
        displayName: 'Sessions',
        iconName: 'speaker_notes',
        route: 'devfestfl/sessions',
        children: [
          {
            displayName: 'Create Enterprise UIs',
            iconName: 'star_rate',
            route: 'devfestfl/sessions/material-design'
          },
          {
            displayName: 'What\'s up with the Web?',
            iconName: 'star_rate',
            route: 'devfestfl/sessions/what-up-web'
          },
          {
            displayName: 'My ally, the CLI',
            iconName: 'star_rate',
            route: 'devfestfl/sessions/my-ally-cli'
          },
          {
            displayName: 'Become an Angular Tailor',
            iconName: 'star_rate',
            route: 'devfestfl/sessions/become-angular-tailer'
          }
        ]
      },
      {
        displayName: 'Feedback',
        iconName: 'feedback',
        route: 'devfestfl/feedback'
      }
    ]
  }  

]


}
