import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-device-data-usage',
  templateUrl: './device-data-usage.component.html',
  styleUrls: ['./device-data-usage.component.scss']
})
export class DeviceDataUsageComponent implements OnInit {
  vHub_mode: any;
  dpcData: {}[];
  links: any = [];
  @Input() set dataUsage(obj) {
    if (obj) {
      this.LastHistData = obj.LastHistData;
      this.histdata = obj.histdata
    }
  }
  @Input() model;
  @Input() set device(obj) {
    this.vHub_mode = obj[0].templateConfig?.team?.config?.team1?.mode == 'VHUB';
    if (obj && obj[0] && obj[0]['statistics'] && obj[0]['statistics']['teams']) {
      let _teams = obj[0]['statistics']['teams']
      this.teams = [];
      this.is_oldfirmware = false;
      Object.keys(_teams).forEach(t => {
        if (_teams[t]['links']) {
          this.teams.push({
            ...{ title: t, }, links: _teams[t]['links']
          })
          this.links = [...this.links, ..._teams[t]['links']]
        }
      })
    }
    const firmwareVersion = obj[0].statistics.firmware.currentVersion.charAt(0)
   
    if (obj && obj[0] && obj[0]['statistics'] && obj[0]['statistics']['bridges'] && obj[0]['statistics']['bridges']['lan']) {
      if (parseInt(firmwareVersion) > 3) {
        this.bridges_lan = this.modifyIFname(obj[0]['statistics']['bridges']['lan'])
        this.bridges_lan2 = this.modifyIFname(obj[0]['statistics']['bridges']['lan2'])
        this.bridges_lan3 = this.modifyIFname(obj[0]['statistics']['bridges']['lan3'])
        this.bridges_guest = this.modifyIFname(obj[0]['statistics']['bridges']['guest'])
      } else {
        this.bridges_lan = obj[0]['statistics']['bridges']['lan']
        this.bridges_lan2 = obj[0]['statistics']['bridges']['lan2']
        this.bridges_lan3 = obj[0]['statistics']['bridges']['lan3']
        this.bridges_guest = obj[0]['statistics']['bridges']['guest']
      }
      
    }
    if (obj && obj[0] && obj[0]['statistics'] && obj[0]['statistics']['wifi']) {
      this.wifi_2g_ap = obj[0]['statistics']['wifi']['2g_ap']
      this.wifi_2g_guest = obj[0]['statistics']['wifi']['2g_guest']
      this.wifi_5g_ap = obj[0]['statistics']['wifi']['5g_ap']
      this.wifi_5g_guest = obj[0]['statistics']['wifi']['5g_guest']
    }
    if (obj && obj[0] && obj[0]['statistics'] && obj[0]['statistics']['4glte']) {
      this.foutglte = obj[0]['statistics']['4glte']
    }

  };
  @Output() edit = new EventEmitter();


  public teams = [];
  public bridges_lan: any;
  public bridges_lan2: any;
  public bridges_lan3: any;
  public bridges_guest: any;
  public wifi_2g_ap: any;
  public wifi_2g_guest: any;
  public wifi_5g_ap: any;
  public wifi_5g_guest: any;
  public foutglte: any;
  public is_oldfirmware = true;
  public LastHistData: any;
  public histdata: any = [];
  private availableLinks = {};

  constructor(
    private cdr: ChangeDetectorRef,
    private apService: ApService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }
  modifyIFname(bridge){
    if(bridge && bridge.ifname){
      let res = bridge.ifname.split("-");
      bridge.ifname = res[1]
      return bridge
    }      
  }
  sum(data) {
    let s = 0;
    data.forEach(element => {
      s += element
    });
    return s;

  }

  total() {
    let s = 0;
    Object.keys(this.availableLinks).forEach(k => {
      s += this.availableLinks[k]
    })
    return this.formatBytes(s)
  }

  formatBytes(bytes, k=0, decimals = 2) {
    bytes = Number(bytes);
    if (bytes === 0) return '0 Bytes';

    k = k == 0? 1024: k;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) {
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }

  getData(label) {
    let sum = 0;
    this.histdata.forEach(element => {
      if (element[label + " Transferred"]) {
        return sum += (Number(element[label + " Transferred"] > 0) ? Number(element[label + " Transferred"]): 0)
      }
    });
    if(label == "wwan0"){
      return this.formatBytes(sum, 1000)
    }
    return this.formatBytes(sum)
  }

  getDataLink(label, link) {
    let sum = 0;
    this.histdata.forEach(element => {
      if(element[link + " " + label + " Transferred"]){
        return sum += (Number(element[label + " Transferred"] > 0) ? Number(element[label + " Transferred"]): (Number(element[link + " " + label + " Transferred"] > 0) ? Number(element[link + " " + label + " Transferred"]): 0))
      } else if (element[label + " Transferred"]) {
        return sum += (Number(element[label + " Transferred"] > 0) ? Number(element[label + " Transferred"]): 0)
      }
    });
    if(label == "wwan0"){
      return this.formatBytes(sum, 1000)
    }
    return this.formatBytes(sum)
  }

  getDPC(label) {
    if (label && label.indexOf("wg") !== -1) {
      let bwCount = Number(label.replace(/[^0-9]/g, ""));
      return `wg${bwCount} (DPC${bwCount})`
    } else {
      return null
    }
  }

  getKey(label) {
    if (this.LastHistData) {
      for (let i = 0; i < Object.keys(this.LastHistData).length; i++) {
        let key = Object.keys(this.LastHistData)[i];
        if (key.indexOf(label) > -1) {
          let str = key.split(label)[1]
          return str.replace(" (", "").replace(")", "");
        }
      }
    }
  }

  editDevice() {
    this.edit.emit();
  }
}
