import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Chart from 'chart.js';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { NovuTransactionsModalComponent } from 'src/app/pages/device/novu-transactions-modal/novu-transactions-modal.component';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { Timezone } from 'src/app/shared/model/timezone';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-alert-links',
  templateUrl: './alert-links.component.html',
  styleUrls: ['./alert-links.component.scss']
})
export class AlertLinksComponent implements OnInit {
    
  @Input() selfSort: any;
  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
    }
  }
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private listOfDeviceState: Subscription;
  selectedTabIndex: number = 0;
  chartLink1: any;
  chartLink2: any;
  chartLink3: any;
  chartLinkB1: any;
  _device: any = []
  displayedColumns: string[] = ["Date Processed", "TransactionId", "Status", 'Action'];
  dynamicColumns: string [] = []
  col: any;
  dir: any;
  data = []
  _dataSource: MatTableDataSource<any>;  
  deviceId = ""
  trackStatus = []
  searchMonthPipe = ""
  searchDatePipe = ""
  searchPipe = ""
  listOfMonths = []
  listOfDates = []
  selectedMonth = ""
  selectedDate = ""
  selectedTimezone = "America/New_York"
  deviceForm: any;
  graphCount: any = []
  upLink1Cnt = 0
  downLink1Cnt = 0
  upLink2Cnt = 0
  downLink2Cnt = 0
  upLink3Cnt = 0
  downLink3Cnt = 0
  upLinkB1Cnt = 0
  downLinkB1Cnt = 0
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  timezoneArray = Timezone;
  loading = true;

  currentPage = 1;
  page = 0
  pageSize = 10
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;

  user
  orgId

  constructor( private changeDetection: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public apService: ApService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router,
    public userService: UserService) { 
    this.deviceForm = this.formBuilder.group({
      searchMonth: new FormControl(null),
      searchDate: new FormControl(null),
      searchTimezone: new FormControl(null),
    });
    this._dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);

    let id = this.route.snapshot.paramMap.get('id');
    this.orgId = id ? id.split("_").pop() : this.user.organization;
    
    this.getMonths()
    this.getDates()
    this.initMonthDate()
    // this.loadCustomizedDate() 
    var _timezone =  this._device[0]?.statistics?.timezone !== undefined ? this._device[0]?.statistics?.timezone : "EST5EDT,M3.2.0,M11.1.0";
    var systemTimeZone = this.timezoneArray.filter(x=> x['tz-posix'] == _timezone);
    this.selectedTimezone = systemTimeZone[0].tz;
    this.deviceForm.controls.searchTimezone.setValue(this.selectedTimezone);
    this.loadFilteredDates()    
  }
  ngAfterViewInit() {
    if (this.selfSort) {
      this.paginator.length = this.length;
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }
  initDynamicColumns(){
    this.dynamicColumns = ["Name","Mac","MSP", "Org"]
    if(this.data && this.data.length > 0) {
      const links = this.data[0]["links"] ?? []
      if(links && links.length > 0){      
        links.forEach(l => {
          this.dynamicColumns.push(l.link)
          // this.dynamicColumns.push(l.link + " State Changed")
          // this.dynamicColumns.push(l.link + " Link Alert")
        });
        this.displayedColumns.forEach(d=>{
          this.dynamicColumns.push(d)
        })
      }else {      
        this.dynamicColumns = this.displayedColumns
      }
    }else {
      this.dynamicColumns = this.displayedColumns
    }
    
  }
  initMonthDate(){
    var today = new Date();
    this.selectedMonth = this.monthNames[today.getMonth()] + ' - ' +today.getFullYear()
    this.selectedDate = today.getDate().toString()
    this.deviceForm.controls.searchMonth.setValue(this.selectedMonth);
    this.deviceForm.controls.searchDate.setValue(this.selectedDate);
  }
  displayLinkState(state){
    if(state){
      return "Up"    
    } else {
      return "Down"
    }
  }
  displayStatus(status){
    if( status == true) {
      return "Sent"
    } else {
      return "Failed"
    }
  }
  displayYesNo(status){
    if( status == true) {
      return "Yes"
    } else {
      return "No"
    }
  }
  displayTimezone(tz){
    return this.timezoneArray.find(x => x["tz-posix"] ==tz).name
  }
  displayFormatTimeZone(date,tz){
    let tzone = this.selectedTimezone
    if(tzone == "") {
      tzone = this.timezoneArray.find(x => x["tz-posix"] ==tz).tz
    }
    return moment(date).tz(tzone).format('ddd MMM D YYYY HH:mm:ss zz')
  }
  displayResult() {
    if (this.selfSort) {
      this._dataSource.data = this.data;
      this.length = this.data.length;
    } else {
      this._dataSource.data = this.data;
    }
  }
  drawLink1Chart() {
    this.upLink1Cnt = this.graphCount.link1.downtime == 0 && this.graphCount.link1.uptime == 0 ? 0 : 100.00 - parseFloat(this.graphCount.link1.downtime)
    this.downLink1Cnt =  parseFloat(this.graphCount.link1.downtime)
    this.chartLink1 = new Chart('link1-canvas', {
      type: 'doughnut',
      data: {
        labels: ['Up', 'Down'],
        datasets: [
          {
            data: [this.upLink1Cnt, this.downLink1Cnt],
            backgroundColor: ['#34a854', '#e81123'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chartLink1.update();
    }, 500)

  }
  drawLink2Chart() {
    this.upLink2Cnt = this.graphCount.link2.downtime == 0 && this.graphCount.link2.uptime == 0 ? 0 : 100.00 - parseFloat(this.graphCount.link2.downtime)
    this.downLink2Cnt =  parseFloat(this.graphCount.link2.downtime)
    this.chartLink2 = new Chart('link2-canvas', {
      type: 'doughnut',
      data: {
        labels: ['Up', 'Down'],
        datasets: [
          {
            data: [this.upLink2Cnt, this.downLink2Cnt],
            backgroundColor: ['#34a854', '#e81123'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chartLink2.update();
    }, 500)

  }
  drawLink3Chart() {
    this.upLink3Cnt = this.graphCount.link3.downtime == 0 && this.graphCount.link3.uptime == 0 ? 0 : 100.00 - parseFloat(this.graphCount.link3.downtime)
    this.downLink3Cnt =  parseFloat(this.graphCount.link3.downtime)
    this.chartLink3 = new Chart('link3-canvas', {
      type: 'doughnut',
      data: {
        labels: ['Up', 'Down'],
        datasets: [
          {
            data: [this.upLink3Cnt, this.downLink3Cnt],
            backgroundColor: ['#34a854', '#e81123'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chartLink2.update();
    }, 500)

  }
  drawLinkB1Chart() {
    this.upLinkB1Cnt = this.graphCount.linkB1.downtime == 0 && this.graphCount.linkB1.uptime == 0 ? 0 : 100.00 - parseFloat(this.graphCount.linkB1.downtime)
    this.downLinkB1Cnt =  parseFloat(this.graphCount.linkB1.downtime)
    this.chartLinkB1 = new Chart('linkB1-canvas', {
      type: 'doughnut',
      data: {
        labels: ['Up', 'Down'],
        datasets: [
          {
            data: [this.upLinkB1Cnt, this.downLinkB1Cnt],
            backgroundColor: ['#34a854', '#e81123'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chartLinkB1.update();
    }, 500)

  }
  domainChange() {
    this.userService.getOrganization({ id: this.orgId }).subscribe(res => {
      if (res && res.data && res.data[0]) {
        this.updateDomainName(res.data[0]);
      }
      this.displayResult()
    })
  }
  getDomainOrg(domain) {
    if (!domain) {
      return;
    }
    let filteredOrg = [];

    filteredOrg.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }
  getMonths(){
    var today = new Date();

    for (let i = 0; i < 3; i++) {
      this.listOfMonths.push(this.monthNames[(today.getMonth() - i)] + ' - ' +today.getFullYear()  );
    }
  }
  getDates(){
    this.listOfDates = []
    var selection = ""
    var curMonth = new Date().getMonth();
    var numDays = 0
    if(this.selectedMonth == ""){
      selection = new Date().getFullYear() + "-" + new Date().getMonth()
      numDays = new Date().getDate();
      
    } else {
      const m = this.selectedMonth.split(" - ")
      selection = m[1] +"-"+ (this.monthNames.indexOf(m[0]) +1)
      if(this.monthNames.indexOf(m[0]) === curMonth) {
        numDays = new Date().getDate();
      } else {
        numDays = moment(selection, "YYYY-MM").daysInMonth()
      }
    }
    for (let i = numDays; i >= 1; i--) {
      this.listOfDates.push(i.toString());
    }
    this.deviceForm.controls.searchDate.setValue(numDays.toString());
  }
  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadFilteredDates()
  }
  loadCustomizedDate(){
    const my = this.selectedMonth.split(' - ')
    const month = this.monthNames.indexOf(my[0]) + 1
    const year = my[1]
    const day = this.selectedDate
    const dateFrom = moment(year+"-"+month+"-"+day+" 00:00:00Z").toISOString()
    const dateTo = moment(year+"-"+month+"-"+day+" 23:59:59Z").toISOString()
    this.loadLinksStates(dateFrom, dateTo)
  }
  loadLinksStates(dateFrom, dateTo){    
    const request =   {
      orgId: this.orgId,     
      date_from: dateFrom,
      date_to: dateTo,
      page: this.currentPage,
      pageSize: this.pageSize
    }
    this.apService.getLinkState(request).subscribe(res=>{
      this.loading = false
      this.data = res.data
      this._dataSource.data = res.data
      this.length = res.count.all
      this.initDynamicColumns()
      this.domainChange()
    })
  }
  loadLinksCalc(dateFrom, dateTo, type){
    const request =   {
      orgId: this.orgId,     
      date_from: dateFrom,
      date_to: dateTo,
      type: type
    }
    this.apService.getLinkCalc(request).subscribe(res=>{
      this.loading = false
      this.data = res.data
      this.graphCount = res.data
      this.drawLink1Chart()
      this.drawLink2Chart()
      this.drawLink3Chart()
      this.drawLinkB1Chart()
    })
  }
  loadFilteredDates(){
    let ed, sd, date_from, date_to
    switch (this.selectedTabIndex) {     
    
      case 0: // 24 hrs
        const thisWeek = new Date();
        thisWeek.setDate(thisWeek.getDate() - thisWeek.getDay()); // Set to start of the week (Sunday)
        ed = moment(new Date()).tz(this.selectedTimezone);
        sd = moment(thisWeek).tz(this.selectedTimezone);
        date_from = moment(sd).toISOString()
        date_to = moment(ed).toISOString()
        this.loadLinksStates(date_from, date_to)
        this.loadLinksCalc(date_from, date_to, 1)
        break;

      case 1: // 7 days
        const today = new Date();
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - today.getDay() - 7); // Set to the start of last week (Sunday)
        const lastSaturday = new Date(today);
        lastSaturday.setDate(today.getDate() - today.getDay() - 1); // Set to the end of last week (Saturday)
        ed = moment(lastSaturday).tz(this.selectedTimezone);
        sd = moment(lastSunday).tz(this.selectedTimezone);
        date_from = moment(sd).toISOString()
        date_to = moment(ed).toISOString()
        this.loadLinksStates(date_from, date_to)
        this.loadLinksCalc(date_from, date_to, 2)
        break;
    
      case 2: // this month
        ed = moment(new Date()).tz(this.selectedTimezone);
        sd = moment(ed).startOf('month').tz(this.selectedTimezone);
        date_from = moment(sd).toISOString()
        date_to = moment(ed).toISOString()
        this.loadLinksStates(date_from, date_to)
        this.loadLinksCalc(date_from, date_to, 3)
        break;

      case 3: // last month
        ed =moment(new Date()).subtract(1, 'months').endOf('month').tz(this.selectedTimezone);
        sd = moment(ed).startOf('month').tz(this.selectedTimezone);
        date_from = moment(sd).toISOString()
        date_to = moment(ed).toISOString()
        this.loadLinksStates(date_from, date_to)
        this.loadLinksCalc(date_from, date_to, 4)
        break;

      default:
        break;
    }   

  }
  onSelectionMonthChange(event){
    if(event.value){
      this.selectedMonth = event.value;
      this.getDates()
      // this.loadHistoryData(this.type);
    }
  }
  onSelectionTZChange(event){
    if(event.value){
      this.selectedTimezone = event.value;
      this.loadFilteredDates()
    }
  }
  onSelectionDateChange(event){
    let dateFrom, dateTo
    if(event.value){
      this.selectedDate = event.value;
      
    }
    this.loadLinksStates(dateFrom,dateTo);
  }
  searchMonthFocus(){
    // setTimeout(() => {
    //   this.searchElement.nativeElement.focus();
    // }, 50);
  } 
  searchDateFocus(){
    // setTimeout(() => {
    //   this.searchElement.nativeElement.focus();
    // }, 50);
  }
  searchFocus(){

  }
  sortData(sort: Sort) {
    if (this.selfSort) {
      const data = this.trackStatus.slice();
      if (!sort.active || sort.direction === '') {
        this._dataSource.data = data;
        return;
      }

      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

      this._dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return compare(a.name, b.name, isAsc);
          case 'mac': return compare(a.mac, b.mac, isAsc);
          case 'msp': return compare(a.msp, b.msp, isAsc);
          case 'domain': return compare(a.domain, b.domain, isAsc);
          case 'location': return compare(a.location, b.location, isAsc);
          case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
          case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
          case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
          case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
          case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
          case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
          case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
          // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
          default: return 0;
        }
      });
    }
  }
  selectedTabChange(event){
    if(event) {
      this.selectedTabIndex = event.index
      this.loadFilteredDates()
    }    
  }
  updateDomainName(org) {
    let orgList = this.getDomainOrg(org);
    if (!orgList) {
      return;
    }
    for (let i = 0; i < this._dataSource.data.length; i++) {
      for (let j = 0; j < orgList.length; j++) {
        if (this._dataSource.data[i].orgId == orgList[j]._id && orgList[j].role == 3) {
          this._dataSource.data[i].domain = orgList[j].name
        } else if (this._dataSource.data[i].orgId == orgList[j]._id && orgList[j].role == 2) {
          this._dataSource.data[i].msp = orgList[j].name
        }
        if (orgList[j].role == 2) {
          for (let k = 0; k < orgList[j].subOrganizations.length; k++) {
            if (this._dataSource.data[i].orgId == orgList[j].subOrganizations[k]._id) {
              this._dataSource.data[i].msp = orgList[j].name
            }
          }
        }
      }
    }
  }
  viewNovu(data): void {
    const dialogRef = this.dialog.open(NovuTransactionsModalComponent, {
      disableClose: true,
      width: '1000px',
      data: { data, showDevice: false},   
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.loadData();
    });
  }
}
