<mat-card>  
    <div class="row">
      <div class="col">
        <ngx-gauge size="150" 
            type="arch" 
            thick="10"           
            cap="butt" 
            [append]="uptime.append"
            [max]="uptime.max"
            [label]="uptime.label" 
            [value]="uptime.value"
            [thresholds]="thresholdConfig1">
        </ngx-gauge>
      </div>
      <!-- <div class="col">
        <ngx-gauge size="150" 
            type="arch" 
            thick="10"            
            cap="butt" 
            append="%"
            max="3"
            [label]="load_avg.label" 
            [value]="load_avg.value"
            [thresholds]="thresholdConfig1">
        </ngx-gauge>
      </div> -->
      <div class="col">
        <ngx-gauge size="150" 
            type="arch" 
            thick="10"            
            cap="butt" 
            append="%"
            [label]="cpu_usage.label" 
            [value]="cpu_usage.value" 
            [thresholds]="thresholdConfig">
        </ngx-gauge>
      </div>
      <div class="col">
        <ngx-gauge size="150" 
            type="arch" 
            thick="10"            
            cap="butt" 
            append="%"
            [label]="memory_usage.label" 
            [value]="memory_usage.value" 
            [thresholds]="thresholdConfig">
            </ngx-gauge>
      </div>
      <div class="col">        
      </div>
      <div class="col">        
    </div>
    <div class="col">        
    </div>
    </div>
  </mat-card>