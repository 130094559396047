import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceMonitorComponent } from '../device/device-monitor/device-monitor.component';
import { OrgDevicesComponent } from './org-devices.component';
import { WirelessNetworkComponent } from '../template/network/wireless-network/wireless-network.component';

const routes: Routes = [
  { path: ':id/devices', component: OrgDevicesComponent },
  { path: ':orgId/device/:device_id', component: DeviceMonitorComponent },
  { path: ':id/wireless-network', component: WirelessNetworkComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrgRoutingModule { }
