import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NovuService } from 'src/app/shared/services/novu.service';

@Component({
  selector: 'app-novu-transactions-modal',
  templateUrl: './novu-transactions-modal.component.html',
  styleUrls: ['./novu-transactions-modal.component.scss']
})
export class NovuTransactionsModalComponent implements OnInit {
 private loadNotificationSubscription: Subscription
  notifs: any = {}
  noData = false
  isDevice = false
  isLinks = false
  to = ""
  constructor(
    public novuService: NovuService,
    private router: Router,
    public dialogRef: MatDialogRef<NovuTransactionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public showDevice: any
  ) { }

  ngOnInit(): void {
    if(this.data.showDevice){
      this.isDevice = true
    } else {
      this.isLinks = true
    }
    if (this.data.data && this.data.data.novuTransactionId && this.data.data.novuTransactionId != "sync-no-email") {
      const request = {
        transactionId: this.data.data.novuTransactionId
      }
      this.loadNotificationSubscription = this.novuService.getNotifications(request).subscribe(res => {
        this.notifs = res.data[0];
        this.to = this.notifs.jobs[0].overrides.email.to ?? this.notifs.subscriber.email
        if(!res.data && res.data.length > 0){
          this.noData = true
        }
      })
    } else {
      this.noData = true
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
