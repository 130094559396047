import { NgModule } from '@angular/core';

import { AdminLayoutRoutingModule } from './admin-layout-routing.module';
import { DeviceModule } from '../pages/device/device.module';
import { OrgModule } from '../pages/org-devices/org.module';
import { OrganizationModule } from '../pages/organization/organization.module';
import { QosModule } from '../pages/template/qos/qos.module';
import { UserModule } from '../pages/user/user.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { ReportModule } from '../pages/report/report.module';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TopmenuPanelComponent } from './topmenu/topmenu-panel.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { AccordionDirective } from './sidemenu/accordion.directive';
import { AccordionAnchorDirective } from './sidemenu/accordionanchor.directive';
import { AccordionItemDirective } from './sidemenu/accordionItem.directive';
import { ProfileComponent } from '../pages/profile/profile.component';
import { NetworkModule } from '../pages/template/network/network.module';
import { PrtgModule } from '../pages/prtg/prtg.module';
import { PendingDevicesComponent } from '../pages/pending-devices/pending-devices.component';
import { AllSearchesComponent } from '../pages/all-searches/all-searches.component';
import { VhubDeviceComponent } from '../pages/vhub-device/vhub-device.component';


@NgModule({
  declarations: [
    //Directives
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    //Components
    ProfileComponent,
    AdminLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    SidemenuComponent,
    PendingDevicesComponent,
    VhubDeviceComponent,
    TopmenuComponent,
    TopmenuPanelComponent,
    AllSearchesComponent
  ],
  imports: [
    SharedModule,
    AdminLayoutRoutingModule,
    OrganizationModule,
    OrgModule,
    DeviceModule,
    UserModule,
    NetworkModule,
    PrtgModule,
    QosModule,
    ReportModule
  ]
})
export class AdminLayoutModule { }
