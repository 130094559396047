   <ng-container>
    <legend class="mb-1" mat-dialog-title>Subscriber Info</legend>
    <div mat-dialog-content>
        <table>
            <tr>
                <th>SubscriberId</th>
                <th>Name</th>
                <th>Mac Address</th>
                <th>Main Email Address</th>
                <th>Registered Emails</th>
            </tr>
            <tr *ngIf="!noData">
                <td>{{ subsInfo.subscriberId }}</td>
                <td>{{ subsInfo.lastName }}</td>
                <td>{{ subsInfo.firstName }}</td>
                <td>{{ subsInfo.email }}</td> 
                <td>{{ subsInfo.data.alert?.emails }}</td>                    
            </tr>
            <tr *ngIf="noData">
                <td colspan="5">No Registered Subscriber Details</td>
            </tr>
        </table>
    </div>
    <div mat-dialog-actions style="float: right;">           
        <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Close</button>
    </div>
</ng-container> 