<div class="mt-2 monitor-lan-jump-wrapper panel" *ngIf="_device">
    <hr class="y-border" />
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="!hideToggle">Enable</mat-slide-toggle>
    <div class="mb-4">
        <h6>
            General
        </h6>
        <table>
            <tr>
                <th>Jump Sessions</th>
                <th>Description</th>
                <th>Destination Device</th>
                <th>Destination Port</th>
                <th>Expire</th>
                <th>Enable</th>
            </tr>
            <tr *ngFor="let lan of lanData | keyvalue">
                <td *ngIf="lan.value.description">{{ lan.value.cfg }}</td>
                <td *ngIf="lan.value.description">{{ lan.value.description }}</td>
                <td *ngIf="lan.value.description">{{ lan.value.forward_host }}</td>
                <td *ngIf="lan.value.description">{{ lan.value.localport }}</td>
                <td *ngIf="lan.value.description">{{ lan.value.expire_time }}</td>
                <td *ngIf="lan.value.description">{{ lan.value.enabled == 0 ? false : true}}</td>
            </tr>
        </table>
    </div>
</div>