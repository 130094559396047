import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubMenuService {
constructor() { }
private dataSource = new BehaviorSubject(false);
subMenuData = this.dataSource.asObservable();
 private dataSource1 = new BehaviorSubject(false);
 MenuSelectData = this.dataSource1.asObservable();
  changeData(data: boolean) {
    this.dataSource.next(data);
 }
  menuData(data: boolean) {
    this.dataSource1.next(data);
 }


//  serviceCartData = this.dataSource.asObservable();
//  changeCartData(cartdata: any) {
//    this.dataSource.next(cartdata);
// }

// changeVisiblity(message: boolean) {
//   this.messageSource.next(message);
// }
}
