<div class="mt-2 monitor-i4glte-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab [label]="label"></mat-tab>
    </mat-tab-group>
    <hr class="y-border" />
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="!hideToggle">Enable</mat-slide-toggle>
    <table>
        <tbody>
            <tr>
                <th>APN</th>
                <th>Auth Type</th>
                <th>User Name</th>
                <th>Password</th>
                <th>PDP Type</th>
                <th>Service Domain</th>
                <th *ngIf="carrier">Carrier</th>
                <th *ngIf="lte_band">LTE Band</th>
                <th *ngIf="lte_band == 2">Custom LTE Band</th>
            </tr>
            <tr>
                <td>{{apn}}</td>
                <td>{{auth}}</td>
                <td>{{username}}</td>
                <td>{{password}}</td>
                <td>{{pdptype}}</td>
                <td>{{servicedomain}}</td>
                <td *ngIf="carrier">
                    <span *ngIf="carrier == 'auto'">Auto</span>
                    <span *ngIf="carrier == 'verizon'">Verizon</span>
                    <span *ngIf="carrier == 'att'">AT&T</span>
                    <span *ngIf="carrier == 'tmobile'">T-Mobile</span>
                </td>
                <td *ngIf="lte_band">
                    <span *ngIf="lte_band == 1">Enable LTE All Bands</span>
                    <span *ngIf="lte_band == 2">LTE Custom Bands</span>
                </td>
                <td *ngIf="lte_band == 2">
                    <span *ngIf="lte_band_custom.indexOf('2') > -1">B2 (1900 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('4') > -1">B4 (1700/2100 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('5') > -1">B5 (850 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('12') > -1">B12 (700 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('13') > -1">B13 (700 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('14') > -1">B14 (700 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('66') > -1">B66 (1700/2100 MHz)<br/></span>
                    <span *ngIf="lte_band_custom.indexOf('71') > -1">B71 (600 MHz)</span>
                </td>
            </tr>
        </tbody>
    </table>
    <table>
        <tbody>
            <tr>
                <th *ngIf="this.sim">Operator</th>
                <th *ngIf="this.sim">IMEI</th>
                <th *ngIf="this.sim">CCID</th>
                <th *ngIf="this.sim">IMSI</th>
                <th *ngIf="this.sim">ICCID</th>
            </tr>
            <tr>
                <td *ngIf="this.sim">{{operator}}</td>
                <td *ngIf="this.sim">{{imei}}</td>
                <td *ngIf="this.sim">{{ccid}}</td>
                <td *ngIf="this.sim">{{imsi}}</td>
                <td *ngIf="this.sim">{{iccid}}</td>
            </tr>
        </tbody>
    </table>
</div>