import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { TemplateService } from 'src/app/shared/services/template.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prtg-add',
  templateUrl: './prtg-add.component.html',
  styleUrls: ['./prtg-add.component.scss']
})
export class PrtgAddComponent implements OnInit, OnDestroy {
  pageAvailable: boolean = true;
  submitted = false;
  prtgForm: FormGroup;
  cureentuser: any;
  org: any;
  role: any;
  editMode = false;
  data: any;
  type = 0;
  templateId: any;
  templates: any = [];
  triggers: any = [];

  // Recipient Email
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emails = [];

  private loadDataSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public templateService: TemplateService,
    private toastr: ToastrService,
    private router: Router,
    public route: ActivatedRoute,
    private topnavbarService: TopnavbarService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');
        if (id) {
          this.loadData(id.split("_").pop());
        }
      }
    });
    this.route.queryParams.subscribe(
      params => {
        this.templateId = params['template']
        if (this.templateId) {
          this.loadData(params['template'])
        }
      });
  }

  loadData(prtgId) {
    this.loadDataSubscription = this.templateService.getPrtgInfo(prtgId).subscribe(res => {
      if (res && res[0]) {
        this.data = res[0];
        this.org = res[0];
      }
      if (this.data) {
        if (!this.templateId) {
          this.prtgForm.controls._id.setValue(this.data._id);
        }
        this.prtgForm.controls.name.setValue(this.data.name);
        this.prtgForm.controls.endpoint.setValue(this.data.endpoint);
        this.prtgForm.controls.userName.setValue(this.data.userName);
        this.prtgForm.controls.passHash.setValue(this.data.passHash);
        this.prtgForm.controls.rootGroupId.setValue(this.data.rootGroupId);
        this.prtgForm.controls.rootGroupDeviceId.setValue(this.data.rootGroupDeviceId);
        this.prtgForm.controls.emptyGroupIDToClone.setValue(this.data.emptyGroupIDToClone);
        this.prtgForm.controls.emptyDeviceIDToClone.setValue(this.data.emptyDeviceIDToClone);
        this.prtgForm.controls.emptySensorIDToClone.setValue(this.data.emptySensorIDToClone);
        this.emails = this.data.adminEmails;
        this.prtgForm.controls.adminEmails.setValue(this.emails);
      }
      this.setTopNavMenu();
    });
  }

  ngOnInit(): void {
    this.cureentuser = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.role = this.cureentuser['role'];

    this.prtgForm = this.formBuilder.group({
      _id: new FormControl(''),
      orgId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      endpoint: new FormControl('', [Validators.required]),
      userName: new FormControl('', [Validators.required]),
      passHash: new FormControl('', [Validators.required]),
      rootGroupId: new FormControl('', [Validators.required]),
      rootGroupDeviceId: new FormControl('', [Validators.required]),
      emptyGroupIDToClone: new FormControl('', [Validators.required]),
      emptyDeviceIDToClone: new FormControl('', [Validators.required]),
      emptySensorIDToClone: new FormControl('', [Validators.required]),
      adminEmails: new FormControl([], [Validators.required]),
    });
    this.prtgForm.controls.orgId.setValue(this.cureentuser.organization);
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    if (this.router.url.includes("/layout/prtg")) {
      if (!this.router.url.includes("_")) {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/prtg",
            "name": "Prtg",
            "type": "link",
          }
        ])
      } else {
        this.topnavbarService.set([
          {
            "route": "/layout/organization",
            "name": "Root",
            "type": "link",
          },
          {
            "route": "/layout/prtg",
            "name": "Prtg",
            "type": "link",
          },
          {
            "route": "/layout/prtg/" + this.org['name'] + "_" + this.org['_id'],
            "name": this.org ? this.org['name'] : "",
            "type": "text"
          }
        ])
      }
    }
  }

  onSubmit() {
    if (this.submitted) return;
    if (!this.prtgForm.invalid) {
      let data = {
        ...this.prtgForm.value
      }
      this.submitted = true;
      this.templateService.createPRTG(data).subscribe(res => {
        this.submitted = false;
        this.toastr.success('PRTG Created Successfully!');
        this.router.navigate(['/layout/prtg']);
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }))
    } else {
      this.toastr.error('Unable to submit a form. Invalid!');
    }
  }

  selectedTabChange(evt) {
    this.type = evt.index;
  }

  updateTemplates(tpls) {
    this.templates = tpls;
  }

  updateTriggers(triggers) {
    this.triggers = triggers;
  }

  addRecipient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.emails.push(value);
      this.prtgForm.controls.adminEmails.setValue(this.emails);
    }
    // Clear the input value
    event.input.value = "";
  }

  removeRecipient(email): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.prtgForm.controls.adminEmails.setValue(this.emails);
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
