<nav class="navbar navbar-expand-lg navbar-light ">
    <a class="navbar-brand" href="#">
        <img src="assets/images/brand.png" class="imgg"></a>
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link  menu" href="#">
                    <span class="sr-only">(current)</span></a>
            </li>
        </ul>
    </div> -->
</nav>


<div id="logreg-forms">
    <!-- <form class="form-signin" [formGroup]="loginForm"> -->
    <h1 class="h3 mb-3 font-weight-normal sign_in">Sign up</h1>
    <form class="example-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">

        <mat-form-field class="example-full-width">
            <input matInput placeholder="Name" formControlName="name" />
            <mat-error *ngIf="registerForm.controls['name'].hasError('required')">
                Name is required
            </mat-error>

        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input matInput placeholder="Email" formControlName="email" />
            <mat-error *ngIf="registerForm.controls['email'].hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="registerForm.controls['email'].hasError('pattern')">
                Email is invalid
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input [type]="hide1 ? 'password' : 'text'" matInput placeholder="Password" formControlName="password" />
            <mat-icon class="visibility-icon" matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
                Password is required
            </mat-error>
            <mat-error *ngIf="registerForm.controls['password'].hasError('minlength')">
                Password is invalid
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="Retype Password" formControlName="repass" />
            <mat-icon class="visibility-icon" matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="registerForm.controls['repass'].hasError('mustMatch')"> Passwords must match</mat-error>
        </mat-form-field>

        <div class="row">
            <div class="col-md-12">
                <button class="w-full" mat-raised-button color="primary"
                    [disabled]="registerForm.invalid">Register</button>
            </div>
        </div>

        <div class="col-md-12 ">
            <div class="login-or">
                <hr class="hr-or">
                <span class="span-or">or</span>
            </div>
        </div>

        <div class="form-group">
            <p class="text-center">Already account? <a href="#" [routerLink]="['/login']"
                    routerLinkActive="router-link-active" id="signup">Sign in here</a></p>
        </div>
    </form>
    <!-- </div> -->
    <!-- </form> -->
</div>