<div class="mt-2 monitor-qos-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab [label]="label"></mat-tab>
    </mat-tab-group>
    <hr class="y-border" />
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="!hideToggle">Enable</mat-slide-toggle>
    <div class="w-100 overflow-auto">
        <table mat-table [dataSource]="dataSource" style="width: 100%;" *ngIf="dataSource.length">
    
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
    
            <ng-container matColumnDef="config">
                <th mat-header-cell *matHeaderCellDef width="11%"> Configuration </th>
                <td mat-cell *matCellDef="let element"> {{element.config}} </td>
            </ng-container>
    
            <ng-container matColumnDef="c1">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 1 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c1)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c2">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 2 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c2)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c3">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 3 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c3)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c4">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 4 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c4)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c5">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 5 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c5)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c6">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 6 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c6)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c7">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 7 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c7)"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="c8">
                <th mat-header-cell *matHeaderCellDef width="11%"> Class 8 </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element.c8)"></div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>