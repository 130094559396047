import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceRoutingModule } from './device-routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { DeviceBandwidthComponent } from './device-monitor/device-bandwidth/device-bandwidth.component';
import { DeviceCurrentStateComponent } from './device-monitor/device-current-state/device-current-state.component';
import { DeviceDataUsageComponent } from './device-monitor/device-data-usage/device-data-usage.component';
import { DeviceLinkLatencyComponent } from './device-monitor/device-link-latency/device-link-latency.component';
import { DeviceLinkStateComponent } from './device-monitor/device-link-state/device-link-state.component';
import { DeviceMonitorAnalyticsComponent } from './device-monitor/device-monitor-analytics/device-monitor-analytics.component';
import { DeviceMonitorMonitorComponent } from './device-monitor/device-monitor-monitor/device-monitor-monitor.component';
import { DeviceMonitorTeam1Component } from './device-monitor/device-monitor-team1/device-monitor-team1.component';
import { DeviceMonitorWnetworkComponent } from './device-monitor/device-monitor-wnetwork/device-monitor-wnetwork.component';
import { DeviceMonitorComponent } from './device-monitor/device-monitor.component';
import { DeviceMosComponent } from './device-monitor/device-mos/device-mos.component';
import { DeviceUptimeComponent } from './device-monitor/device-uptime/device-uptime.component';
import { DeviceMonitorQosComponent } from './device-monitor/device-monitor-qos/device-monitor-qos.component';
import { DialogChartContentComponent } from './device-monitor/dialog-chart-content/dialog-chart-content.component';
import { DeviceMonitorI4glteComponent } from './device-monitor/device-monitor-i4glte/device-monitor-i4glte.component';
import { FirmwareUpdateModelComponent } from './firmware-update-model/firmware-update-model.component';
import { DeviceMonitorSystemComponent } from './device-monitor/device-monitor-system/device-monitor-system.component';
import { DeviceMonitorInterfacesComponent } from './device-monitor/device-monitor-interfaces/device-monitor-interfaces.component';
import { VhubVceModalComponent } from './vhub-vce-modal/vhub-vce-modal.component';
import { DeviceVceVhubComponent } from './device-monitor/device-vce-vhub/device-vce-vhub.component';
import { DeviceVceVhubInfoComponent } from './device-monitor/device-vce-vhub/device-vce-vhub-info/device-vce-vhub-info.component';
import { VhubConnectModalComponent } from './vhub-connect-modal/vhub-connect-modal.component';
import { DeviceRasComponent } from './device-monitor/device-ras/device-ras.component';
import { DeviceLanJumpComponent } from './device-monitor/device-lan-jump/device-lan-jump.component';
import { SystemInformationMonitorComponent } from './device-monitor/device-monitor-analytics/system-information-monitor/system-information-monitor.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { DeviceDataUsageChartComponent } from './device-monitor/device-data-usage-chart/device-data-usage-chart.component';
import { DeviceMonitorAlertComponent } from './device-monitor/device-monitor-alert/device-monitor-alert.component';
import { DeviceAlertDeviceStateComponent } from './device-monitor/device-monitor-alert/device-alert-device-state/device-alert-device-state.component';
import { DeviceAlertLinkStateComponent } from './device-monitor/device-monitor-alert/device-alert-link-state/device-alert-link-state.component';
import { SubcriberInfoModalComponent } from './subcriber-info-modal/subcriber-info-modal.component';
import { NovuTransactionsModalComponent } from './novu-transactions-modal/novu-transactions-modal.component';
import { DeviceAttackShieldComponent } from './device-monitor/device-attack-shield/device-attack-shield.component';
import { DeviceMonitorSipwatchComponent } from './device-monitor/device-monitor-sipwatch/device-monitor-sipwatch.component';
import { DeviceSipwatchAlertSettingsComponent } from './device-monitor/device-monitor-sipwatch/device-sipwatch-alert-settings/device-sipwatch-alert-settings.component';
import { DeviceSipwatchAlertLogsComponent } from './device-monitor/device-monitor-sipwatch/device-sipwatch-alert-logs/device-sipwatch-alert-logs.component';
import { DeviceSipwatchCalleridLogsComponent } from './device-monitor/device-monitor-sipwatch/device-sipwatch-callerid-logs/device-sipwatch-callerid-logs.component';
import { DeviceSipwatchCalleridSettingsComponent } from './device-monitor/device-monitor-sipwatch/device-sipwatch-callerid-settings/device-sipwatch-callerid-settings.component';
import { DeviceMonitorEtherVlanComponent } from './device-monitor/device-monitor-ether-vlan/device-monitor-ether-vlan/device-monitor-ether-vlan.component';
import { DeviceNotesComponent } from './device-monitor/device-notes/device-notes.component';
import { NoteModalComponent } from './note-modal/note-modal.component';

@NgModule({
  declarations: [
    DeviceMonitorComponent,
    DeviceBandwidthComponent,
    DeviceLinkLatencyComponent,
    DeviceDataUsageComponent,
    DeviceUptimeComponent,
    DeviceCurrentStateComponent,
    DeviceMosComponent,
    DeviceLinkStateComponent,
    DialogChartContentComponent,
    DeviceMonitorAnalyticsComponent,
    DeviceMonitorTeam1Component,
    DeviceMonitorQosComponent,
    DeviceMonitorMonitorComponent,
    DeviceMonitorWnetworkComponent,
    DeviceMonitorI4glteComponent,
    FirmwareUpdateModelComponent,
    DeviceMonitorSystemComponent,
    DeviceMonitorInterfacesComponent,
    VhubVceModalComponent,
    DeviceVceVhubComponent,
    DeviceVceVhubInfoComponent,
    VhubConnectModalComponent,
    DeviceRasComponent,
    DeviceLanJumpComponent,
    SystemInformationMonitorComponent,
    DeviceDataUsageChartComponent,
    DeviceMonitorAlertComponent,
    DeviceAlertDeviceStateComponent,
    DeviceAlertLinkStateComponent,
    SubcriberInfoModalComponent,
    NovuTransactionsModalComponent,
    DeviceAttackShieldComponent,
    DeviceMonitorSipwatchComponent,
    DeviceSipwatchAlertSettingsComponent,
    DeviceSipwatchAlertLogsComponent,
    DeviceSipwatchCalleridLogsComponent,
    DeviceSipwatchCalleridSettingsComponent,
    DeviceMonitorEtherVlanComponent,
    DeviceNotesComponent,
    NoteModalComponent
  ],
  imports: [
    CommonModule,
    DeviceRoutingModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    NgxGaugeModule
  ]
})
export class DeviceModule { }
