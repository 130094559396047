<div>
    <div class="px-3">
        <ng-container *ngIf="vHub_mode">
            <p class="mt-3">DPC (Data Plane Cloud) Info</p>
            <table>
                <tbody>
                    <tr>
                        <th>DPC</th>
                        <th>Team Link</th>
                        <th>Description</th>
                        <th>vHUB Endpoint</th>
                        <th>Listen Port</th>
                        <th>MTU</th>
                        <th>Addresses</th>
                    </tr>
                    <tr *ngFor="let wireguard of wireguards">
                        <td>{{wireguard.title}}</td>
                        <td>{{wireguard.link}}</td>
                        <td>{{wireguard.description}}</td>
                        <td>{{wireguard.vhub_endpoint}}</td>
                        <td>{{wireguard.listen_port}}</td>
                        <td>{{wireguard.mtu}}</td>
                        <td>{{wireguard.addresses}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="vHub_mode">
            <p class="mt-4">Peer Info</p>
            <table>
                <thead>
                    <tr>
                        <th>Iface</th>
                        <th>Peer/Device</th>
                        <th>Organization</th>
                        <th>Peer IP:SPort</th>
                        <th style="max-width: 250px;">Peer Ovelay IP</th>
                        <th>Transmitted</th>
                        <th>Received</th>
                        <th>Last Handshake</th>
                        <th>Keep Alive</th>
                        <th>State</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let peer of peers">
                        <tr *ngFor="let info of peer">
                            <td>{{wireguards[info.vhub_dpc]?.title}}</td>
                            <td *ngIf="getOrganization(info.vce_mac)">
                                <a class="table-link"
                                    [routerLink]="['/layout/org/'+(getOrganization(info.vce_mac))+'/device/'+info.vce_mac]"
                                    routerLinkActive="router-link-active" target="_blank">
                                    {{info.vce_hostname}} <br />
                                    {{info.vce_mac}}
                                </a>
                            </td>
                            <td *ngIf="!getOrganization(info.vce_mac)">
                                {{info.vce_hostname}} <br />
                                {{info.vce_mac}}
                            </td>
                            <td class="org">{{getOrgPath(info.vce_mac)}}</td>
                            <td>{{info.addr == "(none)" ? "" : info.addr }}</td>
                            <td>{{info.allowed_ips?info.allowed_ips.split(",").join(", "):""}}</td>
                            <td>{{(!info.tx_bytes || formatBytes(info.tx_bytes) == "0 Bytes") ? "" :
                                formatBytes(info.tx_bytes)}}</td>
                            <td>{{(!info.rx_bytes || formatBytes(info.rx_bytes) == "0 Bytes") ? "" :
                                formatBytes(info.rx_bytes)}}</td>
                            <td>{{info.last_handshake == 0 ? '' : info.last_handshake*1000 | date:'short' }}</td>
                            <td>{{info.keepalive}}</td>
                            <td>
                                <span style='color: #45d645;' *ngIf="info.up == 1">Up</span>
                                <span style='color: #b0b0b0;' *ngIf="info.up == 0">Down</span>
                                <span style='color: #3580bb;' *ngIf="info.request_action == 'new'">Pending</span>
                            </td>
                            <td>
                                <mat-icon class="pointer v-align-middle" matTooltip="Disable" role="img"
                                    aria-hidden="true" (click)="_toggle(info, true)"
                                    *ngIf="role<=3 && (info?.disabled!='1' && info?.request_action!='disable')">
                                    stop_circle</mat-icon>
                                <mat-icon class="pointer v-align-middle" matTooltip="Enable" role="img"
                                    aria-hidden="true" (click)="_toggle(info, false)"
                                    *ngIf="role<=3 && (info?.disabled=='1' || info?.request_action=='disable')">
                                    play_circle</mat-icon>
                                <mat-icon class="pointer v-align-middle" matTooltip="Delete" role="img"
                                    aria-hidden="true" (click)="_delete(info)" *ngIf="role<=3">
                                    restore_from_trash</mat-icon>
                            </td>
                        </tr>
                    </ng-container>
                    
                </tbody>
            </table>
        </ng-container>
    </div>

    <ng-container *ngIf="vCE_mode">
        <table>
            <thead>
                <tr>
                    <th>Host/Device</th>
                    <th>Organization</th>
                    <th>Peer IP:SPort</th>
                    <th>Peer Ovelay IP</th>
                    <th>Transmitted</th>
                    <th>Received</th>
                    <th>Last Handshake</th>
                    <th>Keep Alive</th>
                    <th>State</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="vce_devices">
                    <tr *ngFor="let info of vce_devices">
                        <td *ngIf="getOrganization(info.vhub_mac)">
                            <a class="table-link"
                                [routerLink]="['/layout/org/'+(getOrganization(info.vhub_mac))+'/device/'+info.vhub_mac]"
                                routerLinkActive="router-link-active" target="_blank">
                                {{info.vhub_hostname}} <br />
                                {{info.vhub_mac}}
                            </a>
                        </td>
                        <td *ngIf="!getOrganization(info.vhub_mac)">
                            {{info.vhub_hostname}} <br />
                            {{info.vhub_mac}}
                        </td>
                        <td class="org">{{getOrgPath(info.vhub_mac)}}</td>
                        <td>{{info.addr == "(none)" ? "" : info.addr }}</td>
                        <td [innerHtml]="info.allowed_ips"></td>
                        <td>{{(!info.tx_bytes || formatBytes(info.tx_bytes) == "0 Bytes") ? "" :
                            formatBytes(info.tx_bytes)}}</td>
                        <td>{{(!info.rx_bytes || formatBytes(info.rx_bytes) == "0 Bytes") ? "" :
                            formatBytes(info.rx_bytes)}}</td>
                        <td>{{info.last_handshake == 0 ? '' : info.last_handshake*1000 | date:'short' }}</td>
                        <td>{{info.keepalive}}</td>
                        <td>
                            <span style='color: #45d645;' *ngIf="info.up == 1">Up</span>
                            <span style='color: #b0b0b0;' *ngIf="info.up == 0">Down</span>
                            <span style='color: #3580bb;' *ngIf="info.request_action == 'new'">Pending</span>
                        </td>
                        <td>
                            <mat-icon class="pointer v-align-middle" matTooltip="Disable Ovelay" role="img" aria-hidden="true"
                                (click)="_toggle(info, true)" *ngIf="role<=3 && (info?.disabled!='1' && info?.request_action!='disable')">
                                stop_circle</mat-icon>
                            <mat-icon class="pointer v-align-middle" matTooltip="Enable Ovelay" role="img" aria-hidden="true"
                                (click)="_toggle(info, false)" *ngIf="role<=3 && (info?.disabled=='1' || info?.request_action=='disable')">
                                play_circle</mat-icon>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
</div>