import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDevice'
})
export class SearchDevicePipe implements PipeTransform {

  transform(deviceArray: any[], searchPipe: string): any[] {
    if(!deviceArray || !deviceArray.length) return deviceArray;
    if(!searchPipe || !searchPipe.length) return deviceArray;

    return deviceArray.filter(device => {
      if(device.name !== undefined){
        if(device.name.toLowerCase().includes(searchPipe.toLowerCase())){
          return device.name.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1 
        }
      }

      if(device.domain !== undefined){
        if(device.domain.toLowerCase().includes(searchPipe.toLowerCase())){
          return device.domain.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
        }
      }
      
      if(device.mac !== undefined){
        if(device.mac.toLowerCase().includes(searchPipe.toLowerCase())){
          return device.mac.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
        }
      }

      if(device.location !== undefined){
        if(device.location.toLowerCase().includes(searchPipe.toLowerCase())){
          return device.location.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
        }
      }

      // if(device.statistics.firmware.currentVersion.toLowerCase() !== undefined){
      //   if(device.statistics.firmware.currentVersion.toLowerCase().includes(searchPipe.toLowerCase()) ){
      //     return device.statistics.firmware.currentVersion.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
      //   }
      // }

      if(device.deviceMetaInfo.deviceModel !== undefined){
        if(device.deviceMetaInfo.deviceModel.toLowerCase().includes(searchPipe.toLowerCase())){
          return device.deviceMetaInfo.deviceModel.toLowerCase().indexOf(searchPipe.toLowerCase()) > -1
        }
      }


    });
  }

}
