export class FormatterHelper {

  static formatBytes(bytes, decimals = 2) {
    bytes = Number(bytes);
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }
  static uptimeToDecimal(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    hour = (input / 3600);
    return hour;
  }
  static formatNumber(number, decimals = 2) {
    return Number(number).toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }  


}
