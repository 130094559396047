import { Login } from 'src/app/shared/model/login';
import { LoginService } from 'src/app/shared/services/login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  inputValue: string;
  hide: boolean = true;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private loginService: LoginService,
              private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    localStorage.clear();
  }
  onSubmit() {
    this.loginService.userLogin(this.loginForm.value as Login).subscribe(res => {
      if (res.token) {
        this.toastr.success('Login success');
        this.authService.setCookie("USER_TOKEN", res.token, 60);
        localStorage.setItem(UserConstant.TOKEN, res.token);
        localStorage.setItem(UserConstant.USER, JSON.stringify(res.user));
        localStorage.setItem(UserConstant.LOGINTYPE, "local")
        this.router.navigate(['/layout']);
      }
    }, (err => {
      err.status = 400;
      this.toastr.warning('Invalid login credentials');
    }));
  }
  clearinput() {
    this.loginForm.controls.email.setValue('');
    this.loginForm.controls.password.setValue('');

  }
}
