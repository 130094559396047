import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Chart from 'chart.js';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { NovuTransactionsModalComponent } from 'src/app/pages/device/novu-transactions-modal/novu-transactions-modal.component';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { Timezone } from 'src/app/shared/model/timezone';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-alert-devices',
  templateUrl: './alert-devices.component.html',
  styleUrls: ['./alert-devices.component.scss']
})
export class AlertDevicesComponent implements OnInit {
  @Input() selfSort: any;
  @Input() set deviceInfo(obj) {
    if (obj) {
      this._device = obj;
    }
  }
  @Input() set dataSource(data) {
    data = data.map(v => ({...v, orderdate: v.orderdate ?? ""}))
    // for (let i = 0; i < data.length; i++) {
    //   const arr = data[i];
    //   arr["status"] = ""
    //   if(arr.lastSeen){
    //     arr["status"] = this.getStatus(arr.lastSeen,arr.track)
    //   } else {
    //     arr["status"] ="off"
    //   }
    // }
    // this.deviceArray = data;
    // this.domainChange();
    // setInterval(() => {
    //   this.domainChange()
    // }, 480000);
  };
  
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private listOfDeviceState: Subscription;
  
  chart: any;
  _device: any = []
  graphCount: any = []
  displayedColumns: string[] = ["Name","Mac","MSP","Org","Device State", "Last Seen", "Date Processed", "TransactionId", "Status", 'Action'];
  col: any;
  dir: any;
  _dataSource: MatTableDataSource<any>;
  deviceId = ""
  trackStatus = []
  searchMonthPipe = ""
  searchDatePipe = ""
  searchPipe = ""
  listOfMonths = []
  listOfDates = []
  selectedMonth = ""
  selectedDate = ""
  selectedTimezone = "America/New_York"
  selectedTabIndex: number = 0;
  upDeviceCnt = 0
  downDeviceCnt = 0
  warnDeviceCnt = 0
  deviceForm: any;
  monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  timezoneArray = Timezone;
  loading = true;

  currentPage = 1;
  page = 0
  pageSize = 10
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  pageEvent: PageEvent;


  user
  orgId
  constructor( private changeDetection: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public apService: ApService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router,
    public userService: UserService) { 
    this.deviceForm = this.formBuilder.group({
      searchMonth: new FormControl(null),
      searchDate: new FormControl(null),
      searchTimezone: new FormControl(null),
    });
    this._dataSource = new MatTableDataSource<any>();
  }

  
  ngOnInit(): void {
    // this._dataSource.data = this.tempData
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);

    let id = this.route.snapshot.paramMap.get('id');
    this.orgId = id ? id.split("_").pop() : this.user.organization;
    this.getMonths()
    this.getDates()
    this.initMonthDate()
    var _timezone =  this._device[0]?.statistics?.timezone !== undefined ? this._device[0]?.statistics?.timezone : "EST5EDT,M3.2.0,M11.1.0";
    var systemTimeZone = this.timezoneArray.filter(x=> x['tz-posix'] == _timezone);
    this.selectedTimezone = systemTimeZone[0].tz;
    this.deviceForm.controls.searchTimezone.setValue(this.selectedTimezone);
    this.loadFilteredDates()
    if (this.router.url.includes("column=")) {
      let url = (this.router.url.split("?page=").pop()).split("&").splice(1, 2)
      this.col = url[0].split("=").pop()
      this.dir = url[1].split("=").pop()
      if (this.dir == 'asc') {
        this.sortData({ active: `${this.col}`, direction: 'asc' })
      } else if (this.dir == 'desc') {
        this.sortData({ active: `${this.col}`, direction: 'desc' })
      }
    }
  }
  ngAfterViewInit() {
    if (this.selfSort) {
      this.paginator.length = this.length;
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }
  initMonthDate(){
    var today = new Date();
    this.selectedMonth = this.monthNames[today.getMonth()] + ' - ' +today.getFullYear()
    this.selectedDate = today.getDate().toString()
    this.deviceForm.controls.searchMonth.setValue(this.selectedMonth);
    this.deviceForm.controls.searchDate.setValue(this.selectedDate);
  }
  displayDeviceState(state){
    if(state == 1){
      return "On"
    } else if(state == 2) {
      return "Warn"
    } else {
      return "Off"
    }
  }
  displayStatus(status){
    if( status == true) {
      return "Sent"
    } else {
      return "Failed"
    }
  }
  displayTimezone(tz){
    return this.timezoneArray.find(x => x["tz-posix"] ==tz).name
  }
  displayFormatTimeZone(date,tz){
    let tzone = this.selectedTimezone
    if(tzone == "") {
      tzone = this.timezoneArray.find(x => x["tz-posix"] ==tz).tz
    }
    return moment(date).tz(tzone).format('ddd MMM D YYYY HH:mm:ss zz')
  }
  displayResult() {
    // if (this.selfSort) {
    //   this._dataSource.data = this.data;
    //   this.length = this.data.length;
    // } else {
    //   this._dataSource.data = this.data;
    // }
  }
  drawDeviceChart() {
    this.upDeviceCnt = 100 - parseInt(this.graphCount.down)
    this.warnDeviceCnt = parseInt(this.graphCount.warn)
    this.downDeviceCnt = parseInt(this.graphCount.down)
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: ['Online', 'Offline', 'Warning'],
        datasets: [
          {
            data: [this.upDeviceCnt, this.downDeviceCnt, ],//this.warnDeviceCnt
            backgroundColor: ['#34a854', '#e81123', '#cf853e'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chart.update();
    }, 500)

  }
  domainChange() {
    this.userService.getOrganization({ id: this.orgId }).subscribe(res => {
      if (res && res.data && res.data[0]) {
        this.updateDomainName(res.data[0]);
      }
      //this.displayResult()
    })
  }
  getDomainOrg(domain) {
    if (!domain) {
      return;
    }
    let filteredOrg = [];

    filteredOrg.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }
  getMonths(){
    var today = new Date();

    for (let i = 0; i < 3; i++) {
      this.listOfMonths.push(this.monthNames[(today.getMonth() - i)] + ' - ' +today.getFullYear()  );
    }
  }
  getDates(){
    this.listOfDates = []
    var selection = ""
    var curMonth = new Date().getMonth();
    var numDays = 0
    if(this.selectedMonth == ""){
      selection = new Date().getFullYear() + "-" + new Date().getMonth()
      numDays = new Date().getDate();
      
    } else {
      const m = this.selectedMonth.split(" - ")
      selection = m[1] +"-"+ (this.monthNames.indexOf(m[0]) +1)
      if(this.monthNames.indexOf(m[0]) === curMonth) {
        numDays = new Date().getDate();
      } else {
        numDays = moment(selection, "YYYY-MM").daysInMonth()
      }
    }
    for (let i = numDays; i >= 1; i--) {
      this.listOfDates.push(i.toString());
    }
    this.deviceForm.controls.searchDate.setValue(numDays.toString());
  }
  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadFilteredDates()
  }
  loadCustomizedDate(){
    const my = this.selectedMonth.split(' - ')
    const month = this.monthNames.indexOf(my[0]) + 1
    const year = my[1]
    const day = this.selectedDate
    const dateFrom = moment(year+"-"+month+"-"+day+" 00:00:00Z").toISOString()
    const dateTo = moment(year+"-"+month+"-"+day+" 23:59:59Z").toISOString()
    this.loadDeviceStates(dateFrom, dateTo)
  }
  loadDeviceStates(dateFrom, dateTo){    
    const request =   {
      orgId: this.orgId,     
      date_from: dateFrom,
      date_to: dateTo,
      page: this.currentPage,
      pageSize: this.pageSize
    }    
    this.apService.getDeviceState(request).subscribe(res=>{
      this.loading = false
      this._dataSource.data = res.data
      this.length = parseInt(res.count.all)
      this.drawDeviceChart();
      this.domainChange()
    })
  }
  loadDeviceCalc(dateFrom, dateTo, type){    
    const request =   {
      orgId: this.orgId,     
      date_from: dateFrom,
      date_to: dateTo,
      type: type
    }    
    this.apService.getDeviceCalc(request).subscribe(res=>{
      this.loading = false
      this.graphCount = res.data
      this.drawDeviceChart();
    })
  }
  loadFilteredDates(){
    let ed, sd
    switch (this.selectedTabIndex) {
      case 0: // this week
        const thisWeek = new Date();
        thisWeek.setDate(thisWeek.getDate() - thisWeek.getDay()); // Set to start of the week (Sunday)
        ed = moment(new Date()).toISOString()
        sd = moment(thisWeek).toISOString()
        this.loadDeviceStates(sd, ed)
        this.loadDeviceCalc(sd, ed, 0)
        break;
    
      case 1: // last week
        const today = new Date();
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - today.getDay() - 7); // Set to the start of last week (Sunday)
        const lastSaturday = new Date(today);
        lastSaturday.setDate(today.getDate() - today.getDay() - 1); // Set to the end of last week (Saturday)
        ed = moment(lastSaturday).toISOString();
        sd = moment(lastSunday).toISOString();
        this.loadDeviceStates(sd, ed)
        this.loadDeviceCalc(sd, ed, 1)
        break;

      case 2: // 7 days
        ed = moment(new Date()).toISOString()
        sd = moment(new Date(new Date(ed).getTime() - (7 * 24 * 60 * 60 * 1000))).toISOString()
        this.loadDeviceStates(sd, ed)
        this.loadDeviceCalc(sd, ed, 2)
        break;
    
      case 3: // this month
        ed = moment(new Date()).toISOString()
        sd = moment(ed).startOf('month').toISOString(); 
        this.loadDeviceStates(sd, ed)
        this.loadDeviceCalc(sd, ed, 3)
        break;

      case 4: // last month
        ed =moment(new Date()).subtract(1, 'months').endOf('month').toISOString(); 
        sd = moment(ed).startOf('month').toISOString()
        this.loadDeviceStates(sd, ed)
        this.loadDeviceCalc(sd, ed, 4)
        break;

      default:
        break;
    }   
  }
  loadTab(){
    if(this.router.url.includes("?type=")){
      let url = this.router.url.split("?type=").pop().split("-").reverse()
      this.selectedTabIndex = Number(url[0]) - 1;
      this.changeDetection.detectChanges();
    }
  }
  onSelectionMonthChange(event){
    if(event.value){
      this.selectedMonth = event.value;
      this.getDates()
      
    }
  }
  onSelectionTZChange(event){
    if(event.value){
      this.selectedTimezone = event.value;
      this.loadFilteredDates()
    }
  }
  onSelectionDateChange(event){
    if(event.value){
      this.selectedDate = event.value;
      this.loadCustomizedDate();
    }
  }
  searchMonthFocus(){
    // setTimeout(() => {
    //   this.searchElement.nativeElement.focus();
    // }, 50);
  } 
  searchDateFocus(){
    // setTimeout(() => {
    //   this.searchElement.nativeElement.focus();
    // }, 50);
  }
  searchFocus(){

  }
  sortData(sort: Sort) {
    if (this.selfSort) {
      const data = this.trackStatus.slice();
      if (!sort.active || sort.direction === '') {
        this._dataSource.data = data;
        return;
      }

      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

      this._dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'Device State': return compare(a.lastDeviceState, b.lastDeviceState, isAsc);
          case 'Timezone': return compare(a.timezone, b.timezone, isAsc);
          case 'Last Seen': return compare(a.lastSeen, b.lastSeen, isAsc);
          case 'Date Processed': return compare(a.novuDateProcessed, b.novuDateProcessed, isAsc);
          case 'TransactionId': return compare(a.novuTransactionId, b.novuTransactionId, isAsc);
          case 'Status': return compare(a.isDeviceAlertSent, b.isDeviceAlertSent, isAsc);
          // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
          default: return 0;
        }
      });
    }

    if (this.router.url.includes("page=")) {
      let url;
      if (this.router.url.includes("column")) {
        url = this.router.url.split("&column").shift()
        if (sort.direction == 'asc') {
          this.router.navigateByUrl(url + '&column=' + sort.active + '&order=asc');
        } else if (sort.direction == 'desc') {
          this.router.navigateByUrl(url + '&column=' + sort.active + '&order=desc');
        } else {
          if (this.router.url.includes("page=1")) {
            this.router.navigateByUrl("/layout/devices")
          } else {
            this.router.navigateByUrl(url);
          }
        }
      } else {
        if (sort.direction == 'asc') {
          this.router.navigateByUrl(this.router.url + '&column=' + sort.active + '&order=asc');
        } else if (sort.direction == 'desc') {
          this.router.navigateByUrl(this.router.url + '&column=' + sort.active + '&order=desc');
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    } else {
      if (sort.direction == 'asc') {
        this.router.navigateByUrl(this.router.url + '?page=1&column=' + sort.active + '&order=asc');
      } else if (sort.direction == 'desc') {
        this.router.navigateByUrl(this.router.url + '?page=1&column=' + sort.active + '&order=desc');
      } else {
        this.router.navigateByUrl(this.router.url);
      }
    }
  }
  selectedTabChange(event){
    if(event) {
      this.selectedTabIndex = event.index
      this.loadFilteredDates()
    }
  }
  updateDomainName(org) {
    let orgList = this.getDomainOrg(org);
    if (!orgList) {
      return;
    }
    for (let i = 0; i < this._dataSource.data.length; i++) {
      for (let j = 0; j < orgList.length; j++) {
        if (this._dataSource.data[i].orgId == orgList[j]._id && orgList[j].role == 3) {
          this._dataSource.data[i].domain = orgList[j].name
        } else if (this._dataSource.data[i].orgId == orgList[j]._id && orgList[j].role == 2) {
          this._dataSource.data[i].msp = orgList[j].name
        }
        if (orgList[j].role == 2) {
          for (let k = 0; k < orgList[j].subOrganizations.length; k++) {
            if (this._dataSource.data[i].orgId == orgList[j].subOrganizations[k]._id) {
              this._dataSource.data[i].msp = orgList[j].name
            }
          }
        }
      }
    }
  }
  viewNovu(data): void {
    const dialogRef = this.dialog.open(NovuTransactionsModalComponent, {
      disableClose: true,
      width: '1000px',
      data: { data, showDevice: true},      
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.loadData();
    });
  }

}
