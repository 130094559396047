import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';

@Component({
  selector: 'app-expanded-organization',
  templateUrl: './expanded-organization.component.html',
  styleUrls: ['./expanded-organization.component.scss']
})
export class ExpandedOrganizationComponent implements OnInit, OnChanges {
  @Input() org: any;
  @Input() role: any;
  @Input() col: any;
  @Input() dir: any;
  @Input() defaultData: any;
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<any>();

  expand_detail = [];
  deviceArray = [];
  selectedOrg: any;
  expandIdx: any;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public apService: ApService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //If sort default, data sorted to original order
        if(this.route.snapshot.queryParams.order == undefined){
          let defaultData = JSON.parse(this.defaultData)
          defaultData.filter((subOrg) => {
            if(subOrg._id == this.org._id){
              this.org = []
              this.org = subOrg
            }
          })
        }
      }
    });
   }

  ngOnInit(): void {
    this.expand_detail = new Array(this.org?.subOrganizations?.length).fill(false)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.expand_detail = []
    if(changes.col){
      this.col = changes.col.currentValue;
    }
    if(changes.dir){
      this.dir = changes.dir.currentValue;
      if(this.dir === "asc"){
        if(this.col == 'name'){
          return this.org.subOrganizations.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
        } else if(this.col == 'domain'){
          return this.org.subOrganizations.sort(function(a, b) {
            return a.domain.localeCompare(b.domain);
          });
        } else if(this.col == 'type'){
          return this.org.subOrganizations.sort(function(a, b) {
            return a.type.localeCompare(b.type);
          });
        }
      } else if(this.dir === "desc"){
        if(this.col == 'name'){
          return this.org.subOrganizations.sort(function(a, b) {
            return b.name.localeCompare(a.name);
          });
        } else if(this.col == 'domain'){
          return this.org.subOrganizations.sort(function(a, b) {
            return b.domain.localeCompare(a.domain);
          });
        } else if(this.col == 'type'){
          return this.org.subOrganizations.sort(function(a, b) {
            return b.type.localeCompare(a.type);
          });
        }
      }
    }
  }

  loadDevice(org) {
    if (org._id == this.selectedOrg) {
      this.deviceArray = []
      this.selectedOrg = null;
    } else {
      this.selectedOrg = org._id;
      let data = {
        params: {
          id: org._id,
        }
      };
      this.apService.getListing(data).subscribe(res => {
        this.deviceArray = res.data;
      })
    }
  }

  editEl(objData) {
    this.edit.emit(objData)
  }

  deleteEl(data) {
    this.delete.emit(data)
  }

  expand(org, idx) {
    this.expandIdx = idx;
    if (org.role == 3) {
      this.deviceArray = []
      this.selectedOrg = null;
      this.loadDevice(org)
    } else {
      this.expand_detail[idx] = !this.expand_detail[idx]
    }
  }

}
