<div class="mt-2 monitor-vce-vhub-wrapper panel">
    <button class="pointer vhub-vce-btn" (click)="openvHUBvCE()"
        [matTooltip]="vHub_mode?'Add vCE Peer':vCE_mode?'Connect to vHUB':''">
        <mat-icon role="img" aria-hidden="true" *ngIf="role<=3 && (vHub_mode || vCE_mode)" style="color: #3580bb;">
            vpn_lock
        </mat-icon>
    </button>
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab label="Peer Info (Device)">
        </mat-tab>
    </mat-tab-group>
    <hr class="y-border" />
    <!--Temporarily Disable-->
    <!--<mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="role == 0">
        {{!checked ? 'Enable Sync' : 'Disable Sync'}}</mat-slide-toggle>-->
    <app-device-vce-vhub-info [device]="_device" [vCE_devices]="vCE_devices" [role]="role" (delete)="delete($event)"
        (toggle)="toggle($event.con, $event.b)"></app-device-vce-vhub-info>
</div>