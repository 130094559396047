import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { EditOrganizationComponent } from '../../../pages/organization/edit-organization/edit-organization.component';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { UserService } from 'src/app/shared/services/user.service';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-organization-table',
  templateUrl: './organization-table.component.html',
  styleUrls: ['./organization-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrganizationTableComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'domain', 'role', 'action'];
  col: any;
  dir: any;
  defaultData: any;
  url: string[];
  @Input() set dataSource(data) {
    this.allOrgData = data;
    if (this.router.url.includes("page=")) {
      this.currentPage = parseInt(this.router.url.split("page=").pop());
      this.pageIndex = this.currentPage;
      if (this.paginator)
      this.paginator.pageIndex = this.currentPage - 1;
    }
    this._dataSource.data = this.allOrgData;
    this.defaultData = JSON.stringify(data);
    this.length = data.length;
  };
  @Input() orgId: any;
  @Input() selfSort: any;
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();

  _dataSource: MatTableDataSource<any>;
  role: any;
  org: any;
  orgName: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 25;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  pageIndex = 0;
  searchTxt = "";
  allOrgData: any = [];
  orgType = [
    "Master MSP", "MSP", "Organization"
  ]
  expandedRows = [];
  deviceArray = [];
  selectedOrg: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog, public userService: UserService, public apService: ApService, public route: ActivatedRoute,
    private toastr: ToastrService, private submenuService: SubMenuService,
    private topnavbarService: TopnavbarService, private router: Router,
    private changeDetection: ChangeDetectorRef) {
    this._dataSource = new MatTableDataSource<any>();
  }


  dtOptions: any = {};

  ngOnInit(): void {
    this.orgId = this.route.snapshot.paramMap.get('id');
    if (this.orgId) {
      this.orgId = this.orgId.split("_").pop()
    }
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;
  }

  ngAfterViewInit() {
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = this.url[0].split("=").pop()
        this.col = this.url[1].split("=").pop()
        this.dir = this.url[2].split("=").pop()
      } else {
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = this.url[0].split("=").pop()
        this.dir = this.url[1].split("=").pop()
      }
      if(this.dir == 'asc'){
        this.sortData({active: `${this.col}`, direction: 'asc'})
      } else if (this.dir == 'desc'){
        this.sortData({active: `${this.col}`, direction: 'desc'})
      }
    } else {
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,1)
        this.searchTxt = this.url[0].split("=").pop()
      }
    }

    if (this.selfSort) {
      this.paginator.length = this.length;
      this.paginator.pageIndex =  this.currentPage;
      this.paginator.pageSize = this.pageSize;
      // Start: sort columns by URL parameters
      if(this.router.url.includes("column")){
        this.sort.active = this.col;
        if(this.dir == 'asc'){
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
          } else {
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&column='+this.col+'&order=asc');
          }
          this.sort.direction = 'asc';
        } else if(this.dir == 'desc') {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
          } else {
            this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&column='+this.col+'&order=desc');
          }
          this.sort.direction = 'desc'
        } else {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt);
          } else {
            this.router.navigateByUrl('/layout/organization');
          }
          this.sort.direction = '';
        }
      }
      // End: sort columns by URL parameters
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }

  deviceClick(id) {
    const url = '/#/layout/users/' + id + '/devices';
    location.href = url;
    // window.open(url,"_self");
  }

  getUserLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/users/' + org;
    } else {
      return '/layout/users'
    }
  }

  getDeviceLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/org/' + org + '/devices';
    } else {
      return '/layout/devices';
    }
  }

  getDomainLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/organization/' + org;
    } else {
      return '/layout/organization/';
    }
  }

 
  edit(objData) {
    const dialogRef = this.dialog.open(EditOrganizationComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh.emit()
    });
  }

  delete(data) {      
      if (data.subOrganizations.length > 0) {
        this.toastr.error('Organization contains active Sub Domains. Please remove them first.');
        return;
      } else {
        
      }
      let sub_users
      if (data.subUsers && data.subUsers.length > 0) {
        this.toastr.error('Organization contains active users. Please remove them first.');
        return;
      } else {
        this.userService.getOrganizationUsers({ id: data['_id'] }).subscribe(res => {
          if (res && res.data.length > 0) {            
            sub_users = res;
          }
        });
      }

      if (confirm('Are you sure you want to delete?')) {
        let obj = {
          orgId: data['_id']
        }
  
        let device_data = {
          params: {
            id: data['_id']
          }
        };
        this.apService.getListing(device_data).subscribe(res => {
          if(!data.subUsers && sub_users && sub_users.data[0].subUsers.length > 0) {    
            this.toastr.error('Organization contains active users. Please remove them first.');
            return;
          }
          if (res && res.data.length > 0) {
            this.toastr.error('Organization contains active devices. Please remove them first.');
            return;
          } else {
            this.userService.deleteOrganization(obj).subscribe(res => {
              this.toastr.success('Organization deleted successfully');
              this.refresh.emit()
            });
          }
        });
      }
  }

  deletePrompt(data){
   
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;

    // Succeeding code are for the URL parameters on click next/previous page
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
        this.col = url[1].split("=").pop()
        this.dir = url[2].split("=").pop()
      } else {
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = url[0].split("=").pop()
        this.dir = url[1].split("=").pop()
      }
    } else {
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
      }
    }

    if (this.router.url === "/layout/organization") {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
        }
      }
    } else {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page=' + this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url.split('?').shift() + '?page=' + this.currentPage);
        }
      }
    }
  }

  isExapandedRow(row) {
    if (this.expandedRows.includes(row._id)) {
      return true;
    } else {
      return false;
    }
  }

  toggleExpansion(row) {
    if (this.expandedRows.includes(row._id)) {
      this.expandedRows = []
    } else {
      this.expandedRows = [row._id]
      if (row.role == 3) {
        this.deviceArray = []
        this.selectedOrg = null;
        this.loadDevice(row)
      }
    }
  }

  hasChildren(row) {
    return row.subOrganizations && row.subOrganizations.length
  }

  loadSubOrgs(name,id){
    this.router.navigate(["/layout/organization/" + name + "_" + id]).then(() => {
      if (this.router.url.includes('/layout/organization/')) {
        window.location.reload();
      }
    });
  }

  loadDevice(org) {
    if (org._id != this.selectedOrg) {
      this.selectedOrg = org._id;
      let data = {
        params: {
          id: org._id,
        }
      };
      this.apService.getListing(data).subscribe(res => {
        this.deviceArray = res.data;
      })
    }
  }

  sortData(sort: Sort) {
    // Succeeding code are for the URL parameters on click on the sortable columns
    this.col = sort.active;
    if(this.router.url.includes("page=")){
      if(this.router.url.includes("column")){
        if(sort.direction == 'asc'){
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
          } else {
            this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+sort.active+'&order=asc');
          }          
          this.dir = 'asc';
        } else if(sort.direction == 'desc') {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+sort.active+'&order=desc');
          } else {
           this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&column='+sort.active+'&order=desc');
          }
          this.dir = 'desc';
        } else {
          if(this.router.url.includes("page=1")){
            if(this.router.url.includes("search=")){
              if(this.searchTxt && this.searchTxt.length > 0){
                this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt);
              } else {
                this.router.navigateByUrl("/layout/organization")
              }
            } else {
              this.router.navigateByUrl("/layout/organization")
            }
          } else {
            this.router.navigateByUrl('/layout/organization?page='+this.currentPage);
          }
        }
      } else {
        if(sort.direction == 'asc'){
          this.router.navigateByUrl(this.router.url+'&column='+sort.active+'&order=asc');
          this.dir = 'asc';
        } else if(sort.direction == 'desc') {
          this.router.navigateByUrl(this.router.url+'&column='+sort.active+'&order=desc');
          this.dir = 'desc';
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    } else {
      if(sort.direction == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
        } else {
          this.router.navigateByUrl(this.router.url+'?page=1&column='+sort.active+'&order=asc');
        }
        this.dir = 'asc';
      } else if(sort.direction == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt+'&column='+sort.active+'&order=asc');
        } else {
          this.router.navigateByUrl(this.router.url+'?page=1&column='+sort.active+'&order=desc');
        }
        this.dir = 'desc';
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page=1&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    }
  }

  ngOnDestroy() {
  }
}
