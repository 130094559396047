<mat-toolbar class="matero-toolbar" (window:resize)="onResize($event)">
  <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle" (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <nav aria-label="breadcrumb" class="d-md-block"
    [ngClass]="{'d-block': isVisible !== true, 'd-none': isVisible === true}">
    <ol class="breadcrumb  bg-none ">
      <ng-container *ngFor="let m of menuList;">
        <li class="breadcrumb-item" *ngIf="m.type=='link'">
          <a *ngIf="m.route.includes('/layout/organization/')" (click)="loadBreadcrumbs(m.name,m.route)" routerLinkActive="router-link-active">{{m.name}}</a>
          <a *ngIf="!(m.route.includes('/layout/organization/'))" [routerLink]="m.route" routerLinkActive="router-link-active">{{m.name}}</a>
        </li>
        <li class="breadcrumb-item" *ngIf="m.type=='text'">{{m.name}}</li>
        <li class="breadcrumb-item" *ngIf="m.type=='dropdown'">
          <span [matMenuTriggerFor]="viewMenu" class="current-label">{{m.name}}
            <mat-icon>arrow_drop_down</mat-icon>
          </span>
          <mat-menu #viewMenu="matMenu">
            <ng-container *ngFor="let sub of m.sub">
              <a mat-menu-item [routerLink]="sub.route" routerLinkActive="router-link-active">{{sub.name}}</a>
            </ng-container>
          </mat-menu>
        </li>
      </ng-container>
    </ol>
  </nav>

  <span fxFlex></span>

  <mat-icon class="d-md-none" [ngClass]="{'d-block': isVisible !== true, 'd-none': isVisible === true}"
    (click)="openSearch()">search</mat-icon>

  <div class="search-bar d-md-block" [ngClass]="{'d-block': isVisible === true, 'd-none': isVisible !== true}">
    <mat-icon>search</mat-icon>
    <input #input [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
      (focus)="loadAllOrganization();" placeholder="Search here..." size="50" (blur)="openSearch();"
      (keyup.enter)="clearSearch()" autocomplete="off">
    <ul
      *ngIf="searchPipe && ((deviceArray | searchDevice : searchPipe).length > 0 || (allOrgList | searchOrg : searchPipe).length > 0)">
      <li class="btn btn-default btn-lg disabled" *ngIf="(deviceArray | searchDevice : searchPipe).length > 0">
        DEVICE
      </li>
      <li *ngFor="let device of deviceArray | searchDevice : searchPipe | slice:0:5"
        (mousedown)="clickDeviceSearch(device.name,device._id)">
        <a class="btn btn-link">
          <span *ngIf="device.name != undefined">
            {{ device.name.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ? device.name : '' }}
          </span>
          <span *ngIf="device.domain !== undefined">
            {{ device.domain.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ? device.domain : '' }}
          </span>
          <span *ngIf="device.mac != undefined">
            {{ device.mac.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ? device.mac : '' }}
          </span>
          <span *ngIf="device.location != undefined">
            {{ device.location.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ? device.location : '' }}
          </span>
          <span *ngIf="device.statistics.firmware && device.statistics.firmware.currentVersion">
            {{ device.statistics.firmware.currentVersion.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ?
            device.statistics.firmware.currentVersion : '' }}
          </span>
          <span *ngIf="device.deviceMetaInfo.deviceModel != undefined">
            {{ device.deviceMetaInfo.deviceModel.toLowerCase().indexOf(this.searchPipe.toLowerCase()) != -1 ?
            device.deviceMetaInfo.deviceModel : '' }}
          </span>
        </a>
      </li>
      <li class="btn btn-default btn-lg disabled" *ngIf="(allOrgList | searchOrg : searchPipe).length > 0">
        ORGANIZATION
      </li>
      <li *ngFor="let organization of allOrgList | searchOrg : searchPipe | slice:0:5"
        (mousedown)="clickOrgSearch(organization.name,organization.orgId)">
        <a class="btn btn-link">{{ organization.name }}</a>
      </li>
      <li class="btn btnn mat-raised-button mat-button-base mat-primary" (mousedown)="clearSearch()">
        ALL SEARCHES
      </li>
    </ul>
  </div>

  <button mat-button [matMenuTriggerFor]="menu" *ngIf="loginType!='iframe'">
    <mat-icon class="mr-1">account_circle</mat-icon>
    <span class="d-none d-md-inline">{{userName}}</span>
    <i class="material-icons"> keyboard_arrow_down </i>
  </button>

  <mat-menu #menu="matMenu">
    <a class="m-w-150" routerLink="/layout/profile" routerLinkActive="router-link-active" mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span>Settings</span>
    </a>
    <button class="m-w-150" mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

</mat-toolbar>