import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Event, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ApService } from 'src/app/shared/services/ap.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-all-searches',
  templateUrl: './all-searches.component.html',
  styleUrls: ['./all-searches.component.scss']
})
export class AllSearchesComponent implements OnInit, OnDestroy {
  private pageAvailable = true;
  orgId: any;
  org: any;
  user: any;
  orgDataSource: any = [];
  allOrgData: any = [];
  deviceDataSource: any = [];
  deviceArray: any = [];
  orgLength = 0;
  searchTxt = "";

  private loadDataSubscription: Subscription;

  constructor(
    public userService: UserService,
    public apService: ApService,
    private ref: ChangeDetectorRef,
    public route: ActivatedRoute,
    private topnavbarService: TopnavbarService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem(UserConstant.USER));
    this.orgId = user.organization;

    if (this.router.url.includes("text=")) {
      let getSearchTxt = this.router.url.split("text=").pop();
      this.searchTxt = decodeURIComponent(getSearchTxt);
    } else {
      this.searchTxt = '';
    }
    this.loadData();
  }

  ngAfterViewInit() {
    this.setTopNavMenu();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "/layout/search/",
        "name": "Search",
        "type": "text",
      }
    ])
  }

  loadData() {
    let obj = {
      id: this.orgId
    }
    let data = {
      params: {
        id: this.orgId,
        searchTxt: this.searchTxt,
      }
    };

    this.loadDataSubscription = combineLatest([
      this.userService.getOrganization(obj),
      this.apService.getListing(data)
    ]).subscribe(results => {
      let res = results[0].data[0];
      this.allOrgData = this.getDomainOrg(res);
      this.searchOrgData();

      res = results[1];
      this.deviceArray = res.data;
      this.searchDeviceData();
    })
  }

  searchOrgData() {
    let orgData = this.allOrgData.filter(organization => {
      return organization.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) > -1
    });
    this.orgLength = orgData.length;
    this.orgDataSource = orgData
  }

  searchDeviceData() {
    let deviceData = this.deviceArray.filter(device => {
      if (device.name && device.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.domain.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.mac.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.location && device.location.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.deviceMetaInfo.deviceModel.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (device.statistics.firmware.currentVersion.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
    this.orgLength = this.deviceDataSource.length;
    this.deviceDataSource = deviceData;
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ _id: domain._id, name: domain.name, domain: domain.domain, role: domain.role, subOrganizations: domain.subOrganizations })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
