<nav class="navbar navbar-expand-lg navbar-light ">
  <a class="navbar-brand" href="#">
    <img src="assets/images/brand.png" class="imgg"></a>
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link  menu" href="#">
          <span class="sr-only">(current)</span></a>
      </li>
    </ul>
  </div> -->
</nav>


<!-- <body> -->
<div id="logreg-forms">
  <h1 class="h3 mb-3 font-weight-normal forget_pwd"> Forgot password</h1>
  <form class="example-form" [formGroup]="forgotpasswordform">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Username" formControlName="emailId" />
      <mat-error *ngIf="forgotpasswordform.controls['emailId'].hasError('required')">
        Email is required
      </mat-error>
      <mat-error *ngIf="forgotpasswordform.controls['emailId'].hasError('pattern')">
        Email is invalid
      </mat-error>
    </mat-form-field>
    <div class="row">
      <div class="col-md-12">
        <button class="w-full" mat-raised-button color="primary" [disabled]="forgotpasswordform.invalid"
          (click)="onSubmit()">Submit</button>
      </div>
    </div>
    <div class="m-t-12">
      <div class="row">
        <div class="col-md-12 text-right">
          <mat-card-actions>
            <a [routerLink]="['/login']" routerLinkActive="router-link-active"> Go back to Login</a>
          </mat-card-actions>
        </div>
      </div>
    </div>
  </form>
</div>