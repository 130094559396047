import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export interface TopMenu {
  route: string;
  name: string;
  type: 'link' | 'dropdown' | 'text';
  sub?: TopMenu[];
}

@Injectable({
  providedIn: 'root',
})
export class TopnavbarService {
  private menu$: BehaviorSubject<TopMenu[]> = new BehaviorSubject<TopMenu[]>([]);

  /** Get all the menu data. */
  getAll(): Observable<TopMenu[]> {
    return this.menu$.asObservable();
  }

  /** Observe the change of menu data. */
  change() {
    return this.menu$.pipe(share());
  }

  /** Initialize the menu data. */
  set(menu: TopMenu[]): Observable<TopMenu[]> {
    this.menu$.next(menu);
    return this.menu$.asObservable();
  }

  /** Add one item to the menu data. */
  add(menu: TopMenu) {
    const tmpMenu = this.menu$.value;
    tmpMenu.push(menu);
    this.menu$.next(tmpMenu);
  }

  /** Reset the menu data. */
  reset() {
    this.menu$.next([]);
  }
}
