import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/pages/login/login.component';

import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout/admin-layout.component';
import { AutoLoginComponent } from './pages/auto-login/auto-login.component';
import { LogoutComponent } from './pages/logout/logout.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'layout', pathMatch: 'full' },
  {
    path: 'layout', component: AdminLayoutComponent,
    loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
  },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'autologin', component: AutoLoginComponent },
  { path: 'logout', component: LogoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
