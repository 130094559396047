<ng-container>
    <legend class="mb-1" mat-dialog-title>Email Details</legend>
    <div mat-dialog-content>
        <table>
            <tr>
                <th>TransactionId</th>
                <th>Subject</th>
                <th>To</th>
                <th>Org</th>
                <th>Host</th>
                <th>Mac</th>
                <th>Time Reported</th>
            </tr>
            <tr *ngIf="!noData">
                <td>{{ notifs.transactionId }}</td>
                <td>{{ notifs.payload.subjectText }}</td>
                <td>{{ to }}</td>
                <td>{{ notifs.payload.org }}</td> 
                <td>{{ notifs.payload.mac }}</td>  
                <td>{{ notifs.payload.host }}</td>
                <td>{{ notifs.payload.time_reported }}</td>
            </tr>
            <tr *ngIf="noData">
                <td colspan="7">No Details</td>
            </tr>
        </table>
        <table *ngIf="isDevice">
            <tr>
                <th colspan="2">Device State</th>
            </tr>            
            <tr>
                <td>{{ notifs.payload.device_state }}</td>                              
            </tr>
            <!-- <tr>
                <td colspan="5">No Registered Subscriber Details</td>
            </tr> -->
        </table>
        <table *ngIf="isLinks">
            <tr>
                <th colspan="2">Link1</th>
                <th colspan="2">Link2</th>
                <th colspan="2">Link3</th>
                <th colspan="2">LinkB1</th>
            </tr>
            <tr>
                <th>Alert</th>
                <th>State</th>
                <th>Alert</th>
                <th>State</th>
                <th>Alert</th>
                <th>State</th>
                <th>Alert</th>
                <th>State</th>
            </tr>
            <tr>
                <td>{{ notifs.payload.link1.isLink1 }}</td>
                <td>{{ notifs.payload.link1.link1_state }}</td>
                <td>{{ notifs.payload.link2.isLink2 }}</td>
                <td>{{ notifs.payload.link2.link2_state }}</td>
                <td>{{ notifs.payload.link3.isLink3 }}</td>
                <td>{{ notifs.payload.link3.link3_state }}</td>  
                <td>{{ notifs.payload.linkB1.isLinkB1 }}</td>
                <td>{{ notifs.payload.linkB1.linkB1_state }}</td>             
            </tr>
            <!-- <tr>
                <td colspan="5">No Registered Subscriber Details</td>
            </tr> -->
        </table>
    </div>
    <div mat-dialog-actions style="float: right;">           
        <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Close</button>
    </div>
</ng-container> 