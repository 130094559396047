import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationModelComponent } from './organization-model/organization-model.component';
import { UserModelComponent } from '../user/user-model/user-model.component';
import { ApModelComponent } from '../device/ap-model/ap-model.component';

import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { PageEvent } from '@angular/material/paginator';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dataSource: any = [];
  displayedColumns: string[] = ['name', 'domain', 'role', 'status', 'action'];
  role: any;
  org: any;
  id: any;
  orgId: any;
  orgName: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 25;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  searchTxt = "";
  allData = [];
  allOrgData = [];
  result: boolean = false;
  orgType = [
    "Master MSP", "MSP", "Organization"
  ]

  loadDataSubscription: Subscription;
  private routeSubscription: Subscription;
  col: string;
  dir: string;
  url: string[];

  constructor(public dialog: MatDialog, public userService: UserService, public apService: ApService, public route: ActivatedRoute,
    private toastr: ToastrService, private submenuService: SubMenuService,
    private topnavbarService: TopnavbarService, private router: Router) {

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');

        if (id && id != this.orgId) {
          if (this.router.url.includes("_")) {
            this.orgId = id.split("_").pop();
          } else {
            this.orgId = id.split("/").pop();
          }
        }
      }
    });
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id && this.router.url.includes("_")) {
      this.orgId = this.id.split("_").pop();
    } else if (this.id && !this.router.url.includes("_")) {
      this.orgId = this.id.split("/").pop();
    } else {
      this.orgId = this.user.organization;
    }

    this.loadData();
  }

  setTopNavMenu(orgs) {
    if (!this.pageAvailable) return;
    //Set organization links
    this.topnavbarService.set([
      ...[{
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      }],
      ...orgs.reverse().map(o => ({
        "route": o ? "/layout/organization/" + o['_id'] : "/layout/organization",
        "name": o ? o['name'] : "",
        "type": "link",
      })),
      ...[{
        "route": "",
        "name": "Domain",
        "type": "dropdown",
        "sub": [
          {
            "route": this.getDomainLink(),
            "name": "Domain",
            "type": "link",
          },
          {
            "route": this.getDeviceLink(),
            "name": "Device",
            "type": "link",
          },
          {
            "route": this.getUserLink(),
            "name": "User",
            "type": "link",
          }
        ]
      }]
    ])
  }

  deviceClick(id) {
    const url = '/#/layout/users/' + id + '/devices';
    location.href = url;
    // window.open(url,"_self");
  }

  searchData() {
    if(this.router.url.includes("page=")){
      let url = (this.router.url.split("/layout/organization?").pop()).split("&")
      this.currentPage = Number(url[0].split("=").pop())
    } else {
      this.currentPage = 1;
    }

    if(this.router.url === "/layout/organization"){
      if(this.searchTxt.length > 0){
        this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt);
      } else {
        this.router.navigateByUrl(this.router.url);
      }
    } else {
      if(this.router.url.includes("column=")){
        if(this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order='+this.dir);
        } else {
          this.router.navigateByUrl('/layout/organization?page=1&column='+this.col+'&order='+this.dir);
        }
      } else {
        if(this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/organization?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url.split('?').shift());
        }
      }
    }

    let data = this.allOrgData.filter(organization => {
      if (this.searchTxt == ""){
        this.result = false;
        return false
      } else if(organization.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) > -1){
        this.result = true;
        return true
      } else if(organization.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) == -1){
        this.result = true;
        return false
      }
      return false
    });
    if(this.result == true){
      this.length = data.length;
      this.dataSource = data;
    } else {
      this.length = this.allData.length;
      this.dataSource = this.allData;
    }
  }

  getUserLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/users/' + org;
    } else {
      return '/layout/users'
    }
  }

  getDeviceLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/org/' + org + '/devices';
    } else {
      return '/layout/devices';
    }
  }

  getDomainLink() {
    if (this.orgId) {
      let org = this.orgId ? this.orgId : this.user.organization;
      return '/layout/organization/' + org;
    } else {
      return '/layout/organization/';
    }
  }

  addUserClick() {
    let objData = {};
    objData['orgId'] = this.orgId ? this.orgId : this.user.organization;
    objData['role'] = this.domain.role;
    objData['domain'] = this.domain;
    const dialogRef = this.dialog.open(UserModelComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  addDeviceClick() {
    const dialogRef = this.dialog.open(ApModelComponent, {
      disableClose: true,
      width: '700px',
      data: {
        domain: this.domain.domain,
        domainObj: this.domain
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  loadData() {
    this.loadDataSubscription = combineLatest([
      this.userService.getOrganization({ id: this.orgId }), 
      this.userService.parentOrganizationList(this.orgId)]
    ).subscribe(results => {
      let res = results[0].data[0];
      if (res) {
        this.domain = res;
        this.allOrgData = this.getDomainOrg(this.domain);
        if (this.domain.role == 3 && res['subOrganizations'].length == 0) {
          this.allData = [this.domain]
        } else {
          this.allData = res['subOrganizations']
          this.allData = this.allData.filter((org, index, self) =>
            index === self.findIndex((t) => (
              t['_id'] === org['_id']
            ))
          ).sort((o1, o2) => {
            if (o1['domain'] == "rrtmmsp") {
              return -1;
            } else if (o2['domain'] == 'rrtmmsp') {
              return 1;
            } else {
              return 0;
            }
          })
        }
        this.length = this.allData.length;
        this.dataSource = this.allData;
        this.org = res;
      } else {
        this.domain = []
        this.dataSource = [];
      }
      if (results[1]) {
        this.setTopNavMenu(results[1]);
      }

    // Start: sort columns by URL parameters
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = this.url[0].split("=").pop()
        this.col = this.url[1].split("=").pop()
        this.dir = this.url[2].split("=").pop()
        this.searchData();
      } else {
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = this.url[0].split("=").pop()
        this.dir = this.url[1].split("=").pop()
      }
    } else {
      if(this.router.url.includes("search=")){
        this.url = (this.router.url.split("?page=").pop()).split("&").splice(1,1)
        this.searchTxt = this.url[0].split("=").pop()
        this.searchData();
      }
    }
    // End: sort columns by URL parameters
    }, (err) => {
      this.toastr.error(err.error.error);
    });
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ _id: domain._id, name: domain.name, domain: domain.domain, role: domain.role, subOrganizations: domain.subOrganizations })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  addDomainClick(): void {
    const dialogRef = this.dialog.open(OrganizationModelComponent, {
      disableClose: true,
      width: '350px',
      data: { role: this.domain.role, domain: this.domain }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe()
    }
  }
}
