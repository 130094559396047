import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApModelComponent } from '../device/ap-model/ap-model.component';
import { ApService } from 'src/app/shared/services/ap.service';
import { formatDate, DatePipe } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { SubMenuService } from 'src/app/shared/services/sub-menu.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserModelComponent } from '../user/user-model/user-model.component';
import { OrganizationModelComponent } from '../organization/organization-model/organization-model.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { combineLatest, Subscription } from 'rxjs';


@Component({
  selector: 'app-devices',
  templateUrl: './org-devices.component.html',
  styleUrls: ['./org-devices.component.scss']
})
export class OrgDevicesComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  dnetwork: any = [];
  divicessArray: any = [];
  today = new Date();
  todaysDataTime: any;
  clock: Observable<any>;
  datedifferance: any;
  cdate: any;
  status: any;
  menuObj: any;
  uptimeToString: any;
  id: any;
  org: any;
  orgId: any;
  role: any;
  user: any;
  domain: any;

  length = 0;
  pageSize = 25;
  currentPage = 1;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  pageIndex = 0;
  @ViewChild('paginator') paginator: MatPaginator;
  searchTxt = "";
  sortData: any;

  private loadDataSubscription: Subscription;
  col: any;
  dir: string;
  loadOrgDataSubscription: Subscription;
  orgName: any;

  constructor(public dialog: MatDialog, public apService: ApService, public userService: UserService,
    private router: Router, public route: ActivatedRoute,
    private submenuService: SubMenuService, private topnavbarService: TopnavbarService
  ) {
    this.todaysDataTime = formatDate(this.today, 'hh:mm:ss', 'en-US', '+0530');
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    this.id = this.route.snapshot.paramMap.get('id');
    this.orgId = this.id ? this.id.split("_").pop() : this.user.organization;


    let obj = {
      id: this.orgId
    }
    this.loadOrgDataSubscription = combineLatest([this.userService.getOrganizationUsers(obj)]).subscribe(res => {
      let result = res[0].data;
      res = result[0];
      this.org = res;
      this.domain = res;
      this.setTopNavMenu();
    })

    if(this.router.url.includes("/layout/org")){
      if (this.id && this.router.url.includes("_")) {
        let url = (this.id.split("/devices").shift()).split("/layout/org").pop().split("_");
        this.orgName = url[0];
        this.orgId = url[1];
      }
    }

    if (this.router.url.includes("page=")) {
      this.currentPage = parseInt(this.router.url.split("page=").pop());
      this.pageIndex = this.currentPage - 1;
      if(this.router.url.includes("column=")){
        if(this.router.url.includes("search=")){
          let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
          this.searchTxt = url[0].split("=").pop()
          this.col = url[1].split("=").pop()
          this.dir = url[2].split("=").pop()
        } else {
          let url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
          this.col = url[0].split("=").pop()
          this.dir = url[1].split("=").pop()
        }
      } else {
        if(this.router.url.includes("search=")){
          let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
          this.searchTxt = url[0].split("=").pop()
        }
      }
    }

    this.loadData();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": this.org ? "/layout/organization/" + this.org['_id'] : "/layout/organization",
        "name": this.org ? this.org['name'] : "",
        "type": "link",
      },
      {
        "route": "",
        "name": "Device",
        "type": "dropdown",
        "sub": [
          {
            "route": this.getDomainLink(),
            "name": "Domain",
            "type": "link",
          },
          {
            "route": this.getDeviceLink(),
            "name": "Device",
            "type": "link",
          },
          {
            "route": this.getUserLink(),
            "name": "User",
            "type": "link",
          }
        ]
      }
    ])
  }

  loadData(init_p = false) {
    if (!this.orgId) return;
    if (init_p) {
      let url = (this.router.url.split("/layout/devices?").pop()).split("&");
      if(this.router.url.includes("page=")){
        if(Number(url[0].split("=").pop()) == 1){
          this.pageIndex = 0
          if (this.paginator) 
            this.paginator.firstPage();
        } else {
          this.paginator.pageIndex = Number(url[0].split("=").pop()) - 1
        }
      } else {
        this.pageIndex = 0
        if (this.paginator) 
          this.paginator.firstPage();
      }

      if(this.router.url.includes("page=")){
        this.currentPage = Number(url[0].split("=").pop())
      } else {
        this.currentPage = 1;
      }
      if(url.length == 3){
        this.col = url[1].split("=").pop()
        this.dir = url[2].split("=").pop()
      } else if(url.length == 4){
        this.col = url[2].split("=").pop()
        this.dir = url[3].split("=").pop()
      }

      if(this.router.url === "/layout/devices"){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url+'?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          if(this.currentPage == 1){
            this.router.navigateByUrl("/layout/devices");
          } else {
            this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
          }
        }
      } else {
        if(this.router.url.includes("column=")){
          if(this.router.url.includes("search=")){
            if(this.searchTxt && this.searchTxt.length > 0){
              this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt+"&column="+this.col+"&order="+this.dir);
            } else {
              this.router.navigateByUrl('/layout/devices?page=' + this.currentPage+"&column="+this.col+"&order="+this.dir);
            }
          } else {
            this.router.navigateByUrl('/layout/devices?page=' + this.currentPage+"&column="+this.col+"&order="+this.dir);
          }
        } else {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt);
          } else {
            if(this.currentPage == 1){
              this.router.navigateByUrl("/layout/devices");
            } else {
              this.router.navigateByUrl('/layout/devices?page='+this.currentPage);
            }
          }
        }
      }
    }
    let data = {
      params: {
        id: this.orgId,
        page: this.currentPage,
        pageSize: this.pageSize,
        searchTxt: this.searchTxt,
      }
    };
    if (this.sortData && this.sortData.active) {
      data.params['sortBy'] = this.sortData.active;
      data.params['sortDirection'] = this.sortData.direction;
    } else {
      // Start: sort columns by URL parameters
      if(this.router.url.includes("column")){
        data.params['sortBy'] = this.col;
        if(this.dir == 'asc'){
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
          } else {
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&column='+this.col+'&order=asc');
          }
          data.params['sortDirection'] = 'asc';
        } else if(this.dir == 'desc') {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
          } else {
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&column='+this.col+'&order=desc');
          }
          data.params['sortDirection'] = 'desc'
        } else {
          if(this.searchTxt && this.searchTxt.length > 0){
            this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt);
          } else {
            this.router.navigateByUrl('/layout/devices');
          }
          data.params['sortDirection'] = '';
        }
      }
      // End: sort columns by URL parameters
    }
    this.loadDataSubscription = combineLatest([this.apService.getListing(data)]).subscribe(res => {
      if (res[0]) {
        this.divicessArray = res[0].data;
        this.length = res[0].count;
        this.divicessArray.forEach(element => {
          this.uptimeToString = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
          element.upTime = this.uptimeToString;
        });
      }
    });
  }

  addUserClick() {
    let objData = {};
    objData['orgId'] = this.orgId ? this.orgId : this.user.organization;
    objData['role'] = this.domain.role;
    objData['domain'] = this.domain;

    const dialogRef = this.dialog.open(UserModelComponent, {
      disableClose: true,
      width: '350px',
      data: objData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  addDeviceClick() {
    const dialogRef = this.dialog.open(ApModelComponent, {
      disableClose: true,
      width: '700px',
      data: {
        domain: this.domain.domain,
        domainObj: this.domain
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  addDomainClick(): void {
    const dialogRef = this.dialog.open(OrganizationModelComponent, {
      disableClose: true,
      width: '350px',
      data: { role: this.domain.role, domain: this.domain }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadData();
    });
  }

  getUserLink() {
    let url;
    if (this.id) {
      url = '/layout/users/' + this.orgId;
    } else {
      url = '/layout/users/';
    }
    return url;
  }

  getDeviceLink() {
    let url;
    if (this.id) {
      url = '/layout/org/' + this.orgId + '/devices';
    } else {
      url = '/layout/devices';
    }
    return url;
  }

  getDomainLink() {
    let url;
    if (this.id) {
      url = '/layout/organization/' + this.orgId;
    } else {
      url = '/layout/organization/';
    }
    return url;
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    
    // Succeeding code are for the URL parameters on click next/previous page
    if(this.router.url.includes("column=")){
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
        this.col = url[1].split("=").pop()
        this.dir = url[2].split("=").pop()
      } else {
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,2)
        this.col = url[0].split("=").pop()
        this.dir = url[1].split("=").pop()
      }
    } else {
      if(this.router.url.includes("search=")){
        let url = (this.router.url.split("?page=").pop()).split("&").splice(1,3)
        this.searchTxt = url[0].split("=").pop()
      }
    }

    if (this.router.url === "/layout/devices") {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/devices?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
        }
      }
    } else if (this.router.url === "/layout/org") {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/org?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl('/layout/org?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/org?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl('/layout/org?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl('/layout/org?page='+this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage);
        }
      }
    } else {
      if(this.dir == 'asc'){
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url + '?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=asc');
        } else {
          this.router.navigateByUrl(this.router.url + '?page='+this.currentPage+'&column='+this.col+'&order=asc');
        }
      } else if(this.dir == 'desc') {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url + '?page='+this.currentPage+'&search='+this.searchTxt+'&column='+this.col+'&order=desc');
        } else {
          this.router.navigateByUrl(this.router.url + '?page='+this.currentPage+'&column='+this.col+'&order=desc');
        }
      } else {
        if(this.searchTxt && this.searchTxt.length > 0){
          this.router.navigateByUrl(this.router.url + '?page=' + this.currentPage+'&search='+this.searchTxt);
        } else {
          this.router.navigateByUrl(this.router.url.split('?').shift() + '?page=' + this.currentPage);
        }
      }
    }

    this.pageSize = event.pageSize;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.loadOrgDataSubscription) {
      this.loadOrgDataSubscription.unsubscribe();
    }
  }
}
