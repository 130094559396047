import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserConstant } from '../constants/user-constant';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }

    // Customize the default error handler here if needed
    private errorHandler(response): Observable<HttpEvent<any>> {
        if (response?.error?.error == "Access Denied" || response?.status == 401) {
            localStorage.removeItem(UserConstant.TOKEN);
            localStorage.removeItem(UserConstant.USER);
            localStorage.removeItem(UserConstant.LOGINTYPE);
            window.location.href = "/"
        }
        throw response;
    }

}