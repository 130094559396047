<form [formGroup]="deviceForm">
    <fieldset>
        <legend class="mb-1" mat-dialog-title>Select Organization Who Can View Pending</legend>
        <div mat-dialog-content>
            <div>
                <mat-form-field>
                    <mat-label>Mac</mat-label>
                    <input type="text" matInput formControlName="mac" readonly/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Select Organization</mat-label>
                    <mat-select formControlName="org" (click)="searchFocus()">
                        <div class="dropdown-search">
                            <input #orginput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
                                placeholder="Search Organization">
                        </div>
                        <div>
                            <mat-option *ngFor="let organization of allOrgList | searchOrg : searchPipe"
                                [value]="organization.orgId">
                                {{ organization.name }} ({{ (organization.role == 0? "Root": organization.role
                                == 1? "Master Msp":organization.role == 2? "Msp": organization.role == 3?
                                "Organization": "") }})
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>

        <div mat-dialog-actions style="float: right;">
            <button mat-raised-button color="primary" class=" btn btnn" (click)="onSubmit()">Update</button>
            <button mat-raised-button color="warn" class=" btnn" (click)="onNoClick()">Cancel</button>
        </div>
    </fieldset>
</form>