<div class="mt-2 monitor-wnetwork-wrapper panel" *ngIf="_device">
    <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
        <mat-tab [label]="label"></mat-tab>
    </mat-tab-group>
    <hr class="y-border" />
    <mat-slide-toggle [checked]="checked" class="tpl-toggle-btn" (change)="onTplToggle($event)" *ngIf="!hideToggle">Enable</mat-slide-toggle>
    <div class="w-100 overflow-auto">
        <table mat-table [dataSource]="dataSource" style="width: 100%;" *ngIf="dataSource.length">
    
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
    
            <ng-container matColumnDef="config">
                <th mat-header-cell *matHeaderCellDef width="11%"> Configuration </th>
                <td mat-cell *matCellDef="let element"> {{element.config | titlecase}} </td>
            </ng-container>
    
            <ng-container matColumnDef="2g_ap">
                <th mat-header-cell *matHeaderCellDef width="11%"> 2G Ap </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['2g_ap'])"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="2g_guest">
                <th mat-header-cell *matHeaderCellDef width="11%"> 2G Guest </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['2g_guest'])"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="5g_ap">
                <th mat-header-cell *matHeaderCellDef width="11%"> 5G Ap </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['5g_ap'])"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="5g_guest">
                <th mat-header-cell *matHeaderCellDef width="11%"> 5G Guest </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['5g_guest'])"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="2g_client">
                <th mat-header-cell *matHeaderCellDef width="11%"> 2G Client </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['2g_client'])"></div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="5g_client">
                <th mat-header-cell *matHeaderCellDef width="11%"> 5G Client </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHtml]="render(element['5g_client'])"></div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>