<div class="w-100 overflow-hidden">
    <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
    (matSortChange)="sortData($event)" multiTemplateDataRows>
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="25%">Name</th>
            <td mat-cell *matCellDef="let element" class="pointer">
                <i class="material-icons" style="font-size: 16px; margin-left: -16px; vertical-align: middle;"
                    matTooltip="Expand Organization"
                    *ngIf="hasChildren(element) && !isExapandedRow(element)">expand_more</i>
                <i class="material-icons" style="font-size: 16px; margin-left: -16px; vertical-align: middle;"
                    matTooltip="Expand Organization"
                    *ngIf="hasChildren(element) && isExapandedRow(element)">expand_less</i>
                <span>{{ element.name }}</span>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="25%">Domain</th>
            <td mat-cell *matCellDef="let element">{{ element.domain }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="25%">Type</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.role==1"> Master MSP</span>
                <span *ngIf="element.role==2"> MSP</span>
                <span *ngIf="element.role==3"> Organization</span>
            </td>

        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="bg-color" width="25%">Action</th>
            <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                    <mat-icon class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                        matTooltip="Edit" role="img" aria-hidden="true" (click)="edit(element)" *ngIf="role<=2">edit
                    </mat-icon>
                    <a class="table-link" (click)="loadSubOrgs(element.name,element._id)"
                        routerLinkActive="router-link-active">
                        <mat-icon class="pointer" matTooltip="View Organizations" role="img" aria-hidden="true"
                            *ngIf="element.role != 3">apartment</mat-icon>
                    </a>
                    <a class="table-link" [routerLink]="['/layout/users/'+element.name+'_'+element._id]"
                        routerLinkActive="router-link-active">
                        <mat-icon class="pointer" matTooltip="View Users" role="img" aria-hidden="true">person
                        </mat-icon>
                    </a>
                    <a class="table-link" [routerLink]="['/layout/org/'+element.name+'_'+element._id+'/devices']"
                        routerLinkActive="router-link-active">
                        <mat-icon class="pointer" matTooltip="View Devices" role="img" aria-hidden="true">device_hub
                        </mat-icon>
                    </a>
                    <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true"
                        (click)="delete(element)" *ngIf="role<=3">restore_from_trash</mat-icon>
                </div>
            </td>
        </ng-container>

        <!-- Template for details row -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let org" [attr.colspan]="displayedColumns.length" class="pl-0 pr-0">
                <div class="organization-element-detail d-block"
                    [@detailExpand]="isExapandedRow(org) ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="org.role < 3">
                        <app-expanded-organization [org]="org" [role]="role" (edit)="edit($event)"
                            (delete)="delete($event)" [col]="col" [dir]="dir" [defaultData]="defaultData"></app-expanded-organization>
                    </ng-container>
                    <ng-container *ngIf="deviceArray.length && isExapandedRow(org)">
                        <app-device-table [dataSource]="deviceArray" [selfSort]="true"></app-device-table>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.row-is-clicked]="isExapandedRow(row)"
            (click)="toggleExpansion(row)"></tr>

        <!-- Extra row to show detail content column -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="organization-detail-row"
            [ngClass]="{'no-bottom-color': !isExapandedRow(row)}"></tr>
    </table>

    <!-- PAGINATION -->
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>
</div>