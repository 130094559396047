<div class="mt-2 monitor-device-sipwatch-alert-logs-wrapper panel" *ngIf="_device">
    <div class="mt-4">        
        <hr class="y-border" />
        <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let element">{{ displayDate(element) | date: "MM/dd/yyyy hh:mm:ss a" }}</td>
        </ng-container>

        <ng-container matColumnDef="cfg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CFG</th>
            <td mat-cell *matCellDef="let element">{{element.watchlist_alert.cfg}} </td>
        </ng-container>

        <ng-container matColumnDef="SIPName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SIPName</th>
            <td mat-cell *matCellDef="let element">{{element.watchlist_alert.SIPName}}</td>
        </ng-container>

        <ng-container matColumnDef="SIPServerPort">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SIPServerPort</th>
            <td mat-cell *matCellDef="let element">{{ element.watchlist_alert.SIPServerPort }}</td>
        </ng-container>

        <ng-container matColumnDef="alert">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Alert</th>
            <td mat-cell *matCellDef="let element">{{displayAlert(element.watchlist_alert.alert)}}</td>
        </ng-container>

        <ng-container matColumnDef="alarm">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Alarm</th>
            <td mat-cell *matCellDef="let element">{{displayAlarm(element.watchlist_alert.alarm) }}</td>
        </ng-container>        

        <ng-container matColumnDef="ptrack">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>PTrack</th>
            <td mat-cell *matCellDef="let element">
                {{ element.watchlist_alert.ptrack }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>          
    </div>
</div>
