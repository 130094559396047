<div>
    <mat-card>
        <form>
            <fieldset>
                <legend class="mb-1">
                    <span *ngIf="qosId">Edit </span>
                    <span *ngIf="!qosId">Add </span>
                    QoS (Quality-of-Service) using 8 x Flow Isolation Queues for Priority
                        Queuing
                </legend>
                <mat-card-content>
                    <a class="device-link" [routerLink]="['/layout/qos/'+name+'_'+qosId+'/devices']"
                        routerLinkActive="router-link-active" *ngIf="qosId">
                        Devices
                    </a>
                    <mat-form-field class="mb-2" style="max-width: 540px;">
                        <mat-label>Name</mat-label>
                        <input type="text" matInput [(ngModel)]="name" [ngModelOptions]="{standalone: true}" autocomplete="off"/>
                        <mat-hint class="mat-error" *ngIf="showError && !name.length">Name is required</mat-hint>
                    </mat-form-field>

                    <mat-form-field class="mb-2 d-block" style="max-width: 540px;">
                        <mat-label>Select Organization</mat-label>
                        <mat-select [(ngModel)]="orgId" [ngModelOptions]="{standalone: true}"
                            (click)="searchFocus()">
                            <div class="dropdown-search">
                                <input #orginput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}" matInput
                                    placeholder="Search Organization">
                            </div>
                            <div>
                                <mat-option *ngFor="let organization of allOrgList | searchOrg : searchPipe"
                                    [value]="organization.orgId">
                                    {{ organization.name }}
                                </mat-option>
                            </div>
                        </mat-select>
                        <mat-error>
                            Organization is required
                        </mat-error>
                    </mat-form-field>

                    <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)"
                        [selectedIndex]="selectedTabIndex">
                        <ng-container *ngFor="let class of classes; index as idx;">
                            <mat-tab [label]="getLabel(idx)">
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                    <ng-container *ngFor="let class of classes; index as idx;">
                        <div *ngIf="selectedTabIndex == idx" class="w-100 overflow-auto">
                            <app-qos-class [data]="class" (dataChange)="setData($event, idx)" (submit)="onSubmit()">
                            </app-qos-class>
                        </div>
                    </ng-container>
                </mat-card-content>
            </fieldset>
        </form>
    </mat-card>
</div>