<!-- <app-header></app-header> -->
<nav class="navbar navbar-expand-lg navbar-light ">
    <a class="navbar-brand" href="#">
        <img src="assets/images/brand.png" class="imgg"></a>
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link  menu" href="#">
                    <span class="sr-only">(current)</span></a>
            </li>
        </ul>
    </div> -->
</nav>
<!-- <body> -->
<div id="logreg-forms">
    <h1 class="h3 mb-3 font-weight-normal sign_in"> Sign in</h1>
    <form class="example-form" [formGroup]="loginForm">
        <mat-form-field class="example-full-width">
            <input matInput placeholder="Username" formControlName="email"/>
            <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="loginForm.controls['email'].hasError('pattern')">
                Email is invalid
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input [type]="hide ? 'password' : 'text'" matInput placeholder="Password" formControlName="password"/>
            <mat-icon class="visibility-icon" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                Password is required
            </mat-error>
            <mat-error *ngIf="loginForm.controls['password'].hasError('minlength')">
                Password is invalid
            </mat-error>
        </mat-form-field>

        <div class="row">
            <div class="col-md-12">
                <button class="w-full" mat-raised-button color="primary" [disabled]="loginForm.invalid"
                    (click)="onSubmit()">Login</button>
            </div>
        </div>
        <div class="m-t-12">
            <div class="row">
                <div class="col-md-12 text-right">
                    <mat-card-actions>
                        <!-- <a [routerLink]="['/registration']" routerLinkActive="router-link-active"> Click
                            here to create one</a> -->
                        <a [routerLink]="['/forgotpassword']" routerLinkActive="router-link-active"
                            class="forgot-pwd">Forgot
                            password?</a>
                    </mat-card-actions>
                </div>
            </div>
        </div>
    </form>
</div>