import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApService } from 'src/app/shared/services/ap.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-org-devices-table',
  templateUrl: './org-devices-table.component.html',
  styleUrls: ['./org-devices-table.component.scss']
})
export class OrgDevicesTableComponent implements OnInit, OnDestroy {
  displayedOrgColumns: string[] = ['name', 'domain', 'role', 'subOrgs', 'subUsers', 'deviceCnt']
  @Input() set dataSource(data) {
    this.allOrgData = data;
    this.orgDataSource.data = this.allOrgData;
    this.length = data.length;
  };
  @Input() set deviceSource(data) {
    this.deviceArray = data;
  }
  @Input() orgId: any;
  @Input() nav: any;
  @Input() selfSort: any;
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();

  orgCatDataSource: MatTableDataSource<any>;
  displayedOrgCatColumns: string[] = ['type', 'count'];

  chart: any;
  upDeviceCnt = 0;
  downDeviceCnt = 0;
  templateDataSource: MatTableDataSource<any>;
  displayedTemplateColumns: string[] = ['t_type', 't_count', 't_device_count'];

  org: any;
  domain: any;
  uptimeToString: any;
  user: any;
  orgType = [
    "Master MSP", "MSP", "Organization"
  ]

  orgDataSource: MatTableDataSource<any>;
  allOrgData: any = [];
  deviceArray: any = [];
  deviceDataSource: MatTableDataSource<any>;

  loading = true;

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  searchTxt = "";
  allData = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public userService: UserService,
    public apService: ApService,
    public route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef
  ) {
    this.orgDataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);
  }

  ngAfterViewInit() {
    if (this.selfSort) {
      this.paginator.length = this.length;
      this.orgDataSource.paginator = this.paginator;
      this.orgDataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  getDeviceCount(org) {
    let cnt = 0;
    let orgList = this.getDomainOrg(org);
    for (let i = 0; i < this.deviceArray.length; i++) {
      for (let j = 0; j < orgList.length; j++) {
        if (this.deviceArray[i].orgId == orgList[j]._id) {
          cnt += 1;
        }
      }
    }
    return cnt;
  }

  searchData() {
    this.currentPage = 1;
    let data = this.allData.filter(subOrg => {
      if (subOrg.name.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (subOrg.domain.toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (this.orgType[subOrg.role - 1].toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
    this.length = data.length;
    this.orgDataSource.data = data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
  }

  ngOnDestroy(): void {
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  }
}
