import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ApService } from 'src/app/shared/services/ap.service';
import { MatSort, SortDirection, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { EditDeviceModelComponent } from 'src/app/pages/device/edit-device-model/edit-device-model.component';
import { UserConstant } from '../../constants/user-constant';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { UserService } from '../../services/user.service';
import { BaseLicensesTypes } from 'src/app/shared/model/template';
import { DeviceOrgModalComponent } from 'src/app/pages/device/device-org-modal/device-org-modal.component';
import { DELETE } from '@angular/cdk/keycodes';
import { SubcriberInfoModalComponent } from 'src/app/pages/device/subcriber-info-modal/subcriber-info-modal.component';

@Component({
  selector: 'app-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss']
})
export class DeviceTableComponent implements OnInit {
  displayedColumns: string[] = ['name', 'mac', 'msp', 'domain', 'location', 'model', 'firmware', 'baseLicense', 'orderdate', 'activatedAt', 'createdAt', 'lastSeen', 'status', 'Action'];
  col: any;
  dir: any;

  @Input() set dataSource(data) {
    data = data.map(v => ({...v, orderdate: v.orderdate ?? ""}))
    for (let i = 0; i < data.length; i++) {
      const arr = data[i];
      arr["status"] = ""
      if(arr.lastSeen){
        arr["status"] = this.getStatus(arr.lastSeen,arr.track)
      } else {
        arr["status"] ="off"
      }
    }
    this.deviceArray = data;
    this.domainChange();
    setInterval(() => {
      this.domainChange()
    }, 480000);
  };

  @Input() isReportView: boolean;
  @Input() pending: boolean;
  @Input() set orgId(_id) {
    this._orgId = _id ? _id.split("_").pop() : this.user.organization;
  }
  @Input() userId: any;
  @Input() selfSort: any;
  @Input() tpl: any;
  @Input() vhub: any;
  @Output() refresh = new EventEmitter<void>();
  @Output() sortChange = new EventEmitter<any>();
  @Output() connectDevice = new EventEmitter<any>();

  _dataSource: MatTableDataSource<any>;
  role: any;

  deviceArray: any = [];
  id: any;
  org: any;
  user: any;
  domain: any;
  _orgId: any;

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog,
    public userService: UserService,
    public apService: ApService,
    public route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private changeDetection: ChangeDetectorRef
  ) {
    this._dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;
    // Start: sort columns by URL parameters
    if (this.router.url.includes("column=")) {
      let url = (this.router.url.split("?page=").pop()).split("&").splice(1, 2)
      this.col = url[0].split("=").pop()
      this.dir = url[1].split("=").pop()
      if (this.dir == 'asc') {
        this.sortData({ active: `${this.col}`, direction: 'asc' })
      } else if (this.dir == 'desc') {
        this.sortData({ active: `${this.col}`, direction: 'desc' })
      }
    }
    // End: sort columns by URL parameters
  }

  ngAfterViewInit() {
    if (this.selfSort) {
      this.paginator.length = this.length;
      this._dataSource.paginator = this.paginator;
      this._dataSource.sort = this.sort;
      this.changeDetection.markForCheck();
    } else {
      this.sort.sortChange.subscribe(() => {
        this.sortChange.emit(this.sort)
      });
    }
    this.changeDetection.detectChanges();
  }

  domainChange() {
    this.userService.getOrganization({ id: this._orgId }).subscribe(res => {
      if (res && res.data && res.data[0]) {
        this.updateDomainName(res.data[0]);
      }
      this.displayResult()
    })
  }

  updateDomainName(org) {
    let orgList = this.getDomainOrg(org);
    if (!orgList) {
      return;
    }
    for (let i = 0; i < this.deviceArray.length; i++) {
      for (let j = 0; j < orgList.length; j++) {
        if (this.deviceArray[i].orgId == orgList[j]._id && orgList[j].role == 3) {
          this.deviceArray[i].domain = orgList[j].name
        } else if (this.deviceArray[i].orgId == orgList[j]._id && orgList[j].role == 2) {
          this.deviceArray[i].msp = orgList[j].name
        }
        if (orgList[j].role == 2) {
          for (let k = 0; k < orgList[j].subOrganizations.length; k++) {
            if (this.deviceArray[i].orgId == orgList[j].subOrganizations[k]._id) {
              this.deviceArray[i].msp = orgList[j].name
            }
          }
        }
      }
    }
  }

  getDomainOrg(domain) {
    if (!domain) {
      return;
    }
    let filteredOrg = [];

    filteredOrg.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  teminalclick(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "terminal").subscribe()
    const url = element.terminal_url;
    window.open(url, '_blank');
  }

  webclick(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "webui").subscribe()
    const url = element.host_url;
    window.open(url, '_blank');
  }

  delete(id) {
    const deviceArray = [];
    deviceArray.push(id);
    if (confirm('Are you sure you want to delete?')) {
      this.apService.deviceDelete(deviceArray).subscribe(res => {
        this.toastr.success('Device Deleted');
        this.refresh.emit();
      });
    }
  }

  editDevice(data): void {
    const dialogRef = this.dialog.open(EditDeviceModelComponent, {
      disableClose: true,
      width: '700px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh.emit();
    });
  }  
  lan1_status(element){
    if (element?.templateConfig?.jump?.landevice1?.enabled && element.templateConfig.jump.landevice1.enabled == 1) {
      let time1 = parseInt(element.templateConfig.jump.landevice1.applied_time) * 1000;
      let ex1 = parseInt(element.templateConfig.jump.landevice1.expire_time) * 60 * 1000;
      let date1 = new Date();
      if ((time1 + ex1 -  date1.getTime()) > 0) {
        return true;
      }
    }
    return false;
  }
  lan2_status(element){   
    if (element?.templateConfig?.jump?.landevice2?.enabled && element.templateConfig.jump.landevice2.enabled == 1) {
        let time2 = parseInt(element.templateConfig.jump.landevice2.applied_time) * 1000;
        let ex2 = parseInt(element.templateConfig.jump.landevice2.expire_time) * 60 * 1000;
        let date2 = new Date();
        if ((time2 + ex2 -  date2.getTime()) > 0) {
          return true;
        }
    }
    return false;
  }

  setParentOrganization(data): void {
    const dialogRef = this.dialog.open(DeviceOrgModalComponent, {
      disableClose: true,
      width: '500px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refresh.emit();
    });
  }

  _connectDevice(data): void {
    this.connectDevice.emit(data);
  }

  getDisplayedColumns() {
    // if(this.isReportView == undefined){
    //   delete this.displayedColumns[8]
    // }
    // return this.displayedColumns
  }
  getStatus(date, track) {
    if(track && track.length>0){      
      // if(track[0].lastDeviceState == "alert_on"){
      //   return "on"
      // }else if(track[0].lastDeviceState == "alert_off"){
      //   return "off"
      // } else {
      //   return track[0].lastDeviceState
      // } OLD TRACK STATUS
      if(track[0].lastDeviceState === 1){
        return "on"
      }else if(track[0].lastDeviceState === 2){
        return "warn"
      } else {
        return "off"
      }

      
    } else {
      const time = new Date(date);
      let cdate = new Date();
      let datedifferance = cdate.getTime() - time.getTime();
      if (datedifferance > 600e3) { // greater than 10 min
        return 'off';
      } else if(datedifferance < 600e3  && datedifferance > 300e3){
        return 'warn';
      } else {
        return 'on';
      }
    }
    
  }

  onTplToggle(checked, element) {
    this.apService.toggleTemplate(element._id, this.tpl, checked).subscribe(res => {
      if (element.templateConfig && element.templateConfig[this.tpl]) {
        element.templateConfig[this.tpl].template_enabled = checked;
      } else {
        element.templateConfig[this.tpl] = {
          template_enabled: checked
        }
      }
    })
  }

  getTplToggle(device) {
    if (device.templateConfig && device.templateConfig[this.tpl] && device.templateConfig[this.tpl].template_enabled) {
      return true
    } else {
      return false;
    }
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  }

  displayResult() {
    if (this.selfSort) {
      this._dataSource.data = this.deviceArray;
      this.length = this.deviceArray.length;
    } else {
      this._dataSource.data = this.deviceArray;
    }
  }

  getLicenseLabel(license) {
    if (license) {
      return BaseLicensesTypes.filter(l => l.value == license)[0]?.name
    }
  }

  sortData(sort: Sort) {
    if (this.selfSort) {
      const data = this.deviceArray.slice();
      if (!sort.active || sort.direction === '') {
        this._dataSource.data = data;
        return;
      }

      function compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      }

      this._dataSource.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return compare(a.name, b.name, isAsc);
          case 'mac': return compare(a.mac, b.mac, isAsc);
          case 'msp': return compare(a.msp, b.msp, isAsc);
          case 'domain': return compare(a.domain, b.domain, isAsc);
          case 'location': return compare(a.location, b.location, isAsc);
          case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
          case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
          case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
          case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
          case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
          case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
          case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
          case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
          default: return 0;
        }
      });
    }

    // Succeeding code are for the URL parameters on click on the sortable columns
    if (this.router.url.includes("page=")) {
      let url;
      if (this.router.url.includes("column")) {
        url = this.router.url.split("&column").shift()
        if (sort.direction == 'asc') {
          this.router.navigateByUrl(url + '&column=' + sort.active + '&order=asc');
        } else if (sort.direction == 'desc') {
          this.router.navigateByUrl(url + '&column=' + sort.active + '&order=desc');
        } else {
          if (this.router.url.includes("page=1")) {
            this.router.navigateByUrl("/layout/devices")
          } else {
            this.router.navigateByUrl(url);
          }
        }
      } else {
        if (sort.direction == 'asc') {
          this.router.navigateByUrl(this.router.url + '&column=' + sort.active + '&order=asc');
        } else if (sort.direction == 'desc') {
          this.router.navigateByUrl(this.router.url + '&column=' + sort.active + '&order=desc');
        } else {
          this.router.navigateByUrl(this.router.url);
        }
      }
    } else {
      if (sort.direction == 'asc') {
        this.router.navigateByUrl(this.router.url + '?page=1&column=' + sort.active + '&order=asc');
      } else if (sort.direction == 'desc') {
        this.router.navigateByUrl(this.router.url + '?page=1&column=' + sort.active + '&order=desc');
      } else {
        this.router.navigateByUrl(this.router.url);
      }
    }
  }

  redirect_lan1(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "lan").subscribe()
    window.open(element.lan1_url, '_blank');
  }

  redirect_lan2(element) {
    this.apService.createDeviceBrowseHistory(element.mac, "lan").subscribe()
    window.open(element.lan2_url, '_blank');
  }
  viewSubs(data): void {
    const dialogRef = this.dialog.open(SubcriberInfoModalComponent, {
      disableClose: true,
      width: '1000px',
      data: data
    });
  }
}
