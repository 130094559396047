import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QosRoutingModule } from './qos-routing.module';

import { QosAddComponent } from './qos-add/qos-add.component';
import { QosDevicesComponent } from './qos-devices/qos-devices.component';
import { QosComponent } from './qos.component';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { QosClassComponent } from './qos-add/qos-class/qos-class.component';

@NgModule({
  declarations: [
    QosAddComponent,
    QosDevicesComponent,
    QosComponent,
    QosClassComponent
  ],
  imports: [
    CommonModule,
    QosRoutingModule,
    SharedModule
  ]
})
export class QosModule { }
