import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NoteModalComponent } from '../../note-modal/note-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { NoteService } from 'src/app/shared/services/note.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-device-notes',
  templateUrl: './device-notes.component.html',
  styleUrls: ['./device-notes.component.scss'],
  animations: [
      trigger('detailExpand', [
        state('collapsed,void', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class DeviceNotesComponent implements OnInit {
  _dataSource: MatTableDataSource<any>;  
    _device: any = []
    deviceId = ""
    status = ""
    state = ""
    sensitivity = ""
    logSize = ""
    stateLogSize = ""
    maxFlows = ""
    flowsUse = ""
    troublePkts = ""
    stateHistory = []
    showNote = true
    displayedColumns = ["Date", "Type", "Title", "Notes", "Created By", "Action"]
    columnsToDisplayWithExpand = [ 'expand', ...this.displayedColumns,];
    col: any;
    dir: any;
    expandedElement: any = []
    
    currentPage = 1;
    page = 0
    pageSize = 10
    length = 0;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    pageEvent: PageEvent;
    
    @Input() selfSort: any;
    @Input() set device(obj) {
      if (!obj || !obj[0]) {
        return;
      }

    }
  
    @Output() refresh = new EventEmitter<void>();
    @Output() sortChange = new EventEmitter<any>();
    
    //@ViewChild(NoteModalComponent) fileTable!: NoteModalComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    constructor(
      public noteService: NoteService,
      public dialog: MatDialog,
      public route: ActivatedRoute,
      private cdr: ChangeDetectorRef,
      private toastr: ToastrService,
    ) {
      this._dataSource = new MatTableDataSource<any>();
     }
  
    ngOnInit(): void {
      this.deviceId = this.route.snapshot.paramMap.get('device_id');
      this.loadData()
    }
    handlePageEvent(event: PageEvent) {
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.loadData()
    }
    loadData(){
      const request =   {
        page: this.currentPage,
        pageSize: this.pageSize
      }
      this.noteService.getNotes(this.deviceId,request).subscribe(res=>{
        this.length = res.totalCount        
        this._dataSource.data = res.data
      })
      
    }
    sortData(sort: Sort) {
      if (this.selfSort) {
        const data = this.stateHistory.slice();
        if (!sort.active || sort.direction === '') {
          this._dataSource.data = data;
          return;
        }
  
        function compare(a: number | string, b: number | string, isAsc: boolean) {
          return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }
  
        this._dataSource.data = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'name': return compare(a.name, b.name, isAsc);
            case 'mac': return compare(a.mac, b.mac, isAsc);
            case 'msp': return compare(a.msp, b.msp, isAsc);
            case 'domain': return compare(a.domain, b.domain, isAsc);
            case 'location': return compare(a.location, b.location, isAsc);
            case 'model': return compare(a.deviceMetaInfo.deviceModel, b.deviceMetaInfo.deviceModel, isAsc);
            case 'firmware': return compare(a.statistics.firmware ? a.statistics.firmware?.currentVersion : '', b.statistics.firmware ? b.statistics.firmware.currentVersion : '', isAsc);
            case 'baseLicense': return compare(a.baseLicense, b.baseLicense, isAsc);
            case 'orderdate': return compare(a.orderdate, b.orderdate, isAsc);
            case 'activatedAt': return compare(a.activatedAt, b.activatedAt, isAsc);
            case 'createdAt': return compare(a.createdAt, b.createdAt, isAsc);
            case 'lastSeen': return compare(a.lastSeen, b.lastSeen, isAsc);
            // case 'status': return compare(this.getStatus(a.lastSeen, a.track), this.getStatus(b.lastSeen,b.track), isAsc);
            default: return 0;
          }
        });
      }
    }
  displayType(e){
    if(e){
      if(e == 1){
        return "Installation"
      }
      else if(e == 2){
        return "Troubleshooting"
      } else {
        ""
      }
    }
  }
  addModal(): void {
    const dialogRef = this.dialog.open(NoteModalComponent, {
      width: '400px',
      data: {
        deviceId: this.deviceId,
        noteData: null
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loadData(); // Refresh table after modal closes
      }
    });
    this.cdr.detectChanges();
  }
  editModal(note): void {
    const dialogRef = this.dialog.open(NoteModalComponent, {
      width: '400px',
      data: {
        deviceId: this.deviceId,
        noteData: note
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.loadData(); // Refresh table after modal closes
      }
    });
    this.cdr.detectChanges();
  }
  delete(note){
    if(note){
      this.noteService.deleteNote(note._id).subscribe((res) => {      
        this.toastr.success(' Note deleted Successfully!');
        this.loadData()   
      }, (error => {
        //this.submitted = false;
        this.toastr.error(error.error.error);
      }));
    }
  }
  deleteFile(note,fileId){
    if(note){
      this.noteService.deleteFile(note._id,fileId).subscribe((res) => {
        this.toastr.success(' File deleted Successfully!');      
        this.loadData()     
      }, (error => {
        //this.submitted = false;
        this.toastr.error(error.error.error);
      }));  
    }
  }
  download(noteId, element){
    this.noteService.getFile(noteId,element._id).subscribe(res=>{
      const link = document.createElement("a");
      link.href = URL.createObjectURL(res);
      link.download = element.filename; // Set the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }
  
}
