<div class="w-100 overflow-hidden device-table">
    <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Host Name</th>
            <td mat-cell *matCellDef="let element" class="pointer">
                <a class="table-link" [routerLink]="['/layout/org/'+orgId+'/device/'+element.name+'_'+element._id]"
                    routerLinkActive="router-link-active" *ngIf="orgId">
                    {{ element.name }}
                </a>
                <span *ngIf="!orgId">{{ element.name }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="mac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Mac Address</th>
            <td mat-cell *matCellDef="let element">{{ element.mac.toUpperCase()}}</td>
        </ng-container>

        <ng-container matColumnDef="msp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>MSP</th>
            <td mat-cell *matCellDef="let element">{{element.msp}} </td>
        </ng-container>

        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
            <td mat-cell *matCellDef="let element">{{element.domain}}</td>
        </ng-container>

        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
            <td mat-cell *matCellDef="let element">{{ element.location || element.city || element.state || element.country }}</td>
        </ng-container>

        <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
            <td mat-cell *matCellDef="let element">{{element.deviceMetaInfo.deviceModel}}</td>
        </ng-container>

        <ng-container matColumnDef="firmware">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Firmware</th>
            <td mat-cell *matCellDef="let element">{{element.statistics.firmware ?
                element.statistics.firmware.currentVersion: '' }}</td>
        </ng-container>

        <ng-container matColumnDef="baseLicense">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>License</th>
            <td mat-cell *matCellDef="let element">{{element.baseLicense ?
                getLicenseLabel(element.baseLicense): '' }}</td>
        </ng-container>
    
        <ng-container matColumnDef="orderdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isReportView">Order Date</th>
            <td mat-cell *matCellDef="let element" [hidden]="!isReportView">
                {{ element.orderdate | date: "MM/dd/yy, hh:mm:ss a" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="activatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isReportView">Activated Date</th>
            <td mat-cell *matCellDef="let element" [hidden]="!isReportView">
                {{ element.activatedAt | date: "MM/dd/yy, hh:mm:ss a" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Install Date</th>
            <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date: "MM/dd/yy, hh:mm:ss a" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="lastSeen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
            <td mat-cell *matCellDef="let element">
                {{ element.lastSeen | date: "MM/dd/yy, hh:mm:ss a" }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
                {{ element.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef class="bg-color">Action</th>
            <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                    <mat-icon class="pointer" matTooltip="Edit" role="img" aria-hidden="true"
                        (click)="editDevice(element)" *ngIf="role<=3">edit
                    </mat-icon>
                    <mat-icon class="pointer" matTooltip="Alerts" role="img" aria-hidden="true"
                        (click)="viewSubs(element)" *ngIf="role<=3">contacts
                    </mat-icon>
                    <mat-icon class="pointer" matTooltip="Organization" role="img" aria-hidden="true"
                        (click)="setParentOrganization(element)" *ngIf="role<=3 && pending">apartment
                    </mat-icon>
                    <mat-icon class="pointer" matTooltip="vhub" role="img" aria-hidden="true"
                        (click)="_connectDevice(element)" *ngIf="role==0 && vhub">vpn_lock
                    </mat-icon>
                    <mat-icon class="pointer" matTooltip="Website Jump" role="img" aria-hidden="true"
                        (click)="webclick(element)">
                        language</mat-icon>
                    <mat-icon class="pointer" matTooltip="Teminal Jump" role="img" aria-hidden="true"
                        (click)="teminalclick(element)">cast_connected</mat-icon>
                    <a class="table-link"
                        [routerLink]="['/layout/org/'+(orgId?orgId:element.orgId)+'/device/'+element._id]"
                        routerLinkActive="router-link-active" *ngIf="(orgId || element.orgId) && !pending">
                        <mat-icon class="pointer" matTooltip="Device Info" role="img" aria-hidden="true"><i
                                class="material-icons">router</i></mat-icon>
                    </a>
                    <a class="table-link" [routerLink]="['/layout/org/pending/device/'+element._id]"
                        routerLinkActive="router-link-active" *ngIf="pending">
                        <mat-icon class="pointer" matTooltip="Device Info" role="img" aria-hidden="true"><i
                                class="material-icons">router</i></mat-icon>
                    </a>
                    <mat-icon class="pointer" matTooltip="Enable Template" role="img" aria-hidden="true"
                        (click)="onTplToggle(true, element)" *ngIf="tpl && !getTplToggle(element) && role<=3">
                        sync_problem</mat-icon>
                    <mat-icon class="pointer" matTooltip="Disable Template" role="img" aria-hidden="true"
                        (click)="onTplToggle(false, element)" *ngIf="tpl && getTplToggle(element) && role<=3">
                        sync_disabled</mat-icon>
                    <mat-icon class="pointer v-align-middle" matTooltip="LAN Jump 1" role="img" aria-hidden="true"
                        (click)="redirect_lan1(element)" *ngIf="role<=3 && lan1_status(element)">
                        laptop</mat-icon>
                    <mat-icon class="pointer v-align-middle" matTooltip="LAN Jump 2" role="img" aria-hidden="true"
                    (click)="redirect_lan2(element)" *ngIf="role<=3 && lan2_status(element)">
                    laptop</mat-icon>
                    <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true"
                        (click)="delete(element._id)" *ngIf="role==0">
                        restore_from_trash</mat-icon>                   
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>
</div>
