import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qos-class',
  templateUrl: './qos-class.component.html',
  styleUrls: ['./qos-class.component.scss']
})
export class QosClassComponent implements OnInit {
  editMode = false;
  ports: Array<string> = [];
  newPort = "";
  newURL = "";
  urls: Array<string> = [];
  newIp = "";
  ips: Array<string> = [];
  ipPorts: Array<string> = [];
  newIpPort = "";
  networks: Array<string> = [];
  newNetwork = "";
  networkPorts: Array<string> = [];
  newNetworkPort = "";

  _data: any;
  regExp_pattern: any;

  @Input() set data(data) {
    this._data = JSON.parse(JSON.stringify(data));
    if (data && Object.keys(data).length != 0) {
      this.ports = data.ports;
      this.urls = data.urls;
      this.ipPorts = data.ipPorts;
      this.ips = data.ips;
      this.networkPorts = data.networkPorts;
      this.networks = data.networks;
      this.ports = data.ports;
      this.editMode = true;
    }
  };
  @Input() editable;
  @Output() submit = new EventEmitter<any>();
  @Output() dataChange = new EventEmitter<any>();

  constructor(
    private toastr: ToastrService,
  ) {
    this.regExp_pattern = {
      port: new RegExp(/^(\d+)$/), // AP Conditional pattern
      port_range: new RegExp(/^[\d\s\-]+$/), // AP Conditional pattern
      ip_address: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/),
      ip_port: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\,[\d\s]+$/),
      ip_port_range: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\,[\d\s\-]+$/),
      network: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(\d+)$/),
      network_port: new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(\d+)\,[\d\s\-]+$/)
    }
   }

  ngOnInit(): void {
  }

  addPort() {
    let pattern = this.regExp_pattern.port_range;
    if (this.newPort.length && !isNaN(parseInt(this.newPort)) && pattern.test(this.newPort)) {
      this.ports.push(this.newPort)
      this.newPort = "";
      this.onDataChange();
      return true;
    } else if (this.newPort.length) {
      this.toastr.warning('Please input correct port');
      return false;
    }
    return true;
  }

  removePort(idx) {
    this.ports.splice(idx, 1);
  }

  addURL() {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    if (this.newURL.length && !!pattern.test(this.newURL)) {
      this.urls.push(this.newURL);
      this.newURL = "";
      this.onDataChange();
      return true;
    } else if (this.newURL.length) {
      this.toastr.warning('Please input correct url');
      return false;
    }
    return true;
  }

  removeURL(idx) {
    this.urls.splice(idx, 1);
  }

  addIPAddress() {
    let pattern = this.regExp_pattern.ip_address;
    if (this.newIp.length && !!pattern.test(this.newIp)) {
      this.ips.push(this.newIp);
      this.newIp = "";
      this.onDataChange();
      return true;
    } else if (this.newIp.length) {
      this.toastr.warning('Please input correct ip address');
      return false;
    }
    return true;
  }

  removeIpAddress(idx) {
    this.ips.splice(idx, 1);
  }

  addIpPort() {
    let pattern = this.regExp_pattern.ip_port;
    if (this.newIpPort.length && !!pattern.test(this.newIpPort)) {
      this.ipPorts.push(this.newIpPort)
      this.newPort = "";
      this.onDataChange();
      return true;
    } else if (this.newIpPort.length) {
      this.toastr.warning('Please input ip,port in correct format');
      return false;
    }
    return true;
  }

  removeIpPort(idx) {
    this.ipPorts.splice(idx, 1);
  }

  addNetwork() {
    let pattern = this.regExp_pattern.network;
    if (this.newNetwork.length && !!pattern.test(this.newNetwork)) {
      this.networks.push(this.newNetwork);
      this.newNetwork = "";
      this.onDataChange();
      return true;
    } else if (this.newNetwork.length) {
      this.toastr.warning('Please input network in correct format');
      return false;
    }
    return true;
  }

  removeNetwork(idx) {
    this.networks.splice(idx, 1);
  }

  addNetworkPort() {
    let pattern = this.regExp_pattern.network_port;
    if (this.newNetworkPort.length && !!pattern.test(this.newNetworkPort)) {
      this.networkPorts.push(this.newNetworkPort);
      this.newNetworkPort = "";
      return true;
    } else if (this.newNetworkPort.length) {
      this.toastr.warning('Please input network,port in correct format');
      return false;
    }
    return true;
  }

  removeNetworkPort(idx) {
    this.networkPorts.splice(idx, 1);
  }

  onDataChange() {
    let data = {
      ports: this.ports,
      urls: this.urls,
      ips: this.ips,
      ipPorts: this.ipPorts,
      networks: this.networks,
      networkPorts: this.networkPorts,
    }
    this.dataChange.emit(data);
  }

  onSubmit() {
    if (!this.addPort() || !this.addURL() || !this.addIPAddress() || !this.addIpPort() || !this.addNetwork() || !this.addNetworkPort()) {
      return;
    }
    this.submit.emit();
  }

}
