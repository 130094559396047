import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from '../pages/profile/profile.component';
import { PendingDevicesComponent } from '../pages/pending-devices/pending-devices.component';
import { VhubDeviceComponent } from '../pages/vhub-device/vhub-device.component';

import {
  AuthGuardService as AuthGuard
} from 'src/app/shared/services/authguard.service';
import { AllSearchesComponent } from '../pages/all-searches/all-searches.component';
import { DeviceBrowseHistoryComponent } from '../shared/components/device-browse-history/device-browse-history.component';

const routes: Routes = [
  { path: '', redirectTo: 'organization', pathMatch: 'full' },
  {
    path: 'organization',
    loadChildren: () => import('../pages/organization/organization.module').then(m => m.OrganizationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'org',
    loadChildren: () => import('../pages/org-devices/org.module').then(m => m.OrgModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'devices',
    loadChildren: () => import('../pages/device/device.module').then(m => m.DeviceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../pages/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'template-profile',
    loadChildren: () => import('../pages/template/template-profile/template-profile.module').then(m => m.TemplateProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wireless-network',
    loadChildren: () => import('../pages/template/network/network.module').then(m => m.NetworkModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'qos',
    loadChildren: () => import('../pages/template/qos/qos.module').then(m => m.QosModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'i4glte',
    loadChildren: () => import('../pages/template/i4glte/i4glte.module').then(m => m.I4glteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'alert',
    loadChildren: () => import('../pages/template/alert/alert.module').then(m => m.AlertModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teams',
    loadChildren: () => import('../pages/template/team1/team1.module').then(m => m.Team1Module),
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    loadChildren: () => import('../pages/report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'prtg',
    loadChildren: () => import('../pages/prtg/prtg.module').then(m => m.PrtgModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'firmware',
    loadChildren: () => import('../pages/firmware/firmware.module').then(m => m.FirmwareModule),
    canActivate: [AuthGuard]
  },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'search', component: AllSearchesComponent, canActivate: [AuthGuard] },
  { path: 'browse-history', component: DeviceBrowseHistoryComponent, canActivate: [AuthGuard] },
  { path: 'vhub-devices', component: VhubDeviceComponent, canActivate: [AuthGuard] },
  { path: 'pendingDevices', component: PendingDevicesComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminLayoutRoutingModule { }
