<div class="w-100 overflow-hidden">
    <table mat-table [dataSource]="orgDataSource" class="w-100 d-md-table d-block overflow-auto" matSort>
        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
    
        <!-- Position Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Host Name</th>
            <td mat-cell *matCellDef="let element" class="pointer">
                <a class="table-link" [routerLink]="['/layout/report/overview/'+element.name+'_'+element._id]"
                    routerLinkActive="router-link-active" *ngIf="nav != 'billing'">
                    {{ element.name }}
                </a>
                <a class="table-link" [routerLink]="['/layout/report/billing/'+element.name+'_'+element._id]"
                    routerLinkActive="router-link-active" *ngIf="nav == 'billing'">
                    {{ element.name }}
                </a>
            </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="domain">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
            <td mat-cell *matCellDef="let element">{{ element.domain }}</td>
        </ng-container>
    
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.role==1"> Master MSP</span>
                <span *ngIf="element.role==2"> MSP</span>
                <span *ngIf="element.role==3"> Organization</span>
            </td>
    
        </ng-container>
        <ng-container matColumnDef="subOrgs">
            <th mat-header-cell *matHeaderCellDef>Sub Organizations</th>
            <td mat-cell *matCellDef="let element">{{
                element.subOrganizations?element.subOrganizations.length: '' }}</td>
        </ng-container>
    
        <ng-container matColumnDef="subUsers">
            <th mat-header-cell *matHeaderCellDef>Organization Users</th>
            <td mat-cell *matCellDef="let element">{{
                element.subUsers?element.subUsers.length: '' }}</td>
        </ng-container>
    
        <ng-container matColumnDef="deviceCnt">
            <th mat-header-cell *matHeaderCellDef>Device Count</th>
            <td mat-cell *matCellDef="let element">{{
                getDeviceCount(element) >= 0 ? getDeviceCount(element) : '' }}</td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedOrgColumns"></tr>
    </table>
    
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)"  [hidden]="!length">
    </mat-paginator>
</div>