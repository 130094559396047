import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApService } from 'src/app/shared/services/ap.service';
import { TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'app-device-monitor-qos',
  templateUrl: './device-monitor-qos.component.html',
  styleUrls: ['./device-monitor-qos.component.scss']
})
export class DeviceMonitorQosComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['config', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8'];
  classes = new Array(8).fill({});
  dataSource = []
  label = "";
  checked = false
  hideToggle = false;
  qos_keys: any = []
  _device: any;
  hideOldConfig: boolean = true;
  @Input() set device(obj) {
    if (!obj || !obj[0]) {
      return;
    }
    this._device = obj[0];
    this.loadData()
  }

  private loadDataSubscription: Subscription;

  constructor(
    public qosService: TemplateService,
    public apService: ApService
  ) {
    this.qos_keys = ['URLs List', 'Ports List', 'IP Addresses List', 'Networks List', 'IP Ports List', 'Networks/Ports List & Port-Range']
   }

  ngOnInit(): void {
  }

  loadData() {
    this.dataSource = []
    if (this._device.templateConfig && this._device.templateConfig.qos && (this._device.templateConfig.qos.template_enabled == false)) {
      if (this._device.templateConfig.qos.name) {
        this.label = this._device.templateConfig.qos.name + " (Template-Disabled)"
        let classes = this._device.templateConfig.qos.config
        if (classes[0]) {
          Object.keys(classes[0]).forEach(key => {
            if (key != "_id") {
              let data = {
                config: key,
                c1: classes[0][key],
                c2: classes[1][key],
                c3: classes[2][key],
                c4: classes[3][key],
                c5: classes[4][key],
                c6: classes[5][key],
                c7: classes[6][key],
                c8: classes[7][key]
              };
              this.arrangeDatasource(data);
            }
          })
        }
      } else if (this._device.qos) {
        this.loadDataSubscription = this.qosService.getQos(this._device.qos).subscribe(res => {
          if (res && res[0]) {
            res = res[0];
            this.label = res.name + ' (Template-Disabled)'
          }
        })
        let classes = this._device.templateConfig.qos.config
        if (classes[0]) {
          Object.keys(classes[0]).forEach(key => {
            if (key != "_id") {
              let data = {
                config: key,
                c1: classes[0][key],
                c2: classes[1][key],
                c3: classes[2][key],
                c4: classes[3][key],
                c5: classes[4][key],
                c6: classes[5][key],
                c7: classes[6][key],
                c8: classes[7][key]
              };
              this.arrangeDatasource(data);
            }
          })
        }
      } else {
        let classes = this._device.templateConfig.qos.config
        if (classes[0]) {
          Object.keys(classes[0]).forEach(key => {
            if (key != "_id") {
              let data = {
                config: key,
                c1: classes[0][key],
                c2: classes[1][key],
                c3: classes[2][key],
                c4: classes[3][key],
                c5: classes[4][key],
                c6: classes[5][key],
                c7: classes[6][key],
                c8: classes[7][key]
              };
              this.arrangeDatasource(data);
            }
          })
          this.label = this._device.templateConfig['qos'].template_name !== null ? (this._device.templateConfig['qos'].template_enabled == false ? this._device.templateConfig['qos'].template_name + " (Template-Disabled)" : this._device.templateConfig['qos'].template_name + " (Template-Enabled)") : "Device (Configuration)";
        }
        this.hideOldConfig = true;
        this.hideToggle = true;
      }
    } else if (this._device.templateConfig && this._device.templateConfig.qos && this._device.templateConfig.qos.template_enabled && this._device.qos) {
      this.checked = true;
      this.loadTpl(this._device.qos);
    } else if (this._device && this._device && this._device.qos) {
      this.loadTpl(this._device.qos);
    } else if (this._device && this._device.templateConfig && this._device.templateConfig.qos) {
      let classes = this._device.templateConfig.qos.config
      if (classes[0]) {
        Object.keys(classes[0]).forEach(key => {
          if (key != "_id") {
            let data = {
              config: key,
              c1: classes[0][key],
              c2: classes[1][key],
              c3: classes[2][key],
              c4: classes[3][key],
              c5: classes[4][key],
              c6: classes[5][key],
              c7: classes[6][key],
              c8: classes[7][key]
            };
            this.arrangeDatasource(data);
          }
        })
        this.label = this._device.templateConfig['qos'].template_name !== null ? (this._device.templateConfig['qos'].template_enabled == false ? this._device.templateConfig['qos'].template_name + " (Template-Disabled)" : this._device.templateConfig['qos'].template_name + " (Template-Enabled)") : "Device (Configuration)";
      }
      this.hideOldConfig = true;
      this.hideToggle = true;
    }
  }

  arrangeDatasource (_data) {
    if (_data.config) {
      if(_data.config == 'url' || _data.config == 'urls'){
        _data.config = this.qos_keys[0]
        this.dataSource[0] = _data;
      }
      if(_data.config == 'port' || _data.config == 'ports'){
        _data.config = this.qos_keys[1]
        this.dataSource[1] = _data;
      }
      if(_data.config == 'ip' || _data.config == 'ips'){
        _data.config = this.qos_keys[2]
        this.dataSource[2] = _data;
      }
      if(_data.config == 'net' || _data.config == 'networks'){
        _data.config = this.qos_keys[3]
        this.dataSource[3] = _data;
      }
      if(_data.config == 'ip_port' || _data.config == 'ipPorts'){
        _data.config = this.qos_keys[4]
        this.dataSource[4] = _data;
      }
      if(_data.config == 'net_port' || _data.config == 'networkPorts'){
        _data.config = this.qos_keys[5]
        this.dataSource[5] = _data;
      }
    }
  }
    
  loadTpl(qosId) {
    this.qosService.getQos(qosId).subscribe(res => {
      if (res && res[0]) {
        res = res[0];
        this.label = res.name + (this._device.templateConfig.qos && this._device.templateConfig.qos.template_enabled ? " (Template-Enabled)" : this._device.templateConfig.qos && this._device.templateConfig.qos.template_enabled == false ? " (Template-Disabled)" : " (Template)");
        this.classes = res.classes;
        if (this.classes[0]) {
          Object.keys(this.classes[0]).forEach(key => {
            if (key != "_id") {
              let data = {
                config: key,
                c1: this.classes[0][key],
                c2: this.classes[1][key],
                c3: this.classes[2][key],
                c4: this.classes[3][key],
                c5: this.classes[4][key],
                c6: this.classes[5][key],
                c7: this.classes[6][key],
                c8: this.classes[7][key]
              };
              this.arrangeDatasource(data);
            }
          })
        }
      }
    });
  }

  render(arr) {
    if (Array.isArray(arr)) {
      return arr.map(r => (`<div>${r}</div>`)).join('')
    } else {
      return arr;
    }
  }

  onTplToggle(e) {
    this.checked = e.checked
    this.apService.toggleTemplate(this._device._id, "qos", this.checked).subscribe(res => {
      if (this._device.templateConfig && this._device.templateConfig.qos) {
        this._device.templateConfig.qos.template_enabled = e.checked;
      } else {
        this._device.templateConfig.qos = {
          template_enabled: e.checked
        }
      }
      this.loadData()
    })
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
