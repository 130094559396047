<fieldset>
    <legend class="mb-1" mat-dialog-title>Apply Template</legend>
    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
        [(ngModel)]="templateTrigger">
        <mat-radio-button value="1">Immediately</mat-radio-button>
        <mat-radio-button value="0">On Restart</mat-radio-button>
    </mat-radio-group>
    <div mat-dialog-actions style="float: right;">
        <button mat-raised-button color="primary" class="btn btnn" [mat-dialog-close]="templateTrigger"
            cdkFocusInitial>Update</button>
        <button mat-raised-button color="warn" class="btnn" (click)="onCancel()">Cancel</button>
    </div>
</fieldset>
