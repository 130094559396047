<div class="mt-2">
    <div class="mb-2">
        <mat-tab-group animationDuration="0ms" (selectedTabChange)="selectedTabChange($event)" [selectedIndex]="0">
            <mat-tab label="Last 4 Hrs"></mat-tab>
            <mat-tab label="Last 24 Hrs"></mat-tab>
            <mat-tab label="Last 7 Days"></mat-tab>
            <mat-tab label="This Month"></mat-tab>
            <mat-tab label="Last Month"></mat-tab>
            <!-- <mat-tab label="Overview">
                <hr class="y-border"/>
                <app-system-information-monitor *ngIf="type==5" [device]="_device"></app-system-information-monitor>
            </mat-tab> -->
        </mat-tab-group>
        <hr class="y-border" />
        <div class="col-md-4">           
            <h6>Alerts Graph</h6>
            <hr>
            <mat-spinner *ngIf="loading" [diameter]="30" class="m-auto"></mat-spinner>
            <div style="max-width: 300px" class="chart-wrapper align-items-center justify-content-center">
                <canvas id="canvas" width="200" height="200"></canvas>
            </div>
            <div class="detail-list mt-4 ml-4 flex-fill" *ngIf="chart">
                <div class="detail-list-item m-2">
                    <div class="square online"></div>
                    <div class="ml-2">Online</div>
                    <div class="count ml-2">{{upDeviceCnt | number:'0.3-3'}} %</div>
                </div>
                <div class="detail-list-item m-2 flex-fill">
                    <div class="square offline"></div>
                    <div class="ml-2">Offline</div>
                    <div class="count ml-2">{{downDeviceCnt | number:'0.3-3'}} %</div>
                </div>
                <!-- <div class="detail-list-item m-2 flex-fill">
                    <div class="square warning"></div>
                    <div class="ml-2">Warning</div>
                    <div class="count ml-2">{{warnDeviceCnt}} %</div>
                </div>-->
            </div>
        </div>
        <form [formGroup]="deviceForm">
            <fieldset>
                <mat-form-field>
                    <mat-label>Select Timezone</mat-label>
                    <mat-select formControlName="searchTimezone" (click)="searchFocus()"
                        (selectionChange)="onSelectionTZChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchPipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Timezone">
                        </div>
                        <div>
                            <mat-option *ngFor="let tz of timezoneArray | searchSelect : searchPipe"
                                [value]="tz.tz">
                                {{ tz.name }}, {{ tz['tz-posix'] }}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field>
                    <mat-label>Select Month</mat-label>
                    <mat-select formControlName="searchMonth" (click)="searchMonthFocus()"
                        (selectionChange)="onSelectionMonthChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchMonthPipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Date">
                        </div>
                        <div>
                            <mat-option *ngFor="let month of listOfMonths | searchSelect : searchMonthPipe" [value]="month">
                               {{month}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Select Date</mat-label>
                    <mat-select formControlName="searchDate" (click)="searchDateFocus()"
                        (selectionChange)="onSelectionDateChange($event)">
                        <div class="dropdown-search">
                            <input #tzinput [(ngModel)]="searchDatePipe" [ngModelOptions]="{standalone: true}"
                                matInput placeholder="Search Date">
                        </div>
                        <div>
                            <mat-option *ngFor="let date of listOfDates | searchSelect : searchMonthPipe" [value]="date">
                               {{date}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field> -->
            </fieldset>
        </form>
    </div>
    <div class="mb-2">
        <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
            <ng-container matColumnDef="Device State">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Device State</th>
                <td mat-cell *matCellDef="let element"><strong>{{ displayDeviceState(element.lastDeviceState) }}</strong></td>
            </ng-container>
            <!-- <ng-container matColumnDef="Timezone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Timezone</th>
                <td mat-cell *matCellDef="let element">{{ displayTimezone(element.timezone) }}</td>
            </ng-container> -->
            <ng-container matColumnDef="Last Seen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
                <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.lastSeen, element.timezone) }}</td>
            </ng-container>
            <ng-container matColumnDef="Date Processed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Processed</th>
                <td mat-cell *matCellDef="let element">{{ displayFormatTimeZone(element.novuDateProcessed, element.timezone) }}</td>
            </ng-container>
            <ng-container matColumnDef="TransactionId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>TransactionId</th>
                <td mat-cell *matCellDef="let element">{{ element.novuTransactionId }}</td>
            </ng-container>
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">{{ displayStatus(element.isDeviceAlertSent) }}</td>
            </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                        <mat-icon class="pointer" matTooltip="View Details" role="img" aria-hidden="true"
                            (click)="viewNovu(element)">view_comfy</mat-icon>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>    
    </div>
</div>
