import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-monitor-ether-vlan',
  templateUrl: './device-monitor-ether-vlan.component.html',
  styleUrls: ['./device-monitor-ether-vlan.component.scss']
})
export class DeviceMonitorEtherVlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
