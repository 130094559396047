import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../model/user';
import { Observable } from 'rxjs';
import { ResponseObj } from '../model/responseObj';
import { environment } from 'src/environments/environment';
import { Login } from '../model/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  userLogin(userModel: Login): Observable<any> {
    return this.http.post(environment.endpoints.login, userModel);
  }

  autoLogin({ email, hash }): Observable<any> {
    return this.http.post(environment.endpoints.autologin, { email, hash });
  }

  logout() {
    return this.http.post(environment.endpoints.logout, null);

  }
}
