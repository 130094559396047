import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Toast, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { UserService } from 'src/app/shared/services/user.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  // tslint:disable-next-line: variable-name
  profile_form: FormGroup;
  submitted = false;
  profile: any;
  cureentuser: any;
  organization: any;
  role: any;
  user: any = [];
  token: any;
  public editMode = false;
  public btnLabel: string;
  updateProfilevalue: any;

  private loadDataSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService, private router: Router,
    private topnavbarService: TopnavbarService,
    public profileService: ProfileService,
    public userService: UserService,
    private templateService: TemplateService
  ) { }

  ngOnInit() {
    this.cureentuser = JSON.parse(localStorage.getItem(UserConstant.USER));
    // this.token = localStorage.getItem('token');
    this.profile_form = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]),
      organization: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
    });

    this.profile_form.controls.name.setValue(this.cureentuser ? this.cureentuser.name : '');
    this.profile_form.controls.email.setValue(this.cureentuser ? this.cureentuser.email : '');

    this.userService.getOrganization({ id: this.cureentuser.organization }).subscribe(res => {
      if (this.cureentuser && res.data && res.data[0] && res.data[0].name) {
        this.profile_form.controls.organization.setValue(res.data[0].name);
        this.organization = res.data[0];
        let role = '';
        switch (this.cureentuser.role) {
          case 0:
            role = "Root Admin";
            break;
          case 1:
            role = "MMSP Admin";
            break;
          case 2:
            role = "MSP Admin";
            break;
          case 3:
            role = "Org Admin";
            break;
          default:
            if (this.organization && this.organization.role) {
              if (this.organization.role == 2) {
                role = "MSP User";
              } else {
                role = "Org User";
              }
            } else {
              role = "-Error-";
            }
        }
        this.profile_form.controls.role.setValue(this.cureentuser ? role : '');
        this.setTopNavMenu();
      } else {
        this.profile_form.controls.organization.setValue("-Error-");
      }
    });



    this.profile_form.controls.name.disable();
    this.profile_form.controls.email.disable();
    this.profile_form.controls.organization.disable();
    this.profile_form.controls.role.disable();
    this.btnLabel = 'Edit profile';

    this.loadData();
    this.setTopNavMenu();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": this.organization ? "/layout/profile/" + this.organization['_id'] : "/layout/profile",
        "name": this.organization ? this.organization['name'] : "",
        "type": "link",
      },
      {
        "route": "/layout/profile",
        "name": "Profile",
        "type": "link",
      },
    ])
  }

  loadData() {

  }

  onSubmit() {
    this.submitted = true;
    if (this.editMode === false) {
      this.profile_form.controls.name.enable();
      this.profile_form.controls.email.enable();
      this.btnLabel = 'Update profile';
      this.editMode = true;
    } else {
      if (!this.profile_form.invalid) {
        this.profile = {
          name: this.profile_form.get('name').value,
          email: this.profile_form.get('email').value,
        };
        this.profileService.profile(this.profile).subscribe(res => {
          this.updateProfilevalue = res.user;
          const userobject = JSON.parse(localStorage.getItem(UserConstant.USER));
          userobject.name = this.updateProfilevalue.name;
          userobject.email = this.updateProfilevalue.email;
          localStorage.setItem(UserConstant.USER, JSON.stringify(userobject));
          this.profile_form.controls.name.disable();
          this.profile_form.controls.email.disable();
          this.toastr.success('Profile update success');
          this.ngOnInit();
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
