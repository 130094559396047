export const overlay_subnet_type = [
    {
        name: "DPC IP (Assign Unique IP for this DPC)",
        value: "dpc_ip"
    },
    {
        name: "Public DMZ IP (NAT this IP on vCE)",
        value: "dmz_ip"
    },
    {
        name: "Additional Subnet (Do not NAT)",
        value: "subnet"
    }
]