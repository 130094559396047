import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { months } from 'src/app/shared/model/months';
import { UserService } from 'src/app/shared/services/user.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { ReportService } from 'src/app/shared/services/report.service';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { FormatterHelper } from 'src/app/shared/helper/formatter-helper';


@Component({
  selector: 'app-monitoring-stream',
  templateUrl: './monitoring-stream.component.html',
  styleUrls: ['./monitoring-stream.component.scss']
})
export class MonitoringStreamComponent implements OnInit {
    months = months
    years
    selectedMonth: Number
    selectedYear: Number
    startDate: any;
    endDate: any;
    startMonth = new FormControl(moment(new Date()).subtract(2, 'months'));
    endMonth = new FormControl(moment());
    minDate = moment(new Date()).subtract(3, 'year');
    maxDate = moment(new Date()); 
    @Output() sortChange = new EventEmitter<any>();
  
    orgId: any;
    org: any;    
    orgName: string;
    searchPipe
    allOrgList
    mmspList
    mspList
    orgList
    currentRole: Number
    selectedOrgIds: []
    selectedMMsp: string
    selectedMsp: string
    selectAllOrg = false
    searchForm: FormGroup
  
    displayedReportColumns: string[] = ['mmsp', 'msp', 'org', 'name', 'mac', 'firmware', 'model', 'reportDate', 'overlay', 'glte']
    reportMonthlyDataSource: MatTableDataSource<any>
    @ViewChild(MatTableExporterDirective, { static: true }) exporter!: MatTableExporterDirective;
  constructor(private userService: UserService,
    private reportService: ReportService
  ) { 
    this.reportMonthlyDataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      month: new FormControl(),
      year: new FormControl(),
      mmsp: new FormControl(),
      msp: new FormControl(),
      linked_orgs: new FormControl()
   });
   const currentMonth = Number(new Date().getMonth())
   this.selectedMonth = this.months[currentMonth].value
   const currentYear = Number(new Date().getFullYear())
   this.years = [currentYear, currentYear - 1]
   this.selectedYear = currentYear
   this.searchForm.controls.month.setValue(this.months[currentMonth].value)
   this.searchForm.controls.year.setValue(this.years[0])
   this.loadAllOrganization()
  }
  getDomainOrgs(domain) {
    let filteredOrg = [];    
    filteredOrg.push({ orgId: domain._id, name: domain.name, domain: domain.domain, role: domain.role, parentOrg: domain.parentOrganization })
  
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrgs(org)]
    }
    return filteredOrg;
  }

  
  loadData(){
    let request = {
      month: Number(this.selectedMonth),
      year: this.selectedYear,
      orgIds: this.selectedOrgIds ?? [],
      msp: this.selectedMsp ?? ""
    }
    this.reportService.getReportMonthly(this.selectedMMsp ?? this.orgId, request).subscribe(response=> {
       const data = response.data.map(x=>{
        return {
          mmsp: x.mmsp && x.mmsp!="" ?  x.mmsp : this.org[0]?.name,
          msp: x.msp,
          org: x.org,
          name: x.name ?? "",
          mac: x.mac,
          firmware: x.firmware,
          model: x.model,
          activatedAt: x.activatedAt,
          overlay: FormatterHelper.formatBytes(x.report[0]?.wg1 ?? 0),
          glte: FormatterHelper.formatBytes(x.report[0]?.glte ?? 0, 1000),
          reportDate: this.months[x.report.length > 0 && x.report[0]?.reportDate ? Number(new Date(x.report[0].reportDate).getMonth()) : Number(this.selectedMonth) - 1].name + " " + new Date(x.report.length > 0 && x.report[0]?.reportDate ? x.report[0]?.reportDate : this.selectedYear).getFullYear()
        }
      })
      this.reportMonthlyDataSource.data = data
    })
    // console.log(request)
  }

  loadAllOrganization() {
      let user = JSON.parse(localStorage.getItem(UserConstant.USER));
      this.orgId = user.organization;
      this.orgName = user.organizationName;
      let obj = {
        id: this.orgId
      }
      this.currentRole = user.role 
      if(user.role!= 1 && user.role!=0){
        this.searchForm.controls.msp.setValue(this.orgId)
      }
      this.userService.getOrganizationUsers(obj).subscribe(results => {
            this.allOrgList = this.getDomainOrgs(results.data[0]);
            if(user.role == 0){
              this.mmspList = this.allOrgList.filter(x=> x.role == 1) 
            } else {
              this.mspList = this.allOrgList.filter(x=> x.role == 2) 
            }
            if (this.searchForm.controls.msp.value != "All" && this.searchForm.controls.msp.value != "" && user.role!= 1 && user.role!=0) {
              this.orgList = [ {name: "All", orgId: "All"} ,...results?.data[0].subOrganizations.filter(x=> x.parentOrganization == this.orgId)]
            }
          })   
  }
  searchFocus(){

  }

  onSelectMonth(e){
    this.selectedMonth = e.value
  }

  onSelectYear(e){
    this.selectedYear = e.value
  }

  onSelectMMSP(e){
    this.mspList = []
    this.selectedMMsp = e.value
    this.mspList = [ {name: "All", orgId: "All"}, ...this.allOrgList.filter(x=> x.parentOrg == e.value && x.role == 2)]
  }

  onSelectMSP(e){
    this.orgList = []
    this.selectedMsp = e.value
    this.orgList = [ {name: "All", orgId: "All"} ,...this.allOrgList.filter(x=> x.parentOrg == e.value && x.role == 3)]
  }

  onSelectOrgs(e){
    this.selectedOrgIds = e.value
    if(e.value == "All"){
      this.selectAllOrg = true
    } else {
      this.selectAllOrg = false
    }
   }

   toggleSelection(e){
    if(this.selectAllOrg){
      this.searchForm.controls.linked_orgs.setValue(["All",...this.allOrgList.filter(x=> x.parentOrg == this.selectedMsp && x.role == 3).map(y=> y.orgId)]); // Select all      
    } else {
      if(this.selectedOrgIds.filter(x=> x=='All').length > 0){
        this.searchForm.controls.linked_orgs.setValue([]); // Unselect all
      }
      
    }      
   }
   exportToExcel() {
    this.exporter.exportTable('xlsx', { fileName: 'exported_table' });
  }
}
