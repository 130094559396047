import { Component, OnInit, Inject, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ApService } from 'src/app/shared/services/ap.service';
import { TemplateService } from 'src/app/shared/services/template.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { AddOnLicensesTypes, BaseLicensesTypes } from 'src/app/shared/model/template';
import { MatHorizontalStepper, MatStepper } from '@angular/material/stepper';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Subscription } from 'rxjs';
import { Country, State, City }  from 'country-state-city';

@Component({
  selector: 'app-ap-model',
  templateUrl: './ap-model.component.html',
  styleUrls: ['./ap-model.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ApModelComponent implements OnInit, OnDestroy {
  deviceForm: FormGroup;

  submitted = false;
  getdeviceArray = [];
  device = [];
  filteredOrg = [];
  domain: any;
  monitors = [];
  wnetworks = [];
  qosList = [];
  teamList = [];
  searchMacPipe: any;
  searchOrgPipe: any;
  i4glteList = [];
  tprofileList = [];

  configureType = 0;
  licenseType = 0;
  role: any;
  user: any;
  id: any;
  orgId: any;
  deviceList = [];

  tabIndex = 0;
  licenseList = [];
  baseLicense = new FormControl('', [Validators.required]);
  addOnLicense = new FormControl('', []);

  baseLicenseArray: any = BaseLicensesTypes;
  addOnLicenseArray: any = AddOnLicensesTypes;

  bTemplate = false;

  @ViewChild('orginput') searchOrgElement: ElementRef;
  @ViewChild('matInput') searchMacElement: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  selectedIndex = 0;

  // Alert Section
  alert = {
    device: false,
    team: false,
    link1: false,
    link2: false,
    link3: false,
    emails: []
  }
  // Recipient Email
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emails = [];
  selectedAddOn: any = [];

  // Country State, City dropdown
  countries = Country.getAllCountries();
  selectedCountries = this.countries;
  states = State.getStatesOfCountry('US');
  selectedStates = this.states;
  cities = [];
  selectedCities = this.cities;

  private loadDataSubscription: Subscription;

  constructor(private formBuilder: FormBuilder, public apservices: ApService, private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<ApModelComponent>,
    private templateService: TemplateService,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    if (this.data) {
      this.domain = this.data.domainObj;
      this.filteredOrg = this.getDomainOrg(this.domain);
    }

    this.deviceForm = this.formBuilder.group({
      macid: new FormControl('', [Validators.required]),
      org: new FormControl(null, [Validators.required]),
      name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9_@./#&+-]+')]),
      country: new FormControl('United States', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      baseLicense: this.baseLicense,
      addOnLicense: this.addOnLicense,
      alertId: new FormControl(null, []),
      wnetwork: new FormControl(null, []),
      qos: new FormControl(null, []),
      i4glte: new FormControl(null, []),
      team: new FormControl(null, []),
      tprofile: new FormControl(null, []),
      trigger: new FormControl(0, []),
      activated: new FormControl(false, [])
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.orgId = this.id ? this.id.split("_").pop() : this.user.organization;

    this.loadData();
  }

  loadData() {
    let data = {
      params: {
        orgId: this.orgId,
      }
    };
    this.loadDataSubscription = this.apservices.getPendingDevice(data).subscribe(res => {
      this.deviceList = res.data;
      this.deviceList.filter(d => d.orgId == this.orgId).map(d => d.mac)
    })
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    if(domain.role == 3){
      filteredOrg.push({ _id: domain._id, name: domain.name, domain: domain.domain, role: domain.role })
    }
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  onNgModelChange($event) {
    this.selectedAddOn = $event;
  }

  onStepChange(event) {
    if (event) {
      this.selectedIndex = event.selectedIndex;
    }
  }

  onNext() {
    this.stepper.selectedIndex += 1;
  }

  onSubmit() {
    if (this.submitted) return;
    // stop here if form is invalid
    if (this.deviceForm.invalid || !this.deviceForm.valid) {
      if (((this.deviceForm.get('name').value == '' && this.deviceForm.get('macid').value == '') && this.deviceForm.get('baseLicense').value == '') || ((this.deviceForm.get('name').value == '' && this.deviceForm.get('macid').value == '') && this.deviceForm.get('baseLicense').value != '')) {
        this.stepper.selectedIndex = 0;
      } else if ((this.deviceForm.get('name').value == '' && this.deviceForm.get('macid').value != '') && this.deviceForm.get('baseLicense').value == '') {
        this.stepper.selectedIndex = 1;
      } else if (this.deviceForm.get('country').value == '' || this.deviceForm.get('state').value == '' || this.deviceForm.get('city').value == '') {
        this.stepper.selectedIndex = 0;
      };
      return this.stepper.selectedIndex;
    } else {
      const postObject = {
        name: this.deviceForm.get('name').value,
        mac: [this.deviceForm.get('macid').value],
        orgId: this.deviceForm.get('org').value,
        country: this.deviceForm.get('country').value,
        state: this.deviceForm.get('state').value,
        city: this.deviceForm.get('city').value,
        activated: this.deviceForm.get('activated').value,
        baseLicense: this.baseLicense.value,
        addOnLicense: this.selectedAddOn,
        templateTrigger: this.deviceForm.get('trigger').value, // This is to set Device Template Upgrade Time
      };
      if (this.deviceForm.get('tprofile').value && (this.configureType == 1)) {
        postObject['tprofile'] = this.deviceForm.get("tprofile").value;
        let tp = this.tprofileList.find(v => v._id == this.deviceForm.get("tprofile").value);
        postObject['wnetwork'] = tp.wnetwork;
        postObject['qos'] = tp.qos;
        postObject['team'] = tp.team;
        postObject['alertId'] = tp.alertId;
        postObject['4glte'] = tp['4glte'];
      } else if (this.configureType == 2) {
        postObject['tprofile'] = null;
        postObject['wnetwork'] = this.deviceForm.get('wnetwork').value;
        postObject['qos'] = this.deviceForm.get('qos').value;
        postObject['team'] = this.deviceForm.get('team').value;
        postObject['alertId'] = this.deviceForm.get('alertId').value;
        postObject['4glte'] = this.deviceForm.get('i4glte').value;
      }
      if (this.data) {
        let tmpObj = this.filteredOrg.filter((obj) => { return obj['_id'] == this.deviceForm.get('org').value })[0];
        postObject['domain'] = tmpObj['domain'];
      }
      postObject['alert'] = this.alert;
      this.submitted = true;
      this.apservices.posdevicedata(postObject).subscribe(res => {
        this.submitted = false;
        this.toastr.success('Device registered Successfully!');
        this.dialogRef.close();
      }, (error => {
        this.submitted = false;
        this.toastr.error(error.error.error);
      }));

    }
  }

  searchOrgFocus() {
    setTimeout(() => {
      this.searchOrgElement.nativeElement.focus();
    }, 50);
  }

  searchMacFocus() {
    setTimeout(() => {
      this.searchMacElement.nativeElement.focus();
    }, 50);
  }

  onDomainChange(e) {
    let org = this.filteredOrg.find(o => o['_id'] == e.value)

    this.templateService.getAlertForOrganization(org._id).subscribe(res => {
      this.monitors = res;
      this.deviceForm.controls.alertId.setValue(null);
    })
    this.templateService.getWnetworkforOrg(org._id).subscribe(res => {
      this.wnetworks = res;
      this.deviceForm.controls.wnetwork.setValue(null)
    })
    this.templateService.getQosForOrg(org._id).subscribe(res => {
      this.qosList = res;
      this.deviceForm.controls.qos.setValue(null);
    })
    this.templateService.getI4glteForOrg(org._id).subscribe(res => {
      this.i4glteList = res;
      this.deviceForm.controls.i4glte.setValue(null);
    })
    this.templateService.getTeamForOrg(org._id).subscribe(res => {
      this.teamList = res;
      this.deviceForm.controls.team.setValue(null);
    })
    this.templateService.getTprofileForOrg(org._id).subscribe(res => {
      this.tprofileList = res;
      this.deviceForm.controls.tprofile.setValue(null);
    })
  }

  selectConfigureType(e, type) {
    if (e.checked) {
      this.configureType = type;
      if (type == 1) {
        this.deviceForm.controls.alertId.setValue(null);
        this.deviceForm.controls.wnetwork.setValue(null)
        this.deviceForm.controls.qos.setValue(null);
        this.deviceForm.controls.i4glte.setValue(null);
        this.deviceForm.controls.team.setValue(null);
      } else if (type == 2) {
        this.deviceForm.controls.tprofile.setValue(null);
      }
    } else {
      this.configureType = 0;
    }
  }

  selectAlert(event, type) {
    this.alert[type] = event.checked;
  }

  addRecipient(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.emails.push(value);
      this.alert.emails = this.emails;
    }
    // Clear the input value
    event.input.value = "";
  }

  removeRecipient(email): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.alert.emails = this.emails;
    }
  }

  onCountryChange(event) {
    let country = this.countries.filter(v => v.name == event.value)[0];
    this.states = State.getStatesOfCountry(country?.isoCode)
    this.selectedStates = this.states;
  }

  onStateChange(event) {
    let state = this.states.filter(v => v.name == event.value)[0];
    this.cities = City.getCitiesOfState(state?.countryCode, state?.isoCode)
    this.selectedCities = this.cities;
  }

  onCountrySearch(value: string) {
    this.selectedCountries = this.countries.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  onStateSearch(value: string) {
    this.selectedStates = this.states.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  onCitySearch(value: string) {
    this.selectedCities = this.cities.filter(c => c.name.toLowerCase().startsWith(value.toLowerCase()))
  }

  ngOnDestroy(): void {
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }
}
