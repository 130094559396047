import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MustMatch } from 'src/app/shared/code/password-confirm';

@Component({
  selector: 'app-user-model',
  templateUrl: './user-model.component.html',
  styleUrls: ['./user-model.component.scss']
})
export class UserModelComponent implements OnInit {
  userForm: FormGroup;
  submitted = false;
  getdeviceArray = [];
  device = [];
  userDialogData: any;
  userId: any;
  orgId: any;
  role: any;
  roles = [];
  domain: any;
  filteredOrg = [];
  hide1: boolean = true;
  hide2: boolean = true;

  selected: any;
  searchOrg: any;
  searchOrgPipe: any;
  @ViewChild('orginput') searchElement: ElementRef;

  constructor(private formBuilder: FormBuilder, public userService: UserService, private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<UserModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.orgId = this.data.orgId;
    this.selected = this.orgId;
    this.role = this.data.role;
    this.domain = this.data.domain;
    this.filteredOrg = this.getDomainOrg(this.domain);
    if (this.data && this.data._id) {
      this.userDialogData = this.data;
      this.userId = this.userDialogData._id;
    }
    this.userForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]+$')]),
      confirmpassword: new FormControl(''),
      parent: new FormControl(null, [Validators.required]),
      role: new FormControl('', [Validators.required])
    }, {
      validator: MustMatch('password', 'confirmpassword')
    });

    if (this.userId) {
      this.userForm.controls.name.setValue(this.userDialogData.name);
      this.userForm.controls.email.setValue(this.userDialogData.email);
      this.userForm.controls.role.setValue(this.userDialogData.role);

    }
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ orgId: domain._id, name: domain.name, role: domain.role })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  onSubmit() {
    if (this.submitted) return;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    } else {
      let email = this.userForm.get('email').value;
      let password = this.userForm.get('password').value;
      let name = this.userForm.get('name').value;
      let orgId = this.userForm.get('parent').value;
      let _org = this.filteredOrg.filter(function (obj) { return obj['orgId'] == orgId })
      let role = this.userForm.get('role').value;
      const postObject = {
        email: email,
        password: password,
        name: name,
        role: (role && _org[0]) ? _org[0].role : 5, // 5 is User
        id: this.userId,
        orgId: orgId
      };
      this.submitted = true;
      if (this.userId) {
        this.userService.createUser(postObject).subscribe(res => {
          this.submitted = false;
          this.toastr.success('User Updated Successfully!');
          this.dialogRef.close();
        }, (error => {
          this.submitted = false;
          this.toastr.error(error.error.error);
        }));
      } else {
        this.submitted = true;
        this.userService.createUser(postObject).subscribe(res => {
          this.submitted = false;
          this.toastr.success('User Created Successfully!');
          this.dialogRef.close();
        }, (error => {
          this.submitted = false;
          this.toastr.error(error.error.error);
        }));
      }

    }
  }

  searchFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 50);
  }

  onOrgChange(event) {
  }
}
