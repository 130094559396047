<div class="w-100 overflow-hidden">
    <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort matSortActive="organization"
        matSortDirection="asc" (matSortChange)="sortData($event)">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element" class="pointer">{{ element.name }}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <!-- Org Column -->
        <ng-container matColumnDef="organization">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
            <td mat-cell *matCellDef="let element">
                <a class="table-link" [routerLink]="['/layout/organization/'+element.orgName+'_'+element.organization]"
                    routerLinkActive="router-link-active">
                    {{ element.orgName }}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="orgType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Type</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.orgType==1"> Master MSP</span>
                <span *ngIf="element.orgType==2"> MSP</span>
                <span *ngIf="element.orgType==3"> Organization</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.role==5"> User</span>
                <span *ngIf="element.role!=5"> Admin</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                Active
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="bg-color">Action</th>
            <td mat-cell *matCellDef="let element">
                <div class="d-flex">
                    <mat-icon class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                        matTooltip="Edit" role="img" aria-hidden="true" (click)="edit(element)" *ngIf="role==0">edit
                    </mat-icon>
                    <mat-icon class="menu-icon mat-icon notranslate material-icons mat-icon-no-color icon-btn pointer"
                        matTooltip="Shadow User" role="img" aria-hidden="true" (click)="shadowUser(element)" *ngIf="role==0">follow_the_signs
                    </mat-icon>
                    <a class="table-link"
                    [routerLink]="['/layout/org/'+element.orgName+'_'+element.organization+'/devices']"
                    routerLinkActive="router-link-active">
                    <mat-icon class="pointer" matTooltip="View Devices" role="img" aria-hidden="true">device_hub
                    </mat-icon>
                </a>
                <!-- <a class="table-link" [routerLink]="['/layout/org/'+element.orgName+'_'+element.organization+'/wireless-network']"
                        routerLinkActive="router-link-active">
                        <mat-icon class="pointer" matTooltip="Wirless Network" role="img" aria-hidden="true">
                            network_check
                        </mat-icon>
                    </a> -->
                <mat-icon class="pointer" matTooltip="Delete" role="img" aria-hidden="true"
                    (click)="delete(element._id)" *ngIf="role<=3">restore_from_trash</mat-icon>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- PAGINATION -->
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex" (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>
</div>