import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { TopnavbarService } from 'src/app/shared/services/topnavbar.service';
import { UserConstant } from 'src/app/shared/constants/user-constant';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatSort, SortDirection } from '@angular/material/sort';
import { TemplateService } from 'src/app/shared/services/template.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prtg',
  templateUrl: './prtg.component.html',
  styleUrls: ['./prtg.component.scss']
})
export class PrtgComponent implements OnInit, OnDestroy {
  pageAvailable: boolean = true;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'orgId', 'action'];

  length = 0;
  pageSize = 10;
  currentPage = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent: PageEvent;
  searchTxt = ""
  allData = [];
  loaded: boolean = false;
  orgs = [];
  role: any;
  user: any;
  @ViewChild(MatSort) sort: MatSort;

  private loadDataSubscription: Subscription;

  constructor(
    private topnavbarService: TopnavbarService,
    public templateService: TemplateService,
    public userService: UserService,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    storedUser = JSON.parse(storedUser);
    this.role = storedUser['role'];
    this.user = storedUser;

    this.loadData();
  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    this.topnavbarService.set([
      {
        "route": "/layout/organization",
        "name": "Root",
        "type": "link",
      },
      {
        "route": "/layout/prtg",
        "name": "Prtg",
        "type": "text",
      },
    ])
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.loadDataSubscription = this.templateService.getOrganizationOfPrtg(this.user.organization).subscribe(res => {
      this.dataSource.data = res.map(prtg => ({
        _id: prtg['_id'],
        name: prtg['name'],
        orgId: prtg['orgId']
      }))
      this.allData = res;
      this.loaded = true;
      this.setTopNavMenu();
    })

    this.userService.getOrganization({ id: this.user.organization }).subscribe(res => {
      res = res.data[0];
      if (res) {
        let domain = res;
        this.orgs = this.getDomainOrg(domain);
      }
    });
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push({ id: domain._id, domainOwnerId: domain.owner, name: domain.name, domain: domain.domain, prtg: domain.prtg, prtgDeviceId: domain.prtgDeviceId, prtgOrgId: domain.prtgOrgId })
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  findOrgName(orgId) {
    for (let i = 0; i < this.orgs.length; i++) {
      let organization = this.orgs[i];
      if (organization['id'] == orgId) {
        return organization['name']
      }
    }
  }

  searchData() {
    this.dataSource.data = this.allData.filter(element => {
      if (element['name'].toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      if (this.findOrgName(element.orgId).toLowerCase().indexOf(this.searchTxt.toLowerCase()) != -1) {
        return true
      }
      return false;
    })
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.loadData();
  }

  delete(element) {
    if (confirm('Are you sure you want to delete?')) {
      const prtg = [];
      prtg.push(element._id);
      this.templateService.removePrtg(prtg).subscribe(res => {
        if (res.subDevices && res.subDevices.length > 0) {
          this.toastr.error('PRTG contains active devices. Please remove them first.');
          return;
        } else {
          this.toastr.success('PRTG is successfully deleted');
        }
        this.loadData();
      })
    }
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
  }

}
