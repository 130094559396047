import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Chart} from 'chart.js';
import {UserService} from 'src/app/shared/services/user.service';
import {UserConstant} from 'src/app/shared/constants/user-constant';
import {ApService} from 'src/app/shared/services/ap.service';
import {TemplateService} from 'src/app/shared/services/template.service';
import {Observable, combineLatest, Subscription} from 'rxjs';
import {TopnavbarService} from 'src/app/shared/services/topnavbar.service';
import {ActivatedRoute, NavigationEnd, Router, Event} from '@angular/router';

@Component({
  selector: 'app-device-report',
  templateUrl: './device-report.component.html',
  styleUrls: ['./device-report.component.scss']
})
export class DeviceReportComponent implements OnInit, OnDestroy {
  private pageAvailable: boolean = true;
  orgCatDataSource: MatTableDataSource<any>;
  displayedOrgCatColumns: string[] = ['type', 'count'];

  chart: any;
  upDeviceCnt = 0;
  downDeviceCnt = 0;
  warnDeviceCnt = 0;
  pendingDeviceCnt = 0;
  templateDataSource: MatTableDataSource<any>;
  displayedTemplateColumns: string[] = ['t_type', 't_count', 't_device_count'];

  qosArray = [];
  prtgArray = [];
  wirelessArray = [];
  i4glteArray = [];
  teamsArray = [];

  parentId: any;
  hasParentOrg: any;
  arrReportUrl: any[];

  orgId: any;
  org: any;
  user: any;
  orgType = [
    "Master MSP", "MSP", "Organization"
  ]
  arrOverviewUrl: any[];

  orgDataSource: any[] = [];
  displayedOrgColumns: string[] = ['name', 'domain', 'role', 'subOrgs', 'subUsers', 'deviceCnt']
  allOrgData: any = null;
  deviceArray: any = [];
  jumpDataSource: any;
  length: any;

  loading = true;

  private loadDataSubscription: Subscription;
  private routerSubscription: Subscription;
  l_single_link: any;
  l_single_link_lte: any;
  l_failover: any;
  l_failover_lte: any;
  l_nfr: any;
  l_spare: any;
  l_overlay: any;
  l_cloud_dmz: any;
  l_wan_connect: any;
  l_1gb_lte_data: any;
  chartLicense: any;
  parentOrg: any;

  constructor(
    public userService: UserService,
    public apService: ApService,
    private templateService: TemplateService,
    private topnavbarService: TopnavbarService,
    private ref: ChangeDetectorRef,
    private router: Router,
    public route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef
  ) {
    this.orgCatDataSource = new MatTableDataSource<any>();
    this.templateDataSource = new MatTableDataSource<any>();

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let id = this.route.snapshot.paramMap.get('id');

        if (id && id != this.orgId) {
          this.orgId = id.split("_").pop();

          //topnav route
          if (this.router.url.includes('/layout/report')) {
            if (this.router.url === '/layout/report/overview' || this.router.url === '/layout/report/billing') {
              this.parentId = this.user.organization;
            } else {
              let clickId;
              if (this.router.url.includes("_")) {
                clickId = this.router.url.split("_").pop();
              } else {
                clickId = this.router.url.split("/").pop();
              }
              this.parentId = clickId;
              this.loadData();
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
    let storedUser = localStorage.getItem(UserConstant.USER);
    this.user = JSON.parse(storedUser);

    let id = this.route.snapshot.paramMap.get('id');
    this.orgId = id ? id.split("_").pop() : this.user.organization;

    //topnav route
    if (this.router.url.includes('/layout/report')) {
      if (this.router.url === '/layout/report/overview' || this.router.url === '/layout/report/billing') {
        this.parentId = this.user.organization
      } else {
        let clickId;
        if (this.router.url.includes("_")) {
          clickId = this.router.url.split("_").pop();
        } else {
          clickId = this.router.url.split("/").pop();
        }
        this.parentId = clickId;
      }
    }

    this.loadData();
  }

  drawDeviceChart() {
    this.upDeviceCnt = this.deviceArray.filter(d => {
      return d.status == 'on'
    }).length
    this.warnDeviceCnt = this.deviceArray.filter(d => {
      return d.status == 'warn'
    }).length
    this.downDeviceCnt = this.deviceArray.filter(d => {
      return d.status == 'off'
    }).length
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: ['Online', 'Offline', 'Warning', 'Pending'],
        datasets: [
          {
            data: [this.upDeviceCnt, this.downDeviceCnt, this.warnDeviceCnt, this.pendingDeviceCnt],
            backgroundColor: ['#34a854', 'rgb(255, 205, 86)', '#cf853e', '#b0b0b0'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chart.update();
    }, 500)

  }

  getStatus(date, track) {
    if(track && track.length>0){      
      // if(track[0].lastDeviceState == "alert_on"){
      //   return "on"
      // }else if(track[0].lastDeviceState == "alert_off"){
      //   return "off"
      // } else {
      //   return track[0].lastDeviceState
      // } OLD TRACK STATUS
      if(track[0].lastDeviceState === 1){
        return "on"
      }else if(track[0].lastDeviceState === 2){
        return "warn"
      } else {
        return "off"
      }
    } else {
      const time = new Date(date);
      let cdate = new Date();
      let datedifferance = cdate.getTime() - time.getTime();
      if (datedifferance > 600e3) { // greater than 10 min
        return 'off';
      } else if(datedifferance < 600e3  && datedifferance > 300e3){
        return 'warn';
      } else {
        return 'on';
      }
    }
  }

  drawLicenseChart() {
    this.l_single_link = this.deviceArray.filter(d => {
      return d.baseLicense == "1l"
    }).length
    this.l_single_link_lte = this.deviceArray.filter(d => {
      return d.baseLicense == "1l_lte"
    }).length
    this.l_failover = this.deviceArray.filter(d => {
      return d.baseLicense == "2l"
    }).length
    this.l_failover_lte = this.deviceArray.filter(d => {
      return d.baseLicense == "2l_lte"
    }).length
    this.l_nfr = this.deviceArray.filter(d => {
      return d.baseLicense == "nfr"
    }).length
    this.l_spare = this.deviceArray.filter(d => {
      return d.baseLicense == "spare"
    }).length
    this.l_overlay = this.deviceArray.filter(d => {
      return d.addOnLicense.includes("overlay")
    }).length
    this.l_cloud_dmz = this.deviceArray.filter(d => {
      return d.addOnLicense.includes("cloud_dmz")
    }).length
    this.l_wan_connect = this.deviceArray.filter(d => {
      return d.addOnLicense.includes("wan_connect")
    }).length
    this.l_1gb_lte_data = this.deviceArray.filter(d => {
      return d.addOnLicense.includes("lte_1g")
    }).length
    this.chartLicense = new Chart('license', {
      type: 'doughnut',
      data: {
        labels: ['Single Link',
          'Single Link LTE',
          'Failover',
          'Failover LTE',
          'NFR',
          'Spare',
          'Add-on: Overlay',
          'Add-on: Cloud DMZ',
          'Add-on: WAN Connect',
          'Add-on: 1GB LTE Data'],
        datasets: [
          {
            data: [this.l_single_link,
              this.l_single_link_lte,
              this.l_failover,
              this.l_failover_lte,
              this.l_nfr,
              this.l_spare,
              this.l_overlay,
              this.l_cloud_dmz,
              this.l_wan_connect,
              this.l_1gb_lte_data],
            backgroundColor: ['#fff100',
              '#ff8c00',
              '#e81123',
              '#ec008c',
              '#68217a',
              '#00188f',
              '#00bcf2',
              '#00b294',
              '#009e49',
              '#bad80a'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    });

    setTimeout(() => {
      this.ref.detectChanges();
      this.chartLicense.update();
    }, 500)

  }

  setTopNavMenu() {
    if (!this.pageAvailable) return;
    //Set Report links
    if (this.router.url.includes('/layout/report')) {
      //Detect if page is in Billing or overview
      let page = this.router.url.includes('billing') ? "/layout/report/billing/" : "/layout/report/overview/";

      //Root link
      this.arrReportUrl = [
        {
          "route": page,
          "name": "Root",
          "type": "link",
        }];

      //Detect if org has sub-orgs or not
      if (this.hasParentOrg && this.hasParentOrg.length) {
        for (var i = 0; i < this.hasParentOrg.length; i++) {
          let tempData = {};

          //detect if org has role of 0 or not
          if (i === 0) {
            tempData = {
              "route": page,
              "name": this.hasParentOrg[i] ? this.hasParentOrg[i]['name'] : "",
              "type": "link"
            }
          } else {
            tempData = {
              "route": this.hasParentOrg[i] ? page + this.hasParentOrg[i]['name'] + '_' + this.hasParentOrg[i]['_id'] : page,
              "name": this.hasParentOrg[i] ? this.hasParentOrg[i]['name'] : "",
              "type": "link"
            }
          }

          this.arrReportUrl.push(tempData);
        }
      } else {
        //parent org
        this.arrReportUrl.push(
          {
            "route": this.org ? page + this.org['name'] + '_' + this.org['_id'] : page,
            "name": this.org ? this.org['name'] : "",
            "type": "link",
          }
        );
      }
      //Billing or Overview dropdown
      this.arrReportUrl.push({
        "route": "",
        "name": this.router.url.includes('billing') ? "Billing" : "Overview",
        "type": "dropdown",
        "sub": [
          {
            "route": this.getOverviewLink(),
            "name": "Overview",
            "type": "link",
          },
          {
            "route": this.getBillingLink(),
            "name": "Billing",
            "type": "link",
          }
        ]
      });
      this.topnavbarService.set(this.arrReportUrl)
    } else {
      //Set other links
      this.topnavbarService.set([
        {
          "route": "/layout/organization",
          "name": "Root",
          "type": "link",
        },
        {
          "route": "",
          "name": this.org ? this.org['name'] : "",
          "type": "text"
        }
      ])
    }
  }

  getOverviewLink() {
    if (this.orgId) {
      if (this.org.parentOrganization && typeof this.org.parentOrganization !== "undefined") {
        let org = this.orgId ? this.org['name'] + '_' + this.orgId : this.user.organization;
        return '/layout/report/overview/' + org;
      } else {
        return '/layout/report/overview/';
      }
    } else {
      return '/layout/report/overview/'
    }
  }

  getBillingLink() {
    if (this.orgId) {
      if (this.org.parentOrganization && typeof this.org.parentOrganization !== "undefined") {
        let org = this.orgId ? this.org['name'] + '_' + this.orgId : this.user.organization;
        return '/layout/report/billing/' + org;
      } else {
        return '/layout/report/billing/';
      }
    } else {
      return '/layout/report/billing/';
    }
  }

  loadData() {
    let obj = {};
    obj['id'] = this.orgId
    let data = {
      params: {
        id: this.orgId,
      }
    };

    this.loadDataSubscription = combineLatest([
      this.userService.getOrganization(obj),
      this.userService.parentOrganizationList(this.parentId),
      this.apService.getListing(data),
      this.templateService.getQosofOrg(this.orgId),
      this.templateService.getOrganizationOfPrtg(this.orgId),
      this.templateService.getWnetworkofOrg(this.orgId),
      this.templateService.getI4glteofOrg(this.orgId),
      this.templateService.getTeamofOrg(this.orgId),
      this.apService.getPendingDevice({params: {orgId: this.orgId}}),
      this.apService.getDeviceBrowseHistory(data)
    ]).subscribe(results => {
      this.loading = false;
      let res = results[0].data[0];
      if (res) {
        this.allOrgData = res['subOrganizations']
        this.orgDataSource = this.allOrgData;
        this.org = res;

        this.getAllOrgCatInfo();
      }

      res = results[1];
      if (res) {
        res = res.sort(function (a, b) {
          return a.role - b.role;
        });
        this.hasParentOrg = res;
        this.parentOrg = this.hasParentOrg.filter(org => org._id == this.orgId)
      }

      res = results[2];
      if (res) {
        this.deviceArray = res.data;
        this.deviceArray.forEach(element => {
          element.upTime = this.uptimeToStr(element.statistics ? element.statistics.upTime : element.upTime);
          element.status = this.getStatus(element.lastSeen, element?.track)
        });
        this.drawDeviceChart();
        this.drawLicenseChart();
      }

      this.qosArray = results[3];
      this.prtgArray = results[4];
      this.wirelessArray = results[5];
      this.i4glteArray = results[6];
      this.teamsArray = results[7];
      this.pendingDeviceCnt = results[8].data.filter(d => ((this.org.role == 0) || (d.orgId == this.orgId))).length;

      if (results[9].data) {
        let res = results[9].data;

        if (this.parentOrg[0].role == 0) { //Show all jump access logs
          this.jumpDataSource = res;
        } else if (this.parentOrg[0].role == 1) { //Show jump access logs of specific MMSP and orgs under it
          let subOrgHolder_1 = [];
          let allOrgs = [];
          let temp = []

          if (this.parentOrg[0].subOrganizations.length) {
            subOrgHolder_1 = subOrgHolder_1.concat(this.parentOrg[0].subOrganizations)
            subOrgHolder_1.map((subOrg1) => {
              allOrgs.push(subOrg1.subOrganizations)
            });
            subOrgHolder_1 = subOrgHolder_1.concat(allOrgs);

            subOrgHolder_1.forEach(subOrg => {
              if (res.filter(org => (org.msp_name == subOrg.name || org.org_name == subOrg.name)).length) {
                temp.push(res.filter(org => (org.msp_name == subOrg.name || org.org_name == subOrg.name)))
              }
            });

            this.jumpDataSource = [].concat(...temp);
          }
        } else { //Show jump access logs of specific MSP and orgs
          this.jumpDataSource = res.filter(org => (org.msp_name == this.parentOrg[0].name || org.org_name == this.parentOrg[0].name))
        }

      }
      this.getTemplateInfo();
      this.setTopNavMenu();
      this.changeDetection.detectChanges();
    })
  }

  getDomainOrg(domain) {
    let filteredOrg = [];
    filteredOrg.push(domain)
    for (let i = 0; i < domain.subOrganizations.length; i++) {
      let org = domain.subOrganizations[i];
      filteredOrg = [...filteredOrg, ...this.getDomainOrg(org)]
    }
    return filteredOrg;
  }

  uptimeToStr(input) {
    if (input == null) {
      return '-';
    }
    let day, hour, minutes, seconds, n;
    n = Math.floor(input);
    day = Math.floor(n / (24 * 3600));
    n = Math.floor(n % (24 * 3600));
    hour = Math.floor(n / 3600);
    n = Math.floor(n % 3600);
    minutes = Math.floor(n / 60);
    n = Math.floor(n % 60);
    seconds = n;
    let str = '';
    str = str + (day ? day + 'd ' : '');
    str = str + (hour ? hour + 'h ' : '');
    str = str + (minutes ? minutes + 'm ' : '');
    str = str + (seconds ? seconds + 's' : '');
    return str;
  }

  getDeviceCount(org) {
    let cnt = 0;
    let orgList = this.getDomainOrg(org);
    for (let i = 0; i < this.deviceArray.length; i++) {
      for (let j = 0; j < orgList.length; j++) {
        if (this.deviceArray[i].orgId == orgList[j]._id) {
          cnt += 1;
        }
      }
    }
    return cnt;
  }

  getAllOrgCatInfo() {
    if (!this.org) {
      return;
    }
    let allOrgData = this.getDomainOrg(this.org);
    allOrgData.shift()
    let vendor = 0, msp = 0, org = 0;
    allOrgData.forEach(o => {
      if (o.role == 1) {
        vendor++;
      } else if (o.role == 2) {
        msp++;
      } else if (o.role == 3) {
        org++;
      }
    })
    let data = []
    let txt = "";
    if (vendor) {
      data.push({
        type: "Master MSP",
        count: vendor
      })
    }
    if (msp) {
      data.push({
        type: "MSP",
        count: msp
      })
    }
    if (org) {
      data.push({
        type: "Organization",
        count: org
      })
    }
    this.orgCatDataSource.data = data;
  }

  getTemplateInfo() {
    let t_monitor_count = 0, t_wifi_count = 0, t_qos_count = 0, t_i4glte_count = 0, t_teams_count = 0;
    for (let i = 0; i < this.deviceArray.length; i++) {
      for (let j = 0; j < this.prtgArray.length; j++) {
        if (this.deviceArray[i].prtg == this.prtgArray[j]._id) {
          t_monitor_count++;
        }
      }

      for (let j = 0; j < this.wirelessArray.length; j++) {
        if (this.deviceArray[i].assignedWnetwork && this.deviceArray[i].wnetwork == this.wirelessArray[j]._id) {
          t_wifi_count++;
        }
      }

      for (let j = 0; j < this.i4glteArray.length; j++) {
        if (this.deviceArray[i].i4glte && (this.deviceArray[i]['4glte'] == this.i4glteArray[j]._id)) {
          t_i4glte_count++;
        }
      }

      for (let j = 0; j < this.teamsArray.length; j++) {

        if (this.deviceArray[i].teams && (this.deviceArray[i].team == this.teamsArray[j]._id)) {
          t_teams_count++;
        }
      }

      for (let j = 0; j < this.qosArray.length; j++) {
        if (this.deviceArray[i].qos && (this.deviceArray[i].qos == this.qosArray[j]._id)) {
          t_qos_count++;
        }
      }
    }
    this.templateDataSource.data = [
      {
        't_type': "Team Template",
        't_count': this.teamsArray.length,
        't_device_count': t_teams_count
      },
      {
        't_type': "Monitoring Template",
        't_count': this.prtgArray.length,
        't_device_count': t_monitor_count
      },
      {
        't_type': "WiFi Template",
        't_count': this.wirelessArray.length,
        't_device_count': t_wifi_count
      },
      {
        't_type': "QoS Template",
        't_count': this.qosArray.length,
        't_device_count': t_qos_count
      },
      {
        't_type': "4GLTE Template",
        't_count': this.i4glteArray.length,
        't_device_count': t_i4glte_count
      }
    ]
  }

  ngOnDestroy(): void {
    this.pageAvailable = false;
    if (this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
