import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-monitor-sipwatch',
  templateUrl: './device-monitor-sipwatch.component.html',
  styleUrls: ['./device-monitor-sipwatch.component.scss']
})
export class DeviceMonitorSipwatchComponent implements OnInit {
  @Input() set device(obj) {
    if (obj) {
      this._device = obj;
    }
    const sipwatchLicenses = ["SIPCID04", "SIPCID06", "SIPCID10", "SIPCID20"]
    const checkSipwatches = this._device[0]?.addOnLicense.some(x=> sipwatchLicenses.includes(x))
    if(this._device[0]?.baseLicense === "nfr" || this._device[0]?.addOnLicense.length < 0 || checkSipwatches){
      this.showNote = false
    }
  }

  _device: any = []
  showNote = true

  constructor() { }

  ngOnInit(): void { }

  


}
