import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DatePipe } from '@angular/common';

import { CoreModule } from './admin-layout/core/core.module';
import { AdminLayoutModule } from './admin-layout/admin-layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './pages/homepage/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { AutoLoginComponent } from './pages/auto-login/auto-login.component';
import { LogoutComponent } from './pages/logout/logout.component';

//SERVICES
import { TokeninterceptorService } from './shared/services/tokeninterceptor.service';
import { NavService } from './nav.service';
import { AuthGuardService } from './shared/services/authguard.service';

//SHARED
import { SharedModule } from './shared/modules/shared.module';
import { ErrorHandlerInterceptor } from './shared/http/error-handler.interceptor';

//MODELS
import { ApModelComponent } from './pages/device/ap-model/ap-model.component';
import { OrganizationModelComponent } from './pages/organization/organization-model/organization-model.component';
import { UserModelComponent } from './pages/user/user-model/user-model.component';
import { EditDeviceModelComponent } from './pages/device/edit-device-model/edit-device-model.component';
import { DeviceOrgModalComponent } from './pages/device/device-org-modal/device-org-modal.component';
import { ConfirmationComponent } from './shared/components/confirmation/confirmation.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegistrationComponent,
    ResetPasswordComponent,
    AutoLoginComponent,
    //Model
    ApModelComponent,
    OrganizationModelComponent,
    UserModelComponent,
    EditDeviceModelComponent,
    DeviceOrgModalComponent,
    ConfirmationComponent,
    LogoutComponent,
    DeviceOrgModalComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LoadingBarHttpClientModule,
    NgxMaskModule.forRoot(),
    CoreModule,
    SharedModule,
    AdminLayoutModule
  ],
  entryComponents: [
    ApModelComponent,
    EditDeviceModelComponent,
    UserModelComponent,
    OrganizationModelComponent,
    DeviceOrgModalComponent,
  ],
  providers: [AuthGuardService, NavService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokeninterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
