<div class="mt-2 monitor-device-interfaces-wrapper panel" *ngIf="_device">
  <mat-tab-group animationDuration="0ms" [selectedIndex]="0">
    <mat-tab [label]="label"></mat-tab>
  </mat-tab-group>
  <hr class="y-border" />

  <table>
    <tbody>
    <tr>
      <th>Zone</th>
      <th>State</th>
      <th>Network</th>
      <th>Device</th>
      <th>Type</th>
      <th>Protocol</th>
      <th>Uptime</th>
      <th>MAC</th>
      <th>RX</th>
      <th>RXpkts</th>
      <th>TX</th>
      <th>TXpkts</th>
      <th>IPv4</th>
      <th>IPv6</th>
    </tr>

    <tr *ngFor="let ifc of interfaces" [hidden]="!interfaces.length">
      <td [ngStyle]="{'background': ifc.zone_color}" class="uppercase-text">{{ifc.zone}}</td>
      <td>
        <span style='color: #45d645;' *ngIf="ifc.status == 'Up'">{{ifc.status}}</span>
        <span style='color: #b0b0b0;' *ngIf="ifc.status == 'Down'">{{ifc.status}}</span>
      </td>
      <td>{{ifc.network}}</td>
      <td>{{ifc.ifname}}</td>
      <td>{{ifc.type}}</td>
      <td>{{ifc.proto}}</td>
      <td>{{ifc.uptime_str}}</td>
      <td style="text-transform: uppercase; ">{{ifc.mac}}</td>
      <td>{{ifc.rx}}</td>
      <td>{{ifc.rxpkt}}</td>
      <td>{{ifc.tx}}</td>
      <td>{{ifc.txpkt}}</td>
      <td>{{ifc.address}}</td>
      <td style="text-transform: uppercase;">{{ifc.address6}}</td>
    </tr>
    </tbody>
  </table>
</div>
