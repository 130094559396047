<div class="mt-2 monitor-device-sipwatch-alert-logs-wrapper panel" *ngIf="_device">
    <div class="mt-4">        
        <hr class="y-border" />
        <table mat-table [dataSource]="_dataSource" class="w-100 d-md-table d-block overflow-auto" matSort
        (matSortChange)="sortData($event)">
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->

        <ng-container matColumnDef="SIPId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SIP Id/Ext</th>
            <td mat-cell *matCellDef="let element">{{ getSipExt(element.callerid_hist.To) }}</td>
        </ng-container>

        <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Event</th>
            <td mat-cell *matCellDef="let element">{{displayEvent(element.callerid_hist.evt)}} </td>
        </ng-container>

        <ng-container matColumnDef="line_num">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Line No.</th>
            <td mat-cell *matCellDef="let element">{{ element.callerid_hist.line_num == null ||  element.callerid_hist.line_num == "" ? "" : element.callerid_hist.line_num }}</td>
        </ng-container>

        <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date/Time</th>
            <td mat-cell *matCellDef="let element">{{displayDate(element.callerid_hist.ts)}}</td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>from</th>
            <td mat-cell *matCellDef="let element">{{ getFrom(element.callerid_hist.From) }}</td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
            <td mat-cell *matCellDef="let element">{{ getPhoneNumber(element.callerid_hist.From) }}</td>
        </ng-container>

        <ng-container matColumnDef="destination">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination</th>
            <td mat-cell *matCellDef="let element">{{element.callerid_hist.pos_ip }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" (page)="handlePageEvent($event)" [hidden]="!selfSort && !length">
    </mat-paginator>          
    </div>
</div>
