import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NovuService {


  constructor(private http: HttpClient) { }
  getSubscribersInfo(id): Observable<any> {
    return this.http.get(environment.endpoints.novu + '/subscriber/' + id);
  }
  getNotifications(data): Observable<any> {
    return this.http.get(environment.endpoints.novu + '/notifications/',  { params: data });
  }
}
